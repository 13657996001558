/**
 * Created by thusitha_m on 1/25/2016.
 */

app.controller('UserAboutController', function ($scope,jitoValidations,SharedState, $timeout, $rootScope, jitoCommon, $routeParams, jitoSettings, $translate, jitoEvents,
                                                  jitoAjax, $window, jitoUser, $location, jitoPaths) {
    "ngInject";
    var profileID = $.trim($routeParams.ProfileID);
    //Hide unwanted content at loading screen
    $scope.isError = true;
    $scope.isCompanyIdAvailable = true;

    ///////////////////////////////////////////////////////
    //Load user data
    ///////////////////////////////////////////////////////

    var userProfileSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isError = false;
        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.userProfile = response.data;
                
                if(!jitoValidations.empty($scope.userProfile.user_meta.company_id)){
                    $scope.isCompanyIdAvailable = true;                    
                } else{
                    $scope.isCompanyIdAvailable = false;                    
                }
                
                if(!jitoValidations.empty($scope.userProfile.user_meta.company)){
                    $scope.isCompany = true;
                }
                if(!jitoValidations.empty($scope.userProfile.user_meta.current_job)){
                    $scope.isJob = true;
                }
                if(!jitoValidations.empty($scope.userProfile.user_meta.country.name)){
                    $scope.isLocation = true;
                }

                jitoCommon.prepareAnchorTags();

            }
            else {
                onError($translate.instant('USER_PROFILE_LOADING_ERROR'));
            }
        } else {
            onError(jitoCommon.normalizeErrorMessages(response.data));
        }
    };

    var onError = function (message) {
        $scope.isError = true;
        $scope.errorMessage = message;
    };

    var userProfileError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        onError(errorMessage);
    };

    var loadProfileDetails = function (profileID) {
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.geUserDetailsAPI() + profileID,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, userProfileSuccess, userProfileError);
    };

    loadProfileDetails(profileID);


});