// 
// `$touch example`
// 

app.directive('toucharea', ['$touch', function ($touch) {
    "ngInject";
    // Runs during compile
    return {
        restrict: 'C',
        link: function ($scope, elem) {
            $scope.touch = null;
            $touch.bind(elem, {
                start: function (touch) {
                    $scope.touch = touch;
                    $scope.$apply();
                },

                cancel: function (touch) {
                    $scope.touch = touch;
                    $scope.$apply();
                },

                move: function (touch) {
                    $scope.touch = touch;
                    $scope.$apply();
                },

                end: function (touch) {
                    $scope.touch = touch;
                    $scope.$apply();
                }
            });
        }
    };
}]);

//
// `$drag` example: drag to dismiss
//
app.directive('dragToDismiss', function ($drag, $parse, $timeout) {
    "ngInject";
    return {
        restrict: 'A',
        compile: function (elem, attrs) {
            var dismissFn = $parse(attrs.dragToDismiss);
            return function (scope, elem) {
                var dismiss = false;

                $drag.bind(elem, {
                    transform: $drag.TRANSLATE_RIGHT,
                    move: function (drag) {
                        if (drag.distanceX >= drag.rect.width / 4) {
                            dismiss = true;
                            elem.addClass('dismiss');
                        } else {
                            dismiss = false;
                            elem.removeClass('dismiss');
                        }
                    },
                    cancel: function () {
                        elem.removeClass('dismiss');
                    },
                    end: function (drag) {
                        if (dismiss) {
                            elem.addClass('dismitted');
                            $timeout(function () {
                                scope.$apply(function () {
                                    dismissFn(scope);
                                });
                            }, 300);
                        } else {
                            drag.reset();
                        }
                    }
                });
            };
        }
    };
});

//
// Another `$drag` usage example: this is how you could create 
// a touch enabled "deck of cards" carousel. See `carousel.html` for markup.
//
app.directive('carousel', function () {
    "ngInject";
    return {
        restrict: 'C',
        scope: {},
        controller: function () {
            this.itemCount = 0;
            this.activeItem = null;

            this.addItem = function () {
                var newId = this.itemCount++;
                this.activeItem = this.itemCount === 1 ? newId : this.activeItem;
                return newId;
            };

            this.next = function () {
                this.activeItem = this.activeItem || 0;
                this.activeItem = this.activeItem === this.itemCount - 1 ? 0 : this.activeItem + 1;
            };

            this.prev = function () {
                this.activeItem = this.activeItem || 0;
                this.activeItem = this.activeItem === 0 ? this.itemCount - 1 : this.activeItem - 1;
            };
        }
    };
});

app.directive('carouselItem', function ($drag) {
    "ngInject";
    return {
        restrict: 'C',
        require: '^carousel',
        scope: {},
        transclude: true,
        template: '<div class="item"><div ng-transclude></div></div>',
        link: function (scope, elem, attrs, carousel) {
            scope.carousel = carousel;
            var id = carousel.addItem();

            var zIndex = function () {
                var res = 0;
                if (id === carousel.activeItem) {
                    res = 2000;
                } else if (carousel.activeItem < id) {
                    res = 2000 - (id - carousel.activeItem);
                } else {
                    res = 2000 - (carousel.itemCount - 1 - carousel.activeItem + id);
                }
                return res;
            };

            scope.$watch(function () {
                return carousel.activeItem;
            }, function () {
                elem[0].style.zIndex = zIndex();
            });

            $drag.bind(elem, {
                //
                // This is an example of custom transform function
                //
                transform: function (element, transform, touch) {
                    //
                    // use translate both as basis for the new transform:
                    //
                    var t = $drag.TRANSLATE_BOTH(element, transform, touch);

                    //
                    // Add rotation:
                    //
                    var Dx = touch.distanceX,
                        t0 = touch.startTransform,
                        sign = Dx < 0 ? -1 : 1,
                        angle = sign * Math.min(( Math.abs(Dx) / 700 ) * 30, 30);

                    t.rotateZ = angle + (Math.round(t0.rotateZ));

                    return t;
                },
                move: function (drag) {
                    if (Math.abs(drag.distanceX) >= drag.rect.width / 4) {
                        elem.addClass('dismiss');
                    } else {
                        elem.removeClass('dismiss');
                    }
                },
                cancel: function () {
                    elem.removeClass('dismiss');
                },
                end: function (drag) {
                    elem.removeClass('dismiss');
                    if (Math.abs(drag.distanceX) >= drag.rect.width / 4) {
                        scope.$apply(function () {
                            carousel.next();
                        });
                    }
                    drag.reset();
                }
            });
        }
    };
});


app.directive('dragMe', ['$drag', function ($drag) {
    "ngInject";
    return {
        controller: function ($scope, $element) {
            $drag.bind($element,
                {
                    //
                    // Here you can see how to limit movement
                    // to an element
                    //
                    transform: $drag.TRANSLATE_INSIDE($element.parent()),
                    end: function (drag) {
                        // go back to initial position
                        drag.reset();
                    }
                },
                { // release touch when movement is outside bounduaries
                    sensitiveArea: $element.parent()
                }
            );
        }
    };
}]);

/**
 * This directive is made to avoid the headache of handling
 * routing in the angular application. Because normally we can
 * set the routing in an Angular application with relative path
 * where the html5 mode is on via href. But this will create problems if
 * the browser is not supporting the html5 routing method. On those
 * moments, Angular again fallback for the hash bang routing mechanism and
 * the defined routes may break. So the best way is to change the routing
 * of the application through $location service. This directive ep-href
 * is a simple directive which retrieve the route location from the
 * element it self and change the route accordingly through inbuilt
 * Angular $location provider which is safe...
 */
app.directive('epHref', function(jitoCommon) {
    'ngInject';

    return {
        restrict: 'A',
        link: function(scope, $element, $attrs) {
            $element.bind('click', function(){
                jitoCommon.route($attrs.epHref);
            });
        }
    };
});

/**
 * Confirm password validation
 */
app.directive("match", function($parse) {
    "ngInject";
    return {
        require: '?ngModel',
        restrict: 'A',
        link: function(scope, elem, attrs, ctrl) {
            if(!ctrl || !attrs.match) {
                return;
            }

            var matchGetter = $parse(attrs.match);
            var caselessGetter = $parse(attrs.matchCaseless);
            var noMatchGetter = $parse(attrs.notMatch);
            var matchIgnoreEmptyGetter = $parse(attrs.matchIgnoreEmpty);

            scope.$watch(getMatchValue, function(){
                ctrl.$$parseAndValidate();
            });

            ctrl.$validators.match = function(modelValue, viewValue){
                var matcher = modelValue || viewValue;
                var match = getMatchValue();
                var notMatch = noMatchGetter(scope);
                var value;

                if (matchIgnoreEmptyGetter(scope) && !viewValue) {
                    return true;
                }

                if(caselessGetter(scope)){
                    value = angular.lowercase(matcher) === angular.lowercase(match);
                }else{
                    value = matcher === match;
                }
                /*jslint bitwise: true */
                value ^= notMatch;
                /*jslint bitwise: false */
                return !!value;
            };

            function getMatchValue(){
                var match = matchGetter(scope);
                if(angular.isObject(match) && match.hasOwnProperty('$viewValue')){
                    match = match.$viewValue;
                }
                return match;
            }
        }
    };
});

app.directive('singleClick', function ($parse) {
    return {
        compile: function ($element, attr) {
            var handler = $parse(attr.singleClick);
            return function (scope, element, attr) {
                element.on('click', function (event) {
                    scope.$apply(function () {
                        var promise = handler(scope, { $event: event }); /// calls and execute the function specified in attrs.
                        if (promise && angular.isFunction(promise.finally)) { /// will execute only if it returns any kind of promises.
                            element.attr('disabled', true);
                            promise.finally(function () {
                                element.attr('disabled', false);
                            });
                        }
                    });
                });
            };
        }
    };
});

app.directive('ellipsis',['$timeout', function($timeout){
    return {
        restrict: 'A',
        priority: 100,
        link: function ($scope, element, attrs, ctrl) {
            $scope.$watch(element.html(), function(value) {
                $timeout(function () {
                    $(element).expander({
                        slicePoint: 200,
                        expandText: 'see more',
                        userCollapse: false,
                    });
                }, 1000);
            });
        }
    };
}]);

app.directive("ngFileSelect", function(fileReader, $timeout) {
  return {
    scope: {
      ngModel: '='
    },
    link: function($scope, el) {
      function getFile(file) {
        fileReader.readAsDataUrl(file, $scope)
          .then(function(result) {
            $timeout(function() {
              $scope.ngModel = result;
            });
          });
      }

      el.bind("change", function(e) {
        var file = (e.srcElement || e.target).files[0];
        getFile(file);
      });
    }
  };
});

app.directive('clickCapture', function() {
    return {
        link: function(scope, elm, attrs) {
            elm.bind('click', function() {
                $(elm).find("[data-side-nav-bar]").addClass('menu-closed');
                document.getElementById("mySidenav").style.width = "0";
            });
        }
    }
});

app.directive('jtoPluginConfirmOnExit', ['$location', '$timeout', function ($location, $timeout) {

    "ngInject";
    "use_strict";

    return {
        restrict: 'E',
        scope: {
            form: '=jtoForm'
        },
        link: function (scope, element, attrs) {

            /**
             * Retrieve confirm message from the element
             * @type {string}
             */
            scope.confirmMsg = attrs.jtoMsgConfirm ? attrs.jtoMsgConfirm : '';

            /**
             * This variable is to store the confirm status of the dialog.
             * If the user tries to proceed anyway discarding changes, this
             * variable will avoid showing same dialog twice.
             * @type {boolean}
             */
            var proceedStatus = false;

            /**
             * Handle the browser on close event.
             * This will trigger when browser back, tab refresh and tab closes.
             * @returns {string|*}
             */
            window.onbeforeunload = function () {
                if (scope.form.$dirty) {
                    return scope.confirmMsg;
                }
            };

            /**
             * This is the inbuilt route handling to notify user about the
             * unsaved changes. This will fire just before the route change starts.
             */
            scope.$on('$locationChangeStart', function (event, next, current) {
                if(scope.form.$dirty && !proceedStatus){
                    event.preventDefault();
                    window.onbeforeunload = null;
                    var r = confirm(scope.confirmMsg);
                    if (r == true) {
                        proceedStatus = true;
                        $timeout(function(){
                            location.href = next;
                        }, 150);
                    } else {
                        proceedStatus = false;
                    }
                }
            });

        }
    }
}]);

app.directive('backgroundImageDirective', function () {
    return function (scope, element, attrs) {
        element.css({
            'background-image': 'url(' + attrs.backgroundImageDirective + ')',
            'background-repeat': 'no-repeat',
        });
    };
});

/*
app.directive('setHeight', function($window, $timeout){
    return{
        link: function(scope, element, attrs){

            $(window).load(function() {
                let divHeight = angular.element(".making-difference-wrap")[0].offsetHeight;
                element.css('height', divHeight + 'px');
            });

            angular.element($window).bind('resize', function(){
                let divHeight = angular.element(".making-difference-wrap")[0].offsetHeight;
                element.css('height', divHeight + 'px');
            });
        }
    }
});*/

app.directive('scrollIf', ['jitoShared', function (jitoShared) {
    return function (scope, element, attrs) {
        scope.$watch(attrs.scrollIf, function (value) {
            jitoShared.setSingleThreadScrollStatus(true);
            angular.element(document).ready(function () {
                if (value) {
                    // Scroll to ad.
                    setTimeout(function(){
                        var pos = $(element).position().top + $(element).parent().scrollTop();
                        $(element).parent().parent().animate({
                            scrollTop: pos
                        }, 1000);

                        setTimeout(function(){ jitoShared.setSingleThreadScrollStatus(false); }, 1200);
                        }, 10);
                }
            });
        });
    }
}]);