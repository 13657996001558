/**
 * Created by bagya
 */

app.controller('RejectTicketController', function (titleChange, $scope, jitoPaths, $window, jitoUser, $location, jitoStorage, jitoShared, jitoCommon, $translate, jitoEvents, jitoAjax,
                                                  jitoSettings, jitoHybrid, jitoCamera, jitoValidations, $routeParams, $timeout) {

    "ngInject";
    $scope.statuses = jitoSettings.RELATIONSHIP_STATUS;
    $scope.token = $location.search().token ? $location.search().token : '';
    $scope.ticketData = null;
    $scope.showTypeMsg = 0;
    $scope.headerId = null;
    $scope.ticketId = null;
    $scope.tableUserToken = null;
    $scope.errorMessage = '';


    //Title Change
    titleChange.setTitle('Reject');

    if (jitoUser.isLogged()) {
        $("#jt-top-bar-section").css({
            'visibility': 'hidden',
            'display': 'none'
        });
    }


    /**
     * data loading success
     * @param response
     */
    var loadingSuccess = function (response) {
        $scope.isLoading = false;
        if (response && response.status && response.status === 200) {

            if (response.data.code != 400) {
                $scope.tableUserToken = response.data.user_details.table_user_token;
                $scope.headerId = response.data.event_details.order_header_id;
                $scope.ticketId = response.data.event_details.id;

                //call delete ticket function
                $scope.deleteTicket();
            }
            else {
                $scope.errorMessage = response.data.data.message;
                $scope.showTypeMsg = 2;
            }
        } else {
            $scope.errorMessage = response.data.data.message;
            $scope.showTypeMsg = 2;
        }
    };


    /**
     * get ticket details function
     * @param token
     */
    var loadTicketData = function (token) {

        $scope.isLoading = true;
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getTicketDetailsAPI() + token,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, loadingSuccess, loadingError);

    };

    /**
     * Load ticket details
     * @param response
     */
    loadTicketData($scope.token);


    var loadingError = function () {
        var errorMessage = '';
        $scope.isLoading = false;

        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        $scope.showTypeMsg = 2;
        $scope.errorMessage = errorMessage;
    }


    /////////////// Delete ticket start ///////////////////
    /**
     * delete attendee success
     * @param response
     */
    var deleteAttendeeSuccess = function (response) {
      jitoEvents.triggerLoader(false);
      $scope.isLoading = false;

      if (response && response.status && response.status === 200) {
        if (response.data && response.data.message) {
              $scope.showTypeMsg = 1;
        }
      } else {
          $scope.showTypeMsg = 2;
      }
    };

    /**
     * delete invitations api call
     */
    $scope.deleteTicket = function () {
        jitoEvents.triggerLoader(true);
        $scope.isLoading = true;

        jitoAjax.call({
          method: 'put',
          url: jitoSettings.getAPIDomain() + jitoSettings.deleteAttendeeForTableTicket(),
          data: {
            token: $scope.tableUserToken,
            order_id: $scope.headerId,
            ticket_id: $scope.ticketId,
            isAttendee: true
          },
          timeout: jitoSettings.DEFAULT_TIME_OUT
        }, deleteAttendeeSuccess, requestError);
    };


    /**
     * delete invitations error handler
     */
    var requestError = function (response) {
      var errorMessage = '';
      jitoEvents.triggerLoader(false);
      $scope.isLoading = false;

      if (response.status > 200 && response.status < 500 && response.data) {
        errorMessage = jitoCommon.normalizeErrorMessages(response.data);
      } else {
        errorMessage = $translate.instant('COMMON_NO_NETWORK');
      }

      $scope.showTypeMsg = 2;
      $scope.errorMessage = errorMessage;
    };

    /////////////// Delete ticket END ///////////////////

    $scope.currentYear = new Date().getFullYear();
});