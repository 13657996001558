/**
 * Created by thusitha_m on 1/12/2016.
 */

app.factory('jitoUser', function ($window, $http, $location, jitoStorage, jitoEvents) {
    "ngInject";
    return {

        //Set the logged in user object in the local storage as an object
        setLoggedUser: function (object) {
            if (object && object != null) {
                jitoStorage.set('jitoAppUser', object, true);
            }
        },

        setUserType: function(type){
            jitoStorage.set('jitoAppUserType', type, true);
        },

        getUserType: function(){
            return jitoStorage.get('jitoAppUserType');
        },

        getUserID: function(){
            return this.getLoggedUser().user_id;
        },


        //Return the previously set user object via setLoggedUser()
        getLoggedUser: function () {
            return jitoStorage.get("jitoAppUser");
        },

        //Return the token of the logged in user
        getToken: function () {
            var user = this.getLoggedUser();
            return user && user.token ? user.token : '';
        },

        //Check whether a user has been logged in
        isLogged: function () {
            return this.getLoggedUser() ? true : false;
        },

        getPrivacyStatus: function(){
          return jitoStorage.get("jitoAppUserPrivacyStatus");
        },

        setPrivacyStatus: function(status){
            jitoStorage.set('jitoAppUserPrivacyStatus', status, true);
        },

        getNotificationStatus: function(){
          return jitoStorage.get("jitoAppUserNotificationStatus");
        },

        setNotificationStatus: function(status){
            jitoStorage.set('jitoAppUserNotificationStatus', status, true);
        },

        getEmailStatus: function(){
          return jitoStorage.get("jitoAppUserEmailStatus");
        },

        setEmailStatus: function(status){
            jitoStorage.set('jitoAppUserEmailStatus', status, true);
        },

        //Logout the user from local storage and clear. Then redirects to welcome page
        logout: function () {
            jitoStorage.clear();
            jitoEvents.triggerLogin(false);
            $location.path("/user/welcome");
        }
    };
});
