/**
 * Created by thusitha_m on 1/12/2016.
 */

app.factory('jitoHybrid', function ($window, $translate, jitoHelper, jitoValidations) {
    "ngInject";
    return {
        isApp: $window.isApp,
        alert: function (message, type) {

            //if(!type) {
            //    type = 0;
            //}
            //
            //message = jitoHelper.replaceSpecialChars(message);
            //
            //if (this.isApp && $window.dblink) {
            //    $window.dblink.alert(function(){}, function(){}, message, type);
            //} else {
            //    $window.alert(message);
            //}

            if (this.isApp) {
                $window.navigator.notification.alert(
                    message,
                    function () {
                        callback
                        //Alert dismissed
                    },
                    'Alert!',
                    'OK'
                );
            }
            else {
                alertify.alert(message, function(){});
            }

        },

        dialog: function (title, message, isCancelButton) {
            //message = nzHelper.replaceSpecialChars(message);
            //
            //if (this.isApp && $window.dblink) {
            //    $window.dblink.showDialog(function(){}, function(){}, title, message, isCancelButton);
            //} else {
            //    $window.alert(message);
            //}

            if (this.isApp) {
                $window.navigator.notification.alert(
                    message,
                    function () {
                        callback
                        //Alert dismissed
                    },
                    title,
                    'OK'
                );
            } else {
                alertify.alert(message, function(){});
            }

        },

        toast: function (message) {
            //message = nzHelper.replaceSpecialChars(message);
            //
            //if (this.isApp && $window.dblink) {
            //    $window.dblink.alert(function(){}, function(){}, message, 0);
            //} else {
            //    $window.alert(message);
            //}

            if (this.isApp) {
                $window.navigator.notification.alert(
                    message,
                    function () {
                        callback
                        //Alert dismissed
                    },
                    'Alert!',
                    'OK'
                );
            } else {
                alertify.alert(message, function(){});
            }

        },

        popup: function (message) {
            $window.toastr.options = {
                "closeButton": false,
                "debug": false,
                "newestOnTop": false,
                "progressBar": false,
                "positionClass": "toast-top-center",
                "preventDuplicates": true,
                "onclick": null,
                "showDuration": "300",
                "hideDuration": "300",
                "timeOut": "3500",
                "extendedTimeOut": "500",
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            };

            $window.toastr.success(message);

        },

        popupError: function (message) {
            $window.toastr.options = {
                "closeButton": false,
                "debug": false,
                "newestOnTop": false,
                "progressBar": false,
                "positionClass": "toast-top-center",
                "preventDuplicates": true,
                "onclick": null,
                "showDuration": "300",
                "hideDuration": "300",
                "timeOut": "3500",
                "extendedTimeOut": "500",
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            };
            $window.toastr.error(message);
        },

        openMap: function (lat, long) {
            var addressLongLat = lat + ',' + long;
            if ($window.DEVICE_TYPE == "android") {
                $window.open("geo:" + addressLongLat);
            } else if ($window.DEVICE_TYPE == "ios") {
                $window.open("maps://?q=" + lat + "," + long);
            } else {
                $window.open("http://maps.google.com/?q=" + addressLongLat, '_system');
            }
        },

        openExternalLink: function (link) {
            if ($window.DEVICE_TYPE == "android") {
                $window.open(link, '_system');
            } else if ($window.DEVICE_TYPE == "ios") {
                $window.open(link, '_system');
            } else {
                $window.open(link, '_system');
            }
        },

        createEvent: function (date, title, address) {

            var startDate = new Date(moment(date).year(), moment(date).month(), moment(date).date(), moment(date).hours(), moment(date).minutes(), 0, 0, 0),
                endDate = new Date(moment(date).year(), moment(date).month(), moment(date).date(), moment(date).hours(), moment(date).minutes(), 0, 0, 0),
                title = title,
                location = address;

            var eventSuccess = function (message) {
                //Event created successfully
            };
            var eventError = function (message) {
                //An error occurred during event creation
                this.popupError($translate.instant('CREATE_EVENT_ERROR'));
            };
            $window.plugins.calendar.createEventInteractively(title, location, "", startDate, endDate, eventSuccess, eventError);
        },

        setBadgeNumber: function (count, push) {
            if (this.isApp) {
                if (DEVICE_TYPE == "ios") {
                    cordova.plugins.notification.badge.hasPermission(function (granted) {
                        cordova.plugins.notification.badge.set(count);
                    });
                } else {
                    //TODO: This method is not always supported in every android device. Some shows the badge number and some shows a notification
                    //cordova.plugins.notification.badge.hasPermission(function (granted) {
                    //    cordova.plugins.notification.badge.set(count);
                    //});
                }
            }

        }

    };
});