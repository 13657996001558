/**
 * Created by thusitha_m on 1/29/2016.
 */

app.controller('ConversationController', function (titleChange, $scope, $timeout, $interval, $routeParams, $translate, $location,
                                                   jitoHybrid, jitoValidations, jitoShared, jitoSettings, jitoAjax, SharedState, jitoCommon, jitoPaths, jitoUser, jitoEvents) {
    "ngInject";
    var threadID = null, currentPage = 0, isMoreToFetch = true, receiverID = null,
        isNewThread = false, isManualScrolling = false, isMessaging = false,
        isConversationLoaded = false, promise, offset = 0;

    $scope.messages = [];
    $scope.friendName = "";
    $scope.friendDetails = {
        status: 1
    };
    $scope.friendId = null;

    //If the user is already logged in redirects him to home page
    $scope.goToPage = function (path) {
        $location.path(path);
    };

    //Title Change
    titleChange.setTitle('Conversation'); 

    var threadSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;
        if (response && response.status && response.status === 200) {
            if (response.data) {
                // $scope.noMessages = false;
                // $scope.isError = false;
                // isNewThread = false;
                // $scope.friendDetails = response.data.details.friend_details;
                // $scope.friendName = response.data.details.friend_details.display_name;
                // threadID = response.data.details.id;
                // receiverID = JSON.stringify(response.data.details.friend_details.id);
                //
                // if(isManualScrolling){
                //     isManualScrolling = false;
                //
                // }else{
                //     $timeout(function() {
                //         scrollToBottom();
                //     }, 50);
                //     $scope.messages.length = 0;
                // }
                //
                // $scope.isMessageSending = false;
                // isConversationLoaded = true;
                //
                // $scope.messages.push.apply($scope.messages, response.data.messages.data);
                //
                // if ($scope.messages.length < 1) {
                //     $scope.noMessages = true;
                // } else if (!(response.data.messages.data.length > 0)) {
                //     isMoreToFetch = false;
                // }
                //
                // jitoCommon.prepareAnchorTags();

                var thread = response.data.data.details.thread;
                $location.path("/message/" + thread.id);
            }
            else {
                // //A new thread
                // $scope.isMessageSending = false;
                // isConversationLoaded = true;
                //
                // $scope.noMessages = true;
                // isNewThread = true;
                // isMoreToFetch = false;

                var url = "/messages", params = {};

                if($scope.friendId) {
                    params.member = $scope.friendId;
                }

                $location.path(url).search(params);
            }
        } else {
            //$scope.isMessageSending = false;
            //isConversationLoaded = true;
            //onError(response);
            var url = "/messages", params = {};

            if($scope.friendId) {
                params.member = $scope.friendId;
            }

            $location.path(url).search(params);
        }
    };

    var noEventsError = function () {
        $scope.noMessages = true;
    };

    var onError = function (response) {

        if (!($scope.messages.length > 0)) {
            noEventsError(response);
        }
        if (!(response.data.messages.data.length > 0)) {
            isMoreToFetch = false;
        }

    };

    var threadError = function (response) {
        // var errorMessage = '';
        // if (response.status > 200 && response.status < 500 && response.data) {
        //     errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        // }
        // else {
        //     errorMessage = $translate.instant('COMMON_NO_NETWORK');
        // }
        // jitoEvents.triggerLoader(false);
        // $scope.isMoreLoading = false;
        // $scope.isMessageSending = false;
        // isConversationLoaded = true;
        // $scope.isError = true;
        // $scope.errorMessage = errorMessage;

        var url = "/messages", params = {};

        if($scope.friendId) {
            params.member = $scope.friendId;
        }

        $location.path(url).search(params);
    };

    var loadThread = function (threadID, friendID) {
        if (!($scope.messages.length > 0) && !isMessaging) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        }
        isMessaging = false;

        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getMessagesInSingleThreadAPI() + "/" + threadID + "/" + friendID,
            params: {
                offset: offset
            }, timeout: jitoSettings.DEFAULT_TIME_OUT
        }, threadSuccess, threadError);
    };

    //////////////////////////////////////////////////////////////////////////////
    //////// Create New Thread
    //////////////////////////////////////////////////////////////////////////////

    var newThreadSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            if (response.data) {
                //New thread created.
                //Get thread ID and send new message

                threadID = response.data.data.id;
                sendNewMessage(JSON.stringify(response.data.data.id));
            }
            else {
                newThreadError(response);
            }
        } else {
            newThreadError(response);
        }
    };

    var newThreadError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        isConversationLoaded = true;
        jitoHybrid.popupError(errorMessage);
    };

    var createNewThread = function () {
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getCreateNewThreadAPI(),
            params: {
                user_id: receiverID
            }, timeout: jitoSettings.DEFAULT_TIME_OUT
        }, newThreadSuccess, newThreadError);
    };

    //////////////////////////////////////////////////////////////////////////////
    //////// Create New Message
    //////////////////////////////////////////////////////////////////////////////

    var newMessageSuccess = function (response) {
        if (response && response.status && response.status === 200) {
            if (response.data) {
                //New thread created.
                //Get thread ID and send new message
                $scope.messageContent = "";
                currentPage = 0;
                offset = 0;
                loadThread(threadID, 0);

            }
            else {
                newMessageError(response);
            }
        } else {
            newMessageError(response);
        }
    };

    $scope.loadMoreMessages = function () {

        if (isMoreToFetch) {
            isManualScrolling = true;
            $scope.isMoreLoading = true;
            offset = $scope.messages.length;
            loadThread(threadID, 0);
        }
    };

    var scrollToBottom = function(){
        var elem = angular.element(document.getElementById('messagesContent'));
        var scrollableContentController = elem.controller('scrollableContent');
        scrollableContentController.scrollTo(1500);
    };

    var newMessageError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        isMessaging = false;
        $scope.isMessageSending = false;
        isConversationLoaded = true;

        jitoHybrid.popupError(errorMessage);
    };

    var sendNewMessage = function (threadID) {
        isMessaging = true;
        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getSendNewMessageAPI(),
            params: {
                message_thread_id: threadID,
                send_to: receiverID,
                message: $scope.messageContent
            }, timeout: jitoSettings.DEFAULT_TIME_OUT
        }, newMessageSuccess, newMessageError);
    };

    //////////////////////////////////////////////////////////////////////////////

    $scope.sendMessage = function () {

        if(jitoValidations.empty($scope.messageContent)){
           jitoHybrid.popupError($translate.instant('MESSAGE_CONTENT_EMPTY'));
        } else{
            $scope.isMessageSending = true;
            isConversationLoaded = false;
            if (isNewThread) {
                //Create new thread and then send the message
                createNewThread();
            } else {
                //Send directly the message with thread ID
                sendNewMessage(threadID);
            }
        }
    };

    if (jitoValidations.empty($.trim($routeParams.ThreadID))) {
        receiverID = jitoShared.getMessageSenderID();
        $scope.friendId = receiverID;
        //Create request with this receiverID to check if an thread exist
        offset = 0;
        loadThread(0, receiverID);

    } else {
        threadID = $.trim($routeParams.ThreadID);
        //Load previous thread messages with known thread ID
        offset = 0;
        loadThread(threadID, 0);
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Automatically refresh the message content
    ///////////////////////////////////////////////////////////////////////////////////////

    var messageUpdateSuccess = function(response){
        if (response && response.status && response.status === 200) {
            if (response.data) {
                if(!jitoValidations.empty(response.data.messages) && isConversationLoaded){
                    $scope.messages.unshift.apply($scope.messages, response.data.messages.data);
                }

                jitoCommon.prepareAnchorTags();

            }else{}
        }else{}
    };

    var messageUpdateError = function(response){
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        //No need to inform the user about the real-time error messages
    };

    $scope.startAutoRefresher = function(){

        //Stopping existing timer functions
        $scope.stopAutoRefresher();

        promise = $interval(function() {

            if(isConversationLoaded){
                //Call the refresh method to get new messages
                jitoAjax.call({
                    method: 'get',
                    url: jitoSettings.getAPIDomain() + jitoSettings.getMessageUpdatesAPI(),
                    params: {
                        thread_id: threadID,
                        date: $scope.messages[0].send_at
                    }, timeout: jitoSettings.DEFAULT_TIME_OUT
                }, messageUpdateSuccess, messageUpdateError);
            }
        }, jitoSettings.MESSAGE_UPDATE_INTERVAL);

    };

    $scope.stopAutoRefresher = function() {
        $interval.cancel(promise);
    };

    //Destroy the refresher timer whenever a route change happened
    $scope.$on('$destroy', function() {
        $scope.stopAutoRefresher();
    });

    $scope.startAutoRefresher();

    //Apply link open attribute, Safety check



});