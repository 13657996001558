/**
 * Created by chathura_f on 01/09/2017.
 */

app.controller('TicketOrderSuccess', function ($scope,$routeParams) {

  "ngInject";

    $scope.errorMessage = $.trim($routeParams.errorMessage);
    if($scope.errorMessage == ''){
      $scope.errorMessage = 'error';
    }

    $scope.currentYear = new Date().getFullYear();
});