/**
 * Created by thusitha_m on 2/6/2016.
 */
/**
 * Created by thusitha_m on 1/26/2016.
 */

app.controller('ReportController', function ($scope,$timeout,$rootScope,  $window, jitoAjax, $translate, jitoValidations, jitoCommon, jitoSettings, $location, jitoPaths, jitoEvents, jitoHybrid) {
    "ngInject";
    ////////////////////////////////////////////////////////////////////////////////////
    //POST- 1,COMMENT - 2,PHOTO - 3,USER - 4,BRAND - 5,EVENT - 6


    $scope.report = function () {

        if(jitoValidations.empty($scope.reason)){
            jitoHybrid.popupError($translate.instant('REPORT_CONTENT_EMPTY'));
        } else{
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
            jitoAjax.call({
                method: 'post',
                data: {
                    object_type: $scope.REPORT_TYPE,
                    object_id: $scope.REPORT_ID,
                    reason: $scope.reason
                },
                url: jitoSettings.getAPIDomain() + jitoSettings.getReportAPI(),
                timeout: jitoSettings.DEFAULT_TIME_OUT
            }, reportSuccess, reportError);
        }

    };

    var reportSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));
            toggleReportModel();

        } else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
            toggleReportModel();
        }
    };

    var reportError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        jitoHybrid.popupError(errorMessage);

        toggleReportModel();
    };

    var toggleReportModel = function(){
        $timeout(function() {
            $rootScope.Ui.toggle("report");
        }, 100);
    };

});