/**
 * Created by thusitha_m on 2/2/2016.
 */

app.controller('CalendarController', function ($scope, $window, $routeParams, $translate, $location,
                                               moment, jitoUser, jitoSettings, jitoAjax, jitoEvents, jitoValidations, jitoCommon, jitoHybrid) {
    "ngInject";
    //Initialization
    $scope.noItemsError = false;
    $scope.isLoadingStarted = true;
    $scope.events = [];

    var isMoreToFetch = true, currentPage = -1;
    //////////////////////////////////////////////////////////////////////

    $scope.goToEventPage = function (id) {
        $location.path('/event/' + id);
    };

    /**
     * This method will trigger if the events list request is successful
     * @param response
     */
    var listEventsSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;

        if (response && response.status && response.status === 200) {
            if (response.data) {
                if (Array.isArray(response.data.data) && response.data.data.length > 0) {
                    $scope.events.push.apply($scope.events, response.data.data);
                    //console.log(JSON.stringify(response.data.data));
                }
                else {
                    onError(response);
                }
            }
            else {
                onError(response);
            }
        } else {
            onError(response);
        }
    };

    var noEventsError = function () {
        $scope.noEvents = true;
    };

    var onError = function (response) {
        if (!($scope.events.length > 0)) {
            noEventsError(response);
        }
        if (!(response.data.data.length > 0)) {
            isMoreToFetch = false;
        }
    };

    /**
     * This method will trigger if there is a error with the list events request
     * and display the error.
     * @param response
     */
    var listEventsError = function (response) {

        var errorMessage = '';
        jitoEvents.triggerLoader(false);
        if (response.status > 200 && response.status < 500 && response.data.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        $scope.isError = true;
        $scope.errorMessage = errorMessage;

    };

    /**
     * This method will list all the events nearby
     * It will send the data to the server and call the relevant callback.
     * @param page
     */
    $scope.listEvents = function () {

        if ($scope.events.length < 1) {
            jitoEvents.triggerLoader(true, $translate.instant('EVENTS_LOADING_EVENTS'), true);
        }

        currentPage += 1;

        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getCalendarEventsList(),

            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, listEventsSuccess, listEventsError);

    };

    $scope.loadMoreEvents = function () {
        if (isMoreToFetch) {
            $scope.isMoreLoading = true;
            $scope.listEvents();
        }
    };

    $scope.listEvents();

    $scope.createEvent = function(date, title, address){
        jitoHybrid.createEvent(date, title, address);
    };

});