/**
 * Created by achintha.s on 1/23/2016.
 */

app.controller('ConnectRequestsController', function ($scope, $window, $route, $routeParams, $location, $timeout, $translate,
                                                      moment, jitoUser, jitoSettings, jitoAjax, jitoEvents, jitoCommon, jitoHybrid, jitoShared, jitoPaths) {
    "ngInject";
    $scope.noEvents = false;
    $scope.listItems = [];
    $scope.isMoreLoading = false;
    $scope.errorMessage = '';
    $scope.outGoingRequests = ($location.path() === '/connect/requests/outgoing');
    $scope.pendingInvCount = 0;
    $scope.connectionsCount = 0;

    /** Setting up if got the term via route **/
    if($routeParams.Term) {
        $scope.term = $.trim($routeParams.Term);
    }

    var currentPage = 1,
        isMoreToFetch = true;

    var render = function(){
        jitoEvents.triggerLoader(true, $translate.instant('FRIENDS_LOADING_FRIENDS'), true);
        listFriendRequests();
    };

    $scope.goToPage = function(path, isReload){
        $location.path(path);
        if(isReload){
            $route.reload();
        }
    };

    $scope.reload = function(){
        $route.reload();
    };

    var listFriendRequests = function () {
        var data = {
            status: 0,
            page: currentPage
        };

        var path = $scope.outGoingRequests ? jitoSettings.getFriendRequestsOutgoing() : jitoSettings.getFriendRequestsIncoming();

        jitoAjax.call({
            method: 'GET',
            params: data,
            url: jitoSettings.getAPIDomain() + path,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, listRequestSuccess, listRequestError);

    };

    $scope.loadMore = function () {
        if(isMoreToFetch) {
            $scope.isMoreLoading = true;
            currentPage++;
            listFriendRequests();
        }
    };

    var listRequestSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;

        if (response && response.status && response.status === 200) {
            $scope.pendingInvCount = response.data.pending_requests_count;
            $scope.connectionsCount = response.data.connections_count;
            if (response.data && response.data.data && Array.isArray(response.data.data.data) && response.data.data.data.length) {
                $scope.listItems.push.apply($scope.listItems, response.data.data.data);
            }
            else {
                onError(response);
            }
        } else {
            onError(response);
        }
    };

    var listRequestError = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;

        if (response.status > 200 && response.status < 500 && response.data) {
            /*ToDo: to check already data has been loaded*/
            if($scope.listItems.length === 0) {
                $scope.errorMessage = jitoCommon.normalizeErrorMessages(response.data);
            }
        }
        else {
            if($scope.listItems.length === 0) {
                $scope.errorMessage = $translate.instant('COMMON_NO_NETWORK');
            }
        }
    };

    var onError = function (response) {
        if (!($scope.listItems.length > 0)) {
            $scope.errorMessage = $translate.instant('FRIENDS_REQUESTS_NOT_FOUND');
        }
        if (!(response.data.data.length > 0)) {
            isMoreToFetch = false;
        }
    };

    /** Add friends section **/

    $scope.processRequest = function (index, request, action, event) {
        event.preventDefault();
        event.stopPropagation();

        request.status = {
            disabled: true
        };

        jitoAjax.call({
            method: 'POST',
            data: {id: request.id, status: action},
            url: jitoSettings.getAPIDomain() + jitoSettings.getFriendRequestsProcess(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function(response){requestSuccess(response, index, request, action)}, function(response){requestError(response, index, request)});
    };

    var requestSuccess = function(response, index, request, action) {
        if (response && response.status && response.status === 200 && response.data) {

            $scope.pendingInvCount -= 1;
            if(action == 1 ) {
                jitoShared.setMessageSenderID(request.member_from);
                var popupMsg = jitoCommon.normalizeErrorMessages(response.data);
                popupMsg = (popupMsg) ? popupMsg + "<br/><a href='/conversation'>Click here to send a Jito message.</a>" : "";
                jitoHybrid.popup(popupMsg);
                $scope.connectionsCount += 1;
                $scope.listItems[index].is_approved = 1;
            } else {
                jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));
                $timeout(function(){
                    $scope.listItems.splice(index, 1);
                }, 1000);
            }
            jitoEvents.triggerUpdateNotifications();

        } else {
            requestError(response);
        }
    };

    var requestError = function(response, index, request) {
        request.status.disabled = false;

        if (response.status > 200 && response.status < 500 && response.data) {
            jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response.data));
        }
        else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    render();

    $scope.goToFriendPage = function(id, type){
        if(parseInt(type) == 1){
            $location.path('/profile/' + id);
        } else{
            $location.path('/company/profile/' + id);
        }
    };

    $scope.mutualFriendsList = function(member_id){
        $scope.LIST_TYPE = jitoSettings.LIST_STATE.MUTUAL_FRIENDS;
        $scope.LIST_ID = jitoUser.getUserID();
        $scope.MODAL_TITLE = $translate.instant('MODAL_DIALOG_MUTUAL_FRIENDS_LIST');
        $scope.MODAL_NO_ITEMS = $translate.instant('MODAL_DIALOG_MFRIENDS_NOT_FOUND');
        jitoShared.setCurrentUserID(member_id);
        jitoCommon.openModal();
    };

    $scope.goToMessage = function (userID) {
        jitoShared.setMessageSenderID(userID);
        $location.path(jitoPaths.CONVERSATION);
    };

    $scope.removeAcceptedFriend = function (index) {
        $scope.listItems.splice(index, 1);
    };
});