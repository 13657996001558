function template(name) {
    return '/assets/app/templates/' + name;
}

/**
 * Hide the spinning loader in angular loading progress bar
 */
app.config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
    "ngInject";
    cfpLoadingBarProvider.includeSpinner = false;
}]);

app.config(['flowFactoryProvider', function (flowFactoryProvider) {
    "ngInject";
    flowFactoryProvider.defaults = {
        singleFile: true,
        allowDuplicateUploads: true
    };
}]);
/**
 * pageNumber: -1 - other pages
 * pageNumber: 0 - welcome pages
 */
app.config(function ($routeProvider, $locationProvider) {
    "ngInject";

    // $routeProvider.when('/logout', {
    //     templateUrl: template('logout.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    $routeProvider.when('/checkout-success', {
        templateUrl: template('checkout-success.html'),
        reloadOnSearch: false,
        pageNumber: -1
    });

    $routeProvider.when('/checkout-error/:errorMessage', {
        templateUrl: template('checkout-error.html'),
        reloadOnSearch: false,
        pageNumber: -1
    });

    $routeProvider.when('/checkout/:orderID', {
        templateUrl: template('checkout-form.html'),
        reloadOnSearch: false,
        pageNumber: -1
    });

    $routeProvider.when('/', {
        templateUrl: template('welcome-events.html'),
        reloadOnSearch: false,
        pageNumber: -2
    });

    $routeProvider.when('/event/:EventSlug', {
        templateUrl: template('event-flyer.html'),
        reloadOnSearch: false,
        pageNumber: 4
    });

    $routeProvider.when('/tickets/claim', {
        templateUrl: template('claim-ticket.html'),
        reloadOnSearch: false,
        pageNumber: -1
    });

    $routeProvider.when('/tickets/reject', {
        templateUrl: template('reject-ticket.html'),
        reloadOnSearch: false,
        pageNumber: -1
    });

    $routeProvider.when('/terms-web', {
        templateUrl: template('terms-web.html'),
        reloadOnSearch: false,
        pageNumber: -1
    });

    $routeProvider.when('/contact-us', {
        templateUrl: template('contact-us.html'),
        reloadOnSearch: false,
        pageNumber: -1
    });

    $routeProvider.when('/privacy-web', {
        templateUrl: template('privacy-web.html'),
        reloadOnSearch: false,
        pageNumber: -1
    });

    $routeProvider.when('/about-web', {
        templateUrl: template('about-web.html'),
        reloadOnSearch: false,
        pageNumber: -1
    });

    $routeProvider.when('/event/invite/attendees', {
        templateUrl: template('invite-table-attendees.html'),
        reloadOnSearch: false,
        pageNumber: -1
    });

    $routeProvider.when('/event/invite/regular/attendees', {
        templateUrl: template('invite-regular-attendees.html'),
        reloadOnSearch: false,
        pageNumber: -1
    });

    // $routeProvider.when('/event/flyer/:eventSlug', {
    //     templateUrl: template('event-flyer.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 4
    // });

    // $routeProvider.when('/articles/create', {
    //     templateUrl: template('article-create.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 6
    // });

  // $routeProvider.when('/articles/edit/:articleSlug', {
  //   templateUrl: template('article-edit.html'),
  //   reloadOnSearch: false,
  //   pageNumber: 6
  // });

  // $routeProvider.when('/articles', {
  //       templateUrl: template('article-list.html'),
  //       reloadOnSearch: false,
  //       pageNumber: 6
  //   });

    // $routeProvider.when('/articles/draft', {
    //     templateUrl: template('article-draft-list.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 6
    // });

    // $routeProvider.when('/articles/all/:ProfileID', {
    //     templateUrl: template('user-articles-list.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/article/:articleSlug', {
    //     templateUrl: template('article-view.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 6
    // });

    // $routeProvider.when('/user/home', {
    //     templateUrl: template('time-line.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 1
    // });

    // $routeProvider.when('/user/welcome', {
    //     templateUrl: template('welcome.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -2
    // });

    // $routeProvider.when('/user/welcome-events', {
    //     templateUrl: template('welcome-events.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 4
    // });

    // $routeProvider.when('/user/login', {
    //     templateUrl: template('login.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/user/signup/user', {
    //     templateUrl: template('signup-user.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/user/signup/company', {
    //     templateUrl: template('signup-brand.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 5
    // });

    // $routeProvider.when('/walkthrough', {
    //     templateUrl: template('walkthrough.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/user/profile', {
    //     templateUrl: template('profile.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/user/event', {
    //     templateUrl: template('event.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/user/blank', {
    //     templateUrl: template('blank.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });


    // $routeProvider.when('/user/forgot_password/step_one', {
    //     templateUrl: template('forgot-password.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/user/forgot_password/step_two', {
    //     templateUrl: template('change-password.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/profile/:ProfileID', {
    //     templateUrl: template('profile.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/profile/:ProfileID', {
    //     templateUrl: template('member-profile-new.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/profile/:ProfileID/about', {
    //     templateUrl: template('about.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/events/:SearchTerm', {
    //     templateUrl: template('event-list.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 4
    // });

    // $routeProvider.when('/events', {
    //     templateUrl: template('event-list.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 4
    // });

    // $routeProvider.when('/jobs', {
    //     templateUrl: template('jobs.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/company-edit', {
    //     templateUrl: template('brand-edit.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 5
    // });

    // $routeProvider.when('/write-post', {
    //     templateUrl: template('write-post.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/write-post/:Options', {
    //     templateUrl: template('write-post.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/user/profile-edit', {
    //     templateUrl: template('profile-edit.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/user/connect', {
    //     templateUrl: template('connect.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 2
    // });

    // $routeProvider.when('/user/connections', {
    //     templateUrl: template('connections.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 2
    // });

    // $routeProvider.when('/connections', {
    //     templateUrl: template('connections.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 2
    // });

    // $routeProvider.when('/connect/requests', {
    //     templateUrl: template('connect-requests.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 2
    // });

    // $routeProvider.when('/connect/requests/outgoing', {
    //     templateUrl: template('connect-requests.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 2
    // });

    // $routeProvider.when('/profile-edit', {
    //     templateUrl: template('profile-edit.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/settings', {
    //     templateUrl: template('settings.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/company/profile/:ProfileID', {
    //     templateUrl: template('brand-profile-new.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 5
    // });

    // $routeProvider.when('/company/profile/:ProfileID/about', {
    //     templateUrl: template('about-brand.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 5
    // });

    // $routeProvider.when('/company/search', {
    //     templateUrl: template('brand-search.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 5
    // });

    // $routeProvider.when('/album-list/:UserID', {
    //     templateUrl: template('album-list.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/album-view/:ProfileID/:AlbumID', {
    //     templateUrl: template('album-view.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/album-add-photos', {
    //     templateUrl: template('add-photos.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/user/search-templates', {
    //     templateUrl: template('search-templates.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/profile-list/:Type/:ID', {
    //     templateUrl: template('profile-list.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/companies/:SearchTerm', {
    //     templateUrl: template('brand-search.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 5
    // });

    // $routeProvider.when('/companies', {
    //     templateUrl: template('brand-search.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 5
    // });

    // $routeProvider.when('/user/interests', {
    //     templateUrl: template('interests.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/user/about', {
    //     templateUrl: template('about.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    /*Connect Section*/
    // $routeProvider.when('/connect/search', {
    //     templateUrl: template('connect-search.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 2
    // });

    // $routeProvider.when('/connect/search/:Term', {
    //     templateUrl: template('connect-search.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 2
    // });

    // $routeProvider.when('/connections', {
    //     templateUrl: template('connections.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 2
    // });

    // $routeProvider.when('/connections/:Term', {
    //     templateUrl: template('connections.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 2
    // });

    // $routeProvider.when('/connect', {
    //     templateUrl: template('connect.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 2
    // });

    // $routeProvider.when('/user/time_line', {
    //     templateUrl: template('time-line.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 1
    // });

    // $routeProvider.when('/user/time_line/:PostID', {
    //     templateUrl: template('time-line.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 1
    // });

    //Conversations
    // $routeProvider.when('/messages-old', {
    //     templateUrl: template('message-list.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 3
    // });

    // $routeProvider.when('/messages', {
    //     templateUrl: template('message-list-new.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 3
    // });

    // $routeProvider.when('/message/:ThreadID', {
    //     templateUrl: template('message-list-conversation.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 3
    // });

    // $routeProvider.when('/conversation/:ThreadID', {
    //     templateUrl: template('conversation.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 3
    // });

    // $routeProvider.when('/conversation', {
    //     templateUrl: template('conversation.html'),
    //     reloadOnSearch: false,
    //     pageNumber: 3
    // });

    // $routeProvider.when('/interest/:InterestID', {
    //     templateUrl: template('interest-view.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/interests/:SearchTerm', {
    //     templateUrl: template('interests.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/interests/', {
    //     templateUrl: template('interests.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/search/:SearchTerm', {
    //     templateUrl: template('search-templates.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    //Calendar
    // $routeProvider.when('/calendar', {
    //     templateUrl: template('calendar.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/notifications/:Type', {
    //     templateUrl: template('notifications.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/options', {
    //     templateUrl: template('options.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/invite', {
    //     templateUrl: template('invite.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/feedback', {
    //     templateUrl: template('feedback.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/web-walk-1', {
    //     templateUrl: template('web-walk-1.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/web-walk-2', {
    //     templateUrl: template('web-walk-2.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    // $routeProvider.when('/web-walk-3', {
    //     templateUrl: template('web-walk-3.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    //Type:   0: About
    //        1: Terms
    //        2: privacy policy
    // $routeProvider.when('/information/:Type', {
    //     templateUrl: template('information.html'),
    //     reloadOnSearch: false,
    //     pageNumber: -1
    // });

    //$routeProvider.otherwise( { redirectTo: '/' } );

    $routeProvider.otherwise({
        templateUrl: template('error.html')
    });

    $locationProvider.html5Mode(true);

}).run(function run($rootScope, $location, $window, $translate, $timeout, jitoUser,
    jitoEvents, jitoCommon, jitoShared, jitoStorage, jitoSettings, jitoHybrid) {
    "ngInject";
    $rootScope.$on('$routeChangeStart', function (event, next, prev) {

        var allowedPage = $.inArray($location.path(), ['/', '/user/welcome', '/checkout', '/checkout-success', '/checkout-error/:errorMessage', '/event', '/terms-web', '/contact-us', '/privacy-web', '/about-web', '/event/invite/attendees', '/event/invite/regular/attendees', '/tickets/claim', '/jobs']) === -1;
        var nonAllowedPage = $.inArray($location.path(), ['/', '/user/welcome', '/checkout', '/checkout-success', '/checkout-error/:errorMessage', '/event', '/terms-web', '/contact-us', '/privacy-web', '/about-web', '/event/invite/attendees', '/event/invite/regular/attendees', '/tickets/claim', '/jobs']) === -1;

        var pages = ['/', '/user/welcome', '/checkout/:orderID', '/checkout-success', '/checkout-error/:errorMessage', '/event/:eventSlug', '/terms-web', '/contact-us', '/privacy-web', '/about-web', '/event/invite/attendees', '/event/invite/regular/attendees', '/tickets/claim', '/article/:articleSlug', '/jobs'];


        //if ((current.indexOf('/profile-edit') > -1 || current.indexOf('/brand-edit') > -1) && !jitoCommon.isProfileUpdated()) {
        //    event.preventDefault();
        //    return;
        //}

        if (jitoUser.isLogged()) {

            jitoEvents.triggerLogin(true);
            $("#jt-top-bar-section").css({
                'visibility': 'hidden'
            });
        } else {

            jitoEvents.triggerLogin(false);
            $("#jt-top-bar-section").css({
                'visibility': 'visible',
                'display': 'block'
            });
        }

        /**
         * Order cancel
         */
        if (prev && prev.$$route && prev.$$route.originalPath == '/checkout/:orderID') {
            if (!jitoShared.getOrderCancelStatus() && ($location.path().indexOf('/checkout-success') < 0) &&
                ($location.path().indexOf('/checkout-error') < 0)) {
                jitoShared.setOrderCancelStatus(true);
                event.preventDefault();
                jitoEvents.triggerOrderCancel($location.path());
                return;
            }
        } else {
            jitoShared.setOrderCancelStatus(false);
        }

        /**
         * If not logged in
         */
        // if (!jitoUser.isLogged()) {
        //     if (next && next.$$route && $.inArray(next.$$route.originalPath, pages) >= 0) {
        //         //These pages are allowed
        //         //Do nothing
        //     } else {
        //         if(window.location.pathname == '/events') {
        //             $location.path("user/welcome-events").search({ 'previous': window.location.href });
        //         } else {
        //             $location.path("user/welcome").search({ 'previous': window.location.href });
        //         }
        //         return;
        //     }
        // }

        /**
         * Logged in
         */
        // if (jitoUser.isLogged()) {
        //     if (next && next.$$route && $.inArray(next.$$route.originalPath, pages.slice(2, pages.length)) >= 0) {
        //         //These pages are allowed
        //         //But change the header according to this page. Hide side bar and the change header
        //         jitoEvents.triggerPageStatus(true);
        //
        //     } else if (next && next.$$route && $.inArray(next.$$route.originalPath, pages.slice(0, 2)) >= 0) {
        //         jitoEvents.triggerPageStatus(false);
        //         $location.path("/user/time_line");
        //         return;
        //     } else {
        //         jitoEvents.triggerPageStatus(false);
        //     }
        // }

        /**
         * Hide search bar when route change
         */
        if ($location.path().indexOf('search') == -1) {
            angular.element('#jt-mob-search-bar').addClass('jt-hide-bar');
        }

    });

    $rootScope.$on( "$routeChangeSuccess", function(event, current, previous) {
        if (current.pageNumber) {
            jitoEvents.triggerSideMenu(current.pageNumber);
        }

        $timeout(function () {
            var element = angular.element(document.querySelector('.jt-webapp-content-center-content'));
            element[0].scrollTop = 0;
        }, 0);
    });
});