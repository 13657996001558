/**
 * Created by thusitha_m on 2/4/2016.
 */
app.controller('NotificationController', function ($scope, $timeout, $rootScope, $window,$route,
                                                   jitoSettings, jitoAjax, $routeParams, jitoHybrid, SharedState, $translate, jitoCommon, jitoPaths, jitoUser, $location, jitoEvents) {
    "ngInject";
    $scope.notifications = [];
    var currentPage = 0, isMoreToFetch = true;

    jitoEvents.triggerNotificationDecreaser();

    //If the user is already logged in redirects him to home page
    $scope.goToPage = function (path) {
        $location.path(path);
    };

    var notificationSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;
        if (response && response.status && response.status === 200) {
            if (response.data) {

                $scope.notifications.push.apply($scope.notifications, response.data.data.data);

                if ($scope.notifications.length < 1) {
                    $scope.noMessages = true;
                } else if (!(response.data.data.data.length > 0)) {
                    isMoreToFetch = false;
                }
            }
            else {
                onError(response);
            }
        } else {
            onError(response);
        }
    };

    var noNotificationsError = function () {
        $scope.noMessages = true;
    };

    var onError = function (response) {

        if (!($scope.notifications.length > 0)) {
            noNotificationsError(response);
        }
        if (!(response.data.data.data.length > 0)) {
            isMoreToFetch = false;
        }

    };

    $scope.loadMoreNotifications = function () {
        if (isMoreToFetch) {
            $scope.isMoreLoading = true;
            loadNotificationList();
        }
    };

    var notificationError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;

        $scope.isError = true;
        $scope.errorMessage = errorMessage;
    };

    var loadNotificationList = function () {
        if (!($scope.notifications.length > 0)) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        }

        currentPage += 1;

        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getNotificationListAPI(),
            params: {
                page: currentPage
            }, timeout: jitoSettings.DEFAULT_TIME_OUT
        }, notificationSuccess, notificationError);
    };
    loadNotificationList();

    $scope.goToExternalPage = function (notification) {
        $route.reload();
        if (notification.object_type == 1) {
            //Go to posts
            $location.path("/user/time_line/" + notification.object_id);
        } else if (notification.object_type == 2) {
            $location.path('/connect/requests');
        } else if (notification.object_type == 3) {
            //Go to event page
        }
        $timeout(function () {
            $rootScope.Ui.toggle("notify");
        }, 100);
    };

});