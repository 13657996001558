/**
 * Created by thusitha_m on 1/23/2016.
 */


app.controller('OptionsController', function ($window,jitoCommon,$rootScope, $translate, jitoEvents, jitoHybrid, jitoAjax, jitoSettings, $scope, jitoUser, $location, jitoPaths) {

    "ngInject";
    $scope.isPrivacyStatusDisabled = false;
   

    var privacyStatusSuccess = function (response) {
        $scope.isPrivacyStatusDisabled = false;
        if (response && response.status===200 && response.data) {
        } else{
            if(response && response.data && response.status>200 ){
                message = jitoCommon.normalizeErrorMessages(response.data);
            }
        }
    };

    /**
     * This method will trigger if there is a error with the login request
     * and display the error.
     * @param response
     */
    var privacyStatusError = function (response) {
        $scope.privacyStatus = !$scope.privacyStatus;
        jitoUser.setPrivacyStatus($scope.privacyStatus);

        $scope.isPrivacyStatusDisabled = false;
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.popupError(errorMessage);
    };

    $scope.onChangePrivacyStatus = function () {
        $scope.isPrivacyStatusDisabled = true;
        jitoUser.setPrivacyStatus($scope.privacyStatus);
        var pStatus=0;
        if($scope.privacyStatus){
            pStatus = 1;
        } else{
            pStatus = 0;
        }
        //Update serer status
        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getPrivacyUpdateAPI(),
            data: {status: pStatus},
            timeout: jitoSettings.LOGIN_REQUEST_TIMEOUT
        }, privacyStatusSuccess, privacyStatusError);
    };

    var notificationStatusSuccess = function (response) {
        $scope.isNotificationStatusDisabled = false;
        if (response && response.status===200 && response.data) {
        } else{
            if(response && response.data && response.status>200 ){
                message = jitoCommon.normalizeErrorMessages(response.data);
            }
        }
    };

    var notificationStatusError = function (response) {
        $scope.notificationStatus = !$scope.notificationStatus;
        jitoUser.setNotificationStatus($scope.notificationStatus);

        $scope.isNotificationStatusDisabled = false;
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.popupError(errorMessage);
    };

    $scope.onChangeNotificationStatus = function () {
        $scope.isNotificationStatusDisabled = true;
        jitoUser.setNotificationStatus($scope.notificationStatus);
        var nStatus=0;
        if($scope.notificationStatus){
            nStatus = 1;
        } else{
            nStatus = 0;
        }
        //Update server status
        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getChangeNotificationStatusAPI(),
            data: {status: nStatus},
            timeout: jitoSettings.LOGIN_REQUEST_TIMEOUT
        }, notificationStatusSuccess, notificationStatusError);
    };

    //////////////////////////////////////////////////////////////////////////////////

    var emailStatusSuccess = function (response) {
        $scope.isEmailStatusDisabled = false;
        if (response && response.status===200 && response.data) {
        } else{
            if(response && response.data && response.status>200 ){
                message = jitoCommon.normalizeErrorMessages(response.data);
            }
        }
    };

    var emailStatusError = function (response) {
        $scope.emailStatus = !$scope.emailStatus;
        jitoUser.setNotificationStatus($scope.emailStatus);

        $scope.isEmailStatusDisabled = false;
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.popupError(errorMessage);
    };

    $scope.onChangeEmailStatus = function () {
        $scope.isEmailStatusDisabled = true;
        jitoUser.setEmailStatus($scope.emailStatus);
        var eStatus=0;
        if($scope.emailStatus){
            eStatus = 1;
        } else{
            eStatus = 0;
        }
        //Update serer status
        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getEmailStatusAPI(),
            data: {status: eStatus},
            timeout: jitoSettings.LOGIN_REQUEST_TIMEOUT
        }, emailStatusSuccess, emailStatusError);
    };


    var pushNotificationStatusSuccess = function(response, type){

        switch(type){
            case 1:{
                $scope.isMessageNotificationStatusDisabled = false;
                break;
            }
            case 2:{
                $scope.isLikesNotificationStatusDisabled = false;
                break;
            }
            case 3:{
                $scope.isCommentNotificationStatusDisabled = false;
                break;
            }
            case 4:{
                $scope.isFriendRequestNotificationStatusDisabled = false;
                break;
            }
        }

        if (response && response.status===200 && response.data) {
        } else{
            if(response && response.data && response.status>200 ){
                message = jitoCommon.normalizeErrorMessages(response.data);
            }
        }

    };

    var pushNotificationStatusError = function(response, type){

        switch(type){
            case 1:{
                $scope.isMessageNotificationStatusDisabled = false;
                $scope.message_notification = !$scope.message_notification;
                break;
            }
            case 2:{
                $scope.isLikesNotificationStatusDisabled = false;
                $scope.like_notification = !$scope.like_notification;
                break;
            }
            case 3:{
                $scope.isCommentNotificationStatusDisabled = false;
                $scope.comment_notification = !$scope.comment_notification;
                break;
            }
            case 4:{
                $scope.isFriendRequestNotificationStatusDisabled = false;
                $scope.friend_request_notification = !$scope.friend_request_notification;
                break;
            }
        }

        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.popupError(errorMessage);
    };


    $scope.onChangePushNotificationStatus = function(type){

        //jitoUser.setNotificationStatus($scope.notificationStatus);
        var nStatus=0;
        var notification_type='';

        switch(type){
            case 1:{
                $scope.isMessageNotificationStatusDisabled = true;
                notification_type = 'message_notification';
                if($scope.message_notification){
                    nStatus = 1;
                } else{
                    nStatus = 0;
                }
                break;
            }
            case 2:{
                $scope.isLikesNotificationStatusDisabled = true;
                notification_type = 'like_notification';
                if($scope.like_notification){
                    nStatus = 1;
                } else{
                    nStatus = 0;
                }
                break;
            }
            case 3:{
                $scope.isCommentNotificationStatusDisabled = true;
                notification_type = 'comment_notification';
                if($scope.comment_notification){
                    nStatus = 1;
                } else{
                    nStatus = 0;
                }
                break;
            }
            case 4:{
                $scope.isFriendRequestNotificationStatusDisabled = true;
                notification_type = 'friend_request_notification';
                if($scope.friend_request_notification){
                    nStatus = 1;
                } else{
                    nStatus = 0;
                }
                break;
            }
        }

        //Update server status
        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getPushNotificationsChangeAPI(),
            data: {
                type: notification_type,
                status: nStatus
            },
            timeout: jitoSettings.LOGIN_REQUEST_TIMEOUT
        }, function(response){pushNotificationStatusSuccess(response, type);},
            function(response){pushNotificationStatusError(response, type);});


    };



    /////////////////////////////////////////////////////////////////////
    // Load notifications statuses
    /////////////////////////////////////////////////////////////////////

    var  optionsSuccess = function(response){
        if (response && response.status===200 && response.data) {

            if(response.data.data.privacy == "0"){
                $scope.privacyStatus = false;
            } else{
                $scope.privacyStatus = true;
            }

            if(response.data.data.notifications_status == "0"){
                $scope.notificationStatus = false;
            } else{
                $scope.notificationStatus = true;
            }
////
            if(response.data.data.message_notification == "0"){
                $scope.message_notification = false;
            } else{
                $scope.message_notification = true;
            }

            if(response.data.data.like_notification == "0"){
                $scope.like_notification = false;
            } else{
                $scope.like_notification = true;
            }

            if(response.data.data.comment_notification == "0"){
                $scope.comment_notification = false;
            } else{
                $scope.comment_notification = true;
            }

            if(response.data.data.friend_request_notification == "0"){
                $scope.friend_request_notification = false;
            } else{
                $scope.friend_request_notification = true;
            }

            ////

            if(response.data.data.email_notifications_status == "0"){
                $scope.emailStatus = false;
            } else{
                $scope.emailStatus = true;
            }

            $scope.isEmailStatusDisabled = false;
            $scope.isNotificationStatusDisabled = false;
            $scope.isPrivacyStatusDisabled = false;
          
            jitoEvents.triggerLoader(false);    

        } else{
            if(response && response.data && response.status>200 ){
                message = jitoCommon.normalizeErrorMessages(response.data);
            }
        }
    };

    var optionsError = function(response){
        jitoEvents.triggerLoader(false);
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.popupError(errorMessage);
    };



    var getUserOptions = function(){

        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);

        $scope.isEmailStatusDisabled = true;
        $scope.isNotificationStatusDisabled = true;
        $scope.isPrivacyStatusDisabled = true;

        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getUserOptionsStatusAPI(),
            timeout: jitoSettings.LOGIN_REQUEST_TIMEOUT
        }, optionsSuccess, optionsError);
    };

    getUserOptions();


});