app.controller('ArticleListController', function ($scope, jitoUser, $timeout, $rootScope,
                                                  $translate, jitoStorage, jitoValidations,
                                                  jitoPaths, jitoEvents, jitoAjax, jitoSettings,
                                                  jitoHybrid, $location, jitoCommon, $routeParams, $sce, $route) {
  "ngInject";
  $scope.isLoading = false;
  $scope.authorId = -1;
  $scope.articles = [];
  $scope.slugToDelete = "";
  $scope.titleToDelete = "";
  $scope.slugToShare = "";
  $scope.titleToShare = "";
    $scope.atcle_uid = null;

  var render = function () {
    jitoAjax.call({
      method: 'get',
      url: jitoSettings.getAPIDomain() + jitoSettings.getArticleListAPI(),
      timeout: jitoSettings.DEFAULT_TIME_OUT
    }, function (response) {
      try {
       $scope.articles = response.data.articles;
       if($scope.articles.length > 0){
         $scope.articlesNotEmpty = true;
       }
       else{
         $scope.articlesNotEmpty = false;
       }
        $scope.isLoaded = true;
      }
      catch (ex){
        $scope.isLoaded = true;
      }

    }, function (error) {
      $scope.isLoaded = false;
      var errorMessage = '';
      if (error.status > 200 && error.status < 500 && error.data) {
        errorMessage = jitoCommon.normalizeErrorMessages(error.data);
      } else {
        errorMessage = $translate.instant('COMMON_NO_NETWORK');
      }
      $scope.errorMessage = errorMessage;
    });
  };

  render();

  $scope.popUpForDelete = function (articleSlug, title) {
    $scope.slugToDelete = articleSlug;
      $scope.titleToDelete = title;
    $timeout(function(){
      $rootScope.Ui.turnOn("articleDelete");
    }, 100);
  };

  $scope.cancelDelete = function () {
    $timeout(function(){
      $rootScope.Ui.turnOff("articleDelete");
    }, 100);
  };

  $scope.articleDelete = function () {
    var data = {
      article_slug: $scope.slugToDelete
    };
    jitoAjax.call({
      method: 'post',
      data: JSON.stringify(data),
      url: jitoSettings.getAPIDomain() + jitoSettings.getArticleDeleteAPI(),
      timeout: jitoSettings.PICTURE_TIME_OUT
    }, function (response) {
      jitoHybrid.popup("Article deleted Successfully");
        $timeout(function () {
          $route.reload();
          $location.path(jitoPaths.ARTICLE_PUBLISH_LIST);
        }, 100);
    }, function (response) {
     jitoHybrid.popupError("Error while deleting the article");
    });
  };

  $scope.articleShareButton = function (title, slug, atcle_uid) {
    $scope.slugToShare = baseURL + "article/" + slug;
    $scope.titleToShare = title;
      $scope.atcle_uid = atcle_uid;
    $timeout(function(){
      $rootScope.Ui.turnOn("articleShare");
    }, 100);
  };

  $scope.shareArticle = function () {
    $scope.isRequesting = true;
    var desc = createShareDescription();
    var shareData = {
      "description" : desc,
      "slug" : $scope.slugToShare,
        "atcle_uid": $scope.atcle_uid
    };
    jitoAjax.call({
      method: 'post',
      data: JSON.stringify(shareData),
      url: jitoSettings.getAPIDomain() + jitoSettings.getShareAricleAPI(),
      timeout: jitoSettings.DEFAULT_TIME_OUT
    }, function (response) {
      $scope.isRequesting = false;
      jitoHybrid.popup(response.data.message);
      $location.path(jitoPaths.USER_TIME_LINE);
    }, function (response) {
      $scope.isRequesting = false;
      jitoHybrid.popupError(response.data.message);
      $location.path(jitoPaths.USER_TIME_LINE);
    });
  };

  $scope.cancelShare = function () {
    $timeout(function(){
      $rootScope.Ui.turnOff("articleShare");
    }, 100);
  };

  var createShareDescription = function () {
    var description = $scope.topic + "\n" + $scope.articleSlug;
    return description;
  };

});