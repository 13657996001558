/**
 * Created by thusitha_m on 1/20/2016.
 */


app.factory('jitoCamera', function ($window, jitoSettings, jitoValidations, jitoHybrid, $translate) {
    "ngInject";
    return {
        takeProfilePicture: function (profileImgUploadSuccess, profileImgUploadFail) {


        }
    };
});