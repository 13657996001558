app.controller('ConversationViewController', function ($scope, $timeout, $rootScope, jitoSettings, jitoAjax, jitoHybrid,
                                                SharedState,$translate, jitoCommon, jitoPaths, jitoUser,
                                                $location, jitoEvents, $routeParams, $interval, jitoValidations, jitoShared) {
    "ngInject";

    $scope.threads = [];
    $scope.isCreatingNewMessage = false;
    $scope.currentThreadId = $.trim($routeParams.ThreadID);
    $scope.currentThreadDetails = {
        author: null,
        thread: null,
        members: null,
        other_members: null,
        messages: []
    };
    $scope.createThreadMembers = [];
    $scope.createThreadMemberIds = [];
    $scope.isLoading = false;
    $scope.isMessageSending = false;
    $scope.isMoreLoading = false;
    $scope.users = [];
    $scope.isBack = ($location.search().redirect && $location.search().redirect == "true");
    $scope.params = {
        term: $location.search().term ? $location.search().term : ''
    };
    $scope.createThreadForm = {
        member_search: "",
        group_name: "",
        message: ""
    };
    var currentPage = 0, isMoreToFetch = true, currentMessagesPage = -1,
        isConversationLoaded = false, promise;

    $timeout(function () {
        if($scope.params.term != '') {
            $location.search({
                term: $scope.params.term
            });
        } else {
            $location.search({});
        }
    }, 100);

    $scope.clearThreadSearch = function () {
        $timeout(function () {
            $scope.params.term = '';
            $scope.search();
        }, 100);
    };

    $scope.createNewThread = function () {
        $scope.isBack = false;

        if(!$scope.isCreatingNewMessage) {
            $scope.isCreatingNewMessage = true;
            $scope.createThreadMembers = [];
            $scope.createThreadMemberIds = [];
            $scope.createThreadForm.message = "";
        }
    };

    $scope.back = function () {
        $scope.isBack = true;
        $timeout(function() {
            $scope.setMessageSidebarHeight();
        }, 100);
    };

    $scope.goToProfilePage = function(id, type){
        if(parseInt(type) == 1){
            $location.path('/profile/' + id);
        } else{
            $location.path('/company/profile/' + id);
        }
    };

    var scrollToBottom = function(){
        $timeout(function () {
            var elem = angular.element(document.getElementById('messagesContent'));
            var scrollableContentController = elem.controller('scrollableContent');
            scrollableContentController.scrollTo(2500);
        }, 0);

        $timeout(function () {
            var element = angular.element(document.querySelector('.messages_container'));
            element[0].scrollTop = element[0].scrollHeight;
        }, 100);
    };

    //If the user is already logged in redirects him to home page
    $scope.goToPage = function(path){
        $scope.isCreatingNewMessage = false;
        $scope.isBack = false;
        $scope.createThreadForm.message = "";
        $location.path(path);

        scrollToBottom();
    };

    var threadSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isLoading = false;
        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.threads.push.apply($scope.threads, response.data);
                if ($scope.threads.length < 1) {
                    $scope.noMessages = true;

                    if($scope.params.term == '') {
                        $scope.isCreatingNewMessage = true;
                    }
                } else if(!(response.data.length > 0)){
                    //isMoreToFetch = false;
                }

                jitoEvents.triggerFetchNotifications();
            } else {
                onError(response);
            }
        } else {
            onError(response);
        }
    };

    var noEventsError = function () {
        $scope.noMessages = true;
    };

    var onError = function (response) {
        if (!($scope.threads.length > 0)) {
            noEventsError(response);
        }
        if (!(response.data.length > 0)) {
            //isMoreToFetch = false;
        }
    };

    var threadError = function (response) {
        $scope.isLoading = false;
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);

        $scope.isError = true;
        $scope.errorMessage = errorMessage;
    };

    var loadThreadList = function () {
        if($scope.currentLoggedUserType == 1) {
            if (!($scope.threads.length > 0)) {
                jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
            }

            currentPage += 1;
            $scope.isLoading = true;
            $scope.threads = [];

            jitoAjax.call({
                method: 'get',
                url: jitoSettings.getAPIDomain() + jitoSettings.getMessageThreadsListAPI(),
                params: {
                    page: currentPage,
                    term: $scope.params.term
                }, timeout: jitoSettings.DEFAULT_TIME_OUT
            }, threadSuccess, threadError);
        }
    };

    $scope.search = function () {
        if($scope.params.term != "") {
            $location.search({
                term: $scope.params.term
            });
        } else {
            $location.search({});
        }

        currentPage = 0;
        $scope.threads = [];
        loadThreadList();
    };

    var userSearchSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isLoading = false;

        if (response && response.status && response.status === 200) {
            if (response.data && response.data.data) {
                $scope.users.push.apply($scope.users, response.data.data.data);
            } else {
                //onError(response);
            }
        } else {
            //onError(response);
        }
    };

    var userSearchError = function (response) {
        $scope.isLoading = false;
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);

        $scope.isError = true;
        $scope.errorMessage = errorMessage;
    };

    $scope.userSearch = function () {
        $scope.users = [];
        $scope.isLoading = true;

        if($scope.createThreadForm.member_search != "") {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);

            jitoAjax.call({
                method: 'get',
                url: jitoSettings.getAPIDomain() + jitoSettings.getAllUsersForMessaging(),
                params:{
                    term: $scope.createThreadForm.member_search
                }, timeout: jitoSettings.DEFAULT_TIME_OUT
            }, userSearchSuccess, userSearchError);
        }
    };

    $scope.addMemberToThread = function (user) {
        $scope.users = [];
        $scope.createThreadForm.member_search = "";

        if($scope.createThreadMemberIds.indexOf(user.id) === -1) {
            $scope.createThreadMembers.push(user);
            $scope.createThreadMemberIds.push(user.id);
        }
    };

    $scope.removeMemberFromThread = function (user_id) {
        angular.forEach($scope.createThreadMembers, function (value, index) {
            if(value.id === user_id) {
                $scope.createThreadMembers.splice(index, 1);
            }
        });

        angular.forEach($scope.createThreadMemberIds, function (value, key) {
            if(value === user_id) {
                $scope.createThreadMemberIds.splice(key, 1);
            }
        });
    };

    var sendMessageSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isLoading = false;
        $scope.createThreadForm.message = "";
        $scope.isMessageSending = false;

        if (response && response.status && response.status === 200) {
            jitoHybrid.popup(response.data.message);
        } else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }

        $scope.goToPage('/message/' + $scope.currentThreadId);
    };

    var sendMessageError = function (response) {
        $scope.isLoading = false;
        jitoEvents.triggerLoader(false);
        var errorMessage = '';
        $scope.isMessageSending = false;

        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        jitoHybrid.popupError(errorMessage);

        $scope.goToPage('/message/' + $scope.currentThreadId);
    };

    var sendThreadMessage = function () {
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        $scope.isLoading = true;

        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getSendNewMessageAPI(),
            data: {
                message_thread_id: $scope.currentThreadId,
                message: $scope.createThreadForm.message
            }, timeout: jitoSettings.DEFAULT_TIME_OUT
        }, sendMessageSuccess, sendMessageError);
    };

    var createThreadSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isLoading = false;

        if (response && response.status && response.status === 200 && response.data.data) {
            $scope.currentThreadId = response.data.data.thread.id;
            sendThreadMessage();
        } else {
            $scope.isMessageSending = false;
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    var onRequestError = function (response) {
        $scope.isLoading = false;
        $scope.isMessageSending = false;
        jitoEvents.triggerLoader(false);
        var errorMessage = '';

        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        jitoHybrid.popupError(errorMessage);
    };

    $scope.createAndSendMessage = function () {
        if($scope.createThreadMemberIds.length == 0) {
            jitoHybrid.popupError("Atleast one member is required.");
        } else if($scope.createThreadForm.message == "") {
            jitoHybrid.popupError("Please enter the message.");
        } else {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
            $scope.isLoading = true;
            $scope.isMessageSending = true;

            jitoAjax.call({
                method: 'post',
                url: jitoSettings.getAPIDomain() + jitoSettings.getCreateNewThreadAPI(),
                data: {
                    user_ids: $scope.createThreadMemberIds,
                    group_name: $scope.createThreadForm.group_name
                }, timeout: jitoSettings.DEFAULT_TIME_OUT
            }, createThreadSuccess, onRequestError);
        }
    };

    var loadThreadMessagesSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isLoading = false;
        $scope.isMoreLoading = false;
        isConversationLoaded = true;

        if (response && response.status && response.status === 200 && response.data.data) {
            $scope.currentThreadDetails.author = response.data.data.details.author;
            $scope.currentThreadDetails.thread = response.data.data.details.thread;
            $scope.currentThreadDetails.members = response.data.data.details.members;
            $scope.currentThreadDetails.other_members = response.data.data.details.other_members;
            $scope.currentThreadDetails.last_read_msg_id = response.data.data.details.last_read_msg_id;
            $scope.currentThreadDetails.messages.push.apply($scope.currentThreadDetails.messages, response.data.data.messages.data);

            $scope.isMoreToFetch = (response.data.data.messages.data.length > 0);
        } else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    var loadThreadMessages = function () {
        if($scope.currentLoggedUserType == 1) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
            $scope.isLoading = true;
            currentMessagesPage += 1;

            if (currentMessagesPage > 0) {
                $scope.isMoreLoading = true;
            }

            jitoAjax.call({
                method: 'get',
                url: jitoSettings.getAPIDomain() + jitoSettings.getMessagesInSingleThreadAPI() + "/" + $scope.currentThreadId + "/0",
                params: {
                    offset: currentMessagesPage,
                }, timeout: jitoSettings.DEFAULT_TIME_OUT
            }, loadThreadMessagesSuccess, onRequestError);
        }
    };

    loadThreadList();

    $timeout(function () {
        loadThreadMessages();
    }, 500);

    var pushMessageSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMessageSending = false;
        $scope.createThreadForm.message = "";

        if (response && response.status && response.status === 200) {
            //jitoHybrid.popup(response.data.message);
            currentMessagesPage = -1;
            $scope.currentThreadDetails.messages = [];
            loadThreadMessages();
            updateThreadInfo();
        } else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    var pushThreadMessage = function () {
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        $scope.isMessageSending = true;

        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getSendNewMessageAPI(),
            data: {
                message_thread_id: $scope.currentThreadId,
                message: $scope.createThreadForm.message
            }, timeout: jitoSettings.DEFAULT_TIME_OUT
        }, pushMessageSuccess, onRequestError);
    };

    $scope.sendThreadMessage = function () {
        if($scope.createThreadForm.message == "") {
            jitoHybrid.popupError("Please enter the message.");
        } else {
            if(!$scope.isMessageSending) {
                pushThreadMessage();
            }
        }
    };

    var subscribeSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isLoading = false;

        if (response && response.status && response.status === 200) {
            jitoHybrid.popup(response.data.message);
            currentMessagesPage = -1;
            $scope.currentThreadDetails.messages = [];
            loadThreadMessages();
        } else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    $scope.approveThread = function () {
        if(!$scope.isLoading) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
            $scope.isLoading = true;

            jitoAjax.call({
                method: 'post',
                url: jitoSettings.getAPIDomain() + jitoSettings.getThreadSubscribeAPI(),
                data: {
                    thread_id: $scope.currentThreadId
                }, timeout: jitoSettings.DEFAULT_TIME_OUT
            }, subscribeSuccess, onRequestError);
        }
    };

    $scope.openMessageUnsubscribeWarning = function () {
        $timeout(function () {
            $rootScope.Ui.toggle("messageUnsubscribeWarning");
        }, 100);
    };

    var unsubscribeSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isLoading = false;

        if (response && response.status && response.status === 200) {
            jitoHybrid.popup(response.data.message);
            $location.path("/messages");
        } else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    $scope.unsubscribeThread = function () {
        $scope.openMessageUnsubscribeWarning();

        if(!$scope.isLoading) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
            $scope.isLoading = true;

            jitoAjax.call({
                method: 'post',
                url: jitoSettings.getAPIDomain() + jitoSettings.getThreadUnsubscribeAPI(),
                data: {
                    thread_id: $scope.currentThreadId
                }, timeout: jitoSettings.DEFAULT_TIME_OUT
            }, unsubscribeSuccess, onRequestError);
        }
    };

    var updateThreadInfo = function () {
        angular.forEach($scope.threads, function (thread, key) {
            if(thread.id == $scope.currentThreadId) {
                $scope.threads[key].thread_name = $scope.currentThreadDetails.thread.thread_name;

                if($scope.currentThreadDetails.messages.length > 0) {
                    $scope.threads[key].message_details.message = $scope.currentThreadDetails.messages[0].message;
                    $scope.threads[key].message_details.send_at = $scope.currentThreadDetails.messages[0].send_at;
                }
            }
        });
    };

    var messageUpdateSuccess = function (response) {
        $scope.isLoading = false;

        if (response && response.status && response.status === 200 && response.data.data) {
            if(!jitoValidations.empty(response.data.data.messages.data) && isConversationLoaded){
                $scope.currentThreadDetails.messages.unshift.apply($scope.currentThreadDetails.messages, response.data.data.messages.data);
            }

            angular.forEach($scope.threads, function (thread, key) {
                if(thread.id == $scope.currentThreadId) {
                    $scope.threads[key].thread_name = response.data.data.details.thread.thread_name;
                    $scope.threads[key].message_details.message = response.data.data.details.thread.intro_msg.message;
                    $scope.threads[key].message_details.send_at = response.data.data.details.thread.intro_msg.send_at;
                }
            });

            //updateThreadInfo();
            jitoCommon.prepareAnchorTags();
        }
    };

    $scope.startAutoRefresher = function(){
        //Stopping existing timer functions
        $scope.stopAutoRefresher();

        promise = $interval(function() {
            if(isConversationLoaded){
                $scope.isLoading = true;
                //Call the refresh method to get new messages
                jitoAjax.call({
                    method: 'get',
                    url: jitoSettings.getAPIDomain() + jitoSettings.getMessageUpdatesAPI(),
                    params: {
                        thread_id: $scope.currentThreadId,
                        date: $scope.currentThreadDetails.messages[0].send_at
                    }, timeout: jitoSettings.DEFAULT_TIME_OUT
                }, messageUpdateSuccess, function () {$scope.isLoading = false;});
            }
        }, jitoSettings.MESSAGE_UPDATE_INTERVAL);
    };

    $scope.stopAutoRefresher = function() {
        $interval.cancel(promise);
    };

    //Destroy the refresher timer whenever a route change happened
    $scope.$on('$destroy', function() {
        $scope.stopAutoRefresher();
    });

    $scope.startAutoRefresher();

    var toggleMessageSettings = function () {
        $timeout(function () {
            $rootScope.Ui.toggle("messageSettings");
        }, 100);
    };

    $scope.openMessageSettings = function () {
        $scope.editThreadDetails = null;
        $scope.editThreadDetails = $scope.currentThreadDetails.thread;
        $scope.createThreadMembers = [];
        $scope.createThreadMemberIds = [];
        $scope.createThreadForm.member_search = "";
        $scope.users = [];

        angular.forEach($scope.currentThreadDetails.other_members, function(member, index) {
            $scope.createThreadMembers.push(member);
        });

        angular.forEach($scope.currentThreadDetails.other_members, function (value, key) {
            $scope.createThreadMemberIds.push(value.id);
        });

        toggleMessageSettings();
    };

    $scope.openMessageDetails = function () {
        $timeout(function () {
            $rootScope.Ui.toggle("messageDetails");
        }, 100);
    };

    var updateThreadSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isLoading = false;

        if (response && response.status && response.status === 200 && response.data.data) {
            $scope.currentThreadDetails.author = response.data.data.author;
            $scope.currentThreadDetails.thread = response.data.data.thread;
            $scope.currentThreadDetails.members = response.data.data.members;
            $scope.currentThreadDetails.other_members = response.data.data.other_members;
            $scope.currentThreadDetails.last_read_msg_id = response.data.data.details.last_read_msg_id;

            updateThreadInfo();
            jitoHybrid.popup(response.data.message);
            toggleMessageSettings();
        } else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    $scope.updateThreadDetails = function () {
        if($scope.createThreadMemberIds.length == 0) {
            jitoHybrid.popupError("Atleast one member is required.");
        } else if(!$scope.isLoading) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
            $scope.isLoading = true;

            jitoAjax.call({
                method: 'put',
                url: jitoSettings.getAPIDomain() + jitoSettings.getThreadUpdateAPI(),
                data: {
                    thread_id: $scope.currentThreadId,
                    user_ids: $scope.createThreadMemberIds,
                    group_name: $scope.editThreadDetails.group_name
                }, timeout: jitoSettings.DEFAULT_TIME_OUT
            }, updateThreadSuccess, onRequestError);
        }
    };

    $scope.loadMoreMessages = function () {
        if(!$scope.isMoreLoading && $scope.isMoreToFetch && !jitoShared.getSingleThreadScrollStatus()) {
            $scope.isMoreLoading = true;
            $timeout(function () {
                loadThreadMessages();
            }, 500);
        }
    };
});