/**
 * Created by thusitha_m on 1/22/2016.
 */
app.controller('BrandEditController', function ($scope, $window, $location, $translate,
                                                jitoUser, jitoCommon, jitoStorage, jitoPaths, jitoEvents, jitoAjax, jitoSettings, jitoHybrid, jitoCamera, jitoValidations) {
    "ngInject";
    $scope.profile = {};
    $scope.isBooting = true;
    $scope.confirmPassword = "";
    $scope.newPassword = "";
    $scope.isProfileImageEmpty = false;
    $scope.isDebut = jitoStorage.get('IS_NEW');
    var logUser = jitoUser.getLoggedUser();
    var isStartingUp = true;

    $scope.skip = function(){
        if (jitoStorage.get('IS_NEW')) {
            jitoStorage.set('IS_NEW', false);
            $location.path(jitoPaths.WALKTHROUGH);
        }
    };

    $scope.pictureAdded = function($file){
        var fileReader = new FileReader();
        fileReader.onload = function (event) {
            profileImgUploadSuccess(event.target.result);
        };
        fileReader.readAsDataURL($file.file);
    };

        var profileImgUploadSuccess = function (imageData) {
            jitoEvents.triggerLoader(false);
            var profilePictureUploadSuccess = function (response) {
                jitoEvents.triggerLoader(false);
                if (response && response.status && response.status === 200) {
                    $scope.isProfileImageEmpty = false;
                    //Save user object in the local db
                    logUser.url = response.data.image_path;
                    jitoUser.setLoggedUser(logUser);
                    jitoEvents.triggerLogin(true);
                    $scope.profile.profile_pic_id.url = response.data.image_path;
                    jitoHybrid.popup(response.data.message);
                }
            };

            var profilePictureUploadError = function (response) {
                var errorMessage = '';

                if (response.status > 200 && response.status < 500 && response.data) {
                    errorMessage = jitoCommon.normalizeErrorMessages(response.data);
                } else {
                    errorMessage = $translate.instant('COMMON_NO_NETWORK');
                }

                jitoEvents.triggerLoader(false);
                jitoHybrid.popupError(errorMessage, 0);
            };

            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);

            jitoSettings.getImageData(imageData).then(function (image) {
                jitoAjax.call({
                    method: 'post',
                    data: {
                        "profile_picture": image
                    },
                    url: jitoSettings.getAPIDomain() + jitoSettings.getUserProfileImageUpdateAPI(),
                    timeout: jitoSettings.PICTURE_TIME_OUT
                }, profilePictureUploadSuccess, profilePictureUploadError);
            });

        };

    /////////////////////////////////////////////////////////////////////////

    var setDefaultDetails = function () {
        getLocationData();
        $scope.postalCode = $scope.profile.brand_meta ? parseInt($scope.profile.brand_meta.postal_code):'';
    };

    /////////////////////////////////////////////////////////////////////////
    //Get location data

    $scope.updateCityList = function (countryCode) {

        if (jitoValidations.empty(countryCode)) {
            $scope.isLocationEmpty = true;
        } else {
            $scope.isLocationEmpty = false;
        }
        updateCityList(countryCode);
    };

    var updateCityList = function (countryCode) {

        if (!jitoValidations.empty($scope.profile.brand_meta.country_code) && isStartingUp) {
            countryCode = $scope.profile.brand_meta.country_code;
        }

        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getCityAPI() + countryCode,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function (response) {

            if (response && response.status && response.status === 200) {
                $scope.cityList = response.data;
                for (first in $scope.cityList) {
                    $scope.cityValue = first;
                    break;
                }
                if (!jitoValidations.empty($scope.profile.brand_meta.city_id) && isStartingUp) {
                    $scope.cityValue = $scope.profile.brand_meta.city_id;
                }
                isStartingUp = false;
            }

        }, function (response) {

            //Error in city list getting
            var errorMessage = '';
            if (response.status > 200 && response.status < 500 && response.data) {
                errorMessage = jitoCommon.normalizeErrorMessages(response.data);
            } else {
                errorMessage = $translate.instant('COMMON_NO_NETWORK');
            }
            jitoHybrid.popupError(errorMessage);

        }, false);
    };

    var countryListSuccess = function (response) {
        if (response && response.status && response.status === 200) {
            $scope.countryList = response.data;
            for (first in $scope.countryList) {
                if (!jitoValidations.empty($scope.profile.brand_meta.country_code) && isStartingUp) {
                    $scope.countryValue = $scope.profile.brand_meta.country_code;
                } else {
                    $scope.countryValue = first;
                }
                updateCityList($scope.countryValue);
                break;
            }
        }
    };

    var countryListError = function (response) {
        //Error in country list getting
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        } else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        jitoHybrid.popupError(errorMessage);

    };

    var getLocationData = function () {
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getCountriesAPI(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, countryListSuccess, countryListError, false);
    };

    /////////////////////////////////////////////////////////////////////////
    // Load Profile Data on first time
    /////////////////////////////////////////////////////////////////////////

    var brandGetSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.profile = response.data;
                setDefaultDetails();
                $scope.isBooting = false;
                $scope.profile.brand_meta.website = $scope.profile.brand_meta.website.replace("http://", "");
            } else {
                onError($translate.instant('PROFILE_LOADING_ERROR'));
            }
        } else {
            onError($translate.instant('PROFILE_LOADING_ERROR'));
        }
    };

    var brandGetError = function (response) {
        var errorMessage = '';

        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        } else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        jitoEvents.triggerLoader(false);
        onError(errorMessage);
        $scope.isBooting = false;
    };

    var loadBrandDetails = function () {
        jitoEvents.triggerLoader(true, $translate.instant('PROFILE_LOADING_MESSAGE'), true);
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getBrandProfileDetailsAPI(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, brandGetSuccess, brandGetError);
    };

    var onError = function (message) {
        $scope.isError = true;
        $scope.errorMessage = message;
    };

    /////////////////////////////////////////////////////////////////////////
    //load profile data
    loadBrandDetails();

    var brandUpdateSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        if (response && response.status === 200) {

            //Save user object in the local db
            jitoUser.setLoggedUser(response.data.user_object);

            if (jitoStorage.get('IS_NEW')) {
                jitoStorage.set('IS_NEW', false);
                $location.path(jitoPaths.WALKTHROUGH);

            } else {
                message = jitoCommon.normalizeErrorMessages(response.data);
                jitoHybrid.popup(message, 0);
            }
        } else {
            if (response && response.data && response.status > 200) {
                message = jitoCommon.normalizeErrorMessages(response.data);
                jitoHybrid.alert(message, 0);
            } else {
                jitoHybrid.alert($translate.instant('COMMON_NO_NETWORK'), 0);
            }
        }
    };

    var brandUpdateError = function (response) {
        var errorMessage = '';

        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        } else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        jitoEvents.triggerLoader(false);
        jitoHybrid.alert(errorMessage, 0);
    };

    $scope.updateBrandInfo = function (isValid) {
        //Update the brand information

        if($scope.profile.profile_pic_id.url == jitoSettings.NO_BRAND_IMAGE){
            $scope.isProfileImageEmpty = true;
            return;
        } else{
            $scope.isProfileImageEmpty = false;
        }

        if (jitoValidations.empty($scope.countryValue)) {
            $scope.isLocationEmpty = true;
            return;
        }

        var website = "";

        if (!jitoValidations.empty($scope.profile.brand_meta.website)) {
            website = "http://" + $scope.profile.brand_meta.website;
        }

        if (isValid && ($scope.newPassword === $scope.confirmPassword)) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
            jitoAjax.call({
                method: 'post',
                url: jitoSettings.getAPIDomain() + jitoSettings.getBrandProfileUpdateAPI(),
                data: {
                    "email": $scope.profile.email,
                    "new_password": $scope.newPassword,
                    "current_password": $scope.currentPassword,
                    "confirm_password": $scope.confirmPassword,
                    "brand_name": $scope.profile.brand_meta.brand_name,
                    "website": website,
                    "about": $scope.profile.brand_meta.about,
                    "street": $scope.profile.brand_meta.street,
                    "city_id": $scope.cityValue,
                    "postal_code": $scope.postalCode,
                    "state": $scope.profile.brand_meta.state,
                    "tag_line": $scope.profile.brand_meta.tag_line,
                    "country_code": $scope.countryValue,
                    "contact_phone_no": $scope.profile.brand_meta.contact_phone_no
                },
                timeout: jitoSettings.LOGIN_REQUEST_TIMEOUT
            }, brandUpdateSuccess, brandUpdateError);
        }
    };

    $scope.checkPassword = function () {
        $scope.brandUpdateForm.cPassword.$error.dontMatch = ($scope.newPassword != $scope.confirmPassword);
    };

});