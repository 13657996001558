/**
 * Created by thusitha_m on 2/9/2016.
 */


app.controller('WalkthroughController', function ($scope,$location) {

    "ngInject";
    $scope.goToPage = function(path){
        $location.path(path);
    };


});