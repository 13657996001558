/**
 * Created by thusitha_m on 3/10/2016.
 */

app.controller('EditPostController', function ($scope, $window, jitoShared, jitoUser, $compile, $translate, $routeParams,
                                               jitoSettings, $location, jitoAjax, jitoEvents, jitoValidations, jitoCommon,
                                               jitoHybrid, jitoPaths, $timeout, $rootScope, $compile) {
    "ngInject";
    jitoShared.setEditPostOpen(true);
    var attachmentIds = [];
    var deletedAttacment = [];

    $scope.deleteAttachmentPhoto = function (attachmentId, index) {
        deletedAttacment.push(attachmentId);
        $scope.edittingPost.attachments.splice(index, 1);

        for (var i = 0; i < attachmentIds.length; i++) {
            if (attachmentIds[i] == attachmentId) {
                attachmentIds.splice(i, 1);
                break;
             }
        };

    };

    $scope.uploadPicture = function () {
        if ($scope.edittingPost.attachments.length == 1) {
            jitoHybrid.popupError($translate.instant('WRITE_POST_IMAGE_UPLOAD_LIMIT'));
        } else {
            $timeout(function () {
                $rootScope.Ui.toggle("profilePicture");
            }, 100);
        }
    };


    $scope.pictureAdded = function ($file) {
        var fileReader = new FileReader();
        fileReader.onload = function (event) {
            postUploadImage(event.target.result);
        };
        fileReader.readAsDataURL($file.file);
    };

    var postUploadImage = function (imageData) {
        jitoEvents.triggerLoader(false);

        var postImgUploadSuccess = function (response) {
            jitoEvents.triggerLoader(false);
            if (response && response.status && response.status === 200) {
                // Push image into the images array
                $scope.edittingPost.attachments.push(response.data);
                attachmentIds.push(response.data.id);
            }
        };

        var postImgUploadError = function (response) {
            var errorMessage = '';
            if (response.status > 200 && response.status < 500 && response.data) {
                errorMessage = jitoCommon.normalizeErrorMessages(response.data);
            }
            else {
                errorMessage = $translate.instant('COMMON_NO_NETWORK');
            }

            jitoEvents.triggerLoader(false);
            jitoHybrid.popupError(errorMessage, 0);
        };

        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);

        jitoSettings.getImageData(imageData).then(function (image) {
            jitoAjax.call({
                method: 'post',
                data: {"attachment": image},
                url: jitoSettings.getAPIDomain() + jitoSettings.getCreatePostAttachmentAPI(),
                timeout: jitoSettings.PICTURE_TIME_OUT
            }, postImgUploadSuccess, postImgUploadError);
        });

    };


    $scope.updatePost = function () {

        if (jitoValidations.empty($scope.edittingPost.original_description) && attachmentIds.length <= 0 && $scope.edittingPost.attachments.length <= 0) {
            jitoHybrid.popupError($translate.instant('EDIT_POST_CONTENT_EMPTY'));
            return;
        }

        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        jitoAjax.call({
            method: 'PUT',
            url: jitoSettings.getAPIDomain() + jitoSettings.getPostUpdateAPI() + $scope.edittingPost.id,
            data: {
                description: $scope.edittingPost.original_description,
                attachment_ids: attachmentIds,
                deleted_attachment_ids: deletedAttacment
            },
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function (response) {

            jitoEvents.triggerLoader(false);
            if (response && response.status && response.status === 200) {
                //Successfully updated the post
                jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));
                //Get updated post and send those details into the post object in relevant time line
                getSinglePostDetails($scope.edittingPost.id);
            } else {
                jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
            }

        }, function (response) {
            if (response.status > 200 && response.status < 500) {
                jitoEvents.triggerLoader(false);
                jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response.data));
            }
        });
    };

    $scope.close = function () {
        jitoShared.setEditPostOpen(false);
        jitoCommon.toggleEditPostModal();
    };

    var getSinglePostDetails = function (id) {

        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        jitoAjax.call({
            method: 'GET',
            url: jitoSettings.getAPIDomain() + jitoSettings.getSinglePost() + id,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function (response) {

            jitoEvents.triggerLoader(false);
            if (response && response.status && response.status === 200) {
                var post = response.data;

                if (!$scope.isViewMoreComments) {
                    $scope.posts[parseInt($scope.index)] = post;
                    $scope.singlePostArr[0] = post;
                } else {
                    $scope.posts[parseInt($scope.editPostIndex)] = post;
                }

                $("#post_video_" + id).empty();

                jitoCommon.prepareAnchorTags();

                jitoShared.setEditPostOpen(false);
                jitoCommon.toggleEditPostModal();

                if(post.post_meta_data != "") {
                    var postVideoPreview = '<youtube-preview source="' + post.post_meta_data.source + '" v="' + post.post_meta_data.video_id + '" thumbnail="' + post.post_meta_data.thumbnail + '" width="100%" height="315" class="jt-post-video" play-icon="assets/app/images/video-play-icon.png"></youtube-preview>';

                    var referralDivFactory = $compile(postVideoPreview);
                    var referralDiv = referralDivFactory($scope);
                    var containerDiv = document.getElementById('post_video_' + id);
                    angular.element(containerDiv).append(referralDiv);
                } else {
                    $("#post_video_" + id).hide();
                }
            } else {
                jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
            }

        }, function (response) {
            if (response.status > 200 && response.status < 500) {
                jitoEvents.triggerLoader(false);
                jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response.data));
            }
        });

    };

});