app.factory('jitoAjax', function ($http, $window, jitoHybrid, jitoUser, $q, jitoEvents, jitoValidations) {
    "ngInject";
    var success = function (response, successCallback) {
        //count
        if(response.data && response.data.notifications){
            jitoEvents.triggerCounterUpdater(response.data.notifications.likes,
                response.data.notifications.comment,
                response.data.notifications.friend_request,
                response.data.notifications.message,
                response.data.notifications.total);

        }
        successCallback(response);
    };
    
    var error = function (response, errorCallback) {
       
        if(response.status == 403){
            jitoEvents.triggerLoader(false);
            jitoEvents.triggerLogout();
            jitoEvents.triggerResetBadgeCounter();
            jitoUser.logout();
        } else{
            errorCallback(response);
        }

    };

    return {

        //Web service call function
        call: function (config, successCallback, errorCallback, disableHeaders) {

            if (config.url.indexOf('?') > -1) {
                config.url = config.url+'&c=' + Math.random();
            } else {
                config.url = config.url+'?c=' + Math.random();
            }
            if (!disableHeaders) {
                config.headers = {
                    'X-Auth-Token': jitoUser.getToken(),
                    'Content-Type': 'application/json'
                };
            }
            return $http(config).then(function(response){
                success(response, successCallback);
            }, function (response){
                error(response, errorCallback);
            });
        },

          callMultipart: function (config, successCallback, errorCallback, disableHeaders) {

            if (config.url.indexOf('?') > -1) {
              config.url = config.url+'&c=' + Math.random();
            } else {
              config.url = config.url+'?c=' + Math.random();
            }
            if (!disableHeaders) {
              config.headers = {
                'X-Auth-Token': jitoUser.getToken(),
                'Content-Type': undefined
              };
            }
            return $http(config).then(function(response){
              success(response, successCallback);
            }, function (response){
              error(response, errorCallback);
            });
          },

        callMany: function (requests, successCallback, errorCallback, disableHeaders) {
            if (!disableHeaders) {
                angular.forEach(requests, function(request) {
                    request.headers = {
                        'X-Auth-Token': jitoUser.getToken(),
                        'Content-Type': 'application/json'
                    };
                });
            }
            $q.all(requests).then(successCallback, errorCallback);

        }

    };

});