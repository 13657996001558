/**
 * Created by thusitha_m on 1/17/2016.
 */

app.controller('EventController', function ($scope, $routeParams, $timeout, $window, $location, $translate, $rootScope,
                                            jitoCommon, jitoHybrid, jitoEvents, jitoShared, jitoSettings, jitoUser, jitoAjax, jitoValidations, jitoPaths, titleChange) {
    "ngInject";
    var subscribedState = null, eventSlug = $routeParams.EventSlug; eventID = null;

    var path;

    $scope.eventItem = {};
    $scope.myParent = {};
    $scope.myParent.postType = "EVENT";
    $scope.myParent.type = "EVENT_TIME_LINE";
    $scope.myParent.eventID = eventID;
    $scope.isPopUpView = false;

    jitoShared.setCurrentEventID(eventID);

    $scope.openMap = function (lat, long) {
        jitoHybrid.openMap(lat, long);
    };

    var onError = function (message) {
        $scope.isError = true;
        $scope.errorMessage = message;
    };

    var eventSuccess = function (response) {

        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            if (response.data) {
            
                titleChange.setTitle(response.data.event_details[0].title);
                titleChange.setImage(response.data.cover_image.url);
                titleChange.setDescription(response.data.event_details[0].title.original_description);  
                titleChange.setKeyword(response.data.event_details[0].title);  
                
                setProperties(response.data.is_attending);
                $scope.event = response.data;

                eventID = response.data.id;
                $scope.myParent.eventID = eventID;
                jitoShared.setCurrentEventID(eventID);

                //TODO: Put this location validation inside a service
                //validate address
                $scope.location = '';
                if( !jitoValidations.empty($scope.event.address_1)){
                    $scope.location += $scope.event.address_1;
                }
                if( !jitoValidations.empty($scope.event.address_2)){
                    $scope.location += ", "+ $scope.event.address_2;
                }
                if( !jitoValidations.empty($scope.event.city)){
                    $scope.location += ", "+ $scope.event.city;
                }
                if(!jitoValidations.empty($scope.location)){
                    $scope.isLocationSet = true;
                } else{
                    $scope.isLocationSet = false;
                }

                path = jitoSettings.getAPIDomain() + jitoSettings.getUserPostList();

                if ($scope.myParent) {
                    $scope.isTimeLine = false;
                    switch ($scope.myParent.type) {
                        case "USER_TIME_LINE":
                            path = jitoSettings.getAPIDomain() + jitoSettings.getUserTimeLineAPI() + "/" + $scope.profileID;
                            break;
                        case "EVENT_TIME_LINE":
                            path = jitoSettings.getAPIDomain() + jitoSettings.getEventTimeLineAPI() + "/" + eventID;
                            break;
                    }
                }

                $scope.reloadPosts();

                $scope.isDataFetched = true;

                jitoCommon.prepareAnchorTags();

            }
            else {
                onError($translate.instant('EVENT_LOADING_ERROR'));
            }
        } else {
            onError($translate.instant('EVENT_LOADING_ERROR'));
        }
    };

    var eventError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        onError(errorMessage);
    };

    var loadEventDetails = function (eventID) {
        jitoEvents.triggerLoader(true, $translate.instant('EVENT_LOADING_MESSAGE'), true);
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getEventDetails() + eventSlug,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, eventSuccess, eventError);
    };

    ///////////////////////////////////////////////////////////////////////
    //Load the event details when controller starts up
    loadEventDetails(eventID);
    ///////////////////////////////////////////////////////////////////////

    var setEventData = function (isLeftDisabled, isAttending,
                                 isNotAttending, leftButtonText, rightButtonText, isRightDisabled) {
        $scope.eventItem.isLeftDisabled = isLeftDisabled;
        $scope.eventItem.isAttending = isAttending;
        $scope.eventItem.isNotAttending = isNotAttending;
        $scope.eventItem.leftButtonText = leftButtonText;
        $scope.eventItem.rightButtonText = rightButtonText;
        $scope.eventItem.isRightDisabled = isRightDisabled;

    };

    var setProperties = function (value) {

        switch (value) {
            case jitoSettings.EVENT_STATE.PENDING://1
                setEventData(true, true, false,
                    $translate.instant('EVENTS_PENDING'), $translate.instant('EVENTS_I_NOT_ATTEND'), false);
                break;
            case jitoSettings.EVENT_STATE.APPROVED://2
                setEventData(true, true, false,
                    $translate.instant('EVENTS_I_ATTEND'), $translate.instant("EVENTS_I_NOT_ATTEND"), false);
                break;
            case jitoSettings.EVENT_STATE.REJECTED://3
                $scope.isRejected = true;
                $scope.eventItem.alertText = $translate.instant("EVENTS_REJECTED");
                break;
            case jitoSettings.EVENT_STATE.NOT_GOING://4
                setEventData(false, false, true,
                    $translate.instant('EVENTS_I_ATTEND'), $translate.instant("EVENTS_I_NOT_ATTEND"), true);
                break;
            default:
                setEventData(false, false, false,
                    $translate.instant('EVENTS_I_ATTEND'), $translate.instant("EVENTS_I_NOT_ATTEND"), false);
                break;
        }
    };

    ///////////////////////////////////////////////////////////////////////
    //RSVP Status
    ///////////////////////////////////////////////////////////////////////

    var rsvpStatusSuccess = function (response) {

        if (response && response.status === 200 && response.data) {

            $scope.eventItem.isLeftProcessing = false;
            $scope.eventItem.isRightProcessing = false;
            jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));
            $scope.isSubscribing = false;
            setProperties(subscribedState);

        } else {
            if (response && response.data && response.status > 200) {
                jitoHybrid.popup($translate.instant('EVENTS_SUBSCRIPTION_ERROR'));
            }
        }

    };

    var rsvpStatusError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.alert(errorMessage, 0);

        $scope.eventItem.isLeftProcessing = false;
        $scope.eventItem.isRightProcessing = false;
    };

    var rsvpEvent = function (status) {
        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getEventRsvpAPI(),
            data: {eventId: eventID, rsvpStatus: status},
            timeout: jitoSettings.LOGIN_REQUEST_TIMEOUT
        }, rsvpStatusSuccess, rsvpStatusError, false);
    };

    //Handle left button click event
    $scope.leftClick = function (state) {
        $scope.eventItem.isLeftProcessing = true;
        switch (state) {
            case jitoSettings.EVENT_STATE.NOT_GOING://4
                subscribedState = '1';
                rsvpEvent(1);
                break;
            default:
                subscribedState = '1';
                rsvpEvent(1);
                break;
        }
    };

    //Handle right button click event
    $scope.rightClick = function (state) {
        $scope.eventItem.isRightProcessing = true;
        switch (state) {
            case jitoSettings.EVENT_STATE.APPROVED://2
                subscribedState = '4';
                rsvpEvent(4);
                break;
            default:
                subscribedState = '4';
                rsvpEvent(4);
                break;
        }
    };

    $scope.redirectPage = function(userID, type){
        if(type==1){
            $location.path(jitoPaths.USER_PROFILE+userID);
        } else{
            $location.path(jitoPaths.BRAND_PROFILE+userID);
        }
    };

    $scope.goToWebsite = function(link){
        jitoHybrid.openExternalLink(link);
    };

    $scope.goToAttendeeList = function(){
        //Set parameters and then open the modal dialog
        //Note: these parameters are directly visible to the modal controller
        $scope.LIST_TYPE = jitoSettings.LIST_STATE.USERS_ATTEND_IN_EVENT;
        $scope.LIST_ID =  eventID;
        $scope.MODAL_TITLE = $translate.instant('MODAL_DIALOG_EVENT_ATTENDEE_TITLE');
        $scope.MODAL_NO_ITEMS = $translate.instant('MODAL_DIALOG_NO_ATTENDEES_FOUND');
        jitoCommon.openModal();

    };

    $scope.report = function(){
        //POST- 1,COMMENT - 2,PHOTO - 3,USER - 4,BRAND - 5,EVENT - 6
        $scope.REPORT_TYPE = 6;
        $scope.REPORT_ID = eventID;
        jitoCommon.openReportModal();
    };

    /////////////////////////////////////////////////////////////////////////////////
    // TODO: Add this code into a single file

    //Initialization
    $scope.posts = [];
    $scope.singlePostArr = [];
    var currentPage = 0;
    var userDetails = jitoUser.getLoggedUser();
    $scope.userImage = userDetails.url;
    $scope.index = 0;
    $scope.myUserID = jitoUser.getUserID();
    $scope.modalActive = false;
    $scope.singlePost = false;
    var deletePostIndex = 0;
    $scope.isTimeLine = true;
    var isMoreToFetch = true;
    $scope.isViewMoreComments = true;
    var isMoreCommentsAvailable = true;
    $scope.edittingPost = null;
    $scope.editPostIndex = 0;

    var redirectedPostID;
    var isFromNotifications = false;
    if (!jitoValidations.empty($.trim($routeParams.PostID))) {
        isFromNotifications = true;
        redirectedPostID = $.trim($routeParams.PostID);
    }

    $scope.turnOffPostModal = function () {
        $scope.modalActive = !$scope.modalActive;
        $scope.isViewMoreComments = true;

        $timeout(function () {
            $rootScope.Ui.toggle("singlePost");
        }, 100);
    };

    $scope.loadMore = function () {
        if (isMoreToFetch) {
            listPosts();
        }
    };

    $scope.reloadPosts = function () {
        $scope.posts = [];
        $scope.singlePostArr = [];
        currentPage = 0;
        $scope.userImage = userDetails.url;
        $scope.index = 0;
        $scope.myUserID = jitoUser.getUserID();
        $scope.modalActive = false;
        $scope.singlePost = false;
        deletePostIndex = 0;
        $scope.isTimeLine = true;
        listPosts();
    };

    var listPostSuccess = function (response) {

        $scope.isMoreLoading = false;
        jitoEvents.triggerLoader(false);

        if (response && response.status && response.status === 200) {

            angular.forEach(response.data, function (data) {
                $scope.posts.push(data);
            });

           

            jitoCommon.prepareAnchorTags();

            if ($scope.posts.length < 1) {
                $scope.noPosts = true;
            }

            if (response.data.length < 1) {
                isMoreToFetch = false;
            } else {
                //Check if the request is from a notification and if so open the model
                if (isFromNotifications) {

                    var post_id = redirectedPostID;
                    $scope.isViewMoreComments = false;
                    jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
                    var path = jitoSettings.getAPIDomain() + jitoSettings.getSinglePost() + post_id;
                    jitoAjax.call({
                        method: 'GET',
                        url: path,
                        timeout: jitoSettings.DEFAULT_TIME_OUT
                    }, singlePostSuccess, function (response) {
                        jitoEvents.triggerLoader(false);
                        jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response.data));
                    });

                }
            }

        } else {
            if (response.data && response.data.length < 1) {
                isMoreToFetch = false;
            }
        }

    };

    var listPostError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;

        $scope.isPostError = true;
        $scope.postErrorMessage = errorMessage;
        onPostError(response);
    };

    var listPosts = function () {
        currentPage++;
        var data = {page: currentPage};
        //var path = jitoSettings.getAPIDomain() + jitoSettings.getUserPostList();
        //Make the get request
        if ($scope.posts.length == 0 && !$scope.noPosts) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        } else {
            $scope.isMoreLoading = true;
        }

        jitoAjax.call({
            method: 'get',
            url: path,
            timeout: jitoSettings.DEFAULT_TIME_OUT,
            params: data
        }, listPostSuccess, listPostError);
    };

    $scope.countLikes = function (isLiked) {
        var like = this.post.is_liked;
        var path = "";
        if (like) {
            this.post.like_count = parseInt(this.post.like_count) - 1;
            this.post.is_liked = 1;
            path = jitoSettings.getAPIDomain() + jitoSettings.setPostLikesDelete();
        } else {
            this.post.like_count = parseInt(this.post.like_count) + 1;
            this.post.is_liked = 0;
            path = jitoSettings.getAPIDomain() + jitoSettings.setPostLikes();
        }

        var data = {object_type: 1, object_id: this.post.id};
        jitoAjax.call({
            method: 'POST',
            url: path,
            timeout: jitoSettings.DEFAULT_TIME_OUT,
            data: data
        });
    };

    var sharePostSuccess = function (response) {
         $scope.ajaxCall = false;
        jitoHybrid.popup($translate.instant('POST_SHARED_SUCCESS'));
    };

    var sharePostError = function(){
        $scope.ajaxCall = false;
    }

    $scope.sharePost = function () {
         $scope.ajaxCall = true;
        var path = jitoSettings.getAPIDomain() + jitoSettings.setSharePost();
        var id = 0;
        if (this.post.parent_id != 0) {
            id = this.post.parent_id;
        } else {
            id = this.post.id;
        }
        var data = {parent_id: id};
        jitoAjax.call({
            method: 'POST',
            url: path,
            timeout: jitoSettings.DEFAULT_TIME_OUT,
            data: data
        }, sharePostSuccess, sharePostError);
    };

    var sendComments = function (data, type, post, comment) {
        var path = jitoSettings.getAPIDomain() + jitoSettings.setComments();

        jitoAjax.call({
            method: 'POST',
            url: path,
            timeout: jitoSettings.DEFAULT_TIME_OUT,
            data: data
        }, function (response) {
            if (response && response.status && response.status === 200) {

                response.data.data.display_name = userDetails.display_name;
                response.data.data.url = $scope.userImage;
                response.data.data.child_comments = [];

                if (type == 1) {
                    post.comments.data.unshift(response.data.data);
                    post.comments_count = parseInt(post.comments_count) + 1;
                } else if (type == 2) {
                    comment.child_comments.unshift(response.data.data);
                }

                jitoCommon.prepareAnchorTags();

            }
        }, function (response) {
            jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response.data));
        });
    };

    $scope.deleteComment = function (type, index) {
        var path = "";
        if (type == 1) {
            path = jitoSettings.getAPIDomain() + jitoSettings.setComments() + "/" + this.post.comments.data[index].id;
            this.post.comments.data.splice(index, 1);
            this.post.comments_count = parseInt(this.post.comments_count) - 1;
        } else if (type == 2) {
            path = jitoSettings.getAPIDomain() + jitoSettings.setComments() + "/" + this.comment.child_comments[index].id;
            this.comment.child_comments.splice(index, 1);
        }

        //Make the get request

        jitoAjax.call({
            method: 'DELETE',
            url: path,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, listDeleteCommentSuccess, listDeleteCommentError);

    };

    var listDeleteCommentSuccess = function (response) {

    };

    var listDeleteCommentError = function (response) {
        jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response));
    };

    $scope.addComments = function (type, text) {
        var commentText = text;
        var data = {};
        if (commentText != null && commentText != '') {
            if (type == 1) {
                data = {
                    object_type: jitoSettings.OBJECT_TYPE_COMMENT,
                    object_id: this.post.id,
                    comment_text: commentText,
                    parent_id: 0
                }
            } else if (type == 2) {
                data = {
                    object_type: jitoSettings.OBJECT_TYPE_COMMENT,
                    object_id: this.comment.object_id,
                    comment_text: commentText,
                    parent_id: this.comment.id
                }
            }
            sendComments(data, type, this.post, this.comment);
        }
    };

    $scope.goToPage = function (userID, type,item) {

        if (parseInt(type) == 1) {
            $location.path(jitoPaths.USER_PROFILE + userID);
        } else {
            $location.path(jitoPaths.BRAND_PROFILE + userID);
        }

    };

    $scope.getFriendList = function (type, id) {



        $scope.LIST_TYPE = jitoSettings.LIST_STATE.LIKERS;
        $scope.LIST_ID = id;
        $scope.OBJECT_TYPE = type;
        $scope.MODAL_TITLE = $translate.instant('MODAL_DIALOG_FRIEND_LIST_TITLE');
        $scope.MODAL_NO_ITEMS = $translate.instant('MODAL_DIALOG_LIKERS_NOT_FOUND');
        jitoCommon.openModal();
    };

    $scope.listMoreComment = function () {
        if (isMoreCommentsAvailable) {
            if (isNaN($scope.singlePostArr[0].comment_page)) {
                $scope.singlePostArr[0].comment_page = 2;
            } else {
                $scope.singlePostArr[0].comment_page += 1;
            }

            var path = jitoSettings.getAPIDomain() + jitoSettings.getPostComments() + '1/' + $scope.singlePostArr[0].id;
            var data = {page: $scope.singlePostArr[0].comment_page};
            //Make the get request
            $scope.isMoreLoading = true;
            jitoAjax.call({
                method: 'get',
                url: path,
                timeout: jitoSettings.DEFAULT_TIME_OUT,
                params: data
            }, listPostCommentSuccess, listPostCommentError);

        }
    };

    var listPostCommentSuccess = function (response) {

        $scope.isMoreLoading = false;
        if (response && response.status && response.status === 200) {
            if (response.data && Array.isArray(response.data)) {
                angular.forEach(response.data, function (data) {
                    $scope.singlePostArr[0].comments.data.push(data);
                });

                if (response.data.length < 1) {
                    isMoreCommentsAvailable = false;
                }

            } else {

                $scope.singlePostArr[0].comments_count = 0;
            }
        }
    };

    var listPostCommentError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        $scope.isMoreLoading = false;

        onPostError(response);
    };

    var noPostsError = function () {
        $scope.noPosts = true;
    };

    var onPostError = function (response) {
        if (!($scope.posts.length > 0)) {
            noPostsError(response);

        }
        if (!(response.length > 0)) {
            isMoreToFetch = false;
        }
    };


    var singlePostSuccess = function (response) {

        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            $scope.singlePostArr[0] = response.data;
            jitoCommon.prepareAnchorTags();
            $scope.openSinglePostModal();
        } else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    $scope.getSingleUserPost = function (postId, index) {

        $scope.index = index;

        isMoreCommentsAvailable = true;
        if (!$scope.isViewMoreComments) {
            $scope.isViewMoreComments = !$scope.isViewMoreComments;
            $timeout(function () {
                $rootScope.Ui.toggle("singlePost");
            }, 100);
            return;
        }

        $scope.isViewMoreComments = !$scope.isViewMoreComments;
        var post_id = this.post.id;
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        var path = jitoSettings.getAPIDomain() + jitoSettings.getSinglePost() + post_id;
        jitoAjax.call({
            method: 'GET',
            url: path,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, singlePostSuccess, function (response) {
            jitoEvents.triggerLoader(false);
            $scope.isViewMoreComments = true;
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        });
    };

    $scope.openSinglePostModal = function () {
        $timeout(function () {
            $rootScope.Ui.toggle("singlePost");
            this.modalActive = true;
        }, 100);
    };

    $scope.$on('triggerTimeLineLoader', function (event) {
        $scope.loadMore();
    });


    $scope.reportPostTimeLine = function (id) {
        //POST- 1,COMMENT - 2,PHOTO - 3,USER - 4,BRAND - 5,EVENT - 6
        $scope.REPORT_TYPE = 1;
        $scope.REPORT_ID = id;
        jitoCommon.openReportModal();
    };


    var deletePostSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.posts[deletePostIndex].isHide = true;
                jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));
            } else {
                jitoHybrid.popup($translate.instant('COMMON_NO_NETWORK'));
            }
        } else {
            jitoHybrid.popup($translate.instant('COMMON_NO_NETWORK'));
        }
    };
    var deletePostError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        jitoHybrid.popupError(errorMessage);

    };

    $scope.deletePost = function (postID, index) {
        deletePostIndex = index;
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);

        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getPostDeleteAPI() + "/" + postID,
            data: {
                _method: "DELETE"
            },
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, deletePostSuccess, deletePostError);
    };

    ///////////////////////////////////////////////////////////////////////
    // Edit post
    ///////////////////////////////////////////////////////////////////////

    $scope.editPost = function (id, index) {

        $scope.editPostIndex = index;
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        jitoAjax.call({
            method: 'GET',
            url: jitoSettings.getAPIDomain() + jitoSettings.getSinglePost() + id,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function(response){

            jitoEvents.triggerLoader(false);
            if (response && response.status && response.status === 200) {
                $scope.edittingPost = response.data;
                $timeout(function () {
                    $rootScope.Ui.toggle("editpost");
                }, 100);
            } else {
                jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
            }

        }, function (response) {
            if (response.status > 200 && response.status < 500) {
                jitoEvents.triggerLoader(false);
                jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response.data));
            }
        });

    };

    $scope.goToEventPage = function (id, isPaid) {
        if(isPaid){
            $location.path('/event/' + id);
        } else{
            $location.path('/event/' + id);
        }
    };


});