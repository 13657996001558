/**
 * Created by thusitha_m on 1/26/2016.
 */

app.controller('WritePostController', function ($route, $scope, $window, $rootScope, $routeParams, jitoShared, jitoAjax, $translate, jitoValidations, $timeout, jitoCommon, jitoSettings, $location, jitoPaths, jitoEvents, jitoHybrid) {
    "ngInject";
    $scope.images = [];
    $scope.imageIDs = [];
    var options = $.trim($routeParams.Options);
    $scope.ajaxCall = false;
    $scope.pictureAdded = function ($file) {
        var fileReader = new FileReader();
        fileReader.onload = function (event) {
            selectImage(event.target.result);
        };
        fileReader.readAsDataURL($file.file);
    };
   
    $scope.click = function () {
        //alert(123);

    };

    //Upload photos...
    /////////////////////////////////////////////////////////////////////////////////////

    var selectImage = function (imageData) {

        var postImgUploadSuccess = function (response) {
            jitoEvents.triggerLoader(false);
            if (response && response.status && response.status === 200) {
                // Push image into the images array
                //$scope.images[0] = response.data.url;
                //$scope.imageIDs[0] = response.data.id;

                $scope.images.push(response.data.url);
                $scope.imageIDs.push(response.data.id);
            }
        };

        var postImgUploadError = function (response) {
            var errorMessage = '';
            if (response.status > 200 && response.status < 500 && response.data) {
                errorMessage = jitoCommon.normalizeErrorMessages(response.data);
            }
            else {
                errorMessage = $translate.instant('COMMON_NO_NETWORK');
            }

            jitoEvents.triggerLoader(false);
            jitoHybrid.popupError(errorMessage, 0);
        };

        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);

        jitoSettings.getImageData(imageData).then(function (image) {
            jitoAjax.call({
                method: 'post',
                data: {"attachment": image},
                url: jitoSettings.getAPIDomain() + jitoSettings.getCreatePostAttachmentAPI(),
                timeout: jitoSettings.PICTURE_TIME_OUT
            }, postImgUploadSuccess, postImgUploadError);
        });



    };


    ////////////////////////////////////////////////////////////////////////////////////

    $scope.updatePost = function (location) {
         $scope.ajaxCall = true;
         $scope.location = location;
        if (jitoValidations.empty($scope.postContent) && $scope.images.length == 0) {
            jitoHybrid.popupError($translate.instant('POSTS_EMPTY_TEXT'));
            $scope.ajaxCall = false;
        } else {
            jitoEvents.triggerLoader(true, $translate.instant('POST_WAIT_MESSAGE'), true);
            $scope.ajaxCall =  true;
            var data;
            if ($scope.myParent) {
                switch ($scope.myParent.postType) {
                    case "EVENT":
                        data = {
                            description: $scope.postContent,
                            privacy: 0,
                            attachment_ids: $scope.imageIDs,
                            object_type: 5,
                            object_id: $scope.myParent.eventID
                        };
                        break;
                }
            } else {
                data = {
                    description: $scope.postContent,
                    privacy: 0,
                    attachment_ids: $scope.imageIDs
                };
            }

            jitoAjax.call({
                method: 'post',
                data: data,
                url: jitoSettings.getAPIDomain() + jitoSettings.getCreateNewPostAPI(),
                timeout: jitoSettings.DEFAULT_TIME_OUT
            }, postSuccess, postError);
        }
    };

    var postSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));

            //console.log($scope.myParent.postType);


            if ($scope.myParent) {
                if ($scope.myParent.postType == "EVENT") {
                    $scope.images = [];
                    $scope.imageIDs = [];
                    $scope.postContent = "";
                    $scope.reloadPosts();
                }
            } else {
                if ($scope.location === 'timeline') {
                    $route.reload();
                } else {
                    $location.path(jitoPaths.USER_TIME_LINE);
                }
            }
            $scope.ajaxCall = false;

        } else {
            $scope.ajaxCall = false;
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    var postError = function (response) {
        var errorMessage = '';
        $scope.ajaxCall = false;
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        jitoHybrid.popupError(errorMessage);
    };

    $scope.deletePhoto = function (index) {
        $scope.imageIDs.splice(index, 1);
        $scope.images.splice(index, 1);
    };

    $scope.uploadPicture = function () {
        if ($scope.images.length == 1) {
            jitoHybrid.popupError($translate.instant('WRITE_POST_IMAGE_UPLOAD_LIMIT'));
        } else {
            $timeout(function () {
                $rootScope.Ui.toggle("profilePicture");
            }, 100);
        }
    }


});