/**
 * Created by prasanna_j on 22-Jan-2016.
 */

app.controller('AlbumViewController', function ($scope, $routeParams, $window, $timeout, $location, $rootScope, $translate,
                                                jitoCommon, jitoHybrid, jitoEvents, jitoShared, jitoSettings, jitoUser, jitoAjax, jitoValidations, jitoPaths) {
    "ngInject";
    var initialiseVars = function () {

        $scope.albumPhotos = [];
        $scope.albumDetails = {};
        $scope.isAlbumDetailsFound = false;
        $scope.isError = false;
        $scope.isMoreLoading = false;
        $scope.isRemoveButtonClicked = false;
        $scope.albumID = $.trim($routeParams.AlbumID);
        $scope.profileID = $.trim($routeParams.ProfileID);
        $scope.currentPage = 0;
        $scope.isMoreToFetch = true;
        $scope.selected = [];
        $scope.deleteSuccess = 0;
        $scope.deleteError = 0;
        $scope.currentCounter = -1;
        $scope.isMe = false;
        $scope.currentIndex = -1;
        $scope.albumName = '';

        $scope.REPORT_TYPE = 3;
        $scope.REPORT_ID = 0;

        //Listen into the notification decrease service
        $scope.$on('imageReportClicked', function (event, id) {
            $scope.REPORT_ID = id;
        });


        if (jitoShared.getCurrentUserID() == jitoUser.getUserID()) {
            $scope.isMe = true;
        } else {
            $scope.isMe = false;
        }

    };

    initialiseVars();

    /////////////////////////////////////////////////////////////////////////////////////

    $scope.pictureAdded = function ($file) {
        var fileReader = new FileReader();
        fileReader.onload = function (event) {

            jitoSettings.getImageData(event.target.result).then(function (image) {
                doUpload(image);
            });

        };
        fileReader.readAsDataURL($file.file);
    };

    var doUpload = function (data) {
        jitoAjax.call({
            method: 'post',
            data: JSON.stringify({
                album_picture: data,
                album_id: $scope.albumDetails.id
            }),
            url: jitoSettings.getAPIDomain() + jitoSettings.getUploadAlbumImage(),
            timeout: jitoSettings.SIGNUP_REQUEST_TIMEOUT
        }, imageUploadSuccess, imageUploadError);
    };

    var imageUploadSuccess = function (response) {
        $scope.currentPage = 0;
        loadAlbumsData();
    };

    var imageUploadError = function (response) {
        jitoHybrid.alert($translate.instant('ALBUM_PHOTOS_UPLOAD_ERROR'), 0);
    };

    /////////////////////////////////////////////////////////////////////////////////////

    $scope.openSingleImage = function (index) {
        $scope.currentIndex = index;
        $timeout(function () {
            $rootScope.Ui.toggle("image");
        }, 100);
    };

    var onError = function (message) {
        $scope.isError = true;
        $scope.errorMessage = message;
        $scope.isMoreLoading = false;
    };

    var albumSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;

        if (response && response.status && response.status === 200) {
            if (response.data) {

                $scope.albumPhotos.length = 0;
                $scope.albumPhotos.push.apply($scope.albumPhotos, response.data.photos);

                if ($scope.isAlbumDetailsFound == false) {
                    $scope.albumDetails = response.data.details;
                    $scope.albumName = response.data.details.title;
                    jitoShared.setCurrentAlbumDetails(response.data.details);
                    $scope.isAlbumDetailsFound = true;
                }

                if (!(response.data.photos.length > 0)) {
                    $scope.isMoreToFetch = false;
                }

                if ($scope.isRemoveButtonClicked == true) {
                    $(".overlay-check").show();
                    $(".remove-photo").show();
                }
            }
            else {
                onError($translate.instant('ALBUM_LOADING_ERROR'));
            }
        } else {
            onError($translate.instant('ALBUM_LOADING_ERROR'));
        }
    };

    var albumError = function (response) {

        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.message) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.message);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        onError(errorMessage);
    };

    var loadAlbumDetails = function (albumID, page) {
        var path = jitoSettings.getAPIDomain() + jitoSettings.getAlbumDetails() + "albumId=" + albumID;

        if (page != null) {
            path += "&page=" + page;
        }

        if ($scope.isAlbumDetailsFound == false) {
            jitoEvents.triggerLoader(true, $translate.instant('ALBUM_LOADING_MESSAGE'), true);
        }

        jitoAjax.call({
            method: 'get',
            url: path,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, albumSuccess, albumError);
    };

    var loadAlbumsData = function () {
        $scope.currentPage += 1;
        loadAlbumDetails($scope.albumID, $scope.currentPage);
    };

    $scope.loadMoreAlbumPhotos = function () {
        if ($scope.isMoreToFetch) {
            $scope.isMoreLoading = true;
            loadAlbumsData();
        }
    };

    $scope.$on('albumViewPaginator', function (event) {
       $scope.currentPage += 1;
    });

    $scope.performDelete = function () {
        if ($(".remove-photo:checked").length == 0) {
            jitoHybrid.dialog('', $translate.instant('ALBUM_EMPTY_SELECT'), 0);
        } else {
            jitoEvents.triggerLoader(true, $translate.instant('ALBUM_DELETING_IMAGES'), true);

            $(".remove-photo").each(function () {
                if ($(this).is(":checked")) {
                    $scope.selected.push($(this).attr("value"));
                }
            });

            deleteAllSelectedPhotos();
        }
    };

    var deleteAllSelectedPhotos = function () {
        $scope.currentCounter++;

        if ($scope.selected[$scope.currentCounter] == undefined) {
            var msg = [];

            if ($scope.deleteSuccess > 0) {
                msg.push($scope.deleteSuccess + " " + $translate.instant('ALBUM_PHOTOS_DELETED'))
            }
            if ($scope.deleteError > 0) {
                msg.push($scope.deleteError + " " + $translate.instant('ALBUM_PHOTOS_DELETE_ERROR'))
            }

            showDeletePhotoConfirmation(msg);
        } else {
            deletePhoto($scope.selected[$scope.currentCounter]);
        }
    };

    var showDeletePhotoConfirmation = function (msg) {
        jitoHybrid.dialog('', msg.join(" & "), 0);

        initialiseVars();

        loadAlbumsData();
    };

    var deletePhoto = function (photoId) {
        jitoAjax.call({
            method: 'post',
            data: JSON.stringify({photo_id: photoId, _method: "DELETE"}),
            url: jitoSettings.getAPIDomain() + jitoSettings.getDeleteAlbumImage(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, albumPhotoDeleteSuccess, albumPhotoDeleteError);
    };

    var albumPhotoDeleteSuccess = function (response) {
        if (response.status === 200) {
            $scope.deleteSuccess++;
            deleteAllSelectedPhotos();
        }
    };

    var albumPhotoDeleteError = function (response) {
        $scope.deleteError++;
        deleteAllSelectedPhotos();
    };

    ///////////////////////////////////////////////////////////////////////
    // Load the album details when controller starts up
    loadAlbumsData();
    ///////////////////////////////////////////////////////////////////////

    $scope.toggleDeleteCheck = function () {
        if ($scope.isRemoveButtonClicked) {
            $(".overlay-check").hide();
            $(".remove-photo").hide();
            $scope.isRemoveButtonClicked = false;
        } else {
            $(".overlay-check").show();
            $(".remove-photo").show();
            $scope.isRemoveButtonClicked = true;
        }
    };

    $scope.goToAddPhotos = function () {
        $location.path(jitoPaths.ALBUM_ADD_PHOTOS);
    };

});