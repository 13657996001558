/**
 * Created by thusitha_m on 1/23/2016.
 */


app.controller('SettingsController', function ($window,jitoCommon,$rootScope, $translate, jitoStorage, jitoEvents, jitoHybrid, jitoAjax, jitoSettings, $scope, jitoUser, $location, jitoPaths) {

    "ngInject";
    $scope.isPrivacyStatusDisabled = false;

    $scope.goToProfilePage = function () {
        if (jitoUser.getUserType() == "1") {
            //$location.path(jitoPaths.USER_PROFILE_EDIT);
            //$location.path('/jobs/member/edit');
            $window.location.href = '/jobs/member/edit';
        } else {
            //$location.path(jitoPaths.USER_BRAND_EDIT);
            //$location.path('/jobs/company/edit');
            $window.location.href = '/jobs/company/edit';
        }
    };

    var privacyStatusSuccess = function (response) {
        $scope.isPrivacyStatusDisabled = false;
        if (response && response.status===200 && response.data) {
        } else{
            if(response && response.data && response.status>200 ){
                message = jitoCommon.normalizeErrorMessages(response.data);
            }
        }
    };

    /**
     * This method will trigger if there is a error with the login request
     * and display the error.
     * @param response
     */
    var privacyStatusError = function (response) {
        $scope.privacyStatus = !$scope.privacyStatus;
        jitoUser.setPrivacyStatus($scope.privacyStatus);

        $scope.isPrivacyStatusDisabled = false;
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.popupError(errorMessage);
    };

    $scope.onChangePrivacyStatus = function () {
        $scope.isPrivacyStatusDisabled = true;
        jitoUser.setPrivacyStatus($scope.privacyStatus);
        var pStatus=0;
        if($scope.privacyStatus){
            pStatus = 1;
        } else{
            pStatus = 0;
        }
        //Update serer status
        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getPrivacyUpdateAPI(),
            data: {status: pStatus},
            timeout: jitoSettings.LOGIN_REQUEST_TIMEOUT
        }, privacyStatusSuccess, privacyStatusError);
    };

    ///////////////////////////////////////////////////////////////////////
    // Logout
    //////////////////////////////////////////////////////////////////////

    var logoutSuccess = function(response){
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {

            jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));
            jitoEvents.triggerResetBadgeCounter();
            $scope.parentObject.searchText = null;
            $scope.parentObject = {};
            jitoUser.logout();

        } else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    var logoutError = function(response){
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        jitoHybrid.popupError(errorMessage);

        if( response.status == 403){
            $scope.parentObject.searchText = null;
            $scope.parentObject = {};
            jitoUser.logout();
        }
    };

    var logout = function(){
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        jitoAjax.call({
            method: 'post',
            data: {
                user_id: jitoUser.getUserID(),
                device_id: jitoStorage.get('DEVICE_ID')
            },
            url: jitoSettings.getAPIDomain() + jitoSettings.getLogoutAPI(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, logoutSuccess, logoutError);
    };

    $scope.logout = function () {
        //Clear search term\

        jitoEvents.triggerLogout();

        logout();
    };

    $scope.goToTerms = function(){
        $scope.TYPE = jitoSettings.INFO_STATE.TERMS;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_TERMS_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_TERMS_DESC');
        jitoCommon.openInfoModal();
    };

    $scope.goToAbout = function(){
        $scope.TYPE =jitoSettings.INFO_STATE.ABOUT;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_ABOUT_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_ABOUT_DESC');
        jitoCommon.openInfoModal();
    };

    $scope.goToPrivacyPolicy = function(){
        $scope.TYPE = jitoSettings.INFO_STATE.PRIVACY;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_PRIVACY_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_PRIVACY_DESC');
        jitoCommon.openInfoModal();
    };



    //////////////////////////////////////////////////////////////////////////////////



});