/**
 * Created by thusitha_m on 1/20/2016.
 */

app.factory('jitoShared', function () {
    "ngInject";
    var currentEventID = 0;
    var isEditPostOpen = false;
    var albumDetails = {};
    var userID = 0;
    var messageSenderID = 0;
    var cancelStatus = false;
    var singleThreadScrollStatus = true;


    return {
        getCurrentEventID: function () {
            return currentEventID;
        },
        setCurrentEventID: function (eventID) {
            currentEventID = eventID;
        },
        getCurrentAlbumDetails: function () {
            return albumDetails;
        },
        setCurrentAlbumDetails: function (currentAlbumDetails) {
            albumDetails = currentAlbumDetails;
        },
        setCurrentUserID: function (id) {
            userID = id;
        },
        getCurrentUserID: function () {
            return userID;
        },
        setMessageSenderID: function (userID) {
            messageSenderID =  userID;
        },
        getMessageSenderID: function () {
            return messageSenderID;
        },
        setEditPostOpen: function (state) {
            isEditPostOpen =  state;
        },
        getEditPostOpen: function () {
            return isEditPostOpen;
        },
        setOrderCancelStatus: function (status) {
            cancelStatus =  status;
        },
        getOrderCancelStatus: function () {
            return cancelStatus;
        },
        setSingleThreadScrollStatus: function (sThreadScrollStatus) {
            singleThreadScrollStatus = sThreadScrollStatus;
        },
        getSingleThreadScrollStatus: function () {
            return singleThreadScrollStatus;
        }

    };
});