app.controller('ArticleViewController', function ($scope, jitoUser, $timeout, $rootScope, titleChange,
                                                  $translate, jitoStorage, jitoValidations,
                                                  jitoPaths, jitoEvents, jitoAjax, jitoSettings,
                                                  jitoHybrid, $location, jitoCommon, $routeParams, $sce) {
  "ngInject";
  $scope.headerImage = "";
  $scope.profilePic = "";
  $scope.topic = "";
  $scope.content = "";
  $scope.postedDate = "";
  $scope.articleSlug = $.trim($routeParams.articleSlug);
  $scope.headerImageSet = false;
  $scope.facebookShare = "";
  $scope.linkedInShare = "";
  $scope.twitterShare = "";
  $scope.isAuthor = false;
  $scope.authorId = -1;
  $scope.editPath = "";
  $scope.shareSlug= "";
  $scope.jobTitle = "";
  $scope.jobCompany = "";
    $scope.atcle_uid = null;

  var render = function () {

    jitoAjax.call({
      method: 'get',
      url: jitoSettings.getAPIDomain() + jitoSettings.getViewAricleAPI() + $scope.articleSlug,
      timeout: jitoSettings.DEFAULT_TIME_OUT
    }, function (response) {
      try {
          titleChange.setTitle(response.data.data.topic);
          titleChange.setKeyword(response.data.data.topic);
          titleChange.setDescription(response.data.data.shorten_content);

          if(!jitoValidations.empty( response.data.data.header_image)) {
            $scope.headerImage = response.data.data.header_image;
            $scope.headerImageSet = true;
            titleChange.setImage(response.data.data.header_image);
          }

          $scope.atcle_uid = response.data.data.id;
          $scope.topic = response.data.data.topic;
          $scope.content = response.data.data.content;
          $scope.profilePic = response.data.data.profile_pic;
          $scope.authorName = response.data.data.author_name;
          $scope.jobTitle = response.data.data.present_job;
          $scope.jobCompany = response.data.data.company;
          $scope.publishedDate = response.data.data.updated_at;
          $scope.authorId = response.data.data.user_id;
          $scope.userType = response.data.data.user_type;
          $scope.facebookShare = "https://www.facebook.com/sharer/sharer.php?u=" + response.data.data.slug;
          $scope.twitterShare = "https://twitter.com/home?status=" +  response.data.data.slug;
          $scope.linkedInShare = "https://www.linkedin.com/shareArticle?mini=true&url=" + response.data.data.slug + "&title=" + $scope.topic + "&summary=&source=";

          if(jitoUser.isLogged() && jitoUser.getUserID() == $scope.authorId){
            $scope.isAuthor = true;
            $scope.editPath = jitoPaths.ARTICLE_EDIT + $scope.articleSlug;
          }

          $scope.isLoaded = true;
      }
      catch (ex){
        $scope.isLoaded = true;
      }

    }, function (error) {
      $scope.isLoaded = false;
      var errorMessage = '';
      if (error.status > 200 && error.status < 500 && error.data) {
        errorMessage = jitoCommon.normalizeErrorMessages(error.data);
      } else {
        errorMessage = $translate.instant('COMMON_NO_NETWORK');
      }
      $scope.errorMessage = errorMessage;
    });
  };

  render();

  $scope.trustedHtml = function (plainText) {
    return $sce.trustAsHtml(plainText);
  };

  $scope.goToPage = function (userID, type) {
    if (parseInt(type) == 1) {
      $location.path(jitoPaths.USER_PROFILE + userID);
    } else {
      $location.path(jitoPaths.BRAND_PROFILE + userID);
    }
  };

  $scope.articleShareOnJito = function () {
    $scope.shareSlug = baseURL + "article/" + $scope.articleSlug;
    $timeout(function(){
      $rootScope.Ui.turnOn("articleShare");
    }, 100);
  };

  $scope.shareArticle = function () {
    $scope.isRequesting = true;
    var desc = createShareDescription();
    var shareData = {
      "description" : desc,
      "slug" : $scope.shareSlug,
        "atcle_uid": $scope.atcle_uid
    };
    jitoAjax.call({
      method: 'post',
      data: JSON.stringify(shareData),
      url: jitoSettings.getAPIDomain() + jitoSettings.getShareAricleAPI(),
      timeout: jitoSettings.DEFAULT_TIME_OUT
    }, function (response) {
      $scope.isRequesting = false;
      jitoHybrid.popup(response.data.message);
      $rootScope.Ui.turnOff("articleShare");
    }, function (response) {
      $scope.isRequesting = false;
      jitoHybrid.popupError(response.data.message);
      $rootScope.Ui.turnOff("articleShare");
    });
  };

  $scope.cancelShare = function () {
    $timeout(function(){
      $rootScope.Ui.turnOff("articleShare");
    }, 100);
  };

  var createShareDescription = function () {
    var description = $scope.topic + "\n" + $scope.articleSlug;
    return description;
  };


});