app.factory('titleChange', function ($timeout, $rootScope, $window) {
    "ngInject";

  var title = 'A FORCE FOR GOOD Hub';
  var keyword = 'jitoconnected.com, jito, jitoconnected, Travel, Tourism Industry, Events, Professional Network, Network, Networking APP, Hospitality Industry, Travel Industry, Tourism Industry, Event';
  var keywordTemp = 'jitoconnected.com, jito, jitoconnected, Travel, Tourism Industry, Events, Professional Network, Network, Networking APP, Hospitality Industry, Travel Industry, Tourism Industry, Event';
  var description ='JITO CONNECTED IS ALL ABOUT JOINING THE TRAVEL AND HOSPITALITY INDUSTRY TOGETHER Through our app and our networking events, our aim is to bridge the gap between agents, corporate travel, wholesalers, juniors and potential mentors to create a positive environment where business partnerships and life-long friends can be formed using innovation and a place where expression of ideas can flourish.';

   var descriptionTemp ='JITO CONNECTED IS ALL ABOUT JOINING THE TRAVEL AND HOSPITALITY INDUSTRY TOGETHER Through our app and our networking events, our aim is to bridge the gap between agents, corporate travel, wholesalers, juniors and potential mentors to create a positive environment where business partnerships and life-long friends can be formed using innovation and a place where expression of ideas can flourish.';

  var imagePath = $window.appURL;
  var image= imagePath+ 'app/img/home-icons/JITO-ICON.png';
  var titleSuffix = ' - JITO CONNECTED';
  var titlePrefix = 'A FORCE FOR GOOD Hub | ';
  var uploadPath = imagePath+'uploads/profile/';
  var imageStatic= imagePath+ 'app/img/home-icons/JITO-ICON.png';
 
  
  return {
    title: function() { 
      return title; 
    },

    setTitle: function(newTitle) { 
      title = titlePrefix + newTitle;
    },

    setKeyword:function(newKeyword){
      keyword = newKeyword;
    },

    setDescription:function(newDescription){
      description = newDescription;
    },

    setImage:function(newImage){
      image = imagePath+newImage;
    },

    setDefault:function(){
      keyword = keywordTemp;
      description = descriptionTemp;
      image = imageStatic;
    },

    setMeta: function(newTitle, newKeyword , newDescription , newImage){
        title = titlePrefix + newTitle;
        keyword = newKeyword;
        description = newDescription ;
        image = newImage;
    },

    metaData: function(){
      var data = {
        title: title,
        keyword: keyword,
        description: description,
        image: image
      }
      return data;
    }
  };
});