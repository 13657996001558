/**
 * Created by thusitha_m on 1/14/2016.
 */

app.controller('SignUpOptionsController', function ($scope, $location, $rootScope, $timeout) {
    "ngInject";
    $scope.gotoPage = function(type){

        switch(type){
            case 'user':
                $timeout(function(){
                    $rootScope.Ui.toggle("signup");
                    $rootScope.Ui.toggle("userSignup");
                }, 100);
                break;
            case 'brand':
                $timeout(function(){
                    $rootScope.Ui.toggle("signup");
                    $rootScope.Ui.toggle("brandSignup");
                }, 100);
                break;
        }
    }

});