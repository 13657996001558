/**
 * Created by thusitha_m on 1/20/2016.
 */

app.controller('ListController', function ($scope, $window, $rootScope, jitoHybrid, jitoValidations, jitoCommon, jitoUser, $location, jitoEvents,
                                           jitoAjax, $translate, jitoShared, jitoSettings, jitoPaths) {
    "ngInject";
    $scope.items = [];
    var currentPage = 0, isMoreToFetch = true, redirectURL, url;
    $scope.currentUserID = jitoUser.getLoggedUser() ? jitoUser.getUserID() : null;
    $scope.isLoading = false;
    $scope.isEvent = false;
    $scope.isMessage = false;
    $scope.isSearching = false;
    $scope.isNewDataObject = false;
    $scope.isMutualFriendsCall = false;
    $scope.canConnectAndMessage = false;
    var params = {};

    var constructor = function () {

        switch ($scope.LIST_TYPE) {
            case jitoSettings.LIST_STATE.FRIENDS_OF_USER:
                url = jitoSettings.getAPIDomain() + jitoSettings.geFriendsListOfUser();
                params.user_id = $scope.LIST_ID;
                redirectURL = jitoPaths.USER_PROFILE;
                $scope.isNewDataObject = true;
                $scope.canConnectAndMessage = true;
                break;
            case jitoSettings.LIST_STATE.BRANDS_FOLLOWED_BY_USER:
                url = jitoSettings.getAPIDomain() + jitoSettings.geUserFollowBrandsAPI() + $scope.LIST_ID;
                redirectURL = jitoPaths.BRAND_PROFILE;
                break;
            case jitoSettings.LIST_STATE.USERS_ATTEND_IN_EVENT:
                url = jitoSettings.getAPIDomain() + jitoSettings.getEventParticipants() + $scope.LIST_ID;
                redirectURL = jitoPaths.USER_PROFILE;
                $scope.canConnectAndMessage = true;
                break;
            case jitoSettings.LIST_STATE.LIKERS:
                url = jitoSettings.getAPIDomain() + jitoSettings.getPostLikers()+$scope.OBJECT_TYPE+ "/" + $scope.LIST_ID;
                redirectURL = jitoPaths.USER_PROFILE;
                $scope.isEvent  = false;
                break;
            case jitoSettings.LIST_STATE.FRIENDS_OF_USER_MESSAGE:
                currentPage = 0;
                url = jitoSettings.getAPIDomain() + jitoSettings.getAllUsersForMessaging();
                redirectURL = jitoPaths.CONVERSATION;
                $scope.isMessage = true;
                $scope.isNewDataObject = true;
                break;
            case jitoSettings.LIST_STATE.MUTUAL_FRIENDS:
                currentPage = 0;
                url = jitoSettings.getAPIDomain() + jitoSettings.getMutualFriends() + jitoShared.getCurrentUserID();
                redirectURL = jitoPaths.CONVERSATION;
                $scope.isNewDataObject = true;
                $scope.isMutualFriendsCall = true;
                break;
            case jitoSettings.LIST_STATE.BRAND_FOLLOWERS:
                url = jitoSettings.getAPIDomain() + jitoSettings.getBrandFollowersList() + $scope.LIST_ID;
                //params.user_id = $scope.LIST_ID;
                redirectURL = jitoPaths.USER_PROFILE;
                $scope.isNewDataObject = true;
                $scope.canConnectAndMessage = true;
                break;
        }
    };

    //Initialize the scope according to the type of list
    constructor();

    var listSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;
        $scope.isNoItems = false;
        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.isError = false;
                if($scope.isNewDataObject && response.data.data.data) {
                    $scope.items.push.apply($scope.items, response.data.data.data);
                } else {
                    $scope.items.push.apply($scope.items, response.data.data);
                }

                if ($scope.items.length < 1) {
                    $scope.isNoItems = true;
                }

                if($scope.isNewDataObject && response.data.data.data) {
                    if(response.data.data.data.length <= 0){
                        isMoreToFetch = false;
                    }
                } else {
                    if(response.data.data.length <= 0){
                        isMoreToFetch = false;
                    }
                }
            }
            else {
                onError(response);
            }
        } else {
            onError(response);
        }
    };

    var listError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        $scope.isError = true;
        $scope.isNoItems = false;
        $scope.errorMessage = errorMessage;
        //jitoHybrid.alert(errorMessage, 0);
        $scope.isMoreLoading = false;
        $scope.friends = false;
    };

    var loadItemList = function () {
        if (!($scope.items.length > 0)) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        }

        currentPage += 1;

        if($scope.isMutualFriendsCall == true) {
            params = {
                mutual_friends_page: currentPage
            };
        } else {
            params.page = currentPage;
        }


        if($scope.isMessage){
            jitoAjax.call({
                method: 'get',
                url: url,
                params:{
                  term: $scope.searchText,
                  page: currentPage
                },
                timeout: jitoSettings.DEFAULT_TIME_OUT
            }, listSuccess, listError);
        } else{
            jitoAjax.call({
                method: 'get',
                url: url,
                params: params,
                timeout: jitoSettings.DEFAULT_TIME_OUT
            }, listSuccess, listError);
        }

    };

    var onError = function (response) {

        if (!($scope.items.length > 0)) {
            jitoHybrid.alert($translate.instant('COMMON_NO_NETWORK'), 0);
            $scope.friends = false;
        }
        if (!(response.data.data.length > 0)) {
            isMoreToFetch = false;
        }
    };

    //////////////////////////////////////////////////////////////
    // Load attendees
    loadItemList(currentPage);
    //////////////////////////////////////////////////////////////

    $scope.loadMoreItems = function () {
        if (isMoreToFetch) {
            $scope.isMoreLoading = true;
            loadItemList();
        }
    };


    $scope.goToPage = function (userID, index, item) {

      if(item.is_general == -1){
      } else if(!item.hasOwnProperty('is_general') || item.is_general == 1 ) {
        if(($scope.LIST_TYPE == jitoSettings.LIST_STATE.LIKERS) ||
          ($scope.LIST_TYPE == jitoSettings.LIST_STATE.USERS_ATTEND_IN_EVENT)){

          var type = $scope.items[index].type;
          if(parseInt(type) == 1){
            $location.path(jitoPaths.USER_PROFILE + userID);
          } else{
            $location.path(jitoPaths.BRAND_PROFILE + userID);
          }

        } else if($scope.LIST_TYPE == jitoSettings.LIST_STATE.MUTUAL_FRIENDS) {
            if(parseInt(item.type) == 1){
                $location.path('/profile/' + item.id);
            } else{
                $location.path('/company/profile/' + item.id);
            }
        }else{
          if($scope.isMessage){
            //Trigger event for the message controller with friend ID
            jitoShared.setMessageSenderID(userID);
            $location.path(redirectURL);

          }else{
            $location.path(redirectURL + userID);
          }
        }
      }

    };

    $scope.searchFriends = function(){
            $scope.items.length = 0;
            currentPage = 0;
            loadItemList();
    };

    $scope.goToMessage = function (userID, $event) {
        $event.stopPropagation();
        jitoShared.setMessageSenderID(userID);
        $location.path(jitoPaths.CONVERSATION);
    };

    $scope.addFriend = function (friendId, friendIndex, $event) {
        $event.stopPropagation();
        $scope.isLoading = true;

        jitoAjax.call({
            method: 'POST',
            data: {friend_id: friendId},
            url: jitoSettings.getAPIDomain() + jitoSettings.getFriendAdd(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function (response) {
            $scope.isLoading = false;

            $scope.items[friendIndex].friend_status = 0;
            $scope.items[friendIndex].friend_request_status.is_send = 1;
            $scope.items[friendIndex].friend_request_status.status = 0;
        }, function (response) {
            $scope.isLoading = false;

            if (response.status > 200 && response.status < 500 && response.data) {
                jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response.data));
            } else {
                jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
            }
        });
    };
});