/**
 * Created by thusitha_m on 1/12/2016.
 */

app.factory('jitoCommon', function ($timeout, $rootScope, $route, $translate, $location, jitoStorage, jitoHybrid) {
    "ngInject";
    return {
        normalizeErrorMessages: function (response) {

            var result = '';
            for (var err in response.message) {

                if(JSON.stringify(response.message[err])[0] == '['){
                    result += response.message[err].join("\n");
                }else{
                    result += response.message[err];
                }

            }
            return result;

        },

        generateHash: function () {
            return Math.random().toString(36).substr(2) +
                Math.random().toString(36).substr(2);

        },

        isLeapYear: function (year) {
            return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
        },

        isContains: function (needle) {
            // Per spec, the way to identify NaN is that it is not equal to itself
            var findNaN = needle !== needle;
            var indexOf;

            if (!findNaN && typeof Array.prototype.indexOf === 'function') {
                indexOf = Array.prototype.indexOf;
            } else {
                indexOf = function (needle) {
                    var i = -1, index = -1;

                    for (i = 0; i < this.length; i++) {
                        var item = this[i];

                        if ((findNaN && item !== item) || item === needle) {
                            index = i;
                            break;
                        }
                    }

                    return index;
                };
            }

            return indexOf.call(this, needle) > -1;
        },

        daysInMonth: function (month, year) {
            return new Date(year, month, 0).getDate();
        },

        openModal: function(){
            $timeout(function() {
                $rootScope.Ui.toggle("friends");
            }, 100);
        },

        openInfoModal: function(){
            $timeout(function() {
                $rootScope.Ui.toggle("infoModal");
            }, 100);
        },

        openReportModal: function(){
            $timeout(function() {
                $rootScope.Ui.toggle("report");
            }, 100);
        },

        toggleEditPostModal: function(){
            $timeout(function() {
                $rootScope.Ui.toggle("editpost");
            }, 100);
        },

        prepareAnchorTags: function(){
            $timeout(function() {
                $('.jt-linkfy').attr('onclick', 'window.open(this.href,\'_system\'); return false;');
            }, 3000);
        },

        isProfileUpdated: function(){
            //if(jitoStorage.get('IS_NEW') && ($location.path() == '/profile-edit')){
            //    jitoHybrid.popupError($translate.instant('PROFILE_EDIT_INCOMPLETE_ERROR'));
            //    return false;
            //} else{
                return true;
            //}
        },

        /**
         * Change the route of the application
         * @param path
         */
        route: function (path) {
            $location.path(path);
            $route.reload();
        }

    };
});