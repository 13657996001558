/**
 * Created by thusitha_m on 2/15/2016.
 */

app.controller('FeedbackController', function ($window, $rootScope, $timeout, $scope, $translate, $location,
                                               jitoEvents, SharedState, jitoShared, jitoHybrid, jitoCommon, jitoValidations, jitoStorage, jitoAjax, jitoPaths, jitoSettings) {
    "ngInject";
    var feedbackSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.feedback = "";
                jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));
            }
            else {
                jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
            }
        } else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    var feedbackError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        jitoHybrid.popupError(errorMessage);
    };

    $scope.sendFeedback = function () {
        if(!jitoValidations.empty($scope.feedback)){
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
            jitoAjax.call({
                method: 'post',
                url: jitoSettings.getAPIDomain() + jitoSettings.getFeedbackPostAPI(),
                data:{
                    description: $scope.feedback
                },
                timeout: jitoSettings.DEFAULT_TIME_OUT
            }, feedbackSuccess, feedbackError);
        } else{
            jitoHybrid.popupError($translate.instant('FEEDBACK_EMPTY'));
        }
    };

});