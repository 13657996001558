/**
 * Created by thusitha_m on 1/29/2016.
 */

app.controller('ThreadController', function ($scope, jitoSettings, jitoAjax,jitoHybrid, SharedState,$translate, jitoCommon,jitoPaths, jitoUser, $location, jitoEvents) {
    "ngInject";
    $scope.threads = [];
    var currentPage = 0, isMoreToFetch = true;

    //If the user is already logged in redirects him to home page
    $scope.goToPage = function(path){
        $location.path(path);
    };

    $(document).ready(function () {
        setCss()
    });

    $(window).resize(function() {
        setCss()
    });

    function setCss(){
       var left= $('#msg-container').width() - '65';
       left = left+'px';
       $("#jt-msg-create").css({
           "left" :  left,
           "right" : 'auto'
        });
    };

    var threadSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;
        if (response && response.status && response.status === 200) {
            if (response.data) {

                $scope.threads.push.apply($scope.threads, response.data.data);
                if ($scope.threads.length < 1) {
                    $scope.noMessages = true;
                } else if(!(response.data.data.length > 0)){
                    isMoreToFetch = false;
                }
            }
            else {
                onError(response);
            }
        } else {
            onError(response);
        }
    };

    var noEventsError = function () {
        $scope.noMessages = true;
    };

    var onError = function (response) {

        if (!($scope.threads.length > 0)) {
            noEventsError(response);
        }
        if (!(response.data.data.length > 0)) {
            isMoreToFetch = false;
        }

    };

    $scope.loadMoreThreads = function () {
        if (isMoreToFetch && $scope.threads.length > 0) {
            $scope.isMoreLoading = true;
            loadThreadList();
        }
    };

    var threadError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;

        $scope.isError = true;
        $scope.errorMessage = errorMessage;
    };

    var loadThreadList = function () {
        if (!($scope.threads.length > 0)) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        }

        currentPage += 1;

        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getMessageThreadsAPI(),
            params:{
                page : currentPage
            }, timeout: jitoSettings.DEFAULT_TIME_OUT
        }, threadSuccess, threadError);
    };

    loadThreadList();

    $scope.composeNewMessage = function(){
        $scope.LIST_TYPE = jitoSettings.LIST_STATE.FRIENDS_OF_USER_MESSAGE;
        $scope.LIST_ID = jitoUser.getUserID();
        $scope.MODAL_TITLE = $translate.instant('MODAL_DIALOG_FRIENDS_LIST');
        $scope.MODAL_NO_ITEMS = $translate.instant('MODAL_DIALOG_FRIENDS_NOT_FOUND');
        jitoCommon.openModal();
    };

    var threadDeleteSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            if (response.data) {
                jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));
                $scope.threads.length = 0;
                currentPage = 0;
                loadThreadList();
            }
            else {
                threadDeleteError(response);
            }
        } else {
            threadDeleteError(response);
        }
    };

    var threadDeleteError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        jitoHybrid.popupError(errorMessage);
    };


    $scope.deleteThreadItem = function(id){

        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getThreadDeleteAPI(),
            params:{
                thread_id : id
            }, timeout: jitoSettings.DEFAULT_TIME_OUT
        }, threadDeleteSuccess, threadDeleteError);
    };

});