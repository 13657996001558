/**
 * Created by thusitha_m on 1/13/2016.
 */

app.controller('HomeController', function ($rootScope, $window, $scope, $location,$translate,
                                           jitoEvents, jitoUser, jitoHybrid) {

    "ngInject";
    /*if(jitoUser.isLogged()){
        $scope.userType = jitoUser.getUserType();
        $scope.userID = jitoUser.getLoggedUser().user_id;
    }*/

    //Update notification count
    jitoEvents.triggerFetchNotifications();

    if(jitoUser.isLogged()){
        $scope.userType = jitoUser.getUserType();
        $scope.userID = jitoUser.getLoggedUser().user_id;
    }

    $rootScope.$on('loginService', function (event, state) {
        $scope.isLoggedIn = state;

        if(jitoUser.isLogged()){
            $scope.userType = jitoUser.getUserType();
            $scope.userID = jitoUser.getLoggedUser().user_id;
        }

    });


});