/**
 * Created by thusitha_m on 1/12/2016.
 */

app.controller('UserSignUpController', function ($scope, jitoUser, $timeout, $rootScope, $translate, jitoStorage, jitoValidations, jitoPaths, jitoEvents, jitoAjax, jitoSettings, jitoHybrid, $location, jitoCommon) {
    "ngInject";
    //Set init variables for profile picture browse
    var profilePicture = null;
    var deviceID = null;
    //$scope.isProfilePictureSelected = false;
    $scope.isProfileImageEmpty = true;
    $scope.isLocationEmpty = true;
    $scope.isBrandEmpty = true;
    $scope.isBrandLength = true;
    $scope.isInUserSignup = true;
 
    //////////////////////////////////////////////////////////
    // Profile picture selecting section

    $scope.pictureAdded = function($file){
        var fileReader = new FileReader();
        fileReader.onload = function (event) {

            jitoSettings.getImageData(event.target.result).then(function (image) {
                profileImgUploadSuccess(image);
            });

        };
        fileReader.readAsDataURL($file.file);
    };

    var profileImgUploadSuccess = function (imageData) {
        //$scope.$apply();
        //TODO: This is not the best practice. Use Angular directive instead
        var image = document.getElementById('jt-joinUser-img-profilePic');

        image.src = imageData;
        profilePicture = imageData;

        $scope.profilePictureStatus = "true";
        $("#jt-joinUser-btn-join").focus();
        $scope.isProfileImageEmpty = false;
    };


    ///////////////////////////////////////////////////////////

    function onBlurElm() {
        if (jitoValidations.empty($('#jt-joinUser-input-currentEmployment_value').val())) {
            $scope.isBrandEmpty = true;
        } else {
            if(jitoValidations.maxLength($('#jt-joinUser-input-currentEmployment_value').val(),50)){
              $scope.isBrandLength = false;  
            }else{
              $scope.isBrandLength = true;    
            }
            $scope.isBrandEmpty = false;
        }
    }

    $(document).on('click', '#jt-joinUser-input-currentEmployment_value', onBlurElm);

    $(document).on('keyup', '#jt-joinUser-input-currentEmployment_value', onBlurElm);


    $scope.validateFields = function (isValidForm) {

        var brandName = "",
            brandID = "";

        if ($scope.brand && ($scope.brand.originalObject.name == $('#jt-joinUser-input-currentEmployment_value').val())) {

            brandName = $('#jt-joinUser-input-currentEmployment_value').val();
            brandID = $scope.brand.originalObject.id;

        } else {
            brandName = $('#jt-joinUser-input-currentEmployment_value').val();
            brandID = null;
        }

        if (jitoValidations.empty(profilePicture)) {
            $scope.isProfileImageEmpty = true;
        } else {
            $scope.isProfileImageEmpty = false;
        }

        if (jitoValidations.empty($scope.countryValue)) {
            $scope.isLocationEmpty = true;
        } else {
            $scope.isLocationEmpty = false;
        }

        if (jitoValidations.empty($('#jt-joinUser-input-currentEmployment_value').val())) {
            $scope.isBrandEmpty = true;
        } else {
            $scope.isBrandEmpty = false;
        }

        if (jitoValidations.empty($scope.countryValue) ||
            jitoValidations.empty($('#jt-joinUser-input-currentEmployment_value').val()) ||
            jitoValidations.empty(profilePicture)) {

            return;
        }

        if (isValidForm) {

            //Generate a unique device id and store it inside local storage
            if (jitoValidations.empty(jitoStorage.get('DEVICE_ID'))) {
                deviceID = jitoCommon.generateHash();
                jitoStorage.set('DEVICE_ID', deviceID);
            } else {
                deviceID = jitoStorage.get('DEVICE_ID');
            }

            signUp({
                first_name: $scope.user.firstName,
                last_name: $scope.user.lastName,
                email: $scope.user.email,
                profile_picture: profilePicture,
                password: $scope.user.password,
                device_id: deviceID,
                company: brandName,
                company_id: brandID,
                country_code: $scope.countryValue,
                city_id: $scope.cityValue,
                current_job: $scope.user.jobTitle
            });
        }
    };

    var signUp = function (userObject) {

        jitoEvents.triggerLoader(true, $translate.instant('SIGNUP_MESSAGE'), true);
        $scope.isRequesting = true;
        jitoAjax.call({
            method: 'post',
            data: JSON.stringify(userObject),
            url: jitoSettings.getAPIDomain() + jitoSettings.getUserSignupAPI(),
            timeout: jitoSettings.PICTURE_TIME_OUT
        }, signUpSuccess, signUpError);
    };

    var signUpSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isRequesting = false;
        if (response && response.status && response.status === 200) {

            var message = null,
                user = response.data ? response.data : null;
            if (jitoValidations.empty(user.token)) {
                message = $translate.instant('LOGIN_TOKEN_EMPTY');
            } else if (jitoValidations.empty(user.user_id)) {
                message = $translate.instant('LOGIN_USER_ID_EMPTY');
            } else if (jitoValidations.empty(user.device_id)) {
                message = $translate.instant('LOGIN_DEVICE_ID_EMPTY');
            } else if (jitoValidations.empty(user.type)) {
                message = $translate.instant('LOGIN_USER_TYPE_EMPTY');
            }

            if (message) {
                jitoHybrid.alert(message, 0);
            } else {

                jitoEvents.triggerLogin(true);

                $timeout(function(){
                    $rootScope.Ui.toggle("userSignup");
                }, 100);

                jitoUser.setLoggedUser(user);
                jitoUser.setPrivacyStatus(user.privacy);
                jitoUser.setNotificationStatus(user.notifications_status);
                jitoUser.setEmailStatus(user.email_notifications_status);

                if(jitoValidations.empty(user.last_login)){
                    jitoStorage.set("IS_NEW", true);
                }else{
                    jitoStorage.set("IS_NEW", false);
                }

                jitoUser.setUserType(response.data.type);

                if($scope.isFromTickets){
                    jitoEvents.triggerLogin(true);
                    jitoEvents.triggerPageStatus(true);

                    $timeout(function(){
                        $rootScope.Ui.toggle("purchaseTickets");
                        jitoEvents.triggerFromTickets(false);
                    }, 100);

                } else{
                    jitoEvents.triggerPageStatus(false);
                    $location.path(jitoPaths.USER_TIME_LINE);
                }
            }


        } else {
            showErrorMessage(response);
        }
    };

    var signUpError = function (response) {
        showErrorMessage(response);
    };

    var showErrorMessage = function (response) {
        var errorMessage = '';
        $scope.isRequesting = false;
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        jitoEvents.triggerLoader(false);
        jitoHybrid.alert(errorMessage, 0);
    };

    $scope.goToTerms = function(){
        $scope.TYPE = jitoSettings.INFO_STATE.TERMS;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_TERMS_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_TERMS_DESC');
        jitoCommon.openInfoModal();
    };

    $scope.goToAbout = function(){
        $scope.TYPE =jitoSettings.INFO_STATE.ABOUT;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_ABOUT_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_ABOUT_DESC');
        jitoCommon.openInfoModal();
    };

    $scope.goToPrivacyPolicy = function(){
        $scope.TYPE = jitoSettings.INFO_STATE.PRIVACY;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_PRIVACY_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_PRIVACY_DESC');
        jitoCommon.openInfoModal();
    };

    /////////////////////////////////////////////////////////////////////////
    //Get location data

    $scope.updateCityList = function (countryCode) {

        if (jitoValidations.empty(countryCode)) {
            $scope.isLocationEmpty = true;
        } else {
            $scope.isLocationEmpty = false;
        }

        updateCityList(countryCode);
    };
    
    $scope.cityValidation = function () {
        
    };

    var updateCityList = function (countryCode) {

        $scope.isRequesting = true;
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getUserCityAPI() + countryCode,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function (response) {
            $scope.isRequesting = false;
            if (response && response.status && response.status === 200) {

                $scope.cityList = response.data.data;
                if (! $.isEmptyObject($scope.cityList) && !$scope.cityList.hasOwnProperty(4694)) {
                    $scope.cityValue = ""+Object.keys($scope.cityList)[0];
                } else {
                    $scope.cityValue = "";
                    $scope.cityList = {"": "State"};
                }

                isStartingUp = false;
            }
        }, function (response) {
            //Error in city list getting
            var errorMessage = '';
            $scope.isRequesting = false;
            if (response.status > 200 && response.status < 500 && response.data) {
                errorMessage = jitoCommon.normalizeErrorMessages(response.data);
            } else {
                errorMessage = $translate.instant('COMMON_NO_NETWORK');
            }
            jitoHybrid.popupError(errorMessage);
        }, false);
    };

    var countryListSuccess = function (response) {
        if (response && response.status && response.status === 200) {
            $scope.countryList = response.data.data;

            $scope.countryValue = jitoSettings.DEFAULT_COUNTRY;
            updateCityList($scope.countryValue);

        }
    };

    var countryListError = function (response) {
        //Error in country list getting
        $scope.isRequesting = false;
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        } else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.popupError(errorMessage);

    };

    var getLocationData = function () {

        $scope.isRequesting = true;
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getUserCountriesAPI(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, countryListSuccess, countryListError, false);
    };

    getLocationData();

});