app.controller('ManageTableAttendeesController', function (titleChange, $scope, jitoPaths, $window, jitoUser, $location, jitoStorage, $timeout,
                                                           jitoShared, jitoCommon, $translate, jitoEvents, jitoAjax, jitoSettings, $anchorScroll, $rootScope) {

  "ngInject";

  //Title Change
  titleChange.setTitle('Manage table ticket attendees');

  $scope.token = $location.search().token ? $location.search().token : '';
  $scope.isLoading = false;
  $scope.ticketData = null;
  $scope.errorMessage = '';
  $scope.successMessage = '';
  $scope.currentProcessingIndex = null;
  $scope.currentProcessingTicket = {
    first_name: "",
    last_name: "",
    job_title: "",
    company: "",
    dietary_requirements: ""
  };
    $scope.sessionSelectionError = "";

  if (jitoUser.isLogged()) {
    $("#jt-top-bar-section").css({
      'visibility': 'hidden',
      'display': 'none'
    });
  }

  $scope.openEmailFormatDialog = function(){
    $timeout(function(){
      $rootScope.Ui.toggle("inviteAttendeeEmailFormat");
    }, 100);
  };

  var requestError = function (response) {
    var errorMessage = '';
    jitoEvents.triggerLoader(false);
    $scope.isLoading = false;
    $scope.currentProcessingIndex = null;

    if (response.status > 200 && response.status < 500 && response.data) {
      errorMessage = jitoCommon.normalizeErrorMessages(response.data);
    } else {
      errorMessage = $translate.instant('COMMON_NO_NETWORK');
    }

    $scope.errorMessage = errorMessage;
    $scope.successMessage = '';

    $location.hash('main');
    $anchorScroll();
  };

  var showSuccessMessages = function (message) {
    $scope.successMessage = message;
    $scope.errorMessage = '';

    $location.hash('main');
    $anchorScroll();
  };

  ///// Get initial data start ///////////////

  var tableTicketSuccess = function (response) {
    jitoEvents.triggerLoader(false);
    $scope.isLoading = false;

    if (response && response.status && response.status === 200) {
      if (response.data && response.data.data && response.data.data.data) {
        $scope.ticketData = response.data.data.data;

        angular.forEach($scope.ticketData.order_ticket_details, function (ticket) {
          ticket.isEditing = false;
          ticket.isEmailInviteFormError = false;
          ticket.isDetailsSaveFormError = false;
        });
      }
    } else {
      requestError(response);
    }
  };

  var render = function () {
    jitoEvents.triggerLoader(true);
    $scope.isLoading = true;

    jitoAjax.call({
      method: 'get',
      url: jitoSettings.getAPIDomain() + jitoSettings.getTableTicketDetails() + $scope.token,
      timeout: jitoSettings.DEFAULT_TIME_OUT
    }, tableTicketSuccess, requestError);
  };

  render();

  ////////////// Get initial data end /////////////////////////////


  ///////////// Update company name start ///////////////////////////

  var companyNameUpdateSuccess = function (response) {
    jitoEvents.triggerLoader(false);
    $scope.isLoading = false;

    if (response && response.status && response.status === 200) {
      if (response.data && response.data.message) {
        showSuccessMessages(response.data.message);

        angular.forEach($scope.ticketData.order_ticket_details, function (ticket) {
          if(ticket.filling_status == 0) {
            ticket.company = $scope.ticketData.order_details.company;
          }
        });
      }
    } else {
      requestError(response);
    }
  };

  $scope.updateCompanyName = function (isValid) {
    if (isValid) {
      jitoEvents.triggerLoader(true);
      $scope.isLoading = true;

      jitoAjax.call({
        method: 'put',
        url: jitoSettings.getAPIDomain() + jitoSettings.updateCompanyNameForTableTicket(),
        data: {
          token: $scope.token,
          order_id: $scope.ticketData.order_details.order_header_id,
          company_name: $scope.ticketData.order_details.company
        },
        timeout: jitoSettings.DEFAULT_TIME_OUT
      }, companyNameUpdateSuccess, requestError);
    }
  };

  ////////////////// Update company name end ///////////////////////

  ///////////////// Invite attendee start //////////////////////////

  var inviteAttendeeSuccess = function (response) {
    jitoEvents.triggerLoader(false);
    $scope.isLoading = false;

    if (response && response.status && response.status === 200) {
      if (response.data && response.data.message) {
        showSuccessMessages(response.data.message);
      }

      $scope.ticketData.order_ticket_details[$scope.currentProcessingIndex].is_send_email = 1;
      $scope.currentProcessingIndex = null;
    } else {
      requestError(response);
    }
  };

  $scope.inviteAttendee = function (isValid, index) {
    if(isValid) {
      $scope.ticketData.order_ticket_details[index].isEmailInviteFormError = false;
      jitoEvents.triggerLoader(true);
      $scope.isLoading = true;
      $scope.currentProcessingIndex = index;

      jitoAjax.call({
        method: 'put',
        url: jitoSettings.getAPIDomain() + jitoSettings.inviteAttendeeForTableTicket(),
        data: {
          token: $scope.token,
          order_id: $scope.ticketData.order_details.order_header_id,
          ticket_id: $scope.ticketData.order_ticket_details[index].ticket_id,
          attendee_email: $scope.ticketData.order_ticket_details[index].email
        },
        timeout: jitoSettings.DEFAULT_TIME_OUT
      }, inviteAttendeeSuccess, requestError);
    } else {
      $scope.ticketData.order_ticket_details[index].isEmailInviteFormError = true;
    }
  };

  ///////////////// Invite attendee end //////////////////////////

  /////////////// Delete ticket start ///////////////////

  var deleteAttendeeSuccess = function (response) {
    jitoEvents.triggerLoader(false);
    $scope.isLoading = false;

    if (response && response.status && response.status === 200) {
      if (response.data && response.data.message) {
        showSuccessMessages(response.data.message);
      }

      // $scope.ticketData.order_ticket_details[$scope.currentProcessingIndex].first_name = '';
      // $scope.ticketData.order_ticket_details[$scope.currentProcessingIndex].last_name = '';
      // $scope.ticketData.order_ticket_details[$scope.currentProcessingIndex].job_title = '';
      // $scope.ticketData.order_ticket_details[$scope.currentProcessingIndex].company = '';
      // $scope.ticketData.order_ticket_details[$scope.currentProcessingIndex].email = '';
      // $scope.ticketData.order_ticket_details[$scope.currentProcessingIndex].dietary_requirements = '';
      // $scope.ticketData.order_ticket_details[$scope.currentProcessingIndex].is_send_email = 0;
      // $scope.ticketData.order_ticket_details[$scope.currentProcessingIndex].filling_status = 0;
      //
      //   angular.forEach($scope.ticketData.order_ticket_details[$scope.currentProcessingIndex].sessions, function(event_session, event_session_key) {
      //       if(event_session.is_default == 0) {
      //           $scope.ticketData.order_ticket_details[$scope.currentProcessingIndex].sessions[event_session_key].is_selected = null;
      //       }
      //   });
      //
      // $scope.currentProcessingIndex = null;

        $timeout(function() {
            render();
        }, 100);
    } else {
      requestError(response);
    }
  };

  $scope.deleteTicket = function (index) {
    if (confirm($translate.instant('TABLE_TICKET_MANAGEMENT.DELETE_CONFIRM'))) {
      jitoEvents.triggerLoader(true);
      $scope.isLoading = true;
      $scope.currentProcessingIndex = index;

      jitoAjax.call({
        method: 'put',
        url: jitoSettings.getAPIDomain() + jitoSettings.deleteAttendeeForTableTicket(),
        data: {
          token: $scope.token,
          order_id: $scope.ticketData.order_details.order_header_id,
          ticket_id: $scope.ticketData.order_ticket_details[index].ticket_id
        },
        timeout: jitoSettings.DEFAULT_TIME_OUT
      }, deleteAttendeeSuccess, requestError);
    }
  };

  /////////////// Delete ticket end ////////////////////

  //////////// Save ticket details start ////////////////////

  var saveAttendeeSuccess = function (response) {
    jitoEvents.triggerLoader(false);
    $scope.isLoading = false;
    $scope.sessionSelectionError = "";

    if (response && response.status && response.status === 200) {
      if (response.data && response.data.message) {
        showSuccessMessages(response.data.message);
      }

      // $scope.ticketData.order_ticket_details[$scope.currentProcessingIndex].filling_status = 1;
      // $scope.ticketData.order_ticket_details[$scope.currentProcessingIndex].isEditing = false;
      // $scope.currentProcessingIndex = null;

        $timeout(function() {
            render();
        }, 100);
    } else {
      requestError(response);
    }
  };

  $scope.saveAttendeeDetails = function (isValid, index) {
    if(isValid) {
      var sessions = $scope.ticketData.order_ticket_details[index].sessions;
      var sessionErrorCount = 0;
      $scope.sessionSelectionError = "";

      angular.forEach(sessions, function(value, key) {
          //value.is_selected = (value.is_selected) ? value.is_selected : 1;
          if(value.is_default == 0 && value.is_selected == null) {
              sessionErrorCount += 1;
          } else {
              if(value.is_default == 1) {
                  value.is_selected = 1;
              } else {
                  value.is_selected = (value.is_selected) ? value.is_selected : 1;
              }
          }
      });

      if(sessionErrorCount > 0) {
          $scope.sessionSelectionError = $translate.instant('TICKET_SESSION_SELECTION_ERROR');

          return false;
      }

      $scope.ticketData.order_ticket_details[index].isDetailsSaveFormError = false;
      jitoEvents.triggerLoader(true);
      $scope.isLoading = true;
      $scope.currentProcessingIndex = index;

      jitoAjax.call({
        method: 'put',
        url: jitoSettings.getAPIDomain() + jitoSettings.saveAttendeeForTableTicket(),
        data: {
          token: $scope.token,
          order_id: $scope.ticketData.order_details.order_header_id,
          ticket_id: $scope.ticketData.order_ticket_details[index].ticket_id,
          attendee_email: $scope.ticketData.order_ticket_details[index].email,
          first_name: $scope.ticketData.order_ticket_details[index].first_name,
          last_name: $scope.ticketData.order_ticket_details[index].last_name,
          company: $scope.ticketData.order_ticket_details[index].company,
          job_title: $scope.ticketData.order_ticket_details[index].job_title,
          dietary_requirements: $scope.ticketData.order_ticket_details[index].dietary_requirements,
          sessions: sessions,
          room: ($scope.ticketData.session_rooms.event_rooms.rooms) ? $scope.ticketData.session_rooms.event_rooms.rooms.id : null,
          host_event: ($scope.ticketData.session_rooms.event_rooms.rooms) ? $scope.ticketData.session_rooms.event_rooms.rooms.event_id : null
        },
        timeout: jitoSettings.DEFAULT_TIME_OUT
      }, saveAttendeeSuccess, requestError);
    } else {
      $scope.ticketData.order_ticket_details[index].isDetailsSaveFormError = true;
    }
  };

  /////////// Save ticket details end /////////////////////

  ////////////// Resend ticket start ////////////////////

  var resendAttendeeSuccess = function (response) {
    jitoEvents.triggerLoader(false);
    $scope.isLoading = false;

    if (response && response.status && response.status === 200) {
      if (response.data && response.data.message) {
        showSuccessMessages(response.data.message);
      }
    } else {
      requestError(response);
    }
  };

  $scope.resendAttendeeDetails = function (ticketId) {
    if (confirm($translate.instant('TABLE_TICKET_MANAGEMENT.RESEND_ATTENDEE_DETAILS_CONFIRM'))) {
      jitoEvents.triggerLoader(true);
      $scope.isLoading = true;

      jitoAjax.call({
        method: 'get',
        url: jitoSettings.getAPIDomain() + jitoSettings.resendAttendeeForTableTicket() + ticketId,
        timeout: jitoSettings.DEFAULT_TIME_OUT
      }, resendAttendeeSuccess, requestError);
    }
  };

  //////////// Resend ticket end ///////////////////

  ///////////// Resend the invitation start ////////////

  var resendInvitationSuccess = function (response) {
    jitoEvents.triggerLoader(false);
    $scope.isLoading = false;

    if (response && response.status && response.status === 200) {
      if (response.data && response.data.message) {
        showSuccessMessages(response.data.message);
      }
    } else {
      requestError(response);
    }
  };

  $scope.resentInvitation = function (ticketId) {
    if (confirm($translate.instant('TABLE_TICKET_MANAGEMENT.RESEND_INVITATION_CONFIRM'))) {
      jitoEvents.triggerLoader(true);
      $scope.isLoading = true;

      jitoAjax.call({
        method: 'get',
        url: jitoSettings.getAPIDomain() + jitoSettings.resendInvitationForTableTicket() + ticketId,
        timeout: jitoSettings.DEFAULT_TIME_OUT
      }, resendInvitationSuccess, requestError);
    }
  };

  //////////// Resend invitation end ////////////////////

  $scope.editTicketDetails = function (index) {
    angular.forEach($scope.ticketData.order_ticket_details, function (ticket, key) {
      ticket.isEditing = (key == index);
      ticket.isEmailInviteFormError = false;
      ticket.isDetailsSaveFormError = false;
    });

    $scope.currentProcessingTicket = {
      first_name: $scope.ticketData.order_ticket_details[index].first_name,
      last_name: $scope.ticketData.order_ticket_details[index].last_name,
      job_title: $scope.ticketData.order_ticket_details[index].job_title,
      company: $scope.ticketData.order_ticket_details[index].company,
      dietary_requirements: $scope.ticketData.order_ticket_details[index].dietary_requirements
    };
  };

  $scope.cancelEditing = function (index) {
    $scope.ticketData.order_ticket_details[index].isEditing = false;
    $scope.ticketData.order_ticket_details[index].isEmailInviteFormError = false;
    $scope.ticketData.order_ticket_details[index].isDetailsSaveFormError = false;
    $scope.ticketData.order_ticket_details[index].first_name = $scope.currentProcessingTicket.first_name;
    $scope.ticketData.order_ticket_details[index].last_name = $scope.currentProcessingTicket.last_name;
    $scope.ticketData.order_ticket_details[index].job_title = $scope.currentProcessingTicket.job_title;
    $scope.ticketData.order_ticket_details[index].company = $scope.currentProcessingTicket.company;
    $scope.ticketData.order_ticket_details[index].dietary_requirements = $scope.currentProcessingTicket.dietary_requirements;
    $scope.sessionSelectionError = "";
  };

  $scope.getTicketDownloadLink = function (ticketId, eventType) {
    var link = jitoSettings.getAPIDomain() + "events/table/attendee/ticket?order_id=" +
      $scope.ticketData.order_details.order_header_id + "&ticket_id=" + ticketId + "&token=" + $scope.token;

    if(eventType == 2) {
      link += "&is_second_event=true";
    }

    $window.location.href = link;
  };

  $scope.cleanDietaryRequirements = function (contents) {
    contents = contents.replace(/\n/g, '<div class="clearfix"></div>');

    return contents;
  };

    $scope.updateSessionSelection = function(ticket_id, session_id, session) {
        var sessionErrorCount = 0;

        angular.forEach($scope.ticketData.order_ticket_details, function(ticket, key) {
            if(ticket.ticket_id == ticket_id) {
                angular.forEach(ticket.sessions, function(event_session, event_session_key) {
                    if(event_session.id == session_id) {
                        $scope.ticketData.order_ticket_details[key].sessions[event_session_key].is_selected = session;
                    }

                    if($scope.ticketData.order_ticket_details[key].sessions[event_session_key].is_default == 0 &&
                        $scope.ticketData.order_ticket_details[key].sessions[event_session_key].is_selected == null) {
                        sessionErrorCount += 1;
                    }
                });
            }
        });

        $timeout(function () {
            $scope.sessionSelectionError = (sessionErrorCount > 0) ? $translate.instant('TICKET_SESSION_SELECTION_ERROR') : "";
        }, 100);
    };

  $scope.currentYear = new Date().getFullYear();
});