/**
 * Created by thusitha_m on 1/12/2016.
 */

app.controller('LoginController', function (titleChange,$scope, $rootScope, $timeout, $translate,
                                            jitoPaths, jitoEvents, jitoValidations, jitoAjax, jitoShared,
                                            jitoStorage, jitoSettings, jitoUser, jitoHybrid, $location, jitoCommon) {
    "ngInject";
    //Generate a unique device id and store it inside local storage
    var deviceID = null;    
    var type = 1;
    $scope.isUser = true;
    $scope.isInlineLogin = false;

    if (jitoValidations.empty(jitoStorage.get('DEVICE_ID'))) {
        deviceID = jitoCommon.generateHash();
        jitoStorage.set('DEVICE_ID', deviceID);
    } else {
        deviceID = jitoStorage.get('DEVICE_ID');
    }

    var loginSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isLogging = false;

        if (response && response.status === 200) {
            var message = null,
                user = response.data ? response.data : null;
            if (jitoValidations.empty(user.token)) {
                message = $translate.instant('LOGIN_TOKEN_EMPTY');
            } else if (jitoValidations.empty(user.user_id)) {
                message = $translate.instant('LOGIN_USER_ID_EMPTY');
            } else if (jitoValidations.empty(user.device_id)) {
                message = $translate.instant('LOGIN_DEVICE_ID_EMPTY');
            } else if (jitoValidations.empty(user.type)) {
                message = $translate.instant('LOGIN_USER_TYPE_EMPTY');
            }

            if (message) {
                jitoHybrid.alert(message, 0);
            } else {
                jitoEvents.triggerLogin(true);

                if(!$scope.isInlineLogin) {
                    $timeout(function(){
                        $rootScope.Ui.toggle("login");
                    }, 100);
                }

                jitoUser.setLoggedUser(user);
                jitoUser.setPrivacyStatus(user.privacy);
                jitoUser.setNotificationStatus(user.notifications_status);
                jitoUser.setEmailStatus(user.email_notifications_status);
                jitoUser.setUserType(response.data.type);

                if (jitoValidations.empty(user.last_login)) {
                    jitoStorage.set("IS_NEW", true);
                } else {
                    jitoStorage.set("IS_NEW", false);
                }

                if($scope.isFromTickets){

                    jitoEvents.triggerLogin(true);
                    jitoEvents.triggerPageStatus(true);

                    $timeout(function(){
                        $rootScope.Ui.toggle("purchaseTickets");
                        jitoEvents.triggerFromTickets(false);
                    }, 100);

                } else{
                    jitoEvents.triggerPageStatus(false);

                    var params = $location.search();
                    if (params.previous && params.previous != '') {
                        window.location.href = params.previous;
                    } else if (user.last_login == null && user.type == "2") {
                        $location.path(jitoPaths.USER_BRAND_EDIT);
                    } else {
                        $location.path(jitoPaths.USER_HOME);
                    }
                }
            }
        } else {
            if (response && response.data && response.status > 200) {
                message = jitoCommon.normalizeErrorMessages(response.data);
                jitoHybrid.alert(message, 0);
            }
        }
    };

    /**
     * This method will trigger if there is a error with the login request
     * and display the error.
     * @param response
     */
    var loginError = function (response) {
        var errorMessage = '';
        $scope.isLogging = false;
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        } else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        jitoEvents.triggerLoader(false);
        jitoHybrid.alert(errorMessage, 0);
    };


    $scope.setLoginType = function(userType){       
        $scope.isUser = userType;
        if(userType){
            type = 1;        
        } else{
            type = 2;    
        }
    };

    /**
     * This method will trigger when user click on the login button.
     * It will send the data to the server and call the relevant callback.
     * @param isValid
     * @param email
     * @param password
     */
    $scope.login = function (isValid, email, password) {
        $scope.isInlineLogin = false;
        if (!(jitoValidations.empty(email) && jitoValidations.empty(password)) && isValid) {

            $scope.isLogging = true;
            jitoEvents.triggerLoader(true, $translate.instant('LOGIN_MESSAGE'), true);
            jitoAjax.call({
                method: 'post',
                url: jitoSettings.getAPIDomain() + jitoSettings.getLoginAPI(),
                data: {
                    email: email,
                    password: password,
                    device_id: deviceID,
                    type: type
                },
                timeout: jitoSettings.LOGIN_REQUEST_TIMEOUT
            }, loginSuccess, loginError, true);
        }
    };

    $scope.inlineLogin = function (isValid, email, password) {
        $scope.isInlineLogin = true;
        if (!(jitoValidations.empty(email) && jitoValidations.empty(password)) && isValid) {

            $scope.isLogging = true;
            jitoEvents.triggerLoader(true, $translate.instant('LOGIN_MESSAGE'), true);
            jitoAjax.call({
                method: 'post',
                url: jitoSettings.getAPIDomain() + jitoSettings.getLoginAPI(),
                data: {
                    email: email,
                    password: password,
                    device_id: deviceID,
                    type: type
                },
                timeout: jitoSettings.LOGIN_REQUEST_TIMEOUT
            }, loginSuccess, loginError, true);
        }
    };


    $scope.gotoPage = function (path) {
        $location.path(path);
    };

    $scope.goToTerms = function () {
        $scope.TYPE = jitoSettings.INFO_STATE.TERMS;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_TERMS_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_TERMS_DESC');
        jitoCommon.openInfoModal();
    };

    $scope.goToAbout = function () {
        $scope.TYPE = jitoSettings.INFO_STATE.ABOUT;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_ABOUT_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_ABOUT_DESC');
        jitoCommon.openInfoModal();
    };

    $scope.goToPrivacyPolicy = function () {
        $scope.TYPE = jitoSettings.INFO_STATE.PRIVACY;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_PRIVACY_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_PRIVACY_DESC');
        jitoCommon.openInfoModal();
    };

    $scope.showSignup = function(){

        $timeout(function(){
            $rootScope.Ui.toggle("login");
            $rootScope.Ui.toggle("signup");
        }, 100);

    };

    $scope.showForgotPassword = function(){

        $timeout(function(){
            $rootScope.Ui.toggle("login");
            $rootScope.Ui.toggle("forgotPassword");
        }, 100);

    };

    $scope.showSignupOnly = function(){

        $timeout(function(){
            $rootScope.Ui.toggle("signup");
        }, 100);

    };

    $scope.showForgotPasswordOnly = function(){

        $timeout(function(){
            $rootScope.Ui.toggle("forgotPassword");
        }, 100);

    };

});