/**
 * Created by thusitha_m on 1/25/2016.
 */

app.controller('SingleImageViewController', function ($scope, $routeParams, jitoCommon, jitoHybrid, jitoEvents, jitoShared,
                                                      jitoSettings, $window, SharedState, $timeout, jitoUser, $rootScope, $location, jitoAjax, $translate, jitoValidations, jitoPaths) {
    "ngInject";
    var albumPhotos = [];
    var currentPage = 0;
    $scope.albumObject="";
    var index = -1;
    $scope.singleAlbumObject = "";
    var isMoreToLoad = true;
    var maxCount = 0;

    var onError = function (message) {
        $scope.isError = true;
        $scope.errorMessage = message;
        $scope.isMoreLoading = false;
    };

    $scope.closeSingleImageView = function () {
        $timeout(function () {
            $rootScope.Ui.turnOff("image");
        }, 100);
    };


    $scope.nextPhoto = function () {

        index++;
        if (index < albumPhotos.length) {
            if (index >= $scope.albumPhotos.length) {
                $scope.albumPhotos.push(albumPhotos[index]);
            }
            $scope.singleAlbumObject = albumPhotos[index];

        } else {
            if (isMoreToLoad) {
                index--;
                loadAlbumDetails();
            } else {
                index = -1;
                $scope.nextPhoto();
            }
        }
    };

    $scope.previousPhoto = function () {
        if (index >= 1) {
            index--;
        } else {
            index = albumPhotos.length;
            $scope.previousPhoto();
        }
        if (index >= 0) {
            $scope.singleAlbumObject = albumPhotos[index];

        }
    };

    var albumSuccess = function (response) {
        jitoEvents.triggerLoader(false);

        if (response && response.status && response.status === 200) {
            if (response.data) {

                $scope.albumObject = response.data;
                albumPhotos.push.apply(albumPhotos, response.data.photos);

                if (response.data.photos.length > 0) {
                    $scope.nextPhoto();
                    isMoreToLoad = true;
                } else {
                    isMoreToLoad = false;
                    index = -1;
                    $scope.nextPhoto();
                }

            }
            else {
                onError($translate.instant('ALBUM_LOADING_ERROR'));
            }
        } else {
            onError($translate.instant('ALBUM_LOADING_ERROR'));
        }
    };

    var albumError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.message) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.message);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        onError(errorMessage);
    };

    var loadAlbumDetails = function () {
        var path = jitoSettings.getAPIDomain() + jitoSettings.getAlbumDetails() + "albumId=" + $scope.albumID;
        currentPage++;

        if(currentPage > 1){
            jitoEvents.triggerAlbumViewPaginator();
        }

        jitoAjax.call({
            method: 'get',
            url: path,
            params: {
                page: currentPage
            },
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, albumSuccess, albumError);
    };

    index = parseInt($scope.currentIndex);
    currentPage = $scope.currentPage;
    albumPhotos = $scope.albumPhotos;
    $scope.singleAlbumObject = albumPhotos[index];
    //loadAlbumDetails();

    $scope.report = function(id){
        //POST- 1,COMMENT - 2,PHOTO - 3,USER - 4,BRAND - 5,EVENT - 6
        jitoEvents.triggerImageReport(id);
        jitoCommon.openReportModal();
    };


});