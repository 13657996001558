app.controller('PostController', function ($scope, $window, jitoUser, $compile, $interval, $route, $translate,
                                           $routeParams, jitoSettings, jitoStorage, $location, jitoAjax, jitoEvents,
                                           jitoValidations, jitoCommon, jitoHybrid, jitoPaths, $timeout, $rootScope) {
    "ngInject";
    //Initialization
    $scope.posts = [];
    $scope.singlePostArr = [];
    var currentPage = 0;
    var userDetails = jitoUser.getLoggedUser();
    $scope.userImage = userDetails.url;
    $scope.index = 0;
    $scope.myUserID = jitoUser.getUserID();
    $scope.modalActive = false;
    $scope.singlePost = false;
    var deletePostIndex = 0;
    $scope.isTimeLine = true;
    var isMoreToFetch = true;
    $scope.isViewMoreComments = true;
    var isMoreCommentsAvailable = true;
    $scope.edittingPost = null;
    $scope.editPostIndex = 0;
    $scope.isNewStories = false;
    $scope.isBackgroundProcessing = false;
    $scope.ajaxCall = false;
    $scope.isPopUpView = false;
    $scope.isProfileNotComplete = false;
    $scope.isPostsArticlesEmpty = false;

    var path;
    var promise;

    var redirectedPostID;
    var isFromNotifications = false;
    if (!jitoValidations.empty($.trim($routeParams.PostID))) {
        isFromNotifications = true;
        redirectedPostID = $.trim($routeParams.PostID);
    }

    path = jitoSettings.getAPIDomain() + jitoSettings.getUserPostList();

    if ($scope.myParent) {
        $scope.isTimeLine = false;
        switch ($scope.myParent.type) {
            case "USER_TIME_LINE":
                path = jitoSettings.getAPIDomain() + jitoSettings.getUserTimeLineAPI() + "/" + $scope.profileID;
                break;
            case "EVENT_TIME_LINE":
                path = jitoSettings.getAPIDomain() + jitoSettings.getEventTimeLineAPI() + "/" + eventID;
                break;
        }
    }

    $scope.turnOffPostModal = function () {
        $scope.modalActive = !$scope.modalActive;
        $scope.isViewMoreComments = true;
        $scope.isPopUpView = false;
        $timeout(function () {
            $rootScope.Ui.toggle("singlePost");
        }, 100);
    };

    $scope.loadMore = function () {
        if (isMoreToFetch) {
            listPosts();
        }
    };

    $scope.reloadPosts = function () {
        $scope.posts = [];
        $scope.singlePostArr = [];
        currentPage = 0;
        $scope.userImage = userDetails.url;
        $scope.index = 0;
        $scope.myUserID = jitoUser.getUserID();
        $scope.modalActive = false;
        $scope.singlePost = false;
        deletePostIndex = 0;
        $scope.isTimeLine = true;
        listPosts();
    };


    $scope.scrollTop = function(){
        $scope.isNewStories = false;
    };

    $scope.onNewStoriesClicked = function(){

        var elem = angular.element(document.getElementById('messagesContent'));
        var scrollableContentController = elem.controller('scrollableContent');
        scrollableContentController.scrollTo(0);
    };

    var listPostSuccess = function (response) {

        $scope.isMoreLoading = false;

        if (response && response.status && response.status === 200) {

            //This will run on the initial step while there are no local storage
            //is available
            if (jitoValidations.empty(jitoStorage.get('global_posts'))) {
                angular.forEach(response.data, function (data) {
                    $scope.posts.push(data);
                });
                jitoStorage.set('global_posts', JSON.stringify($scope.posts));

            } else {

                //This will run if local storage data is available
                $scope.isBackgroundProcessing = false;

                if (response.data.length > 0 && response.data[0].id != $scope.posts[0].id && currentPage == 1) {

                    if(document.getElementById('messagesContent').scrollTop >= 10){
                        $scope.isNewStories = true;
                    }
                }

                if (currentPage == 1) {
                    for (var i = 0; i < jitoStorage.get('global_posts').length; i++) {
                        $scope.posts[i] = response.data[i];
                    }

                    jitoStorage.set('global_posts', JSON.stringify($scope.posts));

                } else {
                    angular.forEach(response.data, function (data) {
                        $scope.posts.push(data);
                    });
                }
            }

            jitoEvents.triggerLoader(false);

            jitoCommon.prepareAnchorTags();

            if ($scope.posts.length < 1) {
                $scope.noPosts = true;
            }

            if (response.data.length < 1) {
                isMoreToFetch = false;
            } else {
                //Check if the request is from a notification and if so open the model
                if (isFromNotifications) {

                    isFromNotifications = false;
                    var post_id = redirectedPostID;
                    $scope.isViewMoreComments = false;
                    jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
                    var path = jitoSettings.getAPIDomain() + jitoSettings.getSinglePost() + post_id;
                    jitoAjax.call({
                        method: 'GET',
                        url: path,
                        timeout: jitoSettings.DEFAULT_TIME_OUT
                    }, singlePostSuccess, function (response) {
                        jitoEvents.triggerLoader(false);
                        jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response.data));
                    });

                }
            }

        } else {
            jitoEvents.triggerLoader(false);
            if (response.data && response.data.length < 1) {
                isMoreToFetch = false;
            }
        }

    };

    var listPostError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;
        $scope.isBackgroundProcessing = false;
        $scope.isPostError = true;
        $scope.postErrorMessage = errorMessage;
        onPostError(response);
    };

    var listPosts = function () {
        currentPage++;
        var data = {page: currentPage};
        var path = jitoSettings.getAPIDomain() + jitoSettings.getUserPostList();
        //Make the get request

        //Check for the existing posts array
        if (!jitoValidations.empty(jitoStorage.get('global_posts')) && currentPage == 1) {
            $scope.isBackgroundProcessing = true;
            //Set previous fetched data
            $scope.posts = jitoStorage.get('global_posts');
        } else {
            if ($scope.posts.length == 0 && !$scope.noPosts) {
                jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
            } else {
                $scope.isMoreLoading = true;
            }
        }

        jitoAjax.call({
            method: 'get',
            url: path,
            timeout: jitoSettings.DEFAULT_TIME_OUT,
            params: data
        }, listPostSuccess, listPostError);

    };

    $scope.countLikes = function (isLiked) {
        var like = this.post.is_liked;
        var path = "";
        if (like) {
            this.post.like_count = parseInt(this.post.like_count) - 1;
            this.post.is_liked = 1;
            path = jitoSettings.getAPIDomain() + jitoSettings.setPostLikesDelete();
        } else {
            this.post.like_count = parseInt(this.post.like_count) + 1;
            this.post.is_liked = 0;
            path = jitoSettings.getAPIDomain() + jitoSettings.setPostLikes();
        }

        var data = {object_type: 1, object_id: this.post.id};
        jitoAjax.call({
            method: 'POST',
            url: path,
            timeout: jitoSettings.DEFAULT_TIME_OUT,
            data: data
        });
    };

    var sharePostSuccess = function (response) {
        $scope.ajaxCall = false;
        jitoHybrid.popup($translate.instant('POST_SHARED_SUCCESS'));

        $timeout(function () {
            loadProfileDetails();
        }, 0);
    };

    var sharePostError = function(){
        $scope.ajaxCall = false;
    }

    $scope.sharePost = function () {
        $scope.ajaxCall = true;
        var path = jitoSettings.getAPIDomain() + jitoSettings.setSharePost();
        var id = 0;
        if (this.post.parent_id != 0) {
            id = this.post.parent_id;
        } else {
            id = this.post.id;
        }
        var data = {parent_id: id};
        jitoAjax.call({
            method: 'POST',
            url: path,
            timeout: jitoSettings.DEFAULT_TIME_OUT,
            data: data
        }, sharePostSuccess , sharePostError);
    };

    var sendComments = function (data, type, post, comment) {
        var path = jitoSettings.getAPIDomain() + jitoSettings.setComments();

        jitoAjax.call({
            method: 'POST',
            url: path,
            timeout: jitoSettings.DEFAULT_TIME_OUT,
            data: data
        }, function (response) {
            if (response && response.status && response.status === 200) {

                response.data.data.display_name = userDetails.display_name;
                response.data.data.url = $scope.userImage;
                response.data.data.child_comments = [];
                $scope.ajaxCall = false;   
                if (type == 1) {
                    post.comments.data.unshift(response.data.data);
                    post.comments_count = parseInt(post.comments_count) + 1;
                } else if (type == 2) {
                    comment.child_comments.unshift(response.data.data);
                }

                jitoCommon.prepareAnchorTags();

            }
        }, function (response) {
            $scope.ajaxCall = false;
            jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response.data));
        });
    };

    $scope.deleteComment = function (type, index) {
        var path = "";
        if (type == 1) {
            path = jitoSettings.getAPIDomain() + jitoSettings.setComments() + "/" + this.post.comments.data[index].id;
            this.post.comments.data.splice(index, 1);
            this.post.comments_count = parseInt(this.post.comments_count) - 1;
        } else if (type == 2) {
            path = jitoSettings.getAPIDomain() + jitoSettings.setComments() + "/" + this.comment.child_comments[index].id;
            this.comment.child_comments.splice(index, 1);
        }

        //Make the get request

        jitoAjax.call({
            method: 'DELETE',
            url: path,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, listDeleteCommentSuccess, listDeleteCommentError);

    };

    var listDeleteCommentSuccess = function (response) {

    }; 

    var listDeleteCommentError = function (response) {
        jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response));
    };

    $scope.addComments = function (type, text) {
        var commentText = text;
        var data = {};
        $scope.ajaxCall = true;
        if (commentText != null && commentText != '') {
            if (type == 1) {
                data = {
                    object_type: jitoSettings.OBJECT_TYPE_COMMENT,
                    object_id: this.post.id,
                    comment_text: commentText,
                    parent_id: 0
                }
            } else if (type == 2) {
                data = {
                    object_type: jitoSettings.OBJECT_TYPE_COMMENT,
                    object_id: this.comment.object_id,
                    comment_text: commentText,
                    parent_id: this.comment.id
                }
            }
            sendComments(data, type, this.post, this.comment);
        }
    };

    $scope.goToPage = function (userID, type) {


        if (parseInt(type) == 1) {
            $location.path(jitoPaths.USER_PROFILE + userID);
        } else {
            $location.path(jitoPaths.BRAND_PROFILE + userID);
        }

    };

    $scope.getFriendList = function (type, id) {


        $scope.LIST_TYPE = jitoSettings.LIST_STATE.LIKERS;
        $scope.LIST_ID = id;
        $scope.OBJECT_TYPE = type;
        $scope.MODAL_TITLE = $translate.instant('MODAL_DIALOG_FRIEND_LIST_TITLE');
        $scope.MODAL_NO_ITEMS = $translate.instant('MODAL_DIALOG_LIKERS_NOT_FOUND');
        jitoCommon.openModal();
    };

    $scope.listMoreComment = function () {
        if (isMoreCommentsAvailable) {
            if (isNaN($scope.singlePostArr[0].comment_page)) {
                $scope.singlePostArr[0].comment_page = 2;
            } else {
                $scope.singlePostArr[0].comment_page += 1;
            }

            var path = jitoSettings.getAPIDomain() + jitoSettings.getPostComments() + '1/' + $scope.singlePostArr[0].id;
            var data = {page: $scope.singlePostArr[0].comment_page};
            //Make the get request
            $scope.isMoreLoading = true;
            jitoAjax.call({
                method: 'get',
                url: path,
                timeout: jitoSettings.DEFAULT_TIME_OUT,
                params: data
            }, listPostCommentSuccess, listPostCommentError);

        }
    };

    var listPostCommentSuccess = function (response) {

        $scope.isMoreLoading = false;
        if (response && response.status && response.status === 200) {
            if (response.data && Array.isArray(response.data)) {
                angular.forEach(response.data, function (data) {
                    $scope.singlePostArr[0].comments.data.push(data);
                });

                if (response.data.length < 1) {
                    isMoreCommentsAvailable = false;
                }

            } else {

                $scope.singlePostArr[0].comments_count = 0;
            }
        }
    };

    var listPostCommentError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        $scope.isMoreLoading = false;

        onPostError(response);
    };

    var noPostsError = function () {
        $scope.noPosts = true;
    };

    var onPostError = function (response) {
        if (!($scope.posts.length > 0)) {
            noPostsError(response);

        }
        if (!(response.length > 0)) {
            isMoreToFetch = false;
        }
    };


    var singlePostSuccess = function (response) {

        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            $scope.singlePostArr[0] = response.data;
            jitoCommon.prepareAnchorTags();
            $scope.isPopUpView = true;
            $scope.openSinglePostModal();
        } else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    $scope.getSingleUserPost = function (postId, index) {

        $scope.index = index;

        isMoreCommentsAvailable = true;
        if (!$scope.isViewMoreComments) {
            $scope.isViewMoreComments = !$scope.isViewMoreComments;
            $timeout(function () {
                $rootScope.Ui.toggle("singlePost");
            }, 100);
            return;
        }

        $scope.isViewMoreComments = !$scope.isViewMoreComments;
        var post_id = this.post.id;
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        var path = jitoSettings.getAPIDomain() + jitoSettings.getSinglePost() + post_id;
        jitoAjax.call({
            method: 'GET',
            url: path,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, singlePostSuccess, function (response) {
            jitoEvents.triggerLoader(false);
            $scope.isViewMoreComments = true;
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        });
    };

    $scope.openSinglePostModal = function () {
        $timeout(function () {
            $rootScope.Ui.toggle("singlePost");
            this.modalActive = true;
        }, 100);
    };

    listPosts();

    //Add an timer to get posts time to time

    ////////////////////////////////////////////////////////////////////////

    //$scope.startAutoRefresher = function(){
    //
    //    //Stopping existing timer functions
    //    $scope.stopAutoRefresher();
    //
    //    promise = $interval(function() {
    //        //Call the refresh method to refresh news feed
    //        jitoAjax.call({
    //            method: 'get',
    //            url: jitoSettings.getAPIDomain() + jitoSettings.getUserPostList(),
    //            timeout: jitoSettings.DEFAULT_TIME_OUT,
    //            params: data
    //        }, listPostSuccess, listPostError);
    //
    //    }, jitoSettings.POSTS_UPDATE_INTERVAL);
    //
    //};
    //
    //$scope.stopAutoRefresher = function() {
    //    $interval.cancel(promise);
    //};
    //
    ////Destroy the refresher timer whenever a route change happened
    //$scope.$on('$destroy', function() {
    //    $scope.stopAutoRefresher();
    //});
    //
    //$scope.startAutoRefresher();

    ///////////////////////////////////////////////////////////////////////


    $scope.$on('triggerTimeLineLoader', function (event) {
        $scope.loadMore();
    });


    $scope.reportPostTimeLine = function (id) {
        //POST- 1,COMMENT - 2,PHOTO - 3,USER - 4,BRAND - 5,EVENT - 6
        $scope.REPORT_TYPE = 1;
        $scope.REPORT_ID = id;
        jitoCommon.openReportModal();
    };


    var deletePostSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.posts[deletePostIndex].isHide = true;
                var location = $location.path();
                if (location.indexOf('/time_line/') > -1 ){
                    $location.path('/user/time_line');
                }
                jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));

                $timeout(function () {
                    loadProfileDetails();
                }, 0);
            } else {
                jitoHybrid.popup($translate.instant('COMMON_NO_NETWORK'));
            }
        } else {
            jitoHybrid.popup($translate.instant('COMMON_NO_NETWORK'));
        }
    };
    var deletePostError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        jitoHybrid.popupError(errorMessage);

    };

    $scope.deletePost = function (postID, index) {
        deletePostIndex = index;
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);

        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getPostDeleteAPI() + "/" + postID,
            data: {
                _method: "DELETE"
            },
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, deletePostSuccess, deletePostError);
    };

    ///////////////////////////////////////////////////////////////////////
    // Edit post
    ///////////////////////////////////////////////////////////////////////

    $scope.editPost = function (id, index) {

        $scope.editPostIndex = index;
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        jitoAjax.call({
            method: 'GET',
            url: jitoSettings.getAPIDomain() + jitoSettings.getSinglePost() + id,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function (response) {

            jitoEvents.triggerLoader(false);
            if (response && response.status && response.status === 200) {
                $scope.edittingPost = response.data;
                $timeout(function () {
                    $rootScope.Ui.toggle("editpost");
                }, 100);
            } else {
                jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
            }

        }, function (response) {
            if (response.status > 200 && response.status < 500) {
                jitoEvents.triggerLoader(false);
                jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response.data));
            }
        });

    };

    var loadProfileDetails = function () {
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getUserProfileDetailsAPI(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function (response) {
            if (response && response.status && response.status === 200) {
                var user = response.data,
                    currentEmployment = 0,
                    experience = 0;

                angular.forEach(user.user_employment_details, function (value, index) {
                    if(value.employment_status == 2) {
                        currentEmployment += 1;
                    } else if(value.employment_status == 1) {
                        experience += 1;
                    }
                });

                if(user.type == 1) {
                    if (user.user_meta.about == '' || user.user_skills.length == 0 || user.user_meta.experience_year == 0 ||
                        user.user_meta.experience_year == null || user.user_study_place.length == 0 || currentEmployment == 0 || experience == 0) {
                        $scope.isProfileNotComplete = true;
                    } else {
                        $scope.isProfileNotComplete = false;
                    }
                }

                if(user.posts_count == 0 && user.articles_count == 0) {
                    $scope.isPostsArticlesEmpty = true;
                } else {
                    $scope.isPostsArticlesEmpty = false;
                }
            }
        }, function (response) {

        });
    };

    $timeout(function () {
        loadProfileDetails();
    }, 0);
});