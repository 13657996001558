/**
 * Created by thusitha_m on 1/26/2016.
 */

app.controller('SearchController', function (titleChange, $scope, $window, $routeParams, jitoValidations, jitoAjax,
                                             $translate, jitoCommon, jitoSettings, $location, jitoPaths,
                                             jitoEvents, jitoHybrid, jitoShared, jitoUser) {
    "ngInject";
    var term = $.trim($routeParams.SearchTerm);
    $scope.searchTerm = term;
    $scope.currentUserID = jitoUser.getLoggedUser() ? jitoUser.getUserID() : null;
    $scope.isLoading = false;

    //Title Change
    titleChange.setTitle('Search results for '+term);

    var searchSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isError = false;

        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.searchData = response.data;
            }
            else {
                onError($translate.instant('USER_PROFILE_LOADING_ERROR'));
            }
        } else {
            onError(jitoCommon.normalizeErrorMessages(response.data));
        }
    };

    var onError = function (message) {
        $scope.isError = true;
        $scope.errorMessage = message;
    };

    var searchError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        onError(errorMessage);
    };


    var loadSearchResults = function () {
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getGlobalSearchAPI(),
            params:{
              term : term
            },
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, searchSuccess, searchError);
    };

    loadSearchResults();

    $scope.goToPage = function(path){
      $location.path(path);
    };

    //$scope.$on('$destroy', function() {
    //    $scope.parentObject.searchText = null;
    //});

    $scope.goToMessage = function (userID, $event) {
        $event.stopPropagation();
        jitoShared.setMessageSenderID(userID);
        $location.path(jitoPaths.CONVERSATION);
    };

    $scope.addFriend = function (friendId, friendIndex, $event) {
        $event.stopPropagation();
        $scope.isLoading = true;

        jitoAjax.call({
            method: 'POST',
            data: {friend_id: friendId},
            url: jitoSettings.getAPIDomain() + jitoSettings.getFriendAdd(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function (response) {
            $scope.isLoading = false;

            $scope.searchData.users[friendIndex].friend_status = 0;
            $scope.searchData.users[friendIndex].friend_request_status.is_send = 1;
            $scope.searchData.users[friendIndex].friend_request_status.status = 0;
        }, function (response) {
            $scope.isLoading = false;

            if (response.status > 200 && response.status < 500 && response.data) {
                jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response.data));
            } else {
                jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
            }
        });
    };
});