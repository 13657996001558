/**
 * Created by thusitha_m on 1/17/2016.
 */

app.controller('EventFlyerController', function (titleChange, $scope, $routeParams, $rootScope, $timeout, $window, $location, $translate,
                                                 jitoCommon, jitoHybrid, jitoEvents, jitoShared, jitoSettings, jitoUser, jitoAjax, jitoValidations, jitoPaths) {
    "ngInject";
    $scope.eventSlug = $.trim($routeParams.EventSlug);
    $scope.eventID = null;
    $scope.event = {};
    $scope.isInFuture = true;
    $scope.isEventHappening = false;
    $scope.eventDate = 0;
    $scope.isTicketsAvaialable = false;
    $scope.membershipDiscount = 0;
    $scope.showTotalTicketPrice = 0;
    $scope.eventTickets = [];
    $scope.isTableOnly = 'false';
    $scope.isMoreSpeakersClicked = false;
    $scope.isMoreAgendaClicked = false;
    $scope.singleTicketPriceText = [];
    $scope.tableTicketPriceText = [];
    $scope.Math = window.Math;
    $scope.secondary_event_ticket_details = {
        buy_secondary_event_tickets: true,
        secondary_event_ticket_total: 0,
        secondary_event_ticket_discounts: 0,
        totalTickets: 0,
        singleTicketPrice: 0,
        available_tickets: 0
    };
    $scope.tickets_short_description = '';
    $scope.isCouponCodeApplied = false;
    $scope.coupon = {};

    var render = function(){

        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getEventFlyerDetails() + $scope.eventSlug,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function(response){
            try{

                //TODO: Must be encapsulated by another object
                $scope.event = response.data;
                $scope.tickets_short_description = $scope.event.event_details[0].tickets_short_desc;
                //Title Change
                titleChange.setTitle($scope.event.event_details[0].title);
                titleChange.setImage($scope.event.cover_image.url);
                titleChange.setDescription($scope.event.event_details[0].original_description);
                titleChange.setKeyword($scope.event.event_details[0].title);

                /**
                 * Keep an original copy of the response since
                 * we need it later to revert the changes between
                 * coupon code and the membership discount
                 */
                $scope.originalEvent = angular.copy(response.data);

                $scope.eventID = response.data.id;
                $scope.isLoaded = true;

                $scope.secondary_event_ticket_details.buy_secondary_event_tickets = ($scope.event.has_secondary_event == 1);

                var secondaryTicket = null;

                if($scope.event.has_secondary_event == 1 && $scope.event.secondary_event_details) {
                    angular.forEach($scope.event.secondary_event_details.event_tickets, function (secondary_event_ticket) {
                        if(secondary_event_ticket.id == $scope.event.secondary_event_ticket_type) {
                            secondaryTicket = secondary_event_ticket;
                            var available_tickets = (secondary_event_ticket.remaining_ticket_count - secondary_event_ticket.remaining_free_ticket_count);

                            $scope.secondary_event_ticket_details.available_tickets = available_tickets;

                            if(available_tickets <= 0) {
                                $scope.secondary_event_ticket_details.buy_secondary_event_tickets = false;
                            }
                        }
                    });
                }

                angular.forEach($scope.event.event_tickets, function(ticket){
                    ticket.splitDescription = ticket.description.split('\n');

                    ticket.remaining_ticket_count_actual = ticket.remaining_ticket_count;

                    if(ticket.ticket_type == 2){
                        ticket.remaining_ticket_count = (ticket.remaining_ticket_count - ticket.remaining_free_ticket_count) / ticket.seats_per_table;
                        ticket.remaining_ticket_count_actual = ticket.remaining_ticket_count;

                        if(ticket.remaining_ticket_count > 0) {
                            ticket.remaining_ticket_count = 1;
                        }
                    } else if(ticket.ticket_type == 1) {
                        ticket.remaining_ticket_count_actual = (ticket.remaining_ticket_count - ticket.remaining_free_ticket_count);
                    }

                    var tempCount = [];
                    for(var i=0; i< ticket.remaining_ticket_count; i++){
                        if(ticket.ticket_type == 1) {
                            if (i < 10) {
                                tempCount.push({
                                    id: i + 1,
                                    value: i + 1
                                });
                            } else {
                                break;
                            }
                        } else if(ticket.ticket_type == 2) {
                            tempCount.push({
                                id: 1,
                                value: 1
                            });
                        }
                    }

                    if(ticket.status==1 && ticket.remaining_ticket_count > 0){
                        $scope.isTicketsAvaialable = true;
                    }

                    ticket.count = tempCount;
                    ticket.selectedCount = 0;
                    ticket.total = 0;
                    ticket.isDisabled = false;

                    if(ticket.ticket_type == 1 && ticket.status == 1) {
                        $scope.singleTicketPriceText.push(ticket.name + ' Ticket - Day Event $' + ticket.price);

                        if($scope.event.has_secondary_event == 1 && $scope.event.secondary_event_details) {
                            var specialPrice = ((parseFloat(ticket.price)) + (parseFloat(secondaryTicket.price) - parseFloat($scope.event.secondary_event_discount)));
                            $scope.singleTicketPriceText.push('Day Event + ' + $scope.event.secondary_event_details.event_details[0].title + ' $' + specialPrice);
                        }
                    } else if(ticket.ticket_type == 2 && ticket.status == 1) {
                        $scope.tableTicketPriceText.push(ticket.name + ' Ticket - Day Event $' + ticket.price);

                        if($scope.event.has_secondary_event == 1 && $scope.event.secondary_event_details) {
                            var specialPrice = ((parseFloat(ticket.price)) + (ticket.seats_per_table * (parseFloat(secondaryTicket.price) - parseFloat($scope.event.secondary_event_discount))));
                            $scope.tableTicketPriceText.push('Day Event + ' + $scope.event.secondary_event_details.event_details[0].title + ' $' + specialPrice);
                        }
                    }
                });

                $scope.mainSponsor = [];
                $scope.secondarySponsors = [];
                angular.forEach($scope.event.sponsor, function(sponsor){
                    if(sponsor.type==1){
                        $scope.mainSponsor.push(sponsor);
                    } else{
                        $scope.secondarySponsors.push(sponsor);
                    }
                });

                if(moment($scope.event.event_date).valueOf() > moment().valueOf()) {
                    $scope.isInFuture = true;
                    $scope.eventDate = moment($scope.event.event_date).valueOf();
                } else if((moment($scope.event.event_date).valueOf() <= moment().valueOf()) &&
                    (moment($scope.event.event_end_date).valueOf() > moment().valueOf())) {
                    $scope.isInFuture = false;
                    $scope.isEventHappening = true;
                    $scope.eventDate = moment().valueOf() ;
                } else {
                    $scope.isInFuture = false;
                    $scope.eventDate = moment().valueOf() ;
                }

                $scope.eventTickets = $scope.event.event_tickets;

                sameHeightBoxes();
            } catch(ex){
                $scope.isLoaded = true;
            }
        }, function(error){

            $scope.isLoaded = false;
            var errorMessage = '';
            if (error.status > 200 && error.status < 500 && error.data) {
                errorMessage = jitoCommon.normalizeErrorMessages(error.data);
            } else {
                errorMessage = $translate.instant('COMMON_NO_NETWORK');
            }
            $scope.errorMessage = errorMessage;

        }, true);

    };

    render();

    $scope.totalTicketPrice = 0;
    $scope.sub_total = 0;
    $scope.finalPrice = 0;
    $scope.GST = jitoSettings.GST;
    $scope.totalGST = 0;

    /**
     * Calculate price of tickets when selecting
     * @param count
     * @param price
     * @param ticket
     */
    $scope.calculatePrice = function(count, price, ticket){
        if(ticket.total) {
            $scope.totalTicketPrice = $scope.totalTicketPrice - ticket.total;
        }
        ticket.total = count * price;
        $scope.totalTicketPrice = $scope.totalTicketPrice + ticket.total;

        if($scope.isLoggedIn && $scope.event && $scope.event.membership_discount>0){
            $scope.membershipDiscount = $scope.totalTicketPrice * ($scope.event.membership_discount*0.01);
            $scope.showTotalTicketPrice = $scope.totalTicketPrice - $scope.membershipDiscount;
            $scope.totalGST = $scope.showTotalTicketPrice * $scope.GST;
            // if australian only add gst
            //$scope.totalGST = 0;
            $scope.finalPrice = $scope.showTotalTicketPrice + $scope.totalGST;
        } else{

            if($scope.isCouponCodeApplied){
                $scope.membershipDiscount = $scope.totalTicketPrice * ($scope.event.membership_discount*0.01);
                $scope.showTotalTicketPrice = $scope.totalTicketPrice - $scope.membershipDiscount;
            } else{
                $scope.showTotalTicketPrice = $scope.totalTicketPrice;
            }

            $scope.totalGST = $scope.showTotalTicketPrice *  $scope.GST;
            // if australian only add gst
            //$scope.totalGST = 0;
            $scope.finalPrice = $scope.showTotalTicketPrice + $scope.totalGST;
        }

        if($scope.totalTicketPrice == 0) {
            $timeout(function () {
                //$scope.secondary_event_ticket_details.buy_secondary_event_tickets = false;
                $scope.secondary_event_ticket_details.secondary_event_ticket_total = 0;
                $scope.secondary_event_ticket_details.secondary_event_ticket_discounts = 0;
            }, 200);
        }
    };

    $scope.validateTicketsForPurchase = function (count, price, ticket, index) {
        if(ticket.ticket_type == 1) { // Regular ticket
            $scope.calculatePrice(count, price, ticket);
            $scope.isTableOnly = 'false';

            $timeout(function () {
                $scope.secondaryEventTicketsChanged();
            }, 50);
        } else if(ticket.ticket_type == 2) { // Table ticket
            $scope.isTableOnly = 'true';
            angular.forEach($scope.event.event_tickets, function(event_ticket, key) {
                if(key == index) {
                    $scope.calculatePrice(event_ticket.selectedCount, event_ticket.price, event_ticket);
                } else {
                    event_ticket.selectedCount = 0;
                    event_ticket.isDisabled = (count > 0);

                    $scope.calculatePrice(event_ticket.selectedCount, event_ticket.price, event_ticket);
                }
            });

            $timeout(function () {
                $scope.secondaryEventTicketsChanged();
            }, 100);
        }
    };

    $scope.removeTicketPurchase = function (ticket, index) {
        angular.forEach($scope.event.event_tickets, function(event_ticket){
            event_ticket.isDisabled = false;
        });

        $scope.event.event_tickets[index].selectedCount = 0;
        $scope.calculatePrice(ticket.selectedCount, ticket.price, ticket);
    };


    $scope.purchaseTickets = function(){

        $scope.isRequesting = true;
        var tickets = [];

        angular.forEach($scope.event.event_tickets, function(ticket){
            if(ticket.status==1 && ticket.remaining_ticket_count > 0 && ticket.selectedCount>0){
                tickets.push({
                    event_ticket_id: ticket.id,
                    amount : ticket.selectedCount,
                    ticket_price : ticket.price,
                    ticket_type : ticket.ticket_type,
                    row_total : ticket.total,
                    seats_per_table : ticket.seats_per_table
                });
            }
        });

        var secondary_event_tickets = [];

        if($scope.secondary_event_ticket_details.buy_secondary_event_tickets) {
            angular.forEach($scope.event.secondary_event_details.event_tickets, function (secondary_event_ticket) {
                if (secondary_event_ticket.status == 1 && (secondary_event_ticket.id == $scope.event.secondary_event_ticket_type)) {
                    secondary_event_tickets.push({
                        event_ticket_id: secondary_event_ticket.id,
                        amount: $scope.secondary_event_ticket_details.totalTickets,
                        ticket_price: (secondary_event_ticket.price - $scope.event.secondary_event_discount),
                        ticket_type: secondary_event_ticket.ticket_type,
                        row_total: $scope.secondary_event_ticket_details.secondary_event_ticket_total,
                        seats_per_table: secondary_event_ticket.seats_per_table
                    });
                }
            });
        }

        var data = {
            tickets : tickets,
            event_id: $scope.eventID,
            total: $scope.finalPrice,
            coupon_code_id : "",
            discount_price : 0,
            discount_percentage : 0,
            tax : $scope.totalGST,
            coupon_code: "",
            membership_discount : $scope.membershipDiscount,
            is_secondary_event_tickets_purchased: $scope.secondary_event_ticket_details.buy_secondary_event_tickets,
            secondary_event_tickets: secondary_event_tickets,
            secondary_event_id: $scope.event.secondary_event_id
        };

        if($scope.isCouponCodeApplied){
            data.membership_discount = 0;
            data.coupon_temp_usage_id = $scope.coupon.temp_id;
            data.coupon_code_id = $scope.coupon.id;
            data.discount_percentage = $scope.coupon.percentage;
            data.discount_price =  $scope.membershipDiscount;
            data.coupon_code =  $scope.coupon.coupon;
        }

        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getOrderCreationAPI(),
            data: data,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function(response){
            $scope.isRequesting = false;

            $timeout(function(){
                // $rootScope.Ui.toggle("purchaseTickets");
                $location.path("/checkout/" + response.data.data.id).search({tb: $scope.isTableOnly});
            }, 100);

        }, function(error){

            $scope.isRequesting = false;
            var errorMessage = '';
            if (error.status > 200 && error.status < 500 && error.data) {
                errorMessage = jitoCommon.normalizeErrorMessages(error.data);
            } else {
                errorMessage = $translate.instant('COMMON_NO_NETWORK');
            }
            $scope.errorMessage = errorMessage;

        }, $scope.isLoggedIn ? false : true);

    };

    $scope.openTicketDialog = function(){
        $scope.errorMessage = '';
        $timeout(function(){
            $rootScope.Ui.toggle("purchaseTickets");
        }, 100);
    };

    $scope.goToAttendeeList = function(id){
        //Set parameters and then open the modal dialog
        //Note: these parameters are directly visible to the modal controller



        $scope.LIST_TYPE = jitoSettings.LIST_STATE.USERS_ATTEND_IN_EVENT;
        $scope.LIST_ID =  id;
        $scope.MODAL_TITLE = $translate.instant('MODAL_DIALOG_EVENT_ATTENDEE_TITLE');
        $scope.MODAL_NO_ITEMS = $translate.instant('MODAL_DIALOG_NO_ATTENDEES_FOUND');

        jitoCommon.openModal();

    };
    $scope.openRegister = function(){
        $timeout(function(){
            $rootScope.Ui.toggle("purchaseTickets");
            jitoEvents.triggerFromTickets(true);
            $rootScope.Ui.toggle("signup");
        }, 100);
    };

    $scope.openLogin = function(){
        $timeout(function(){
            $rootScope.Ui.toggle("purchaseTickets");
            jitoEvents.triggerFromTickets(true);
            $rootScope.Ui.toggle("login");
        }, 100);
    };

    //membershipDiscount
    $scope.$on('loginService', function (event, state) {
        if(state){
            if($scope.event && $scope.event.membership_discount>0){
                $scope.calculatePriceAgain();
            }
        }
    });


    /**
     * Open coupon applying dialog
     */
    $scope.openCouponDialog = function(){

        $scope.couponErrorMessage = '';
        $scope.couponCode = '';
        $scope.isCouponRequesting = false;

        $timeout(function(){
            $rootScope.Ui.toggle("couponDialog");
        }, 100);
    };


    /**
     * Remove coupon code
     */
    $scope.removeCouponCode = function(){
        $scope.coupon = {};
        $scope.isCouponCodeApplied = false;
        /**
         * Revert coupon discount for the membership discount
         */
        $scope.event.membership_discount =  $scope.originalEvent.membership_discount;

        $scope.calculatePriceAgain();
        $scope.secondaryEventTicketsChanged();

    };

    /**
     * Function to calculate prices again
     */
    $scope.calculatePriceAgain = function(){

        /**
         * Check for login
         */
        if($scope.isLoggedIn){
            $scope.membershipDiscount = $scope.totalTicketPrice * ($scope.event.membership_discount*0.01);
            $scope.showTotalTicketPrice = $scope.totalTicketPrice - $scope.membershipDiscount;
            $scope.totalGST = $scope.showTotalTicketPrice *  $scope.GST;
            // if country is in the billling address australian only add gst
            //$scope.totalGST = 0;
            $scope.finalPrice = $scope.showTotalTicketPrice + $scope.totalGST;
        } else{

            if($scope.isCouponCodeApplied){
                $scope.membershipDiscount = $scope.totalTicketPrice * ($scope.event.membership_discount*0.01);
                $scope.showTotalTicketPrice = $scope.totalTicketPrice - $scope.membershipDiscount;
            } else{
                $scope.showTotalTicketPrice = $scope.totalTicketPrice;
            }
            $scope.totalGST = $scope.showTotalTicketPrice *  $scope.GST;
            // if australian only add gst
            //$scope.totalGST = 0;
            $scope.finalPrice = $scope.showTotalTicketPrice + $scope.totalGST;
        }


    };

    /**
     * Apply coupon code
     */
    $scope.applyCouponCode = function(isValid, coupon){

        if(isValid){
            $scope.isCouponRequesting = true;

            jitoAjax.call({
                method: 'post',
                url: jitoSettings.getAPIDomain() + jitoSettings.getCouponCodeAPI(),
                data:{
                    coupon_code : coupon,
                    event_id: $scope.eventID
                }
            }, function(response){

                $scope.isCouponRequesting = false;

                /**
                 * Retrieve coupon data and close the coupon code dialog
                 */
                $scope.coupon = {
                    percentage: response.data.data.percentage,
                    id: response.data.data.coupon_code_id,
                    temp_id: response.data.data.coupon_temp_usage_id,
                    coupon: response.data.data.coupon_code
                };

                $scope.isCouponCodeApplied = true;

                /**
                 * Set coupon discount fo the membership discount
                 */
                $scope.event.membership_discount =  $scope.coupon.percentage;

                /**
                 * Calculate prices again
                 */
                $scope.calculatePriceAgain();
                $scope.secondaryEventTicketsChanged();

                $timeout(function(){
                    $rootScope.Ui.toggle("couponDialog");
                }, 100);

            }, function(error){

                $scope.isCouponRequesting = false;
                var couponErrorMessage = '';
                if (error.status > 200 && error.status < 500 && error.data) {
                    couponErrorMessage = jitoCommon.normalizeErrorMessages(error.data);
                } else {
                    couponErrorMessage = $translate.instant('COMMON_NO_NETWORK');
                }
                $scope.couponErrorMessage = couponErrorMessage;

            });
        }
    };

    $scope.$watch("totalTicketPrice", function (newValue, oldValue) {
        $scope.sub_total = $scope.totalTicketPrice;
    });

    $scope.secondaryEventTicketsChanged = function () {
        $timeout(function () {
            if($scope.secondary_event_ticket_details.buy_secondary_event_tickets) {
                //$scope.secondary_event_ticket_details.totalTickets = 0;
                //$scope.secondary_event_ticket_details.singleTicketPrice = 0;

                angular.forEach($scope.event.event_tickets, function(ticket){
                    if(ticket.status == 1 && ticket.remaining_ticket_count > 0 && ticket.selectedCount>0) {
                        if(ticket.ticket_type == 1) {
                            $scope.secondary_event_ticket_details.totalTickets = ticket.selectedCount;
                        } else if(ticket.ticket_type == 2) {
                            $scope.secondary_event_ticket_details.totalTickets = (ticket.seats_per_table * ticket.selectedCount);
                        }
                    }
                });

                angular.forEach($scope.event.secondary_event_details.event_tickets, function(secondary_ticket){
                    if(secondary_ticket.id == $scope.event.secondary_event_ticket_type) {
                        $scope.secondary_event_ticket_details.singleTicketPrice = secondary_ticket.price;
                    }
                });

                $timeout(function () {
                    $scope.secondary_event_ticket_details.secondary_event_ticket_total =
                        ($scope.secondary_event_ticket_details.totalTickets * ($scope.secondary_event_ticket_details.singleTicketPrice - $scope.event.secondary_event_discount));
                    $scope.secondary_event_ticket_details.secondary_event_ticket_discounts = ($scope.secondary_event_ticket_details.totalTickets * $scope.event.secondary_event_discount);
                    $scope.sub_total = ($scope.totalTicketPrice + $scope.secondary_event_ticket_details.secondary_event_ticket_total);

                    if($scope.isCouponCodeApplied){
                        $scope.membershipDiscount = $scope.sub_total * ($scope.event.membership_discount*0.01);
                        $scope.totalGST = ($scope.sub_total - $scope.membershipDiscount) *  $scope.GST;
                        // if australian only add gst
                        //$scope.totalGST = 0;
                        $scope.finalPrice = ($scope.sub_total - $scope.membershipDiscount) + $scope.totalGST;
                    } else{
                        $scope.totalGST = $scope.sub_total *  $scope.GST;
                        // if australian only add gst
                        //$scope.totalGST = 0;
                        $scope.finalPrice = $scope.sub_total + $scope.totalGST;
                    }
                }, 200);
            } else {
                $scope.secondary_event_ticket_details.secondary_event_ticket_total = 0;
                $scope.secondary_event_ticket_details.secondary_event_ticket_discounts = 0;
                $scope.secondary_event_ticket_details.totalTickets = 0;
                $scope.secondary_event_ticket_details.singleTicketPrice = 0;
                $scope.sub_total = $scope.totalTicketPrice;

                if($scope.isCouponCodeApplied){
                    $scope.membershipDiscount = $scope.sub_total * ($scope.event.membership_discount*0.01);
                    $scope.totalGST = ($scope.sub_total - $scope.membershipDiscount) *  $scope.GST;
                    // if australian only add gst
                    //$scope.totalGST = 0;
                    $scope.finalPrice = ($scope.sub_total - $scope.membershipDiscount) + $scope.totalGST;
                } else{
                    $scope.totalGST = $scope.sub_total *  $scope.GST;
                    // if australian only add gst
                    //$scope.totalGST = 0;
                    $scope.finalPrice = $scope.sub_total + $scope.totalGST;
                }
            }
        }, 0);
    };

    $scope.moreSpeakersClicked = function (button) {
        if(button == 1) {
            $scope.isMoreSpeakersClicked = true;
        } else if(button == 2) {
            $scope.isMoreSpeakersClicked = false;
        }
    };

    $scope.moreAgendaClicked = function (button) {
        if(button == 1) {
            $scope.isMoreAgendaClicked = true;
        } else if(button == 2) {
            $scope.isMoreAgendaClicked = false;
        }
    };

    $scope.goToCauseLink = function () {
        var link = $scope.event.event_details[0].cause_link;
        var window = $window.open(link, '_blank');
        window.focus();
    };

    $scope.goToSponsor = function (link) {
        if(link) {
            var window = $window.open(link, '_blank');
            window.focus();
        }
    };

    var initNewsletterFormFields = function () {
        $scope.newsletter = {
            fname: '',
            lname: '',
            email: '',
            company: '',
            job: '',
            country: '',
            state: ''
        };
    };

    initNewsletterFormFields();

    $scope.newsletterSignup = function (isValid) {
        if(isValid) {
            $scope.isSubmitting = true;
            $scope.newsletterErrorMessage = '';
            $scope.newsletterSuccessMessage = '';

            jitoAjax.call({
                method: 'post',
                url: jitoSettings.getAPIDomain() + jitoSettings.getNewsletterSignupAPI(),
                data: $scope.newsletter
            }, function(response){
                $scope.isSubmitting = false;
                initNewsletterFormFields();
                $scope.newsletterSuccessMessage = response.data.message;
            }, function(error){
                $scope.isSubmitting = false;
                var couponErrorMessage = '';
                if (error.status > 200 && error.status < 500 && error.data) {
                    couponErrorMessage = jitoCommon.normalizeErrorMessages(error.data);
                } else {
                    couponErrorMessage = $translate.instant('COMMON_NO_NETWORK');
                }
                $scope.newsletterErrorMessage = couponErrorMessage;
            });
        }
    };

    $scope.currentYear = new Date().getFullYear();
});
