/**
 * Created by thusitha_m on 1/14/2016.
 */

app.factory('jitoPaths', function () {
    "ngInject";

    return {
        /**
         * Path variables
         */
        USER_HOME: '/user/home',
        USER_WELCOME: '/user/welcome',
        USER_LOGIN: '/user/login',
        USER_SIGNUP_USER: '/user/signup/user',
        USER_SIGNUP_BRAND: '/user/signup/company',

        USER_EVENT: '/event',
        USER_BLANK: '/user/blank',
        USER_EVENT_LIST: '/events',
        USER_FRIENDS: '/user/friends',

        USER_FP_STEP_ONE: '/user/forgot_password/step_one',
        USER_FP_STEP_TWO: '/user/forgot_password/step_two',

        USER_PROFILE: '/profile/',
        USER_PROFILE_EDIT: '/profile-edit',
        USER_ABOUT: '/profile/:ProfileID/about',

        USER_BRAND_EDIT: '/company-edit',

        BRAND_PROFILE: '/company/profile/',
        PROFILE_LIST: '/profile-list/',
        SETTINGS: '/settings',

        INFORMATION: '/information',

        BRAND_LIST: '/companies',

        ALBUM_VIEW: '/album-view/',
        ALBUM_LIST: '/album-list',
        ALBUM_ADD_PHOTOS: '/album-add-photos',

        ARTICLE_EDIT: '/articles/edit/',
        ARTICLE_PUBLISH_LIST: '/articles',
        ARTICLE_DRAFT_LIST: '/articles/draft',

        USER_TIME_LINE: '/user/time_line',

        SEARCH: '/search/',
        INTEREST: '/interest/',
        MESSAGES: '/messages',
        CONVERSATION: '/conversation',
        NOTIFICATIONS: '/notifications',

        WALKTHROUGH: '/walkthrough'
    };
});