/**
 * Created by thusitha_m on 1/15/2016.
 */

app.controller('EventListController', function ($scope, $window, moment, jitoUser, $routeParams, $translate, jitoSettings, $location, jitoAjax, jitoEvents, jitoValidations, jitoCommon, jitoHybrid, jitoPaths) {
    "ngInject";
    //Initialization
    $scope.noItemsError = false;
    $scope.locationError = false;
    $scope.isLoadingStarted = true;
    $scope.events = [];
    $scope.isUpcomingEventsSelected = true;

    var searchTerm="", isMoreToFetch = true, subscribedArrayIndex = null, subscribedState = null, currentPage = 1;

    $scope.goToEventPage = function (id, isPaid) {

        if(isPaid){
            $location.path('/event/' + id);
        } else{
            $location.path('/event/' + id);
        }
    };

    $scope.eventSold = function(con, id){
        //if (!con){
          $scope.goToEventPage(id);
        //}
    }

    $scope.openMap = function (lat, long) {
        jitoHybrid.openMap(lat, long);
    };

    $scope.goToWebsite = function(link){
        jitoHybrid.openExternalLink(link);
    };

    $scope.redirectPage = function(userID, type){
        if(type==1){
            $location.path(jitoPaths.USER_PROFILE+userID);
        } else{
            $location.path(jitoPaths.BRAND_PROFILE+userID);
        }
    };

    $scope.goToAttendeeList = function(id){
        //Set parameters and then open the modal dialog
        //Note: these parameters are directly visible to the modal controller
        $scope.LIST_TYPE = jitoSettings.LIST_STATE.USERS_ATTEND_IN_EVENT;
        $scope.LIST_ID =  id;
        $scope.MODAL_TITLE = $translate.instant('MODAL_DIALOG_EVENT_ATTENDEE_TITLE');
        $scope.MODAL_NO_ITEMS = $translate.instant('MODAL_DIALOG_NO_ATTENDEES_FOUND');

        jitoCommon.openModal();

    };

    ///////////////////////////////////////////////////////////////////////
    //RSVP Status
    ///////////////////////////////////////////////////////////////////////

    var rsvpStatusSuccess = function (response) {
        $scope.events[subscribedArrayIndex].isSubscribing = false;
        if (response && response.status === 200 && response.data) {
            $scope.events[subscribedArrayIndex].isRightProcessing = false;
            $scope.events[subscribedArrayIndex].isLeftProcessing = false;
            jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));
            setProperties(subscribedState, subscribedArrayIndex, false, null);

        } else {
            if (response && response.data && response.status > 200) {
                jitoHybrid.popup($translate.instant('EVENTS_SUBSCRIPTION_ERROR'));
            }
        }
    };

    var rsvpStatusError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        $scope.events[subscribedArrayIndex].isSubscribing = false;
        jitoHybrid.alert(errorMessage);
        $scope.events[subscribedArrayIndex].isRightProcessing = false;
        $scope.events[subscribedArrayIndex].isLeftProcessing = false;
    };

    var rsvpEvent = function (eventID, status) {
        $scope.events[subscribedArrayIndex].isSubscribing = true;
        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getEventRsvpAPI(),
            data: {eventId: eventID, rsvpStatus: status},
            timeout: jitoSettings.LOGIN_REQUEST_TIMEOUT
        }, rsvpStatusSuccess, rsvpStatusError, false);
    };

    ///////////////////////////////////////////////////////////////////////

    var setEventData = function (isLoading, item, i, isLeftDisabled, isAttending,
                                 isNotAttending, leftButtonText, rightButtonText, isRightDisabled) {
        if (isLoading) {
            item.isLeftDisabled = isLeftDisabled;
            item.isAttending = isAttending;
            item.isNotAttending = isNotAttending;
            item.leftButtonText = leftButtonText;
            item.rightButtonText = rightButtonText;
            item.isRightDisabled = isRightDisabled;
        } else {
            $scope.events[i].isLeftDisabled = isLeftDisabled;
            $scope.events[i].isAttending = isAttending;
            $scope.events[i].isNotAttending = isNotAttending;
            $scope.events[i].leftButtonText = leftButtonText;
            $scope.events[i].rightButtonText = rightButtonText;
            $scope.events[i].isRightDisabled = isRightDisabled;
        }
    };

    var setProperties = function (value, i, isLoading, item) {
        switch (value) {
            case jitoSettings.EVENT_STATE.PENDING://1
                setEventData(isLoading, item, i, true, true, false,
                    $translate.instant('EVENTS_PENDING'), $translate.instant('EVENTS_I_NOT_ATTEND'), false);
                break;
            case jitoSettings.EVENT_STATE.APPROVED://2
                setEventData(isLoading, item, i, true, true, false,
                    $translate.instant('EVENTS_I_ATTEND'), $translate.instant("EVENTS_I_NOT_ATTEND"), false);
                break;
            case jitoSettings.EVENT_STATE.REJECTED://3
                item.iRejected = true;
                item.alertText = $translate.instant("EVENTS_REJECTED");
                break;
            case jitoSettings.EVENT_STATE.NOT_GOING://4
                setEventData(isLoading, item, i, false, false, true,
                    $translate.instant('EVENTS_I_ATTEND'), $translate.instant("EVENTS_I_NOT_ATTEND"), true);
                break;
            default:
                setEventData(isLoading, item, i, false, false, false,
                    $translate.instant('EVENTS_I_ATTEND'), $translate.instant("EVENTS_I_NOT_ATTEND"), false);
                break;
        }
    };

    /**
     * This method will trigger if the events list request is successful
     * @param response
     */
    var listEventsSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;

        if (response && response.status && response.status === 200) {
            if (response.data && response.data.events) {
                if (Array.isArray(response.data.events) && response.data.events.length > 0) {

                    for (var i = 0; i < response.data.events.length; i++) {

                        //Set the time format according to the date difference
                        moment(response.data.events[i].event_date).diff(moment.now(), 'days') >= 7 ?
                            response.data.events[i].isWithinWeek = false :
                            response.data.events[i].isWithinWeek = true;

                        //TODO: Put this location validation inside a service
                        //validate address
                        response.data.events[i].location = '';
                        if( !jitoValidations.empty( response.data.events[i].address_1)){
                            response.data.events[i].location +=  response.data.events[i].address_1;
                        }
                        if( !jitoValidations.empty( response.data.events[i].address_2)){
                            response.data.events[i].location += ", "+  response.data.events[i].address_2;
                        }
                        if( !jitoValidations.empty( response.data.events[i].city)){
                            response.data.events[i].location += ", "+  response.data.events[i].city;
                        }
                        if(!jitoValidations.empty(response.data.events[i].location)){
                            response.data.events[i].isLocationSet = true;
                        } else{
                            response.data.events[i].isLocationSet = false;
                        }

                        //Set environment according to the user attending state
                        setProperties(response.data.events[i].is_attending, i, true, response.data.events[i]);
                    }
                    $scope.events.push.apply($scope.events, response.data.events);
                }
                else {
                    onError(response);
                }
            }
            else {
                onError(response);
            }
        } else {
            onError(response);
        }
    };

    //Handle left button click event
    $scope.leftClick = function (state, arrayIndex, eventIndex) {
        subscribedArrayIndex = arrayIndex;
        $scope.events[subscribedArrayIndex].isLeftProcessing = true;
        switch (state) {
            case jitoSettings.EVENT_STATE.NOT_GOING://4
                subscribedState = '1';
                rsvpEvent(eventIndex, 1);
                break;
            default:
                subscribedState = '1';
                rsvpEvent(eventIndex, 1);
                break;
        }
    };

    //Handle right button click event
    $scope.rightClick = function (state, arrayIndex, eventIndex) {
        subscribedArrayIndex = arrayIndex;
        $scope.events[subscribedArrayIndex].isRightProcessing = true;
        switch (state) {
            case jitoSettings.EVENT_STATE.APPROVED://2
                subscribedState = '4';
                rsvpEvent(eventIndex, 4);
                break;
            default:
                subscribedState = '4';
                rsvpEvent(eventIndex, 4);
                break;
        }
    };

    var noEventsError = function () {
        $scope.noEvents = true;
    };

    var onError = function (response) {
        if (!($scope.events.length > 0)) {
            noEventsError(response);
        }
        if (!(response.data.events.length > 0)) {
            isMoreToFetch = false;
        }
    };

    //Reset the controller
    var reset = function () {
        $scope.locationError = false;
        isMoreToFetch = true;
        $scope.noEvents = false;
        $scope.events.length = 0;
    };

    /**
     * This method will trigger if there is a error with the list events request
     * and display the error.
     * @param response
     */
    var listEventsError = function (response) {
        var errorMessage = '';
        jitoEvents.triggerLoader(false);
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        $scope.locationError = true;
        $scope.locationErrorMessage = errorMessage;
    };

    /**
     * This method will list all the events nearby
     * It will send the data to the server and call the relevant callback.
     * @param page
     * @param term
     * @param lat
     * @param long
     */
    $scope.listEvents = function (page, lat, long, term) {
        var path = jitoSettings.getAPIDomain() + jitoSettings.getEventsList();

        if ($scope.events.length < 1) {
            jitoEvents.triggerLoader(true, $translate.instant('EVENTS_LOADING_EVENTS'), true);
        }

        jitoAjax.call({
            method: 'get',
            url: path,
            params:{
                page: page,
                lat: lat,
                long: long,
                term: term
            },
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, listEventsSuccess, listEventsError);

    };

    //Load all upcoming events
    var loadUpcomingEvents = function (reset) {
        $scope.isUpcomingEventsSelected = true;
        (reset) ? currentPage = 1 : currentPage += 1;
        $scope.listEvents(currentPage);
    };

    var loadNearbyEvents = function (reset) {

        // This method accepts a Position object, which contains the
        // current GPS coordinates
        if ($scope.events.length < 1) {
            jitoEvents.triggerLoader(true, $translate.instant('EVENTS_LOADING_EVENTS'), true);
        }

        var onLocationSuccess = function (position) {
            jitoEvents.triggerLoader(false);
            $scope.isUpcomingEventsSelected = false;
            (reset) ? currentPage = 1 : currentPage += 1;
            $scope.listEvents(currentPage, position.coords.latitude, position.coords.longitude);

        };
        // onError Callback receives a PositionError object
        function onLocationError(error) {
            jitoEvents.triggerLoader(false);
            $scope.locationError = true;
            switch (error.code) {
                case 1:
                    $scope.locationErrorMessage = $translate.instant('EVENTS_LOCATION_PERMISSION_DENIED');
                    break;
                case 2:
                    $scope.locationErrorMessage = $translate.instant('EVENTS_LOCATION_POSITION_UNAVAILABLE');
                    break;
                case 3:
                    $scope.locationErrorMessage = $translate.instant('EVENTS_LOCATION_TIMEOUT');
                    break;
            }
            $scope.$apply();
        }

        navigator.geolocation.getCurrentPosition(onLocationSuccess, onLocationError, {timeout: jitoSettings.LOCATION_TIME_OUT});
    };

    $scope.loadUpcomingEvents = function () {
        reset();
        loadUpcomingEvents(true);
    };

    //Load nearby events
    $scope.loadNearbyEvents = function () {
        reset();
        $scope.isUpcomingEventsSelected = false;
        loadNearbyEvents(true);
    };

    $scope.loadMoreEvents = function () {
        if (isMoreToFetch) {
            $scope.isMoreLoading = true;
            $scope.isUpcomingEventsSelected ? loadUpcomingEvents(false) : loadNearbyEvents(false);
        }
    };

    if(jitoValidations.empty($.trim($routeParams.SearchTerm))){
        loadUpcomingEvents(true);
    } else{
        searchTerm = $.trim($routeParams.SearchTerm);
        //Search
        currentPage = 1;
        $scope.listEvents(currentPage, null,null,searchTerm);
    }

});