/**
 * Created by pathum
 */

app.controller('ClaimTicketController', function (titleChange, $scope, jitoPaths, $window, jitoUser, $location, jitoStorage, jitoShared, jitoCommon, $translate, jitoEvents, jitoAjax,
                                                  jitoSettings, jitoHybrid, jitoCamera, jitoValidations, $routeParams, $timeout) {

    "ngInject";
    $scope.statuses = jitoSettings.RELATIONSHIP_STATUS;
    $scope.subTotal = 0;
    $scope.GST = jitoSettings.GST;
    $scope.token = $location.search().token ? $location.search().token : '';
    $scope.orderData = null;
    $scope.eventId;
    $scope.eventSlug;
    $scope.orderDetails = {};
    $scope.subDetailCount = 0 ;
    $scope.pdfUrl = '';
    $scope.ticketData = null;
    $scope.rooms = {};
    $scope.sessionSelectionError = "";
    $scope.allTicketData = null;

    $scope.personalData = {
        'first_name': '',
        'last_name': '',
        'job_title': '',
        'company': '',
        'email': '',
        'dietary_requirements':'',
        'event_date':'',
        'event_end_date':'',
        'title':'',
        'location':'',
        'order_id': '',
        'ticket_id':'',
        'token':'',
        'deadline':'',
        'attend_status': 0,
        'sessions': []
    };

    //Title Change
    titleChange.setTitle('Claim');

    if (jitoUser.isLogged()) {
        $("#jt-top-bar-section").css({
            'visibility': 'hidden',
            'display': 'none'
        });
    }


    /**
     * data loading success
     * @param response
     */
    var loadingSuccess = function (response) {
        var errorMessage = '';

        $scope.isLoading = false;

        $scope.ticketData = response.data.user_details;
        $scope.allTicketData = response.data;

        if(!$scope.ticketData){
            $scope.errorMessage = $translate.instant('Sorry, The ticket has been cancelled or deleted. Please contact administrator for more details.');
        }
        else{
            if (response && response.status && response.status === 200) {

                if (response.data) {
                    $scope.personalData.first_name = response.data.user_details.first_name;
                    $scope.personalData.last_name = response.data.user_details.last_name;
                    $scope.personalData.job_title = response.data.user_details.job_title;
                    $scope.personalData.company = response.data.user_details.company;
                    $scope.personalData.email = response.data.user_details.email;
                    $scope.personalData.dietary_requirements = response.data.user_details.dietary_requirements;
                    $scope.personalData.attend_status = response.data.user_details.attend_status;

                    $scope.personalData.event_date = response.data.event_details.event_date;
                    $scope.personalData.event_end_date = response.data.event_details.event_end_date;
                    $scope.personalData.title = response.data.event_details.title;
                    $scope.personalData.location = response.data.address;
                    $scope.personalData.deadline = response.data.event_details.deadline_altered;

                    $scope.personalData.token = response.data.event_details.attendee_token;
                    $scope.personalData.order_id = response.data.event_details.order_header_id;
                    $scope.personalData.ticket_id = response.data.event_details.id;
                    $scope.personalData.sessions = response.data.sessions;
                    $scope.rooms = response.data.rooms;

                }
                else {
                    response($translate.instant('COMMON_NO_NETWORK'));
                }
            } else {
                response($translate.instant('COMMON_NO_NETWORK'));
            }
        }


    };

    var loadTicketData = function (token) {

        $scope.isLoading = true;
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getTicketDetailsAPI() + token,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, loadingSuccess, loadingError);

    };

    /**
     * Load ticket details
     * @param response
     */
    loadTicketData($scope.token);


    var loadingError = function(){
        var errorMessage = '';
        $scope.isLoading = false;

        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        $scope.errorMessage = errorMessage;
    }

    var ticketUpdateSuccess = function(response){
        if (response && response.data && response.status == 200) {
            $scope.isLoading = false;

            $window.location.href = response.data.data.ticket_link;

            $scope.successMessage = response.data.data.msg;

            $timeout(function() {
                loadTicketData($scope.token);
            }, 100);
        } else {
            ticketUpdateError(response);
        }
    }

    var ticketUpdateError = function(response){
        var errorMessage = '';
        $scope.isRequesting = false;

        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        } else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        $scope.errorMessage = errorMessage;
    }

    /**
     * ticket update
     * @param response
     */
    $scope.updateTicketInfo = function (isValid, eventType) {

        if (isValid) {
            var sessions = $scope.personalData.sessions;
            var sessionErrorCount = 0;
            $scope.sessionSelectionError = "";

            angular.forEach(sessions, function(value, key) {
                if(value.is_default == 0 && value.is_selected == null) {
                    sessionErrorCount += 1;
                } else {
                    if(value.is_default == 1) {
                        value.is_selected = 1;
                    } else {
                        value.is_selected = (value.is_selected) ? value.is_selected : 1;
                    }
                }
            });

            if(sessionErrorCount > 0) {
                $scope.sessionSelectionError = $translate.instant('TICKET_SESSION_SELECTION_ERROR');

                return false;
            }

            var genDetail ={
                "first_name": $scope.personalData.first_name,
                "last_name": $scope.personalData.last_name,
                "job_title": $scope.personalData.job_title,
                "company": $scope.personalData.company,
                "email": $scope.personalData.email,
                "dietary_requirements": $scope.personalData.dietary_requirements,
                "sessions": sessions,
                "room": ($scope.rooms.event_rooms.rooms) ? $scope.rooms.event_rooms.rooms.id : null,
                "host_event": ($scope.rooms.event_rooms.rooms) ? $scope.rooms.event_rooms.rooms.event_id : null
            };

            if(eventType == 2) {
                genDetail.is_second_event = 'true';
            }

            $scope.isLoading = true;
            jitoAjax.call({
                method: 'PUT',
                url: jitoSettings.getAPIDomain() + jitoSettings.putTicketDetailsAPI() + $scope.personalData.token,
                data: genDetail,
                timeout: 60000 //jitoSettings.LOGIN_REQUEST_TIMEOUT

            }, ticketUpdateSuccess, ticketUpdateError);
        }
    };

    $scope.updateSessionSelection = function(session_id, session) {
        var sessionErrorCount = 0;

        angular.forEach($scope.personalData.sessions, function(event_session, event_session_key) {
            if(event_session.id == session_id) {
                $scope.personalData.sessions[event_session_key].is_selected = session;
            }

            if($scope.personalData.sessions[event_session_key].is_default == 0 &&
                $scope.personalData.sessions[event_session_key].is_selected == null) {
                sessionErrorCount += 1;
            }
        });

        $timeout(function () {
            $scope.sessionSelectionError = (sessionErrorCount > 0) ? $translate.instant('TICKET_SESSION_SELECTION_ERROR') : "";
        }, 100);
    };

    $scope.currentYear = new Date().getFullYear();
});