/**
 * Created by thusitha_m on 1/12/2016.
 */

app.factory('jitoHelper', function () {
    "ngInject";
    return {

        replaceChar: function (str, str1, str2, ignore) {
            return str.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,
                "\\$&"), (ignore ? "gi" : "g")), (typeof (str2) == "string") ? str2.replace(/\$/g, "$$$$") : str2);
        },

        replaceSpecialChars: function (value) {
            value = this.replaceChar(value, "|", "<br>");
            value = this.replaceChar(value, "\u0026amp;bull;", "&bull;");
            value = this.replaceChar(value, "\u0026amp;", "&");
            value = this.replaceChar(value, "\u0026reg;", "®");
            value = this.replaceChar(value, "\u0026copy;", "&copy;");
            value = this.replaceChar(value, "&quot;", "\"");
            return this.replaceChar(value, "&apos;", "'");
        }

    };
});