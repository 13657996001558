app.controller('JobsController', function (titleChange, $scope, $routeParams, $rootScope, $timeout, $window, $location,
                                           $translate, jitoCommon, jitoHybrid, jitoEvents, jitoShared, jitoSettings,
                                           jitoUser, jitoAjax, jitoValidations, jitoPaths) {
    "ngInject";

    //Title Change
    titleChange.setTitle('Jito Jobs');
    titleChange.setImage('');
    titleChange.setDescription('Jito Jobs');
    titleChange.setKeyword('Jito Jobs');
});