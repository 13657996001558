/**
 * Created by thusitha_m on 1/15/2016.
 */

app.controller('ProfileEditController', function ($scope, jitoPaths, $window, jitoUser, $location, jitoStorage, jitoCommon, $translate, jitoEvents, jitoAjax, jitoSettings, jitoHybrid, jitoCamera, jitoValidations) {
    //TODO: Move this photo capture block to a service ;) /////////////////////////////////
    // Profile picture selecting section
    "ngInject";
    $scope.profile = {};
    $scope.places = [];
    $scope.education = [];
    $scope.professionalSkills = [];
    $scope.pastJobs = [];
    $scope.interests = [];
    $scope.quotes = [];
    $scope.birthDate;
    $scope.genders = jitoSettings.GENDER;
    $scope.statuses = jitoSettings.RELATIONSHIP_STATUS;
    //Show general details on start up
    $scope.isGeneralDetails = true;
    $scope.isBooting = true;
    $scope.confirmPassword = "";
    $scope.newPassword = "";
    $scope.isProfileImageEmpty = false;
    $scope.isDebut = jitoStorage.get('IS_NEW');
    var isStartingUp = true;
    var logUser = jitoUser.getLoggedUser();
    /////////////////////////////////////////////////////////////////////////////


    $scope.skip = function () {
        if (jitoStorage.get('IS_NEW')) {
            jitoStorage.set('IS_NEW', false);
            $location.path(jitoPaths.WALKTHROUGH);
        }
    };


    var constructor = function () {

        var now = new Date();
        //$scope.birthDate = new Date(1900, 0, 1);
        $scope.minDate = new Date(1900, 0, 1);
        $scope.maxDate = now;
    };

    $scope.pictureAdded = function ($file) {
        var fileReader = new FileReader();
        fileReader.onload = function (event) {
            profileImgUploadSuccess(event.target.result);
        };
        fileReader.readAsDataURL($file.file);
    };


    var profileImgUploadSuccess = function (imageData) {
        jitoEvents.triggerLoader(false);
        var profilePictureUploadSuccess = function (response) {
            jitoEvents.triggerLoader(false);
            if (response && response.status && response.status === 200) {
                $scope.profile.profile_pic_id.url = response.data.image_path;
                $scope.isProfileImageEmpty = false;
                //Save user object in the local db
                //jitoUser.setLoggedUser(response.data.user_object);
                logUser.url = response.data.image_path;
                jitoUser.setLoggedUser(logUser);
                jitoEvents.triggerLogin(true);
                jitoHybrid.popup(response.data.message);
            }
        };

        var profilePictureUploadError = function (response) {
            var errorMessage = '';
            if (response.status > 200 && response.status < 500 && response.data) {
                errorMessage = jitoCommon.normalizeErrorMessages(response.data);
            } else {
                errorMessage = $translate.instant('COMMON_NO_NETWORK');
            }

            jitoEvents.triggerLoader(false);
            jitoHybrid.popupError(errorMessage, 0);
        };

        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);

        jitoSettings.getImageData(imageData).then(function (image) {
            jitoAjax.call({
                method: 'post',
                data: {
                    "profile_picture": image
                },
                url: jitoSettings.getAPIDomain() + jitoSettings.getUserProfileImageUpdateAPI(),
                timeout: jitoSettings.PICTURE_TIME_OUT
            }, profilePictureUploadSuccess, profilePictureUploadError);

        });

    };


    /////////////////////////////////////////////////////////////////////////

    var setDefaultDetails = function () {

        //$scope.places = //Still not implemented;
        $scope.education = $scope.profile.user_study_place;
        $scope.professionalSkills = $scope.profile.professional_skills;
        $scope.pastJobs = $scope.profile.past_jobs;
        $scope.interests = $scope.profile.user_hobby;
        $scope.quotes = $scope.profile.quotes;

        getLocationData();

        //Set relationship status
        $scope.relationStatus = {
            "id": $scope.profile.user_meta.relationship_status,
            "value": jitoSettings.RELATIONSHIP_STATUS[$scope.profile.user_meta.relationship_status].value
        };

        //Set gender status
        $scope.genderStatus = {
            "id": $scope.profile.user_meta.gender,
            "value": jitoSettings.GENDER[$scope.profile.user_meta.gender].value
        };
    };

    /////////////////////////////////////////////////////////////////////////
    //Get location data

    $scope.updateCityList = function (countryCode) {

        if (jitoValidations.empty(countryCode)) {
            $scope.isLocationEmpty = true;
        } else {
            $scope.isLocationEmpty = false;
        }

        updateCityList(countryCode);
    };

    var updateCityList = function (countryCode) {

        if (!jitoValidations.empty($scope.profile.user_meta.country_code) && isStartingUp) {
            countryCode = $scope.profile.user_meta.country_code;
        }

        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getCityAPI() + countryCode,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function (response) {
            if (response && response.status && response.status === 200) {

                $scope.cityList = response.data;

                if ($scope.profile.user_meta.city_id && $scope.cityList[$scope.profile.user_meta.city_id]) {
                    $scope.cityValue = "" + $scope.profile.user_meta.city_id;
                } else {
                    $scope.cityValue = "" + Object.keys($scope.cityList)[0];
                }

                isStartingUp = false;

            }
        }, function (response) {
            //Error in city list getting
            var errorMessage = '';
            if (response.status > 200 && response.status < 500 && response.data) {
                errorMessage = jitoCommon.normalizeErrorMessages(response.data);
            } else {
                errorMessage = $translate.instant('COMMON_NO_NETWORK');
            }
            jitoHybrid.popupError(errorMessage);
        }, false);
    };

    var countryListSuccess = function (response) {
        if (response && response.status && response.status === 200) {
            $scope.countryList = response.data;
            for (first in $scope.countryList) {

                if (!jitoValidations.empty($scope.profile.user_meta.country_code) && isStartingUp) {
                    $scope.countryValue = $scope.profile.user_meta.country_code;
                } else {
                    $scope.countryValue = first;
                }
                updateCityList($scope.countryValue);
                break;
            }

        }
    };

    var countryListError = function (response) {
        //Error in country list getting
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        } else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.popupError(errorMessage);

    };

    var getLocationData = function () {
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getCountriesAPI(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, countryListSuccess, countryListError, false);
    };


    /////////////////////////////////////////////////////////////////////////
    // Load Profile Data on first time
    /////////////////////////////////////////////////////////////////////////

    var profileGetSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.profile = response.data;

                $('#jt-editUserProfile-input-brandName_value').val($scope.profile.user_meta.company);

                if (jitoValidations.empty($scope.profile.user_meta.user_dob)) {
                    $scope.birthDate = "";
                } else {
                    $scope.birthDate = new Date($scope.profile.user_meta.user_dob);
                }

                setDefaultDetails();
                $scope.isBooting = false;

            } else {
                onError($translate.instant('PROFILE_LOADING_ERROR'));
            }
        } else {
            onError($translate.instant('PROFILE_LOADING_ERROR'));
        }
    };

    var profileGetError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        } else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        onError(errorMessage);
        $scope.isBooting = false;
    };

    var loadProfileDetails = function () {
        jitoEvents.triggerLoader(true, $translate.instant('PROFILE_LOADING_MESSAGE'), true);
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getUserProfileDetailsAPI(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, profileGetSuccess, profileGetError);
    };

    var onError = function (message) {
        $scope.isError = true;
        $scope.errorMessage = message;
    };

    /////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    constructor();
    //load profile data
    loadProfileDetails();


    //Add new place function
    $scope.addNewPlace = function (newPlace) {
        var isContains = false;
        if ($scope.places.length == 0) {
            $scope.places.push({
                "lived_place": newPlace
            });
        } else {
            for (var i = 0; i < $scope.places.length; i++) {
                if ($scope.places[i].lived_place == newPlace) {
                    jitoHybrid.popupError($translate.instant("PROFILE_EDIT_NEW_PLACE_ERROR"));
                    isContains = true;
                    break;
                }
            }
            if (!isContains) {
                $scope.places.push({
                    "lived_place": newPlace
                });
            }
        }
        $scope.newPlace = "";
    };

    //Add new education function
    $scope.addNewEducationPlace = function (newEducationPlace) {
        var isContains = false;
        if ($scope.education.length == 0) {
            $scope.education.push({
                "study_place": newEducationPlace
            });
        } else {
            for (var i = 0; i < $scope.education.length; i++) {
                if ($scope.education[i].study_place == newEducationPlace) {
                    jitoHybrid.popupError($translate.instant("PROFILE_EDIT_EDUCATION_ERROR"));
                    isContains = true;
                    break;
                }
            }
            if (!isContains) {
                $scope.education.push({
                    "study_place": newEducationPlace
                });
            }
        }
        $scope.newEducationPlace = "";
    };

    //Add new skill function
    $scope.addNewSkill = function (newSkill) {
        var isContains = false;
        if ($scope.professionalSkills.length == 0) {
            $scope.professionalSkills.push({
                "skill": newSkill
            });
        } else {
            for (var i = 0; i < $scope.professionalSkills.length; i++) {
                if ($scope.professionalSkills[i].skill == newSkill) {
                    jitoHybrid.popupError($translate.instant("PROFILE_EDIT_SKILLS_ERROR"));
                    isContains = true;
                    break;
                }
            }
            if (!isContains) {
                $scope.professionalSkills.push({
                    "skill": newSkill
                });
            }
        }
        $scope.newSkill = "";
    };

    //Add new past job function
    $scope.addNewPastJob = function (newJobTitle, newJobCompany) {
        var isContains = false;
        if (jitoValidations.empty(newJobCompany)) {
            newJobCompany = "";
        }

        if ($scope.pastJobs.length == 0) {
            $scope.pastJobs.push({
                "job_title": newJobTitle,
                "company": newJobCompany
            });
        } else {
            for (var i = 0; i < $scope.pastJobs.length; i++) {
                if ($scope.pastJobs[i].job_title == newJobTitle && $scope.pastJobs[i].company == newJobCompany) {
                    jitoHybrid.popupError($translate.instant("PROFILE_EDIT_PAST_JOBS_ERROR"));
                    isContains = true;
                    break;
                }
            }
            if (!isContains) {
                $scope.pastJobs.push({
                    "job_title": newJobTitle,
                    "company": newJobCompany
                });
            }
        }

        $scope.newPastJobTitle = "";
        $scope.newPastJobCompany = "";
    };

    //Add new past job function
    $scope.addNewLike = function (newLike) {
        var isContains = false;

        if ($scope.interests.length == 0) {
            $scope.interests.push({
                "hobby": newLike
            });
        } else {
            for (var i = 0; i < $scope.interests.length; i++) {
                if ($scope.interests[i].hobby == newLike) {
                    jitoHybrid.popupError($translate.instant("PROFILE_EDIT_INTERESTS_ERROR"));
                    isContains = true;
                    break;
                }
            }
            if (!isContains) {
                $scope.interests.push({
                    "hobby": newLike
                });
            }
        }
        $scope.newLike = "";
    };


    //Add new Quote function
    $scope.addNewQuote = function (newQuote) {
        var isContains = false;
        if ($scope.quotes.length == 0) {
            $scope.quotes.push({
                "quote": newQuote
            });
        } else {
            for (var i = 0; i < $scope.quotes.length; i++) {
                if ($scope.quotes[i].quote == newQuote) {
                    jitoHybrid.popupError($translate.instant("PROFILE_EDIT_QUOTES_ERROR"));
                    isContains = true;
                    break;
                }
            }
            if (!isContains) {
                $scope.quotes.push({
                    "quote": newQuote
                });
            }
        }
        $scope.newQuote = "";
    };


    var profileUpdateSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        if (response && response.status === 200) {



            //Save user object in the local db
            // console.log(JSON.stringify(response.data.user_object));
            jitoUser.setLoggedUser(response.data.user_object);


            if (jitoStorage.get('IS_NEW')) {

                jitoStorage.set('IS_NEW', false);
                $location.path(jitoPaths.WALKTHROUGH);

            } else {
                message = jitoCommon.normalizeErrorMessages(response.data);
                jitoHybrid.popup(message, 0);
            }

        } else {
            if (response && response.data && response.status > 200) {
                message = jitoCommon.normalizeErrorMessages(response.data);
                jitoHybrid.alert(message, 0);
            } else {
                jitoHybrid.alert($translate.instant('COMMON_NO_NETWORK'), 0);
            }
        }
    };

    var profileUpdateError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        } else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        jitoEvents.triggerLoader(false);
        jitoHybrid.alert(errorMessage, 0);
    };


    //Validate brands search field
    $scope.$on('brandsSearch', function (event, searchTerm) {
        if (jitoValidations.empty($('#jt-editUserProfile-input-brandName_value').val())) {
            $scope.isBrandEmpty = true;
        } else {
            $scope.isBrandEmpty = false;
        }
    });


    $scope.updateUserInfo = function (isValid) {

        var brandName = "",
            brandID = "";

        if ($scope.profile.profile_pic_id.url == jitoSettings.NO_PROFILE_IMAGE) {
            $scope.isProfileImageEmpty = true;
            return;
        } else {
            $scope.isProfileImageEmpty = false;
        }


        if ($scope.brand && ($scope.brand.originalObject.name == $('#jt-editUserProfile-input-brandName_value').val())) {

            brandName = $('#jt-editUserProfile-input-brandName_value').val();
            brandID = $scope.brand.originalObject.id;

        } else {
            brandName = $('#jt-editUserProfile-input-brandName_value').val();
            if ($scope.profile.user_meta.company != $('#jt-editUserProfile-input-brandName_value').val()) {
                brandID = null;
            } else {
                brandID = $scope.profile.user_meta.company_id;
            }
        }

        if (jitoValidations.empty($scope.countryValue)) {
            $scope.isLocationEmpty = true;
            return;
        }

        if (jitoValidations.empty($('#jt-editUserProfile-input-brandName_value').val())) {
            $scope.isBrandEmpty = true;
            return;
        }

        var dateOfBirth = "";

        if (jitoValidations.empty($scope.birthDate)) {
            dateOfBirth = null;

        } else {
            dateOfBirth = $scope.birthDate.getFullYear() + "-" + parseInt($scope.birthDate.getMonth() + 1) + "-" + $scope.birthDate.getDate();
        }


        if (!jitoValidations.empty($scope.newSkill)) {
            $scope.professionalSkills.push({
                "skill": $scope.newSkill
            });
        }

        if (!jitoValidations.empty($scope.newPastJobTitle)) {

            if (jitoValidations.empty($scope.newPastJobCompany)) {
                $scope.newPastJobCompany = "";
            }
            $scope.pastJobs.push({
                "job_title": $scope.newPastJobTitle,
                "company": $scope.newPastJobCompany
            });
        }

        if (!jitoValidations.empty($scope.newEducationPlace)) {
            $scope.education.push({
                "study_place": $scope.newEducationPlace
            });
        }

        if (!jitoValidations.empty($scope.newLike)) {
            $scope.interests.push({
                "hobby": $scope.newLike
            });
        }

        if (!jitoValidations.empty($scope.newQuote)) {
            $scope.quotes.push({
                "quote": $scope.newQuote
            });
        }


        if (isValid && ($scope.newPassword === $scope.confirmPassword)) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);

            jitoAjax.call({
                method: 'post',
                url: jitoSettings.getAPIDomain() + jitoSettings.getUserProfileUpdateAPI(),
                data: {
                    "email": $scope.profile.email,
                    "new_password": $scope.newPassword,
                    "current_password": $scope.currentPassword,
                    "confirm_password": $scope.confirmPassword,
                    "first_name": $scope.profile.user_meta.first_name,
                    "last_name": $scope.profile.user_meta.last_name,
                    "about": $scope.profile.user_meta.about,
                    "current_job": $scope.profile.user_meta.current_job,
                    "company": brandName,
                    "company_id": brandID,
                    "city_id": $scope.cityValue,
                    "relationship_status": $scope.relationStatus.id,
                    "country_code": $scope.countryValue,
                    "user_dob": dateOfBirth,
                    "contact_email": $scope.profile.user_meta.contact_email,
                    "contact_phone_no": $scope.profile.user_meta.contact_phone_no,
                    "gender": $scope.genderStatus.id,
                    "past_jobs": $scope.pastJobs,
                    "professional_skills": $scope.professionalSkills,
                    "user_study_place": $scope.education,
                    "quotes": $scope.quotes,
                    "user_hobby": $scope.interests
                },
                timeout: jitoSettings.LOGIN_REQUEST_TIMEOUT
            }, profileUpdateSuccess, profileUpdateError);

            $scope.newSkill = "";
            $scope.newPastJobTitle = "";
            $scope.newPastJobCompany = "";
            $scope.newEducationPlace = "";
            $scope.newLike = "";
            $scope.newQuote = "";
        }
    };

    $scope.checkPassword = function () {
        $scope.userUpdateForm.cPassword.$error.dontMatch = ($scope.newPassword != $scope.confirmPassword);
    };


});