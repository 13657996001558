/**
 * Created by thusitha_m on 1/12/2016.
 */

app.controller('BrandSignUpController', function ($scope, $translate,$location, $timeout, $rootScope,
                                                  jitoPaths, jitoEvents, jitoAjax, jitoSettings, jitoHybrid, jitoCommon, jitoValidations, jitoStorage, jitoUser) {
    "ngInject";
    //Set init variables for profile picture browse
    var profilePicture = null;
    var deviceID = null;
    $scope.isProfileImageEmpty = true;
    $scope.isLocationEmpty = true;
    $scope.isBrandEmpty = true;

    $scope.isInBrandSignup = true;

    //////////////////////////////////////////////////////////
    // Profile picture selecting section

    $scope.pictureAdded = function($file){
        var fileReader = new FileReader();
        fileReader.onload = function (event) {

            jitoSettings.getImageData(event.target.result).then(function (image) {
                profileImgUploadSuccess(image);
            });

        };
        fileReader.readAsDataURL($file.file);
    };

    var profileImgUploadSuccess = function(imageData) {
        //$scope.$apply();
        var image = document.getElementById('jt-joinBrand-img-profilePic');
        image.src = imageData;
        profilePicture = imageData;
        $scope.profilePictureStatus = "true";
        $("#jt-joinBrand-btn-join").focus();
        $scope.isProfileImageEmpty = false;
    };

    var  profileImgUploadFail = function(message) {
        if(profilePicture == null){}
        $("#jt-joinBrand-btn-join").focus();
    };

    ///////////////////////////////////////////////////////////

    $scope.validateFields = function (isValidForm) {

        if (jitoValidations.empty(profilePicture)) {
            $scope.isProfileImageEmpty = true;
        } else {
            $scope.isProfileImageEmpty = false;
        }
        
        if (jitoValidations.empty(profilePicture)) {
            return;
        }

        if (isValidForm) {

             //Generate a unique device id and store it inside local storage
            if (jitoValidations.empty(jitoStorage.get('DEVICE_ID'))) {
                deviceID = jitoCommon.generateHash();
                jitoStorage.set('DEVICE_ID', deviceID);
            } else {
                deviceID = jitoStorage.get('DEVICE_ID');
            }
            
            signUp({
                brand_name: $scope.brand.name,
                email: $scope.brand.email,
                profile_picture: profilePicture,
                password: $scope.brand.password,
                device_id: deviceID
                /*country_code: $scope.countryValue,
                city_id: $scope.cityValue*/
            });
        }
    };

    var signUp = function (userObject) {
        jitoEvents.triggerLoader(true, $translate.instant('SIGNUP_MESSAGE'), true);
        $scope.isRequesting = true;
        jitoAjax.call({
            method: 'post',
            data: JSON.stringify(userObject),
            url: jitoSettings.getAPIDomain() + jitoSettings.getBrandSignupAPI(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, signUpSuccess, signUpError);
    };

    var signUpSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isRequesting = false;
        if (response && response.status && response.status === 200 && response.data) {


            var user = response.data.auth;
            jitoEvents.triggerLogin(true);

            $timeout(function(){
                $rootScope.Ui.toggle("brandSignup");
            }, 100);

            jitoUser.setLoggedUser(user);
            jitoUser.setPrivacyStatus(user.privacy);
            jitoUser.setNotificationStatus(user.notifications_status);
            jitoUser.setEmailStatus(user.email_notifications_status);
            jitoUser.setUserType(user.type);

            if (jitoValidations.empty(user.last_login)) {
                jitoStorage.set("IS_NEW", true);
            } else {
                jitoStorage.set("IS_NEW", false);
            }
            $location.path(jitoPaths.USER_TIME_LINE);

            /*jitoHybrid.alert(jitoCommon.normalizeErrorMessages(response.data), 0);
            $timeout(function(){
                $rootScope.Ui.toggle("brandSignup");
                $rootScope.Ui.toggle("login");
            }, 100);*/

        } else {
            showErrorMessage(response);
        }
    };

    var signUpError = function (response) {
        showErrorMessage(response);
    };

    var showErrorMessage = function (response) {
        var errorMessage = '';
        $scope.isRequesting = false;
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        jitoEvents.triggerLoader(false);
        jitoHybrid.alert(errorMessage, 0);
    };

    $scope.goToTerms = function(){
        $scope.TYPE = jitoSettings.INFO_STATE.TERMS;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_TERMS_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_TERMS_DESC');
        jitoCommon.openInfoModal();
    };

    $scope.goToAbout = function(){
        $scope.TYPE =jitoSettings.INFO_STATE.ABOUT;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_ABOUT_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_ABOUT_DESC');
        jitoCommon.openInfoModal();
    };

    $scope.goToPrivacyPolicy = function(){
        $scope.TYPE = jitoSettings.INFO_STATE.PRIVACY;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_PRIVACY_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_PRIVACY_DESC');
        jitoCommon.openInfoModal();
    };

    /////////////////////////////////////////////////////////////////////////
    //Get location data

    $scope.updateCityList = function (countryCode) {

        if (jitoValidations.empty(countryCode)) {
            $scope.isLocationEmpty = true;
        } else {
            $scope.isLocationEmpty = false;
        }

        updateCityList(countryCode);
    };

    $scope.cityValidation = function () {

    };

    var updateCityList = function (countryCode) {

        $scope.isRequesting = true;
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getUserCityAPI() + countryCode,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function (response) {
            if (response && response.status && response.status === 200) {
                $scope.isRequesting = false;
                $scope.cityList = response.data.data;
                if (! $.isEmptyObject($scope.cityList) && !$scope.cityList.hasOwnProperty(4694)) {
                    $scope.cityValue = ""+Object.keys($scope.cityList)[0];
                } else {
                    $scope.cityValue = "";
                    $scope.cityList = {"": "-"};
                }

                isStartingUp = false;
            }
        }, function (response) {
            //Error in city list getting
            var errorMessage = '';
            $scope.isRequesting = false;
            if (response.status > 200 && response.status < 500 && response.data) {
                errorMessage = jitoCommon.normalizeErrorMessages(response.data);
            } else {
                errorMessage = $translate.instant('COMMON_NO_NETWORK');
            }
            jitoHybrid.popupError(errorMessage);
        }, false);
    };

    var countryListSuccess = function (response) {
        if (response && response.status && response.status === 200) {
            $scope.countryList = response.data.data;
            for (first in $scope.countryList) {

                $scope.countryValue = first;
                updateCityList($scope.countryValue);
                break;
            }

        }
    };

    var countryListError = function (response) {
        //Error in country list getting
        var errorMessage = '';
        $scope.isRequesting = false;
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        } else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.popupError(errorMessage);

    };

    var getLocationData = function () {
        $scope.isRequesting = true;
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getUserCountriesAPI(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, countryListSuccess, countryListError, false);
    };

    getLocationData();

});