/**
 * Created by prasanna_j on 21-Jan-2016.
 */

app.controller('AlbumListController', function ($scope, $routeParams, $window, $translate, $location,
                                                jitoCommon, jitoHybrid, jitoEvents, jitoShared, jitoSettings, jitoUser, jitoAjax, jitoValidations, jitoPaths) {
    "ngInject";
    var userID = $.trim($routeParams.UserID);
    $scope.userID = userID;
    //Initialization
    $scope.noAlbums = false;
    $scope.isLoadingStarted = true;
    $scope.albumError = false;
    $scope.albums = [];
    $scope.isMe = false;

    //Set current USer ID in shared memory
    jitoShared.setCurrentUserID(userID);

    if (userID == jitoUser.getUserID()) {
        $scope.isMe = true;
    } else {
        $scope.isMe = false;
    }

    /**
     * This method will list all the albums
     * It will send the data to the server and call the relevant callback.
     * @param page
     * @param term
     */
    $scope.listAlbums = function (page, term) {
        var path = jitoSettings.getAPIDomain() + jitoSettings.getAlbumList() + "/" + userID;

        //Make the get request
        if ($scope.albums.length < 1) {
            jitoEvents.triggerLoader(true, $translate.instant('ALBUMS_LOADING_ALBUMS'), true);
        }

        jitoAjax.call({
            method: 'get',
            url: path,
            timeout: jitoSettings.DEFAULT_TIME_OUT,
            params: {
                page: page,
                term: term
            }
        }, listAlbumsSuccess, listAlbumsError);

    };

    /**
     * This method will trigger if the albums list request is successful
     * @param response
     */
    var listAlbumsSuccess = function (response) {

        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;

        if (response && response.status && response.status === 200) {
            if (response.data && response.data.Albums) {
                if (Array.isArray(response.data.Albums) && response.data.Albums.length > 0) {
                    $scope.albums.push.apply($scope.albums, response.data.Albums);

                    if (!(response.data.Albums.length > 0)) {
                        isMoreToFetch = false;
                    }
                }
                else {
                    onError(response);
                }
            }
            else {
                onError(response);
            }
        } else {
            onError(response);
        }
    };

    var currentPage = 0,
        isMoreToFetch = true;

    /**
     * This method will trigger if there is a error with the list albums request
     * and display the error.
     * @param response
     */
    var listAlbumsError = function (response) {

        var errorMessage = '';
        jitoEvents.triggerLoader(false);
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        $scope.albumError = true;
        $scope.albumErrorMessage = errorMessage;
    };

    var noAlbumsError = function () {
        $scope.noAlbums = true;
    };

    var onError = function (response) {
        if (!($scope.albums.length > 0)) {
            noAlbumsError(response);
        }
        if (!(response.data.Albums.length > 0)) {
            isMoreToFetch = false;
        }
    };

    //Load all albums
    var loadAlbums = function () {
        currentPage += 1;
        $scope.listAlbums(currentPage);
    };

    $scope.loadMoreAlbums = function () {
        if (isMoreToFetch) {
            $scope.isMoreLoading = true;
            loadAlbums();
        }
    };

    ///////////////////////////////////////////////////////////////////////
    //Load albums by default
    loadAlbums();
    ///////////////////////////////////////////////////////////////////////

    $scope.goToPage = function (albumID) {
        $location.path(jitoPaths.ALBUM_VIEW + userID + "/" + albumID);
    };

});