app.controller('MainController', function (titleChange, $timeout,  $route, jitoEvents, SharedState, jitoShared,
                                           jitoHybrid, jitoCommon, $scope, jitoValidations, $translate, jitoStorage,
                                           jitoAjax, $location, $window, jitoPaths, jitoSettings, jitoUser, $rootScope, $interval) {
    "ngInject";
    //Hide headers and footers initially at application startup
    $scope.isLoggedIn = false;
    $scope.isPublicPage = false;
    $scope.isFromTickets = false;
    $scope.isMobile = isMobile;

    //This is required to callback from a child controller
    $scope.parentObject = {};
    $scope.isLandscape = true;
    var isSearched = false;
    var lastPage = "";
    var push;

    $scope.parentObject.likes = 0;
    $scope.parentObject.comments = 0;
    $scope.parentObject.requests = 0;
    $scope.parentObject.messages = 0;
    $scope.parentObject.newsfeed = 0;
    $scope.parentObject.total = 0;
    $scope.parentObject.unread = 0;
    $scope.parentObject.pending_requests = 0;

    var promise;
    $scope.isSideBar = false;
    $scope.mobileView = false;
    $scope.staticMenu = false;
    $scope.isSearchBar = false;
    $scope.parentObject.searchText = "";
    $scope.displayName = 'User';
    $scope.pageNumber = null;

    $scope.currentLoggedUser = jitoUser.getLoggedUser() ? jitoUser.getLoggedUser() : null;
    $scope.currentLoggedUserType = jitoUser.getLoggedUser() ? jitoUser.getLoggedUser().type : null;

    $scope.$on('loginService', function(event, message) {
        $scope.currentLoggedUser = jitoUser.getLoggedUser() ? jitoUser.getLoggedUser() : null;
        $scope.currentLoggedUserType = jitoUser.getLoggedUser() ? jitoUser.getLoggedUser().type : null;
    });

    //Title change
    $scope.title =titleChange;
    //Control the behavior of header and footer bars...
    //Listen into the log in event and show if the event is successful
   // titleChange.setMeta();

    $scope.$on('loginService', function (event, state) {
        $scope.isLoggedIn = state;

        if (state) {
            $scope.profilePic = jitoUser.getLoggedUser() ? jitoUser.getLoggedUser().url : '';
            jitoEvents.triggerFetchNotifications();
            $scope.displayName = jitoUser.getLoggedUser() ? jitoUser.getLoggedUser().display_name : 'User';
        }

        if (!state) {
            $scope.parentObject.searchText = null;
            $scope.parentObject = {};
        }
    });

    /**
     * Ticket popup status
     */
    $scope.$on('onFromTickets', function(event, state){
        $scope.isFromTickets = state;
    });

    // Check the route change the highlight the current menu
    $scope.$on('changeMenu', function(event, pageNumber){
        $scope.pageNumber = pageNumber;
    });


    /**
     * On page status changed
     */
    $scope.$on('onPageStatusChanged', function (event, state) {
        $scope.isPublicPage = state;
    });


    $scope.goToTimeline = function(){
        $location.path('/user/time_line');
    };

    $scope.goBack = function () {
        if (jitoCommon.isProfileUpdated()) {
            if ($.inArray($location.path(), ['/connect', '/connect/search', '/connect/search/:Term', '/connect/:Term', '/connect/requests', '/connect/requests/outgoing']) >= 0) {
                $location.path('/user/home');
            }
            else {
                $window.history.back();
            }
        }
    };

    //Show hide back button according to the screen
    $scope.$on('backButtonService', function (event, state) {
        $scope.isBackAvailable = state;
    });

    //Control the loader
    //Listen into the loader service events
    $rootScope.$on('$locationChangeStart', function (event) {
        $scope.isBackAvailable = $.inArray($location.path(), ['/user/welcome', '/user/home']) === -1;
        $timeout(function() {
            setAdsWidth(); // This function is defined in desktop.blade.php
            sameHeightBoxes(); // This function is defined in desktop.blade.php
            setMessageSidebarHeight();
        }, 100);
    });

    $scope.goToPage = function (path) {
        if (jitoCommon.isProfileUpdated()) {
            $location.path(path);
            $route.reload();
        }
    };


    //Control the loader
    //Listen into the loader service events
    $scope.$on('loaderService', function (event, state, message, fullscreen) {

        if (state) {
            $scope.loaderMessage = message;
            $scope.loading = true;
        } else {
            $scope.loaderMessage = null;
            $scope.loading = false;
        }

    });

    //Listen into the notification decrease service
    $scope.$on('messagesTrigger', function (event) {
        $location.path('/messages');
    });

    //Listen into the notification decrease service
    $scope.$on('triggerLogout', function (event) {
        jitoHybrid.setBadgeNumber(0, push);
    });


    //Listen into the messages redirect service
    $scope.$on('notificationDecreaser', function (event) {

        $scope.parentObject.total = 0;
        $scope.parentObject.likes = 0;
        $scope.parentObject.comments = 0;
        $scope.parentObject.requests = 0;

        jitoHybrid.setBadgeNumber($scope.parentObject.total, push);

    });

    $scope.searchGlobal = function (term) {
        if (jitoCommon.isProfileUpdated()) {
            if (term != "") {
                isSearched = true;
                lastPage = $location.path();
                $location.path(jitoPaths.SEARCH + term);
            } else {
                $location.path('/user/home');
                isSearched = false;
            }
        }
    };

    $scope.DEVICE_TYPE = DEVICE_TYPE;

    ////////////////////////////////////////////////////////////////////

    $scope.openNotifications = function () {
        $scope.parentObject.total = 0;
        if (jitoCommon.isProfileUpdated()) {
            $timeout(function () {
                $rootScope.Ui.toggle("notify");
            }, 100);
        }
    };

    $scope.hideMenuModal = function () {
        $timeout(function () {
            $rootScope.Ui.turnOff("menuModal");
        }, 0);
    };

    $scope.togglePopup = function () {

        if ($scope.ShowNotifications == false) {
            $scope.ShowNotifications = true;
            promise = $timeout(function () {
                $scope.ShowNotifications = false;
            }, 3000);
        } else {
            $timeout.cancel(promise);
            $scope.ShowNotifications = false;
        }
    };

    $scope.$on('counterUpdateService', function (event, likes, comments,
                                                 requests, messages, total) {

        $scope.parentObject.likes = likes;
        $scope.parentObject.comments = comments;
        $scope.parentObject.requests = requests;
        $scope.parentObject.messages = messages;
        $scope.parentObject.newsfeed = parseInt(likes) + parseInt(comments);
        $scope.parentObject.total = total;

        jitoHybrid.setBadgeNumber(parseInt($scope.parentObject.total) + parseInt($scope.parentObject.messages), push);

    });

    $scope.$on('fetchNotifications', function (event) {
        if (jitoUser.isLogged()) {
            getNotificationCount();
        }
    });

    //Get notification count
    var notificationGetSuccess = function (response) {
        if (response && response.status === 200 && response.data) {
            $scope.parentObject.likes = response.data.likes;
            $scope.parentObject.comments = response.data.comment;
            $scope.parentObject.requests = response.data.friend_request;
            $scope.parentObject.messages = response.data.message;
            $scope.parentObject.unread = response.data.unread;
            $scope.parentObject.pending_requests = response.data.pending_requests;
            $scope.parentObject.newsfeed = parseInt(response.data.likes) + parseInt(response.data.comment);
            $scope.parentObject.total = parseInt(response.data.likes) + parseInt(response.data.comment) +
                parseInt(response.data.friend_request);

            jitoHybrid.setBadgeNumber(parseInt($scope.parentObject.total) + parseInt($scope.parentObject.messages), push);
        }
    };

    var notificationGetError = function (response) {
        //An error occurred during notification counter fetching
    };

    var getNotificationCount = function () {
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getNotificationGroupingAPI(),
            timeout: jitoSettings.LOGIN_REQUEST_TIMEOUT
        }, notificationGetSuccess, notificationGetError);
    };

    if (jitoUser.isLogged()) {
        
        getNotificationCount();

        // $timeout(function () {
        //    getNotificationCount();
        // }, 30000);

        // Update the notification counts every 10 seconds
        $interval(function () {
            jitoEvents.triggerFetchNotifications();
        }, 10000);
    }

    $scope.$on('updateNotifications', function(event){
        getNotificationCount();
    });

    $scope.newPicturePost = function () {

        if (!jitoValidations.empty(navigator.camera)) {
            $scope.goToPage('/write-post/picture');
        } else {
            jitoHybrid.popupError($translate.instant("COMMON_PLATFORM_NOT_SUPPORT"));
        }
    };

    $scope.$on('resetBadgeCount', function (event) {
        if (push) {
            jitoHybrid.setBadgeNumber(0, push);
        }
    });

    if (jitoUser.isLogged()) {
        $scope.profilePic = jitoUser.getLoggedUser().url;
    }

    $scope.goToProfilePage = function () {
        if (jitoUser.getUserType() == "1") {
            //$location.path(jitoPaths.USER_PROFILE_EDIT);
            $window.location.href = '/jobs/member/edit';
        } else {
            //$location.path(jitoPaths.USER_BRAND_EDIT);
            $window.location.href = '/jobs/company/edit';
        }
    };

    ///////////////// Logout ////////////////////////

    var logoutSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {

            jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));
            jitoEvents.triggerResetBadgeCounter();
            $scope.parentObject.searchText = null;
            $scope.parentObject = {};
            $scope.currentLoggedUserType = null;
            jitoUser.logout();

        } else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    var logoutError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        jitoHybrid.popupError(errorMessage);

        if (response.status == 403) {
            $scope.parentObject.searchText = null;
            $scope.parentObject = {};
            jitoUser.logout();
        }
    };

    var logout = function () {
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        jitoAjax.call({
            method: 'post',
            data: {
                user_id: jitoUser.getUserID(),
                device_id: jitoStorage.get('DEVICE_ID')
            },
            url: jitoSettings.getAPIDomain() + jitoSettings.getLogoutAPI(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, logoutSuccess, logoutError);
    };

    $scope.logout = function () {
        //Clear search term
        jitoEvents.triggerLogout();
        logout();
    };

    $(document).ready(function () {
        $('#wrapper').css("display", "block");
    });


    var profileImagePromise;
    var profileImage;
    $scope.i = null;
    var orientation = null;
    $scope.width = 0;
    $scope.height = 0;

    $scope.$on('onProfileImageSet', function (event, data, promise) {
        orientation = null;
        var img = new mOxie.Image();
        img.onload = function () {
            if (img.size > jitoSettings.IMAGE_MAX_SIZE) {
                jitoHybrid.popupError('Your image exceeds the maximum allowable image size 20mb. Please try again with another image.');
            } else {

                if( img.width > jitoSettings.MAX_PICTURE_WIDTH || img.height > jitoSettings.MAX_PICTURE_HEIGHT ) {
                    jitoHybrid.popupError('Your image exceeds the maximum pixel resolution of ' + jitoSettings.MAX_PICTURE_WIDTH + '*' + jitoSettings.MAX_PICTURE_WIDTH + '. Please try again with another image.');
                    return;
                }

                $timeout(function () {
                    var width = img.width;
                    var height = img.height;

                    // Check if the current width is larger than the max
                    // if(width > maxWidth){
                    //     ratio = maxWidth / width;   // get ratio for scaling image
                    //     height = height * ratio;    // Reset height to match scaled image
                    //     width = width * ratio;    // Reset width to match scaled image
                    // }
                    //
                    // // Check if current height is larger than max
                    // if(height > maxHeight){
                    //     ratio = maxHeight / height; // get ratio for scaling image
                    //     width = width * ratio;    // Reset width to match scaled image
                    //     height = height * ratio;    // Reset height to match scaled image
                    // }

                    if(width >= height) { //Landscape & square
                        if(width > jitoSettings.MAX_IMAGE_WIDTH_HEIGHT) {
                            height = (jitoSettings.MAX_IMAGE_WIDTH_HEIGHT * height) / width;
                            width = jitoSettings.MAX_IMAGE_WIDTH_HEIGHT;
                        }
                    } else { //Portrait
                        if(height > jitoSettings.MAX_IMAGE_WIDTH_HEIGHT) {
                            width = (jitoSettings.MAX_IMAGE_WIDTH_HEIGHT * width) / height;
                            height = jitoSettings.MAX_IMAGE_WIDTH_HEIGHT;
                        }
                    }

                    img.downsize(width, height, false, false);

                }, 100);
            }
        };


        var angle = 0;

        function saveImage(base64ImageSrc) {

            var canvas = document.createElement("canvas");
            var img = new Image();
            img.src = base64ImageSrc;
            var context = canvas.getContext("2d");

            if (Math.abs(angle) % 360 == 0) {
                //Original position
                canvas.width = img.width;
                canvas.height = img.height;
                context = canvas.getContext("2d");
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.save();
                context.translate(canvas.width / 2, canvas.height / 2);
                context.rotate(0 * Math.PI / 180);
                context.drawImage(img, -img.width / 2, -img.height / 2);
            } else if (Math.abs(angle) % 180 == 0) {
                //180 rotation
                canvas.width = img.width;
                canvas.height = img.height;
                context = canvas.getContext("2d");
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.save();
                context.translate(canvas.width / 2, canvas.height / 2);
                context.rotate(180 * Math.PI / 180);
                context.drawImage(img, -img.width / 2, -img.height / 2);
            }
            if (angle == 90 || angle == -270) {
                //Base on Left
                canvas.width = img.height;
                canvas.height = img.width;
                context = canvas.getContext("2d");
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.save();
                context.rotate(90 * Math.PI / 180);
                context.drawImage(img, 0, -img.height);
            } else if (angle == -90 || angle == 270) {
                //Base on Right
                canvas.width = img.height;
                canvas.height = img.width;
                context = canvas.getContext("2d");
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.save();
                context.rotate(-90 * Math.PI / 180);
                context.drawImage(img, -img.width, 0);
            }

            context.restore();

            $timeout(function () {
                $rootScope.Ui.turnOff("profileImage");
                profileImagePromise.resolve(canvas.toDataURL('image/jpeg', 0.9));
            }, 100);
        }

        $scope.rotateRight = function () {
            angle = parseInt(angle + 90);
            (angle == 450) && (angle = 90);

            if (Math.abs(angle) % 360 == 0) {
                $scope.isLandscape = true;
            } else if (Math.abs(angle) % 180 == 0) {
                $scope.isLandscape = true;
            }
            if (angle == 90 || angle == -270) {
                $scope.isLandscape = false;

            } else if (angle == -90 || angle == 270) {
                $scope.isLandscape = false;
            }

            $('#profileImage').css('transform', 'rotate(' + angle + 'deg)');

        };

        $scope.rotateLeft = function () {
            angle = parseInt(angle - 90);
            (angle == -450) && (angle = -90);

            if (Math.abs(angle) % 360 == 0) {
                $scope.isLandscape = true;
            } else if (Math.abs(angle) % 180 == 0) {
                $scope.isLandscape = true;
            }
            if (angle == 90 || angle == -270) {
                $scope.isLandscape = false;
            } else if (angle == -90 || angle == 270) {
                $scope.isLandscape = false;
            }

            $('#profileImage').css('transform', 'rotate(' + angle + 'deg)');
        };

        $scope.saveProfileImage = function () {
            saveImage($scope.i);
        };

        img.onresize = function (inputImage) {

            $scope.i = inputImage.target.getAsDataURL('image/jpeg', 100);
            $scope.profileImage = null;
            $rootScope.Ui.turnOn("profileImage");

            $timeout(function () {
                $scope.profileImage = $scope.i;
            }, 500);

            profileImagePromise = promise;
        };

        img.onerror = function () {
            jitoHybrid.popupError('Invalid image. Please try again.');
        };

        img.load(data);

    });

    function setMessageSidebarHeight() {
        var height = $(".new-messaging-left-bar").height();
        var listHeight = (height - 98) + "px";
        $('.messaging-sidebar-body').css('height', listHeight);
    }

    $scope.setMessageSidebarHeight = function () {
        $timeout(function() {
            setMessageSidebarHeight();
        }, 100);
    };


    //Device width catch with js
    $(document).ready(function () {
        setMenu();

        $timeout(function() {
            setAdsWidth(); // This function is defined in desktop.blade.php
            sameHeightBoxes(); // This function is defined in desktop.blade.php
            setMessageSidebarHeight();
        }, 100);
    });

    $(window).resize(function() {
        setMenu();
        setMessageSidebarHeight();
    });

    function setMenu(){
       var width= $(window).width();
       if (width < 400){
           $scope.staticMenu = false;
           $('.text').css( 'font-size' , '11px');
       }
       else if( width < 769){
           $scope.staticMenu = false;
           $('.text').css( 'font-size' , '13px');
       }else {
           $scope.isSideBar = false;
           $scope.staticMenu = true;
           $scope.isSearchBar = false;
           $('.scrollable-content').css('overflow', 'auto'); 
       }

       var height=$(window).height();  
       height = (height - 74) / 4 + 'px';
       $('.jt-custom-height').css('height', height); 

       if (window.innerHeight < window.innerWidth){
           $('.jt-custom-height').css( 'width' , '25%');
           $('.jt-custom-img').css( 'width','17%');
           $('.jt-custom-img').css( 'height','17%');
           $('.text').css( 'line-height','24px');
           if ( window.innerWidth >= 768 ){ 
               $('.scrollable-content').css('overflow', 'auto'); 
               $scope.isSideBar = false;
               $scope.staticMenu = true;
               $scope.isSearchBar = false;
           }

       }else {
           $('.jt-custom-height').css( 'width' , '33%');  
           $('.jt-custom-img').css( 'width','50%');
           $('.jt-custom-img').css( 'height','50%'); 
       }
    }

    $scope.menuContent = function(){
        $scope.isSideBar = !$scope.isSideBar;
        if ($scope.isSideBar){
            setMenu();
            $( "#sidebar-mobile-content" ).focus();
            $('.scrollable-content').css('overflow', 'hidden');
        } else {
           $('.scrollable-content').css('overflow', 'auto'); 
        }
    };

    $scope.menuClose = function(){
        $scope.isSideBar = false;
        $('.scrollable-content').css('overflow', 'auto'); 
    };

    $scope.mobileView = false;

    $scope.searchBarToggle = function(){
        $scope.isSearchBar = !$scope.isSearchBar;
    };

    $scope.sideMenuInitialOpen = true;

    $scope.openNav =  function($event)  {
        $event.stopPropagation();

        if( $scope.sideMenuInitialOpen || angular.element(document).find("#mySidenav").hasClass('menu-closed')) {
            document.getElementById("mySidenav").style.width = "320px";
            angular.element(document).find("#mySidenav").removeClass('menu-closed');
        } else {
            $scope.closeNav();
        }

        $scope.sideMenuInitialOpen = false;
    };

    $scope.closeNav = function() {
        document.getElementById("mySidenav").style.width = "0";
        angular.element(document).find("#mySidenav").addClass('menu-closed');
    };

});

function auto_grow(element) {
    element.style.height = "5px";
    element.style.height = (element.scrollHeight)+"px";
}