/**
 * Created by prasanna_j on 23-Jan-2016.
 */

app.controller('AlbumAddPhotosController', function ($scope, $routeParams, $window, $location, $translate, jitoCommon,
                                                     jitoHybrid, jitoEvents, jitoShared, jitoSettings, jitoUser, jitoAjax) {
  "ngInject";
  //Initialization
  $scope.albumDetails = jitoShared.getCurrentAlbumDetails();

  var initializeVars = function () {
    $scope.selectedPhotos = [];
    $scope.currentCounter = -1;
    $scope.uploadSuccess = 0;
    $scope.uploadFailed = 0;
  };

  initializeVars();

  $scope.setSource = function(type){
    if(type == 0){
      //Trigger gallery event
      jitoEvents.triggerProfilePictureSelector(0);
    } else if(type == 1){
      //Trigger camera event
      jitoEvents.triggerProfilePictureSelector(1);
    }
  };

  //////////////////////////////////////////////////////////
  // Profile picture selecting section

  $scope.$on('profilePictureService', function (event, type) {

    navigator.camera.getPicture(imgUploadSuccess, imgUploadFail, {
      quality: jitoSettings.PROFILE_PICTURE_QUALITY,
      destinationType: Camera.DestinationType.DATA_URL,
      sourceType: type,
      correctOrientation: true
    });

  });

  var imgUploadSuccess = function (imageData) {
    var data = {
      image: imageData,
      text: "Uploading..."
    };
    $scope.selectedPhotos.push(data);
    uploadAllPhotos();
    $scope.$apply();
  };

  var imgUploadFail = function (message) {
    if(message == "Unable to retrieve path to picture!"){
      jitoHybrid.alert(message);
    }
  };

  ///////////////////////////////////////////////////////////
  /// Upload the selected image
  ///////////////////////////////////////////////////////////

  var uploadAllPhotos = function () {
    jitoEvents.triggerLoader(true);

    $scope.currentCounter++;
    uploadPhoto();
  };

  var uploadPhoto = function () {
    if($scope.selectedPhotos[$scope.currentCounter] == undefined) {
      jitoEvents.triggerLoader(false);

      var msg = [];

      if($scope.uploadSuccess > 0) {
        msg.push($scope.uploadSuccess + " " + $translate.instant('ALBUM_PHOTOS_UPLOAD_SUCCESS'))
      }
      if($scope.uploadFailed > 0) {
        msg.push($scope.uploadFailed + " " + $translate.instant('ALBUM_PHOTOS_UPLOAD_ERROR'))
      }

      showUploadPhotoConfirmation(msg);
    } else {
      doUpload($scope.selectedPhotos[$scope.currentCounter]);
    }
  };

  var showUploadPhotoConfirmation = function (msg) {
    jitoHybrid.dialog('', msg.join(" & "), 0);

    //Initialise the variables again
    initializeVars();

    //Redirect back to album view again
    $window.history.back();
  };

  var doUpload = function (data) {

    jitoSettings.getImageData(data.image).then(function (image) {
      jitoAjax.call({
        method: 'post',
        data: JSON.stringify({
          album_picture: image,
          album_id: $scope.albumDetails.id
        }),
        url: jitoSettings.getAPIDomain() + jitoSettings.getUploadAlbumImage(),
        timeout: jitoSettings.PICTURE_TIME_OUT
      }, imageUploadSuccess, imageUploadError);
    });

  };

  var imageUploadSuccess = function (response) {
    $scope.selectedPhotos[$scope.currentCounter].text = $translate.instant('ALBUM_PHOTO_SUCCESS');
    $scope.uploadSuccess++;
    $scope.currentCounter++;
    uploadPhoto();
  };

  var imageUploadError = function (response) {
    $scope.selectedPhotos[$scope.currentCounter].text = $translate.instant('ALBUM_PHOTO_FAILED');
    $scope.uploadFailed++;
    $scope.currentCounter++;
    uploadPhoto();
  };

});