/**
 * Created by thusitha_m on 1/12/2016.
 */

app.controller('PurchaseTicketsController', function ($scope, $translate,$location, $timeout, $rootScope,
                                                  jitoPaths, jitoEvents, jitoAjax, jitoSettings, jitoHybrid, jitoCommon, jitoValidations) {
    "ngInject";

    
    $scope.Math = window.Math;
});