app.controller('ArticleController', function ($scope, jitoUser, $timeout, $rootScope, $translate, jitoStorage, jitoValidations, jitoPaths, jitoEvents, jitoAjax, jitoSettings, jitoHybrid, $location, jitoCommon) {
  "ngInject";
  var DEFAULT_URL = "assets/app/images/article-header-new.svg";
  $scope.isHeaderImageEmpty = true;
  $scope.isHeaderUploaded = false;
  $scope.topic = "";
  $scope.content = "";
  $scope.headerImage = DEFAULT_URL;
  $scope.originalHeaderImage = DEFAULT_URL;
  $scope.outputImage = null;
  $scope.isHeaderNewlyUploaded = false;
    $scope.isUploadingHeaderImage = false;
  $scope.editable = "";
  $scope.editor = "";
  $scope.isRequesting = false;
  $scope.articleSlug = "";
  $scope.articleId = 0;
  $scope.atcle_uid = null;

  var generateArticleId = function () {
      this.length = 8;
      this.timestamp = +new Date;

      var _getRandomInt = function( min, max ) {
        return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
      };

        var ts = this.timestamp.toString();
        var parts = ts.split( "" ).reverse();
        var id = "";

        for( var i = 0; i < this.length; ++i ) {
          var index = _getRandomInt( 0, parts.length - 1 );
          id += parts[index];
        }

        $scope.articleId = id;
  };

  generateArticleId();

  /**To set the available options for the text editor**/
  $scope.options = {
      minHeight: 250,
      toolbar: [
      ['headline', ['style']],
      ['style', ['bold', 'italic', 'underline']],
      ['alignment', ['ul', 'ol']],
      ['insert', ['link', 'picture', 'video', 'hr']],
      ['view', ['codeview']]
    ]
  };

    /**
     * Adjust image cropper dimensions according to the screen size
     * @type {Number|number}
     */
    var updateCropperDimensions = function () {
        var width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        if(width <= 426){
            $scope.boundary = {w: 300, h: 150};
            $scope.viewport = {w: 300, h: 108};
        } else if (width <= 768){
            $scope.boundary = {w: 600, h: 300};
            $scope.viewport = {w: 600, h: 216};
        } else {
            $scope.boundary = {w: 970, h: 400};
            $scope.viewport = {w: 970, h: 350};
        }
    };

    updateCropperDimensions();

  /************article image upload*****************/
  var ArticleImageUploadError = function (response) {
    showErrorMessage(response);
  };

  $scope.shareArticle = function () {
    $scope.isRequesting = true;
    var desc = createShareDescription();
    var shareData = {
      "description" : desc,
      "slug" : $scope.articleSlug,
        "atcle_uid": $scope.atcle_uid
    };
    jitoAjax.call({
      method: 'post',
      data: JSON.stringify(shareData),
      url: jitoSettings.getAPIDomain() + jitoSettings.getShareAricleAPI(),
      timeout: jitoSettings.DEFAULT_TIME_OUT
    }, function (response) {
      $scope.isRequesting = false;
      jitoHybrid.popup(response.data.message);
      $location.path(jitoPaths.USER_TIME_LINE);
    }, function (response) {
      $scope.isRequesting = false;
      jitoHybrid.popupError(response.data.message);
      $location.path(jitoPaths.USER_TIME_LINE);
    });
  };

  $scope.cancelShare = function () {
      //$location.path(jitoPaths.USER_TIME_LINE);
      $location.path("/articles");
  };

  var createShareDescription = function () {
    var description = $scope.topic + "\n" + $scope.articleSlug;
    return description;
  };

  /**to trigger input button through image**/
  $scope.triggerInput = function () {
    $timeout(function () {
      document.getElementById('image_uploader').click();
    });
  };

  $scope.imageUpload = function (files) {
    var data = new FormData();
    data.append("file", files[0]);
    if (files[0].name.match(/.(jpg|jpeg|png)$/i)) {
      $scope.isRequesting = true;
      jitoAjax.callMultipart({
        method: 'post',
        data: data,
        url: jitoSettings.getAPIDomain() + jitoSettings.getArticleImageUploaderAPI() + $scope.articleId,
        timeout: jitoSettings.PICTURE_TIME_OUT
      }, function (response) {
        $scope.isRequesting = false;
        $scope.editor.summernote('insertImage', response.data.url, response.data.file_name);
      }, ArticleImageUploadError);
    }
    else {
      jitoHybrid.popupError('Invalid image. Please try again.');
    }
  };

  $scope.mediaDelete = function (target) {
    $scope.isRequesting = true;
    jitoAjax.call({
      method: 'post',
      data: JSON.stringify(target),
      url: jitoSettings.getAPIDomain() + jitoSettings.getArticleImageDeleteAPI() + $scope.articleId,
      timeout: jitoSettings.PICTURE_TIME_OUT
    }, function (response) {
      $scope.isRequesting = false;
    }, function (response) {
      $scope.isRequesting = false;
    });
  };
  /********************************************/

  /**triggered for the header image**/
  $scope.pictureAdded = function ($file) {
    $scope.isRequesting = true;
    var fileReader = new FileReader();
    fileReader.onload = function (event) {
      headerPhotoUpload(event.target.result);
    };
    fileReader.readAsDataURL($file.file);
  };

  var headerPhotoUpload = function (imageData) {
    // var img = new Image();
    // img.addEventListener("load", function () {
    //     var validImage = ((imageData.indexOf("data:image") >= 0) && (imageData.indexOf("base64") >= 0));
    //     if (validImage) {
    //         if( img.width > jitoSettings.MAX_PICTURE_WIDTH || img.height > jitoSettings.MAX_PICTURE_HEIGHT ) {
    //             jitoHybrid.popupError('Your image exceeds the maximum pixel resolution of ' + jitoSettings.MAX_PICTURE_WIDTH + '*' + jitoSettings.MAX_PICTURE_WIDTH + '. Please try again with another image.');
    //             return;
    //         } else if (img.size > jitoSettings.IMAGE_MAX_SIZE) {
    //             jitoHybrid.popupError('Your image exceeds the maximum allowable image size 20mb. Please try again with another image.');
    //             return;
    //         } else {
    //             var image = document.getElementById('header-image');
    //             var data = {
    //                 image_data: imageData,
    //                 file_name: $scope.headerImage,
    //                 article_id: $scope.articleId
    //             };
    //             UploadHeaderImage(data);
    //         }
    //     } else {
    //         $scope.isRequesting = false;
    //     }
    // });
    // img.src = imageData;
    // img.onerror = function () {
    //   jitoHybrid.popupError('Invalid image. Please try again.');
    // };

      var img = new mOxie.Image();
      img.onload = function () {
          if (img.size > jitoSettings.IMAGE_MAX_SIZE) {
              jitoHybrid.popupError('Your image exceeds the maximum allowable image size 20mb. Please try again with another image.');
              $scope.isRequesting = false;
              return;
          } else {
              if( img.width > jitoSettings.MAX_PICTURE_WIDTH || img.height > jitoSettings.MAX_PICTURE_HEIGHT ) {
                  jitoHybrid.popupError('Your image exceeds the maximum pixel resolution of ' + jitoSettings.MAX_PICTURE_WIDTH + '*' + jitoSettings.MAX_PICTURE_WIDTH + '. Please try again with another image.');
                  $scope.isRequesting = false;
                  return;
              } else {
                  $timeout(function () {
                      var width = img.width;
                      var height = img.height;

                      if(width >= height) { //Landscape & square
                          if(width > jitoSettings.MAX_IMAGE_WIDTH_HEIGHT) {
                              height = (jitoSettings.MAX_IMAGE_WIDTH_HEIGHT * height) / width;
                              width = jitoSettings.MAX_IMAGE_WIDTH_HEIGHT;
                          }
                      } else { //Portrait
                          if(height > jitoSettings.MAX_IMAGE_WIDTH_HEIGHT) {
                              width = (jitoSettings.MAX_IMAGE_WIDTH_HEIGHT * width) / height;
                              height = jitoSettings.MAX_IMAGE_WIDTH_HEIGHT;
                          }
                      }

                      img.downsize(width, height, false, false);
                  }, 100);
              }
          }
      };
      img.onresize = function (inputImage) {
          var data = {
              image_data: inputImage.target.getAsDataURL('image/jpeg', 100),
              file_name: $scope.headerImage,
              article_id: $scope.articleId
          };
        UploadHeaderImage(data);
      };
      img.onerror = function () {
        jitoHybrid.popupError('Invalid image. Please try again.');
      };
      img.load(imageData);
  };

  var UploadHeaderImage = function (imageData) {
    $scope.isRequesting = true;
    $scope.isUploadingHeaderImage = true;
    jitoAjax.call({
      method: 'post',
      data: JSON.stringify(imageData),
      url: jitoSettings.getAPIDomain() + jitoSettings.getArticleHeaderImageUploaderAPI(),
      timeout: jitoSettings.PICTURE_TIME_OUT
    }, HeaderUploadSuccess, HeaderUploadError);
  };

  var HeaderUploadSuccess = function (response) {
      if($scope.isUploadingHeaderImage == true) {
          //$scope.headerImage = response.data.url;
          $scope.isRequesting = false;
          $scope.isHeaderImageEmpty = false;
          $scope.isHeaderUploaded = true;
          $scope.isUploadingHeaderImage = false;

          $timeout(function () {
              $scope.isHeaderNewlyUploaded = true;
              $scope.safeApply();
          }, 100);

          $timeout(function () {
              $scope.originalHeaderImage = response.data.original;
              $scope.safeApply();
          }, 200);
      }
  };

  var HeaderUploadError = function (response) {
      if($scope.isUploadingHeaderImage == true) {
          showErrorMessage(response);
          $scope.headerImage = DEFAULT_URL;
          $scope.isRequesting = false;
          $scope.isUploadingHeaderImage = false;
      }
  };

  /**to remove the header image**/
  $scope.removeHeaderImage = function () {
    $scope.isRequesting = true;
    var data = {
      file_name: $scope.headerImage,
      article_id: $scope.articleId
    };
    jitoAjax.call({
      method: 'post',
      data: JSON.stringify(data),
      url: jitoSettings.getAPIDomain() + jitoSettings.getHeaderImageDeleteAPI(),
      timeout: jitoSettings.PICTURE_TIME_OUT
    }, function (response) {
      $scope.headerImage = DEFAULT_URL;
      $scope.isRequesting = false;
      $scope.isHeaderImageEmpty = true;
      $scope.isHeaderUploaded = false;
    }, function (response) {
      $scope.isRequesting = false;
    });
  };

  /**check if the header image is empty**/
  $scope.isHeaderEmpty = function () {
    if (jitoValidations.empty($scope.headerImage)) {
      return true;
    }
    else if ($scope.headerImage.localeCompare(DEFAULT_URL) === 0) {
      return true;
    }
    else {
      return false;
    }
  };

  /**check if image is uploaded to the server**/
  $scope.isHeaderNotUploaded = function () {
    if(!jitoValidations.empty($scope.headerImage) && ($scope.headerImage.indexOf("base64") >= 0) && ($scope.headerImage.indexOf("data:image") >= 0)){
      return true;
    }
    else{
      return false;
    }
  };

  /**************************validations for draft and save **********************/
  $scope.validateForDraft = function () {
    if(!jitoValidations.empty($scope.topic)) {
      if (!$scope.isHeaderNotUploaded()) {
        var headerImageValue = $scope.headerImage;
        if ($scope.headerImage.localeCompare(DEFAULT_URL) === 0) {
          headerImageValue = "";
        }
          $scope.articleCreateForm.$setPristine();
        $scope.draftArticle({
          header_image: headerImageValue,
          title: $scope.topic,
          content: $scope.content,
          article_id: $scope.articleId
        });
      }
      else{
        jitoHybrid.popupError($translate.instant('ARTICLE_HEADER_UPLOAD_ERROR', 0));
      }
    }
    else{
      jitoHybrid.popupError($translate.instant('ARTICLE_TOPIC_EMPTY_ERROR', 0));
    }
  };

  $scope.validateForPublishing = function () {
      if(!$scope.isHeaderNotUploaded()) {
        if (!jitoValidations.empty($scope.topic)) {
          var headerImageValue = $scope.headerImage;
          if (!jitoValidations.empty($scope.content)) {
            if ($scope.headerImage.localeCompare(DEFAULT_URL) === 0) {
              headerImageValue = "";
            }
              $scope.articleCreateForm.$setPristine();
            $scope.publishArticle({
              header_image: headerImageValue,
              title: $scope.topic,
              content: $scope.content,
              article_id: $scope.articleId
            });
          }
          else {
            jitoHybrid.popupError($translate.instant('ARTICLE_CONTENT_EMPTY_ERROR', 0));
          }
        }
        else{
          jitoHybrid.popupError($translate.instant('ARTICLE_TOPIC_EMPTY_ERROR', 0));
        }
      }
      else{
        jitoHybrid.popupError($translate.instant('ARTICLE_HEADER_UPLOAD_ERROR', 0));
      }
  };

  /////////////////////////////////////////////////////////////////////////////////

  /**to publish the article**/
  $scope.publishArticle = function (data) {
        $scope.isRequesting = true;
        jitoAjax.call({
          method: 'post',
          data: JSON.stringify(data),
          url: jitoSettings.getAPIDomain() + jitoSettings.getArticleUploaderAPI(),
          timeout: jitoSettings.PICTURE_TIME_OUT
        }, articleSaveSuccess, articleSaveError);
  };

  /**if article is saved successfully**/
  var articleSaveSuccess = function (response) {
    $scope.isRequesting = false;
    $scope.articleSlug = response.data.url;
    $scope.atcle_uid = response.data.article.id;
    $timeout(function(){
      $rootScope.Ui.turnOn("articleShare");
    }, 100);
  };

  var articleSaveError = function (response) {
    showErrorMessage(response);
  };

  /**to save as a draft**/
  $scope.draftArticle = function (data) {
    $scope.isRequesting = true;
    jitoAjax.call({
      method: 'post',
      data: JSON.stringify(data),
      url: jitoSettings.getAPIDomain() + jitoSettings.getArticleDraftAPI(),
      timeout: jitoSettings.PICTURE_TIME_OUT
    }, articleDraftSuccess, articleDraftError);
  };

  var articleDraftSuccess = function (response) {
    $scope.isRequesting = false;
    jitoHybrid.popup(response.data.message, 0);
    //$location.path(jitoPaths.USER_TIME_LINE);
    $location.path("/articles");
  };

  var articleDraftError = function (response) {
    $scope.isRequesting = false;
    showErrorMessage(response);
  };

  /**to display error messages**/
  var showErrorMessage = function (response) {
    var errorMessage = '';
    $scope.isRequesting = false;
    if (response.status > 200 && response.status < 500 && response.data) {
      errorMessage = jitoCommon.normalizeErrorMessages(response.data);
    }
    else {
      errorMessage = $translate.instant('COMMON_NO_NETWORK');
    }
    jitoEvents.triggerLoader(false);
    jitoHybrid.alert(errorMessage, 0);
  };

    /**
     * This method is to create a safe apply rather than triggering
     * the $apply method inside a digest circle
     */
    $scope.safeApply = function (fn) {
        if (this.$root) {
            var phase = this.$root.$$phase;
            if (phase == '$apply' || phase == '$digest') {
                if (fn && (typeof(fn) === 'function')) {
                    fn();
                }
            } else {
                this.$apply(fn);
            }
        } else {
            fn();
        }
    };

    $scope.cropHeaderImage = function () {
        $timeout(function () {
            $scope.isRequesting = true;
            jitoAjax.call({
                method: 'post',
                data: {
                    image_data: $scope.outputImage,
                    article_id: $scope.articleId,
                    image_url: $scope.originalHeaderImage
                },
                url: jitoSettings.getAPIDomain() + jitoSettings.getArticleHeaderRepositionAPI(),
                timeout: jitoSettings.PICTURE_TIME_OUT
            }, function (response) {
                $scope.headerImage = response.data.url;
                $scope.isRequesting = false;
                $scope.isHeaderNewlyUploaded = false;
                $scope.safeApply();
            }, function (response) {
                showErrorMessage(response);
                $scope.headerImage = DEFAULT_URL;
                $scope.isRequesting = false;
            });
        }, 100);
    };

    $scope.cancelCropHeaderImage = function () {
        $scope.isRequesting = false;
        $scope.isHeaderNewlyUploaded = false;
        $scope.safeApply();
    };

    $scope.cancelHeaderImageUpload = function () {
        $scope.isRequesting = false;
        $scope.isUploadingHeaderImage = false;
        $scope.safeApply();
    };
});