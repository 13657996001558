/**
 * Created by thusitha_m on 1/12/2016.
 */

app.controller('ForgotPasswordController', function ($scope, $translate, $location, $timeout, $rootScope,
                                                     jitoPaths, jitoEvents, jitoValidations, jitoAjax, jitoStorage, jitoSettings, jitoUser, jitoHybrid, jitoCommon) {
    "ngInject";
    var type = 1;
    $scope.isUser = true;

    $scope.setLoginType = function(userType){       
        $scope.isUser = userType;
        if(userType){
            type = 1;        
        } else{
            type = 2;    
        }
    };

    $scope.forgotPassword = function (isValid, email) {

        if (isValid) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
            $scope.isRequesting =  true;

            jitoAjax.call({
                method: 'post',
                data: {
                    email: email,
                    type: type
                },
                url: jitoSettings.getAPIDomain() + jitoSettings.getResetKeyAPI(),
                timeout: jitoSettings.SIGNUP_REQUEST_TIMEOUT
            }, sendResetCodeSuccess, sendResetCodeError);
        }
    };

    var sendResetCodeSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isRequesting =  false;
        if (response && response.status && response.status === 200 && response.data.userId) {
            //Clear data of any logged in user
            jitoStorage.clear();
            jitoStorage.set('PASSWORD_REQUESTED', true);
            jitoStorage.set('PASSWORD_REQUESTED_USER_ID', response.data.userId);

            $timeout(function(){
                $rootScope.Ui.toggle("forgotPassword");
                $rootScope.Ui.toggle("changePassword");
            }, 100);

        } else {
            if (response.data) {
                jitoHybrid.alert(jitoCommon.normalizeErrorMessages(response.data));
            }
        }
    };

    var sendResetCodeError = function (response) {
        var errorMessage = '';
        $scope.isRequesting =  false;
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        } else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        jitoEvents.triggerLoader(false);
        jitoHybrid.alert(errorMessage, 0);
    };

    $scope.goToTerms = function () {
        $scope.TYPE = jitoSettings.INFO_STATE.TERMS;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_TERMS_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_TERMS_DESC');
        jitoCommon.openInfoModal();
    };

    $scope.goToAbout = function () {
        $scope.TYPE = jitoSettings.INFO_STATE.ABOUT;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_ABOUT_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_ABOUT_DESC');
        jitoCommon.openInfoModal();
    };

    $scope.goToPrivacyPolicy = function () {
        $scope.TYPE = jitoSettings.INFO_STATE.PRIVACY;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_PRIVACY_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_PRIVACY_DESC');
        jitoCommon.openInfoModal();
    };

});