app.controller('UserArticlesController', function (titleChange,$scope,jitoValidations,SharedState, $timeout, $rootScope,
                                                    jitoCommon, jitoHybrid, $routeParams, jitoSettings, $translate, jitoEvents,
                                                    jitoAjax, $window, jitoUser, $location, jitoPaths, jitoShared) {
    "ngInject";
    var profileID = $.trim($routeParams.ProfileID);
    var currentPage = 0, isMoreToFetch = true;
    $scope.profileID = profileID;
    $scope.currentUserID = jitoUser.getUserID();
    $scope.articles = [];
    $scope.isMoreLoading = false;

    $scope.redirectPage = function (path) {
        $location.path(path);
    };

    $scope.goToProfilePage = function (profileID, type) {
        var url = (type == 1) ? '/profile/' : '/company/profile/';
        $location.path(url + $scope.profileID);
    };

    ///////////////////////////////////////////////////////
    //Load user data
    ///////////////////////////////////////////////////////

    var userProfileSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isError = false;

        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.userProfile = response.data;
                titleChange.setTitle($scope.userProfile.display_name);
                if(!jitoValidations.empty($scope.userProfile.user_meta.company)){
                    $scope.isCompany = true;
                }
                if(!jitoValidations.empty($scope.userProfile.user_meta.current_job)){
                    $scope.isJob = true;
                }
                if(!jitoValidations.empty($scope.userProfile.address)){
                    $scope.isLocation = true;
                }
            } else {
                onError($translate.instant('USER_PROFILE_LOADING_ERROR'));
            }
        } else {
            onError(jitoCommon.normalizeErrorMessages(response.data));
        }
    };

    var onError = function (message) {
        $scope.isError = true;
        $scope.errorMessage = message;
    };

    var userProfileError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        onError(errorMessage);
    };

    var loadProfileDetails = function (profileID) {
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.geUserDetailsAPI() + profileID,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, userProfileSuccess, userProfileError);
    };

    loadProfileDetails(profileID);

    $scope.loadMore = function () {
        if (isMoreToFetch) {
            listPosts();
        }
    };

    var listPostSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;

        if (response && response.status && response.status === 200) {
            angular.forEach(response.data.data.data, function (data) {
                $scope.articles.push(data);
            });

            if (response.data.data.data.length < 1) {
                isMoreToFetch = false;
            }
        } else {
            if (response.data && response.data.length < 1) {
                isMoreToFetch = false;
            }
        }
    };

    var listPostError = function (response) {
        var errorMessage = '';

        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        } else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;

        jitoHybrid.popupError(errorMessage);
    };

    var listPosts = function () {
        currentPage++;
        var data = {page: currentPage};

        //Make the get request
        if ($scope.articles.length == 0) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        } else {
            $scope.isMoreLoading = true;
        }

        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getUserPublishedArticles() + profileID,
            timeout: jitoSettings.DEFAULT_TIME_OUT,
            params: data
        }, listPostSuccess, listPostError);
    };

    listPosts();
});