/**
 * Created by thusitha_m on 2/15/2016.
 */

app.controller('InviteController', function ($rootScope, $timeout, jitoEvents, SharedState, jitoShared, jitoHybrid, jitoCommon, $scope, jitoValidations, $translate, jitoStorage, jitoAjax, $location, $window, jitoPaths, jitoSettings, jitoUser, $rootScope) {
    "ngInject";
    $scope.emailList = [];

    $scope.addEmail = function () {

        //console.log($.inArray($scope.newEmail, $scope.emailList));

        if($scope.emailList.length <= 19){
            if ($scope.inviteForm.uEmail.$error.email) {
                jitoHybrid.popupError($translate.instant('INVITE_INVALID_EMAIL'));
            } else if (jitoValidations.empty($scope.newEmail)) {
                jitoHybrid.popupError($translate.instant('INVITE_EMPTY_EMAIL'));
            }
            else if ($.inArray($scope.newEmail, $scope.emailList) >= 0 ) {
                jitoHybrid.popupError($translate.instant('INVITE_EMAIL_ALREADY_INCLUDED'));
            }
            else {
                $scope.emailList.push($scope.newEmail);
                $scope.newEmail = "";
            }
        } else{
            jitoHybrid.popupError($translate.instant('INVITE_EMAIL_MAXIMUM_AMOUNT'));
        }

    };

    var inviteSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.emailList.length = 0;
                $scope.newEmail = "";
                jitoHybrid.popup(response.data.message);
            }
            else {
                jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
            }
        } else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    var inviteError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        jitoHybrid.popupError(errorMessage);
    };

    $scope.invite = function () {


        if(!$scope.inviteForm.uEmail.$error.email && !jitoValidations.empty($scope.newEmail)){
            $scope.addEmail();
        } else if($scope.inviteForm.uEmail.$error.email && !jitoValidations.empty($scope.newEmail)){
            jitoHybrid.popupError($translate.instant('INVITE_INVALID_EMAIL'));
            return;
        }

        if ($scope.emailList.length > 0) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
            jitoAjax.call({
                method: 'post',
                url: jitoSettings.getAPIDomain() + jitoSettings.getFriendsInviteAPI(),
                data: {
                    emails: $scope.emailList,
                    message: {}
                },
                timeout: jitoSettings.DEFAULT_TIME_OUT
            }, inviteSuccess, inviteError);
        } else {
            jitoHybrid.popupError($translate.instant('INVITE_EMAIL_LIST_EMPTY'));
        }
    };

});