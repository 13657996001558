/**
 * Created by achintha.s on 1/23/2016.
 */

app.controller('ConnectSearchController', function (titleChange, $scope, $window, $route, $routeParams, $location, $translate, $timeout,
                                                    moment, jitoUser, jitoSettings, jitoAjax, jitoEvents, jitoCommon, jitoHybrid, jitoShared, jitoPaths) {
    "ngInject";
    $scope.noEvents = false;
    $scope.listItems = [];
    $scope.isMoreLoading = false;
    $scope.errorMessage = '';
    $scope.term = '';
    $scope.pendingInvCount = 0;
    $scope.connectionsCount = 0;

    /** Setting up if got the term via route **/
    if($routeParams.Term) {
        $scope.term = $.trim($routeParams.Term);
        
        //Title Change
        titleChange.setTitle('Search results for '+$scope.term);
    }

    

    var currentPage = 1,
        isMoreToFetch = true;

    var render = function(){
            jitoEvents.triggerLoader(true, $translate.instant('FRIENDS_LOADING_FRIENDS'), true);
            listEvents();
    };

    $scope.goToPage = function(path){
        $location.path(path);
    };

    $scope.reload = function(){
        $route.reload();
    };

    var listEvents = function () {

        var data = {};

        if($scope.term) {
            data.term = $scope.term;
        }

        data.paginate = currentPage;

        jitoAjax.call({
            method: 'GET',
            params: data,
            url: jitoSettings.getAPIDomain() + jitoSettings.getSearchSuggestionsFriends(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, listRequestSuccess, listRequestError);

    };

    $scope.loadMore = function () {
        if(isMoreToFetch) {
            $scope.isMoreLoading = true;
            currentPage++;
            listEvents();
        }
    };

    var listRequestSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;

        if (response && response.status && response.status === 200) {
            $scope.connectionsCount = response.data.connections_count;
            $scope.pendingInvCount = response.data.pending_requests;

            if (response.data && response.data.data && Array.isArray(response.data.data.data) && response.data.data.data.length) {
                $scope.listItems.push.apply($scope.listItems, response.data.data.data);
            }
            else {
                onError(response);
            }
        } else {
            onError(response);
        }
    };

    var listRequestError = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;

        if (response.status > 200 && response.status < 500 && response.data) {
            /*ToDo: to check already data has been loaded*/
            if($scope.listItems.length === 0) {
                $scope.errorMessage = jitoCommon.normalizeErrorMessages(response.data);
            }
        }
        else {
            if($scope.listItems.length === 0) {
                $scope.errorMessage = $translate.instant('COMMON_NO_NETWORK');
            }
        }
    };

    var onError = function (response) {
        if (!($scope.listItems.length > 0)) {
            $scope.errorMessage = $translate.instant('FRIENDS_NEW_NO_RESULTS_FOUND', {term: $scope.term});
        }
        if (!(response.data.data.length > 0)) {
            isMoreToFetch = false;
        }
    };

    /** Add friends section **/

    $scope.addFriend = function (friend, index) {

        friend.status = {
            disabled: true
        };

        jitoAjax.call({
            method: 'POST',
            data: {friend_id: friend.id},
            url: jitoSettings.getAPIDomain() + jitoSettings.getFriendAdd(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function(response){addFriendSuccess(response, friend, index)}, function(response){addFriendError(response, friend)});
    };

    var addFriendSuccess = function(response, friend, index) {
        if (response && response.status && response.status === 200 && response.data) {
            friend.status.text =  $translate.instant('FRIENDS_SENT_A_REQUEST_NEW', {term: $scope.term});
            jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));
            $scope.listItems[index].is_added = 1;
        } else {
            addFriendError(response);
        }
    };

    var addFriendError = function(response, friend) {
        if (response.status > 200 && response.status < 500 && response.data) {
            jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response.data));
        }
        else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }

        friend.status.disabled = false;
    };

    $scope.goToFriendPage = function(id, type){
        if(parseInt(type) == 1){
            $location.path('/profile/' + id);
        } else{
            $location.path('/company/profile/' + id);
        }
    };

    $scope.goToMessage = function (userID) {
        jitoShared.setMessageSenderID(userID);
        $location.path(jitoPaths.CONVERSATION);
    };

    render();

    $scope.processRequest = function (index, request, action, event) {
        event.preventDefault();
        event.stopPropagation();

        request.status = {
            disabled: true
        };

        jitoAjax.call({
            method: 'POST',
            data: {id: request.friend_request_id, status: action},
            url: jitoSettings.getAPIDomain() + jitoSettings.getFriendRequestsProcess(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function(response){requestSuccess(response, index, request, action)}, function(response){requestError(response, index, request)});
    };

    var requestSuccess = function(response, index, request, action) {
        if (response && response.status && response.status === 200 && response.data) {
            $scope.pending_requests_display_total -= 1;
            if(action == 1) {
                jitoShared.setMessageSenderID(request.member_from);
                var popupMsg = jitoCommon.normalizeErrorMessages(response.data);
                popupMsg = (popupMsg) ? popupMsg + "<br/><a href='/conversation'>Click here to send a Jito message.</a>" : "";
                jitoHybrid.popup(popupMsg);

                $scope.connections += 1;
                //update user status if approved to change the buttons
                $scope.listItems[index].is_friend = true;
                $scope.listItems[index].is_friend_request_pending = false;
            } else {
                $scope.pending_requests_total -= 1;
                jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));

                //remove from list if ignored
                $scope.listItems[index].is_friend = false;
                $scope.listItems[index].is_friend_request_pending = false;
            }
            jitoEvents.triggerUpdateNotifications();
        } else {
            requestError(response);
        }
    };

    $scope.removeAcceptedFriend = function (index) {
        initialLoad = true;
        listFriends();
    };

    var requestError = function(response, index, request) {
        request.status.disabled = false;

        if (response.status > 200 && response.status < 500 && response.data) {
            jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response.data));
        }
        else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    $scope.removeFriend = function (friend, index) {

        friend.friend_status = 1;
        jitoAjax.call({
            method: 'POST',
            data: {user_id: friend.id},
            url: jitoSettings.getAPIDomain() + jitoSettings.getFriendRemove(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function(response){removeFriendSuccess(response, index, friend)}, function(response){removeFriendError(response, friend)});
    };

    var removeFriendSuccess = function(response, index, friend) {
        friend.friend_status = 1;
        if (response && response.status && response.status === 200 && response.data) {
            jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));
            $timeout(function(){
                //$scope.listItems.splice(index, 1);
                $scope.listItems[index].is_friend = false;
                $scope.connectionsCount -= 1;
            }, 1000);
        } else {
            removeFriendError(response);
        }
    };

    var removeFriendError = function(response, friend) {
        if (response.status > 200 && response.status < 500 && response.data) {
            jitoHybrid.popupError(jitoCommon.normalizeErrorMessages(response.data));
        }
        else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
        friend.friend_status = -1;
    };
});