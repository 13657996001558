/**
 * Created by thusitha_m on 1/24/2016.
 */

app.controller('ProfileAboutController', function ($window,jitoEvents, jitoAjax, jitoCommon, $translate, jitoSettings, $scope, jitoUser, jitoHybrid, $location, jitoPaths) {
    "ngInject";
    $scope.goToPage = function (path) {
        $location.path(path);
    };

    var onError = function (message) {
        $scope.isError = true;
        $scope.errorMessage = message;
    };

    var successCallback = function (response) {
        //jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.user = response.data;
                $scope.isDataFetched = true;
                jitoCommon.prepareAnchorTags();
            }
            else {
                onError($translate.instant('PROFILE_LOADING_ERROR'));
            }
        } else {
            onError($translate.instant('PROFILE_LOADING_ERROR'));
        }
    };

    var errorCallback = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        onError(errorMessage);
    };

    var loadUserProfileDetails = function (profileID) {
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getUserProfileDetailsAPI() + profileID,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, successCallback, errorCallback);
    };

    loadUserProfileDetails();

});