/**
 * Created by thusitha_m on 1/12/2016.
 */

app.factory('jitoEvents', function ($rootScope) {
    "ngInject";
    return {
        //Broadcast an event to show the loader screen
        triggerLoader: function (state, message, fullscreen) {
            $rootScope.$broadcast('loaderService', state, message, fullscreen);
        },

        triggerLogin: function (state) {
            $rootScope.$broadcast('loginService', state);
        },

        triggerProfilePictureSelector: function (state) {
            $rootScope.$broadcast('profilePictureService', state);
        },

        triggerCounterUpdater: function (likes, comments, requests, messages, total) {
            $rootScope.$broadcast('counterUpdateService', likes, comments, requests, messages, total);
        },

        triggerRedirectMessages: function () {
            $rootScope.$broadcast('messagesTrigger');
        },

        triggerNotificationDecreaser: function () {
            $rootScope.$broadcast('notificationDecreaser');
        },

        triggerFetchNotifications: function () {
            $rootScope.$broadcast('fetchNotifications');
        },

        triggerImageReport: function (id) {
            $rootScope.$broadcast('imageReportClicked', id);
        },

        triggerTimeLineMoreLoader: function () {
            $rootScope.$broadcast('triggerTimeLineLoader');
        },

        triggerLogout: function () {
            $rootScope.$broadcast('triggerLogout');
        },

        triggerAlbumViewPaginator: function () {
            $rootScope.$broadcast('albumViewPaginator');
        },

        triggerResetBadgeCounter: function () {
            $rootScope.$broadcast('resetBadgeCount');
        },

        triggerProfileImageMiddleware: function (data, promise) {
            $rootScope.$broadcast('onProfileImageSet', data, promise);
        },

        triggerOrderCancel: function (next) {
            $rootScope.$broadcast('onOrderCancel', next);
        },

        triggerPageStatus: function (status) {
            $rootScope.$broadcast('onPageStatusChanged', status);
        },

        triggerFromTickets: function (state) {
            $rootScope.$broadcast('onFromTickets', state);
        },

        triggerSideMenu: function (pageNumber) {
            $rootScope.$broadcast('changeMenu', pageNumber);
        },

        triggerFriendRequestAccept: function () {
            $rootScope.$broadcast('friendRequestAccept', true);
        },

        triggerUpdateNotifications: function () {
            $rootScope.$broadcast('updateNotifications', true);
        },
    };
});