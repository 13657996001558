/**
 * Created by thusitha_m on 1/24/2016.
 */

app.controller('BrandAboutController', function (titleChange, $scope, $window,$routeParams, $translate,
                                                 jitoEvents, jitoAjax, jitoCommon, jitoSettings, jitoUser, jitoHybrid) {
    "ngInject";
    var profileID = $.trim($routeParams.ProfileID);

    //Title Change
    titleChange.setTitle('Brand name');

    ///////////////////////////////////////////////////////
    //Load user data
    ///////////////////////////////////////////////////////

    var brandProfileSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isError = false;

        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.brandProfile = response.data;
            }
            else {
                onError($translate.instant('BRAND_PROFILE_LOADING_ERROR'));
            }
        } else {
            onError(jitoCommon.normalizeErrorMessages(response.data));
        }
    };

    var onError = function (message) {
        $scope.isError = true;
        $scope.errorMessage = message;
    };

    var brandProfileError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        onError(errorMessage);
    };
 
    var loadBrandDetails = function (profileID) {
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.geBrandDetailsAPI() + profileID,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, brandProfileSuccess, brandProfileError);
    };

    loadBrandDetails(profileID);

    $scope.goToWebsite = function(link){
        jitoHybrid.openExternalLink(link);
    };

});