/**
 * Created by prasanna_j on 23-Jan-2016.
 */

app.controller('UpdateAlbumController', function ($scope, $routeParams, $timeout, $rootScope, jitoCommon, jitoHybrid, jitoEvents, jitoShared,
                                                  jitoSettings, $window, jitoUser, $location, jitoAjax, $translate, jitoValidations, jitoPaths) {
    "ngInject";
    //Initialization
    $scope.isLoading = false;
    $scope.album = jitoShared.getCurrentAlbumDetails();

    $scope.updateAlbum = function (isValidForm) {
        if (isValidForm) {
            updateUserAlbum({
                title: $scope.album.title,
                description: $scope.album.description,
                album_id: $scope.album.id
            });
        }
    };

    var albumUpdateSuccess = function (response) {
        jitoEvents.triggerLoader(false);

        if (response && response.status && response.status === 200) {
            jitoHybrid.popup($translate.instant('ALBUM_UPDATE_SUCCESS'));
            reloadAlbumView($scope.album.id);
        } else {
            showErrorMessage(response);
        }
    };

    var reloadAlbumView = function (albumID) {
        $timeout(function () {
            $rootScope.Ui.turnOff("updateAlbum");
            $location.path(jitoPaths.ALBUM_VIEW + $scope.profileID + "/" + albumID);
        }, 100);

    };

    var albumUpdateError = function (response) {
        showErrorMessage(response);
    };

    var showErrorMessage = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        jitoEvents.triggerLoader(false);
        jitoHybrid.alert(errorMessage, 0);
    };

    var updateUserAlbum = function (albumObject) {
        jitoEvents.triggerLoader(true, $translate.instant('ALBUM_UPDATE_LOADER'), true);

        jitoAjax.call({
            method: 'post',
            data: JSON.stringify(albumObject),
            url: jitoSettings.getAPIDomain() + jitoSettings.getAlbumUpdate(),
            timeout: jitoSettings.PICTURE_TIME_OUT
        }, albumUpdateSuccess, albumUpdateError);
    };

});