/**
 * Created by chathura_f on 9/19/2016.
 */

app.controller('PostCheckoutController', function (titleChange, $scope, jitoPaths, $window, jitoUser, $location,
                                                   $timeout, $rootScope, jitoAjax, jitoSettings) {

    "ngInject";

    $scope.isLogged = jitoUser.isLogged();
    $scope.orderID = $location.search().booking_id;

    $scope.goToPage = function(path){
        $location.path(path);
    };

    $scope.isLoading = true;
    $scope.showTypeMsg = 1;
    $scope.eventName = '';
    $scope.tokenURL = '';

    $scope.redirectToManageAttendee = function(){
        window.location = jitoSettings.getAPIDomain() + "event/invite/regular/attendees?token=" + $scope.tokenURL;
    }

    $scope.getTokenURL = function(){
        //should get the token id for the order id
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getBookingDetailsToManageAteendee() + $scope.orderID,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function (response) {
            $scope.isLoading = false;
            $scope.tokenURL = response.data.table_user_token;
        }, function (error) {
            $scope.isLoading = false;
        });
    }

    jitoAjax.call({
        method: 'get',
        url: jitoSettings.getAPIDomain() + jitoSettings.getOrderEventAPI() + $scope.orderID,
        timeout: jitoSettings.DEFAULT_TIME_OUT
    }, function (response) {
        $scope.isLoading = false;
        $scope.showTypeMsg = 2;
        $scope.eventName = response.data.data.event_details.title;
        $scope.getTokenURL();
    }, function (error) {
        $scope.isLoading = false;
    });

    $scope.openLogin = function(){
        $timeout(function(){
            $rootScope.Ui.toggle("login");
        }, 100);
    };

    //Title Change
    titleChange.setTitle('Order success');

    $scope.currentYear = new Date().getFullYear();
});