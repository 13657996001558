/**
 * Created by prasanna_j on 23-Jan-2016.
 */

app.controller('DeleteAlbumController', function ($scope, $routeParams, $window, $location, $translate,
                                                  jitoCommon, jitoHybrid, jitoEvents, jitoShared, jitoSettings, jitoUser, jitoAjax, jitoValidations, jitoPaths) {
  "ngInject";
  //Initialization
  $scope.isLoading = false;
  $scope.albumDetails = jitoShared.getCurrentAlbumDetails();

  $scope.deleteCurrentAlbum = function () {
    jitoEvents.triggerLoader(true, $translate.instant('ALBUM_DELETE_LOADER'), true);

    jitoAjax.call({
      method: 'post',
      data: JSON.stringify({
        album_id: $scope.albumDetails.id,
        _method: "PUT"
      }),
      url: jitoSettings.getAPIDomain() + jitoSettings.getDeleteAlbum(),
      timeout: jitoSettings.PICTURE_TIME_OUT
    }, albumDeleteSuccess, albumDeleteError);
  };

  var albumDeleteSuccess = function(response) {
    jitoEvents.triggerLoader(false);

    if (response && response.status && response.status === 200) {
      jitoHybrid.popup($translate.instant('ALBUM_DELETE_SUCCESS'));
      $location.path(jitoPaths.ALBUM_LIST+"/"+$scope.profileID);
    } else {
      showErrorMessage(response);
    }
  };

  var albumDeleteError = function(response) {
    showErrorMessage(response);
  };

  var showErrorMessage = function (response) {
    var errorMessage = '';
    if (response.status > 200 && response.status < 500 && response.data) {
      errorMessage = jitoCommon.normalizeErrorMessages(response.data);
    }
    else {
      errorMessage = $translate.instant('COMMON_NO_NETWORK');
    }

    jitoEvents.triggerLoader(false);
    jitoHybrid.alert(errorMessage, 0);
  };

});