/**
 * Created by thusitha_m on 1/16/2016.
 */

app.controller('ProfilePictureOptionsController', function ($scope, jitoEvents, jitoHybrid) {
    "ngInject";
    $scope.setSource = function(type){
        if(type==0){
            //Trigger gallery event            ;
            jitoEvents.triggerProfilePictureSelector(0);
        } else if(type==1){
            //Trigger camera event
            jitoEvents.triggerProfilePictureSelector(1);
        }
    }

});