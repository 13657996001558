/**
 * Created by thusitha_m on 1/12/2016.
 */

app.factory('jitoSettings', function (jitoUser, jitoEvents, $q, $window) {
    "ngInject";

    return {
        /**
         * Global Settings
         */
        VERSION: '2.0',

        NO_PROFILE_IMAGE: 'uploads/profile/150-150/no-prof-pic.jpg',

        NO_BRAND_IMAGE: 'uploads/profile/150-150/default-brand-image.jpg',

        SIGNUP_REQUEST_TIMEOUT: 20000,

        LOCATION_TIME_OUT: 5000,

        DEFAULT_TIME_OUT: 60000,

        PICTURE_TIME_OUT: 120000,

        LOGIN_REQUEST_TIMEOUT: 5000,

        MESSAGE_UPDATE_INTERVAL: 10000,

        POSTS_UPDATE_INTERVAL: 300000,

        PROFILE_PICTURE_QUALITY: 80,

        IMAGE_MAX_SIZE: 20971520, //20Mb

        MAX_PICTURE_WIDTH: 30000,

        MAX_PICTURE_HEIGHT: 30000,

        MAX_IMAGE_WIDTH_HEIGHT: 2048,

        MAX_PICTURE_FIXED_PX: 1000,

        NEARBY_EVENTS_RADIUS: 10, //Kilometers,

        GST: 0.1, //Kilometers

        DEFAULT_COUNTRY: 'AU',

        PAYMENT_METHOD_SIMPLIFY: 'simplify',
        PAYMENT_METHOD_SQUARE: 'square',
        DEFAULT_PAYMENT_METHOD: 'square',

        SQUARE_APPLICATION_ID: $window.SQUARE_CONFIG.applicationId,
        SQUARE_LOCATION_ID: $window.SQUARE_CONFIG.locationId,

        WP_SITE_AUTO_LOGIN_URL: "https://jitoconnectedinspire.com/",

        EVENT_STATE: {
            PENDING: "1",
            APPROVED: "2",
            REJECTED: "3",
            NOT_GOING: "4"
        },

        LIST_STATE: {
            FRIENDS_OF_USER: "0",
            BRANDS_FOLLOWED_BY_USER: "1",
            USERS_ATTEND_IN_EVENT: "2",
            LIKERS: "3",
            FRIENDS_OF_USER_MESSAGE: "4",
            MUTUAL_FRIENDS: "5",
            BRAND_FOLLOWERS: "6"
        },

        INFO_STATE: {
            ABOUT: "0",
            PRIVACY: "1",
            TERMS: "2"
        },

        GENDER: [
            {"id": 0, "value": "Not set"},
            {"id": 1, "value": "Male"},
            {"id": 2, "value": "Female"}
        ],

        GST_INCLUDED_COUNTRIES: {
            AUSTRALIA: "14"
        },

        RELATIONSHIP_STATUS: [
            {"id": 0, "value": "Not set"},
            {"id": 1, "value": "Single"},
            {"id": 2, "value": "In a relationship"},
            {"id": 3, "value": "Engaged"},
            {"id": 4, "value": "Married"},
            {"id": 5, "value": "In a civil union"},
            {"id": 6, "value": "In a domestic partnership"},
            {"id": 7, "value": "In an open relationship"},
            {"id": 8, "value": "It's complicated"},
            {"id": 9, "value": "Separated"},
            {"id": 10, "value": "Divorced"},
            {"id": 11, "value": "Widowed"}
        ],

        OBJECT_TYPE_COMMENT: 1,

        SIMPLIFY_PUBLIC_KEY: ($window.appURL.indexOf('tickets.aforceforgoodhub.com') > 0 && $window.appURL.indexOf('tickets-staging') <= 0) ? 'lvpb_YTQyZTE2YjEtNTlhZi00NDM5LWExMTMtMDFmZGRkNWY4MzE3' :
            'sbpb_ODQxMTRiODUtZmVlNC00YzQ2LWExMzQtZDFkOTExOGE1ZmU2',

        getImageData: function (imageData) {

            var promise = $q.defer();
            jitoEvents.triggerProfileImageMiddleware(imageData, promise);

            /**
             * Set orientation for the image as 1
             * which means the default orientation
             */
            return promise.promise;

        },

        /**
         * API End Points
         */
        getAPIDomain: function () {
            //return 'http://jitoconnected.com/';
            //return 'https://jitoconnected.com/';
            return $window.appURL;
            //return 'http://jitostaging.eyepax.info/';
            //return 'http://jito.eyepax.info/';
            //return 'http://jito-dev.eyepax.info/';
            //return 'http://jitolive-staging.eyepax.info/';
            //return 'http://jitoc.eyepax.info/';
        },

        getEventRsvpAPI: function () {
            return 'api/v6/events/rsvp';
        },
        getLoginAPI: function () {
            return 'api/v6/user/login';
        },

        getUserSignupAPI: function () {
            return "api/v6/user";
        },

        getResetKeyAPI: function () {
            return "api/v6/user/send-password-reset-token";
        },

        setNewPassword: function () {
            return "api/v6/user/reset-password";
        },

        getBrandSignupAPI: function () {
            return "api/v6/brand";
        },

        getEventsList: function () {
            return "api/v6/events/list";
        },

        getEventDetails: function () {
            return "api/v6/events/description/";
        },

        getEventParticipants: function () {
            return "api/v6/events/attendees-list/";
        },

        getPostLikers: function () {
            return "api/v6/likes/get/";
        },

        setPostLikes: function () {
            return "api/v6/likes/add";
        },

        setPostLikesDelete: function () {
            return "api/v6/likes/remove";
        },

        getPostComments: function () {
            return "api/v6/comments/list/";
        },

        setSharePost: function () {
            return "api/v6/post/share-post";
        },

        getUserProfileUpdateAPI: function () {
            return "api/v6/user/update-user-profile";
        },

        getBrandProfileDetailsAPI: function () {
            return "api/v6/brand/all-brand-info";
        },

        getBrandProfileUpdateAPI: function () {
            return "api/v6/brand/update-brand-profile";
        },

        getUserProfileDetailsAPI: function () {
            return "api/v6/user/all-user-info";
        },

        getUserProfileImageUpdateAPI: function () {
            return "api/v6/user/upload-profile-image";
        },

        getCountriesAPI: function () {
            return "api/v6/settings/country-list";
        },

        getCityAPI: function () {
            return "api/v6/settings/city-list?countryCode=";
        },

        geUserDetailsAPI: function () {
            return "api/v6/user/all-friend-info/";
        },

        getUserPublishedArticles: function () {
            return "api/v6/articles/all/";
        },

        geBrandDetailsAPI: function () {
            return "api/v6/brand/all-other-brand-info/";
        },

        geUserFollowBrandsAPI: function () {
            return "api/v6/user/follow-brands/";
        },

        geFriendsListOfUser: function () {
            return "api/v6/friend/friends-by-user?=";
        },

        getBrandFollowersList: function () {
            return "api/v6/brand/brand-followers/";
        },

        getPrivacyUpdateAPI: function () {
            return "api/v6/settings/change-privacy";
        },

        getAlbumList: function () {
            return "api/v6/album/list";
        },

        getAlbumDetails: function () {
            return "api/v6/album/photo?";
        },

        getCreateAlbum: function () {
            return "api/v6/album/create-album";
        },

        getDeleteAlbumImage: function () {
            return "api/v6/album/album-image";
        },

        getAlbumUpdate: function () {
            return "api/v6/album/update-album";
        },

        getDeleteAlbum: function () {
            return "api/v6/album/delete-album";
        },

        getUploadAlbumImage: function () {
            return "api/v6/album/upload-album-image";
        },

        getBrandsListAPI: function () {
            return "api/v6/brand/list";
        },

        getUserPostList: function () {
            return 'api/v6/post/news-feed?';
        },

        getSinglePost: function () {
            return 'api/v6/post/';
        },

        setComments: function () {
            return 'api/v6/comments';
        },

        getConnectionsFriends: function () {
            return "api/v6/friend/list";
        },

        getAllUsersForMessaging: function () {
            return "api/v6/user/all";
        },

        getMutualFriends: function () {
            return "api/v6/friend/mutual/";
        },

        getConnectFriends: function () {
            return "api/v6/friend/network";
        },

        getSearchFriends: function () {
            return "api/v6/friend/search";
        },

        getSearchSuggestionsFriends: function () {
            return "api/v6/friend/suggestions";
        },

        getFriendAdd: function () {
            return "api/v6/friend/connect";
        },

        getFriendRemove: function () {
            return "api/v6/friend/remove";
        },

        getFriendRequestsIncoming: function () {
            return "api/v6/friend/incoming";
        },

        getFriendRequestsOutgoing: function () {
            return "api/v6/friend/outgoing";
        },

        getFriendRequestsProcess: function () {
            return "api/v6/friend/process-connection";
        },

        getFollowUnfollowBrandAPI: function () {
            return 'api/v6/brand/brand-follow';
        },

        getCreateNewPostAPI: function () {
            return 'api/v6/post';
        },

        getCreatePostAttachmentAPI: function () {
            return 'api/v6/post/add-attachment';
        },

        getGlobalSearchAPI: function () {
            return 'api/v6/search';
        },

        getInterestsListAPI: function () {
            return 'api/v6/interest/list';
        },

        getInterestsSubscribeAPI: function () {
            return 'api/v6/interest/subscribe';
        },

        getInterestsDetailsAPI: function () {
            return 'api/v6/interest/detail/';
        },

        getInterestEventDataAPI: function () {
            return 'api/v6/events/interest/';
        },

        getMessageThreadsAPI: function () {
            return 'api/v6/message/list';
        },

        getMessageThreadsListAPI: function () {
            return "api/v6/message/list";
        },

        getMessagesInSingleThreadAPI: function () {
            return 'api/v6/message/thread-messages';
        },

        getCreateNewThreadAPI: function () {
            return 'api/v6/message/create-thread';
        },

        getSendNewMessageAPI: function () {
            return 'api/v6/message/send';
        },

        getThreadSubscribeAPI: function () {
            return "api/v6/message/subscribe";
        },

        getThreadUnsubscribeAPI: function () {
            return "api/v6/message/unsubscribe";
        },

        getMessageUpdatesAPI: function () {
            return 'api/v6/message/new';
        },

        getThreadDeleteAPI: function () {
            return 'api/v6/message/delete-thread';
        },

        getThreadUpdateAPI: function () {
            return "api/v6/message/update-thread";
        },

        getCalendarEventsList: function () {
            return 'api/v6/events/calendar';
        },

        getNotificationTokenAPI: function () {
            return 'api/v6/notification/notification-token';
        },

        getNotificationListAPI: function () {
            return 'api/v6/notification/notification-list';
        },

        getNotificationGroupingAPI: function () {
            return 'api/v6/notification/all-notification';
        },

        getReportAPI: function () {
            return 'api/v6/settings/report-content';
        },

        getLogoutAPI: function () {
            return 'api/v6/user/logout';
        },

        getUserBlockAPI: function () {
            return 'api/v6/friend/user-block-status';
        },

        getUserTimeLineAPI: function () {
            return 'api/v6/post/profile-posts';
        },

        getPostDeleteAPI: function () {
            return 'api/v6/post';
        },

        getChangeNotificationStatusAPI: function () {
            return 'api/v6/settings/change-notifications-status';
        },

        getEmailStatusAPI: function () {
            return 'api/v6/settings/change-mail-notification';
        },

        getEventTimeLineAPI: function () {
            return 'api/v6/events/timeline';
        },

        getUserOptionsStatusAPI: function () {
            return 'api/v6/user/user-setting';
        },

        getBrandsListingAPI: function () {
            return 'api/v6/settings/search-brands';
        },

        getFriendsInviteAPI: function () {
            return 'api/v6/friend/invite';
        },

        getFeedbackPostAPI: function () {
            return 'api/v6/user/feedback';
        },

        getDeletePostAttachmentAPI: function () {
            return 'api/v6/post/delete-attachment';
        },

        getPostUpdateAPI: function () {
            return 'api/v6/post/';
        },

        getPushNotificationsChangeAPI: function () {
            return 'api/v6/settings/change-notification-type-status';
        },

        getAdvertisementList: function () {
            return "api/v6/commercials/random-commercials"
        },

        getUserCountriesAPI: function () {
            return "api/v6/settings/user-country-list";
        },

        getUserCityAPI: function () {
            return "api/v6/settings/user-city-list?countryCode=";
        },

        getEventOrderAPI: function () {
            return "api/v6/events/order/";
        },

        getOrderEventAPI: function () {
            return "api/v6/events/order/event/";
        },

        getTicketDetailsAPI: function () {
            return "api/v6/events/attendee/details/";
        },

        putTicketDetailsAPI: function () {
            return "api/v6/events/attendee/update/";
        },

        putEventOrderUpdateAPI: function () {
            return "api/v6/events/order/update/";
        },

        updateTaxTotalPriceForOrder: function () {
            return "api/v6/update-tax-total-price-for-order/";
        },

        getFeaturedEvents: function () {
            return "api/v6/events/feature";
        },

        getEventFlyerDetails: function(){
            return "api/v6/events/description/"
        },

        getOrderCreationAPI: function(){
            return "api/v6/events/order/create"
        },

        getOrderCancelAPI: function(){
            return "api/v6/payment/manual-cancel/"
        },

        getCouponCodeAPI: function(){
            return "api/v6/validate-coupon"
        },

        getContactUsAPI: function () {
            return "api/v6/contact-us";
        },

        getTableTicketDetails: function () {
            return "api/v6/events/table/order/details/";
        },

        updateCompanyNameForTableTicket: function () {
            return "api/v6/events/table/company/update";
        },

        inviteAttendeeForTableTicket: function () {
            return "api/v6/events/table/attendee/invite";
        },

        saveAttendeeForTableTicket: function () {
            return "api/v6/events/table/attendee/update";
        },

        deleteAttendeeForTableTicket: function () {
            return "api/v6/events/table/attendee/delete";
        },

        resendAttendeeForTableTicket: function () {
            return "api/v6/events/table/attendee/resend/ticket/";
        },

        resendInvitationForTableTicket: function () {
            return "api/v6/events/table/attendee/resend/invite/";
        },

        getBookingDetailsToManageAteendee: function () {
            return "api/v6/events/booking-details-manage-attendee/";
        },

      getArticleHeaderImageUploaderAPI: function () {
            return "api/v6/article/upload-header-image";
        },

        getArticleHeaderRepositionAPI: function () {
            return "api/v6/article/reposition-header-image";
        },

      getArticleImageUploaderAPI: function () {
        return "api/v6/article/upload-article-image/";
      },

      getArticleUploaderAPI: function () {
        return "api/v6/article/save-article/1";
      },

      getArticleDraftAPI: function () {
        return "api/v6/article/save-article/0";
      },

      getArticleImageDeleteAPI: function () {
        return "api/v6/article/delete-article-image/";
      },

      getHeaderImageDeleteAPI: function () {
        return "api/v6/article/delete-header-image";
      },

      getShareAricleAPI: function () {
        return "api/v6/article/share";
      },

      getViewAricleAPI: function () {
        return "api/v6/article/view/";
      },

      getEditArticleAPI: function () {
        return "api/v6/article/edit/";
      },

      getEditArticleSaveAPI: function () {
        return "api/v6/article/update/";
      },

      getArticleListAPI: function () {
        return "api/v6/article/list";
      },

        getArticleDraftListAPI: function () {
            return "api/v6/article/list/draft";
        },

      getArticleDeleteAPI: function () {
        return "api/v6/article/delete-article";
      },

        getNewsletterSignupAPI: function(){
            return "api/v6/newsletter/signup"
        },

      get3DSecurePaymentToken: function () {
            return "api/v6/events/order/token/";
      },

      getUpdated3DSecurePaymentToken: function () {
        return "api/v6/events/order/update-token/";
      },

    };
});
