app.controller('ContactUsController', function (titleChange, $scope, $window, $routeParams, jitoValidations, jitoAjax, $translate, jitoCommon,
                                                jitoSettings, $location, jitoPaths, jitoEvents, jitoHybrid) {
  "ngInject";
  //Title Change
  titleChange.setTitle('Contact us');
  $scope.isLoading = false;
  $scope.errorMessage = '';
  $scope.successMessage = '';

  var init = function () {
    $scope.contactUs = {
      name: '',
      email: '',
      contact_number: '',
      comments: ''
    };
  };

  init();

  var contactUsSuccess = function (response) {
    jitoEvents.triggerLoader(false);
    $scope.isError = false;
    $scope.isLoading = false;

    if (response && response.status && response.status === 200) {
      if (response.data) {
        $scope.successMessage = response.data.message;
        init();
        $scope.contactUsForm.$setPristine();
      }
      else {
        onError($translate.instant('USER_PROFILE_LOADING_ERROR'));
      }
    } else {
      onError(jitoCommon.normalizeErrorMessages(response.data));
    }
  };

  var onError = function (message) {
    $scope.isError = true;
    $scope.errorMessage = message;
  };

  var contactUsError = function (response) {
    var errorMessage = '';

    if (response.status > 200 && response.status < 500 && response.data) {
      errorMessage = jitoCommon.normalizeErrorMessages(response.data);
    } else {
      errorMessage = $translate.instant('COMMON_NO_NETWORK');
    }

    jitoEvents.triggerLoader(false);
    $scope.isLoading = false;
    onError(errorMessage);
  };


  $scope.submitContactUs = function (valid) {
    if(valid) {
      jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'));
      $scope.isLoading = true;
      $scope.errorMessage = '';
      $scope.successMessage = '';

      jitoAjax.call({
        method: 'post',
        url: jitoSettings.getAPIDomain() + jitoSettings.getContactUsAPI(),
        data: $scope.contactUs,
        timeout: jitoSettings.DEFAULT_TIME_OUT
      }, contactUsSuccess, contactUsError);
    }
  };

  $scope.currentYear = new Date().getFullYear();
});