/**
 * Created by thusitha_m on 1/19/2016.
 */
app.filter('jitoAsset', function (jitoSettings) {
    "ngInject";
    return function (url) {
         return jitoSettings.getAPIDomain() + url;
    }
});

app.filter('jitoPersonCounting', function ($translate) {
    "ngInject";
    return function (count) {
        if (count == 0) {
            return $translate.instant('EVENT_NO_ONE_ATTEND');
        } else if (count == 1) {
            return $translate.instant('EVENT_ONE_ATTEND');
        } else if (count > 1) {
            return count + " " + $translate.instant('EVENT_MORE_ATTEND');
        }
    }
});

app.filter('words', function () {
    "ngInject";
    return function (input, words) {
        if (isNaN(words)) return input;
        if (words <= 0) return '';
        if (input) {
            var inputWords = input.split(/\s+/);
            if (inputWords.length > words) {
                input = inputWords.slice(0, words).join(' ') + '…';
            }
        }
        return input;
    };
});

app.filter('isEmpty', function () {
    "ngInject";
    return function (input) {
        if (typeof input == "undefined" || input == null || input == "null" || input == "") {
            return true;
        } else {
            return false;
        }
    };
});

app.filter('jitoNameFilter', function ($translate) {
    "ngInject";
    return function (input) {
        if (typeof value == "undefined" || value == null || value == "null" || value == "") {
            return $translate.instant('MODAL_DIALOG_NAME_NOT_FOUND');
        }
    };
});

app.filter('jitoGenderFilter', function (jitoSettings) {
    "ngInject";
    return function (index) {
       return jitoSettings.GENDER[index].value;
    };
});

app.filter('jitoRelationshipFilter', function (jitoSettings) {
    "ngInject";
    return function (index) {
       return jitoSettings.RELATIONSHIP_STATUS[index].value;
    };
});

app.filter('jitoIsWithinToday', function (jitoSettings) {
    "ngInject";
    return function (date) {

        var past = moment(moment.utc(date).local().format());
        var today = moment(moment());

        if(Math.abs(past.diff(today, 'days')) > 0){
            return false;
        } else{
            return true;
        }

    };
});
app.filter('jitoDateOfBirthFilter', function (jitoValidations) {
    "ngInject";
    return function (date) {

        if(jitoValidations.empty(date)){
            return "Not set"
        } else{
            return date;
        }

    };
});

app.filter('jitoIntegerCounter', function () {
    "ngInject";
    return function (value) {

        if(parseInt(value) > 0){
            return true;
        }  else{
            return false;
        }

    };
});

app.filter('jitoEmptyAddressValidator', function () {
    "ngInject";
    return function (value) {

        if(value == "_" || value == "" || value == "-"){
            return true;
        }  else{
            return false;
        }

    };
});


app.filter('jitoBrandFollowersCount', function () {
    "ngInject";
    return function (value) {

        if(parseInt(value) == 0 ){
            return "No Followers";
        } else if(parseInt(value) == 1 ){
            return "1 Follower";
        } else{
            return value + " Followers";
        }

    };
});

app.filter('padNumber', function(){
    return function(input, len, pad){
        input = input.toString();
        if(input.length >= len) return input;
        else{
            pad = (pad || 0).toString();
            return new Array(1 + len - input.length).join(pad) + input;
        }
    };
});

app.filter('removeHTMLTags', function() {
    return function(text) {
        return  text ? String(text).replace(/<[^>]+>/gm, '') : '';
    };
});