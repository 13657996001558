var app = angular.module('jito', [
  'ngRoute',
  'pascalprecht.translate',
  'mobile-angular-ui',
  "ngSanitize",
  'mobile-angular-ui.gestures',
  'angularMoment',
  "angucomplete",
  "ngEmbed",
  'angular-loading-bar',
  'flow',
  'imageCropper',
  'timer',
  'ipCookie',
  'ngCropper',
  'ngDroplet',
  'ngCroppie',
  'summernote',
  'angularjs-autogrow',
    'jkuri.gallery',
    'angularYoutubePreview'
]);

app.run(function ($transform, $rootScope, $location, titleChange, jitoUser) {
  "ngInject";
  window.$transform = $transform;
  $rootScope.$on('$routeChangeSuccess', function () {
    var path = $location.path();
      $rootScope.currentPath = $location.path();

    var menubar = function () {
      if (jitoUser.isLogged()) {
        $("#jt-top-bar-section").css({
          'visibility': 'visible'
        });
      }
    };

    if (path == '/' || path == '/user/welcome') {
      titleChange.setTitle('JC');
      titleChange.setDefault();
    } else if (path == '/user/forgot_password/step_one') {
      titleChange.setTitle('Forgot password');
      titleChange.setDefault();
    } else if (path == '/user/forgot_password/step_two') {
      titleChange.setTitle('Update password');
      titleChange.setDefault();
    } else if (path == '/user/home') {
      titleChange.setTitle('Home');
      titleChange.setDefault();
    } else if (path == '/user/time_line') {
      titleChange.setTitle('Timeline');
      titleChange.setDefault();
    } else if (path == '/about-web') {
      titleChange.setTitle('About Web');
      titleChange.setDefault();
      menubar();
    } else if (path == '/privacy-web') {
      titleChange.setTitle('Privacy Web');
      titleChange.setDefault();
      menubar();
    } else if (path == '/articles/create') {
      titleChange.setTitle('Create Article');
      titleChange.setDefault();
    } else if (path == '/terms-web') {
      titleChange.setTitle('Terms and conditions');
      titleChange.setDefault();
      menubar();
    } else if (path == '/feedback') {
      titleChange.setTitle('Feedback');
      titleChange.setDefault();
    } else if (path == '/options') {
      titleChange.setTitle('Options');
      titleChange.setDefault();
    } else if (path == '/calendar') {
      titleChange.setTitle('Calendar');
      titleChange.setDefault();
    } else if (path == '/interests/') {
      titleChange.setTitle('Interests');
      titleChange.setDefault();
    } else if (path == '/messages') {
      titleChange.setTitle('Messages');
      titleChange.setDefault();
    } else if (path == '/connect') {
      titleChange.setTitle('Connect');
      titleChange.setDefault();
    } else if (path == '/connect/requests') {
      titleChange.setTitle('Incoming requests');
      titleChange.setDefault();
    } else if (path == '/connect/requests/outgoing') {
      titleChange.setTitle('Outgoing requests');
      titleChange.setDefault();
    } else if (path == '/connect/search') {
      titleChange.setTitle('Search');
      titleChange.setDefault();
    } else if (path == '/user/connect') {
      titleChange.setTitle('Connect');
      titleChange.setDefault();
    } else if (path == '/user/interests') {
      titleChange.setTitle('Interests');
      titleChange.setDefault();
    } else if (path == '/companies') {
      titleChange.setTitle('Companies');
      titleChange.setDefault();
    } else if (path == '/user/search-templates') {
      titleChange.setTitle('Search');
      titleChange.setDefault();
    } else if (path == '/album-add-photos') {
      titleChange.setTitle('Add photos');
      titleChange.setDefault();
    } else if (path == '/company/search') {
      titleChange.setTitle('Companies');
      titleChange.setDefault();
    } else if (path == '/settings') {
      titleChange.setTitle('Settings');
      titleChange.setDefault();
    } else if (path == '/profile-edit') {
      titleChange.setTitle('Update profile');
      titleChange.setDefault();
    } else if (path == '/user/profile-edit') {
      titleChange.setTitle('Update profile');
      titleChange.setDefault();
    } else if (path == '/write-post') {
      titleChange.setTitle('Write Post');
      titleChange.setDefault();
    } else if (path == '/company-edit') {
      titleChange.setTitle('Edit company');
      titleChange.setDefault();
    } else if (path == '/events') {
      titleChange.setTitle('Events');
      titleChange.setDefault();
    } else if (path == '/walkthrough') {
      titleChange.setTitle('Walkthrough');
      titleChange.setDefault();
    } else if (path == '/user/blank') {
      titleChange.setTitle('Messenger');
      titleChange.setDefault();
    } else if (path == '/user/event') {
      titleChange.setTitle('Event');
      titleChange.setDefault();
    } else if (path == '/user/profile') {
      titleChange.setTitle('My Profile');
      titleChange.setDefault();
    } else if (path == '/checkout-error/:errorMessage') {
      titleChange.setTitle('Checkout error');
      titleChange.setDefault();
      menubar();
    } else if (path.indexOf('/checkout') > -1 || path.indexOf('/event') > -1) {
      menubar();
    }
  });
});


app.config(['flowFactoryProvider', function (flowFactoryProvider) {
  "ngInject";
  flowFactoryProvider.factory = fustyFlowFactory;
}]);
