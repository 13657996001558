/**
 * Created by thusitha_m on 1/12/2016.
 */

app.controller('ChangePasswordController', function ($scope, $translate, $location, $timeout, $rootScope,
                                                     SharedState, jitoPaths, jitoEvents, jitoValidations, jitoAjax, jitoStorage, jitoSettings, jitoUser, jitoHybrid, jitoCommon) {
    "ngInject";
    //Clear the code sent status and redirect to the step one
    $scope.sendCodeAgain = function () {
        jitoStorage.remove('PASSWORD_REQUESTED');
        $timeout(function(){
            $rootScope.Ui.toggle("changePassword");
            $rootScope.Ui.toggle("forgotPassword");
        }, 100);
    };

    $scope.changePassword = function (isValidForm) {

        if (isValidForm && $scope.password === $scope.cpassword) {
            jitoEvents.triggerLoader(true, $translate.instant('CHANGE_PASSWORD_MESSAGE'), true);
            $scope.isRequesting =  true;
            jitoAjax.call({
                method: 'post',
                data: JSON.stringify({
                    userId: jitoStorage.get('PASSWORD_REQUESTED_USER_ID'),
                    password: $scope.password,
                    resetCode: $scope.code,
                    confirmPassword: $scope.cpassword
                }),
                url: jitoSettings.getAPIDomain() + jitoSettings.setNewPassword(),
                timeout: jitoSettings.SIGNUP_REQUEST_TIMEOUT
            }, changePasswordSuccess, sendResetCodeError);
        }
    };

    var changePasswordSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isRequesting =  false;

        if (response && response.status && response.status === 200) {
            jitoStorage.remove('PASSWORD_REQUESTED');
            jitoStorage.remove('PASSWORD_REQUESTED_USER_ID');
            jitoHybrid.alert($translate.instant('CHANGE_PASSWORD_CHANGED_TEXT'), 0);

            $timeout(function(){
                $rootScope.Ui.toggle("changePassword");
                $rootScope.Ui.toggle("login");
            }, 100);

        } else {
            showError(response);
        }
    };

    var showError = function(response){
        var errorMessage = '';
        $scope.isRequesting =  false;
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        jitoHybrid.alert(errorMessage, 0);
    };


    var sendResetCodeError = function (response) {
        showError(response);
    };

    $scope.checkPassword = function () {
        $scope.userForm.uCPassword.$error.dontMatch = $scope.password !== $scope.cpassword;
    };

    $scope.goToTerms = function(){
        $scope.TYPE = jitoSettings.INFO_STATE.TERMS;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_TERMS_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_TERMS_DESC');
        jitoCommon.openInfoModal();
    };

    $scope.goToAbout = function(){
        $scope.TYPE =jitoSettings.INFO_STATE.ABOUT;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_ABOUT_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_ABOUT_DESC');
        jitoCommon.openInfoModal();
    };

    $scope.goToPrivacyPolicy = function(){
        $scope.TYPE = jitoSettings.INFO_STATE.PRIVACY;
        $scope.TITLE = $translate.instant('MODAL_DIALOG_INFO_PRIVACY_TITLE');
        $scope.DESCRIPTION = $translate.instant('MODAL_DIALOG_INFO_PRIVACY_DESC');
        jitoCommon.openInfoModal();
    };

});