app.controller('LogoutController', function ($rootScope, $timeout, $window, $route, jitoEvents, SharedState, jitoShared, jitoHybrid, jitoCommon, $scope, jitoValidations, $translate, jitoStorage, jitoAjax, $location, $window, jitoPaths, jitoSettings, jitoUser, $rootScope) {
    "ngInject";

    ///////////////// Logout ////////////////////////

    var logoutSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {

            jitoHybrid.popup(jitoCommon.normalizeErrorMessages(response.data));
            jitoEvents.triggerResetBadgeCounter();
            $scope.parentObject.searchText = null;
            $scope.parentObject = {};
            jitoUser.logout();
            jitoEvents.triggerLogout();
        } else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    var logoutError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        jitoHybrid.popupError(errorMessage);

        if (response.status == 403) {
            $scope.parentObject.searchText = null;
            $scope.parentObject = {};
            jitoUser.logout();
        }
    };

    var logout = function () {
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        jitoAjax.call({
            method: 'post',
            data: {
                user_id: jitoUser.getUserID(),
                device_id: jitoStorage.get('DEVICE_ID')
            },
            url: jitoSettings.getAPIDomain() + jitoSettings.getLogoutAPI(),
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, logoutSuccess, logoutError);
    };

    $scope.logout = function () {
        //Clear search term
        logout();
    };

    $scope.logout();

});