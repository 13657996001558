/**
 * Created by thusitha_m on 1/12/2016.
 */

(function () {
    "use strict";

    var translations = {

        ARTICLE_HEADER_IMAGE_REQ: 'Header image is required',

        SIGNUP_EMAIL_REQ: 'Email is required.',
        SIGNUP_FIRST_NAME_REQ: 'First name is required.',
        SIGNUP_LAST_NAME_REQ: 'Last name is required.',
        SIGNUP_BRAND_NAME_REQ: 'Company name is required.',
        SIGNUP_PW_REQ: 'Password is required.',
        SIGNUP_INVALID_EMAIL: 'Invalid email.',
        SIGNUP_REGISTER_PAGE_EMAIL_CHAR_LENGTH: 'Email should be less than 128 characters',
        SIGNUP_ERROR: 'An error has been occurred...',
        SIGNUP_VALIDATION_ERROR: 'The member already registered',
        SIGNUP_NO_NETWORK: 'There is an error occurred in the network connection! Please try again later.',
        SIGNUP_SUCCESS: 'Success!',
        SIGNUP_REGISTERED: 'You have been successfully registered. Please login to continue...',
        SIGNUP_BUTTON: 'I agree & join',
        LOGIN_SIGNUP_BUTTON: 'Sign up',
        SIGNUP_MESSAGE: 'Signing up...',
        SIGNUP_PROFILE_PICTURE_REQ: 'Please select a profile picture.',
        SIGNUP_BRAND_PICTURE_REQ: 'Please select a company logo.',
        SIGNUP_PW_MAX: 'Password should be less than 50 characters.',
        SIGNUP_PW_MIN: 'Password should contain at least 6 characters.',
        SIGNUP_FN_LENGTH: 'First name should be less than 50 characters.',
        SIGNUP_LN_LENGTH: 'Last name should be less than 50 characters.',
        SIGNUP_BRAND_LENGTH: 'Company name should be less than 50 characters.',
        SIGNUP_JOB_TITLE_REQ: 'Job title is required.',
        SIGNUP_JT_LENGTH: 'Job Title should be less than 50 characters.',
        SIGNUP_EMPLOYMENT_REQ: 'Current employment is required.',
        SIGNUP_EMPLOYMENT_LENGTH: 'Current employment should be less than 250 characters.',
        SIGNUP_COUNTRY_REQ: 'Country is required.',
        SIGNUP_CITY_REQ: 'City is required.',
        SIGNUP_EMPLOYMENT_LEN: 'Current employment should be less than 50 characters.',

        LOGIN_BUTTON: 'log in',
        LOGIN_TOKEN_EMPTY: 'Invalid username or password.',
        LOGIN_USER_TYPE_EMPTY: 'User type field is empty.',
        LOGIN_USER_ID_EMPTY: 'Member ID is empty.',
        LOGIN_NO_NETWORK: 'No Internet Access Available.',
        LOGIN_VALIDATION_ERROR: 'Some fields contain incorrect data',
        LOGIN_ERROR: 'Invalid username or password',
        LOGIN_MESSAGE: 'Logging in...',

        FORGOT_PASSWORD_NEXT: 'next',
        FORGOT_PASSWORD_TEXT: 'Forgot Password',
        FORGOT_PASSWORD_PARA: 'Forgot Password? Please submit your email to us.',

        CHANGE_PASSWORD_PARA: 'Please enter the verification number that we sent to your email. It will expire within 24 hours.',
        CHANGE_PASSWORD_BOTTOM_TEXT: 'Didn\'t receive the verification number?',
        CHANGE_PASSWORD_BOTTOM_LINK: 'Resend the verification email',
        CHANGE_PASSWORD_INVALID_VERIFICATION_NO: 'Invalid verification number.',
        CHANGE_PASSWORD_VERIFICATION_NO_REQ: 'Verification number is required.',
        CHANGE_PASSWORD_REQ: 'Password is required.',
        CHANGE_PASSWORD_LENGTH: 'Password length must be between 6-50.',
        CHANGE_NEW_PASSWORD_LENGTH: 'New password length must be between 6-50.',
        CHANGE_PASSWORD_CONFIRM_REQ: 'Confirm password is required.',
        CHANGE_PASSWORD_CONFIRM_MISMATCH: 'Passwords do not match.',
        CHANGE_PASSWORD_BUTTON: 'submit',
        CHANGE_PASSWORD_CHANGED_TEXT: 'Password successfully changed',
        CHANGE_PASSWORD_MESSAGE: 'Setting up your new password...',

        JITO_HEADER_ONE: 'Creating One Community',
        JITO_HEADER_TWO: 'for the travel, hospitality & tourism industry to connect',

        COMMON_ERROR: 'An error has been occurred.',
        COMMON_VALIDATION_ERROR: 'Server validations failed.',
        COMMON_NO_NETWORK: 'There is an error occurred in the network connection! Please try again later.',
        COMMON_PLEASE_WAIT: 'Please wait...',
        COMMON_PLATFORM_NOT_SUPPORT: 'This device does not support this feature',

        COMMON_PRIVACY_POLICY: 'privacy policy',
        COMMON_TERMS_CONDITIONS: 'terms & conditions',
        COMMON_ABOUT_US: 'about us',

        EVENTS_LIST_UPCOMING_EVENTS: 'Events Upcoming',
        EVENTS_LIST_START_DATE: 'Start date',
        EVENTS_LIST_END_DATE: 'End date',
        EVENTS_LIST_NEARBY_EVENTS: 'Events Nearby',
        EVENTS_RETRIEVE: 'Retrieving Events',
        EVENTS_NO_EVENTS: 'No events found.',
        EVENTS_I_ATTEND: 'Going',
        EVENTS_I_NOT_ATTEND: 'Not going',
        EVENTS_PENDING: 'Approval pending',
        EVENTS_REJECTED: 'Your request has been rejected',
        EVENTS_LOADING_EVENTS: 'Loading events...',
        EVENTS_RETRIEVE_LOCATION: 'Retrieving your location...',
        EVENTS_LOCATION_ERROR: 'Retrieving your location...',
        EVENTS_SUBSCRIPTION_ERROR: 'An error occurred while subscribing to the event',

        EVENTS_LOCATION_PERMISSION_DENIED: 'Please enable location services in your device and try again.',
        EVENTS_LOCATION_POSITION_UNAVAILABLE: 'The device is unable to retrieve your current location. Please try again later.',
        EVENTS_LOCATION_TIMEOUT: 'The device is unable to retrieve your current location. Make sure you turned on the location services and ' +
        'try again later.',

        EVENT_LOADING_MESSAGE: 'Loading event details...',
        EVENT_LOADING_ERROR: 'An error occurred while loading event data. Please try again later.',
        EVENT_NO_ONE_ATTEND: 'Be the first to subscribe',
        EVENT_ONE_ATTEND: '1 person is attending',
        EVENT_MORE_ATTEND: 'people are attending',
        EVENT_ATTENDEE_LIST_TEXT: 'See who is attending',
        EVENT_LOADING_USERS: 'Loading participants...',
        EVENT_NEARBY_DISTANCE: 'Events within {{value}} km radius',


        PROFILE_LOADING_ERROR: 'An error occurred while fetching your profile data. Please try again later.',

        PROFILE_EDIT_NEW_PLACE_ERROR: 'This place is already inserted.',
        PROFILE_EDIT_EDUCATION_ERROR: 'This place is already inserted.',
        PROFILE_EDIT_SKILLS_ERROR: 'This skill is already inserted.',
        PROFILE_EDIT_PAST_JOBS_ERROR: 'This past job is already inserted.',
        PROFILE_EDIT_INTERESTS_ERROR: 'This interest is already inserted.',
        PROFILE_EDIT_QUOTES_ERROR: 'This quote is already inserted.',

        PROFILE_EDIT_NAME: 'Name*',
        PROFILE_EDIT_NAME_FIRST: 'First name (max:50)',
        PROFILE_EDIT_NAME_LAST: 'Last name (max:50)',

        PROFILE_EDIT_EMAIL: 'Email*',
        PROFILE_EDIT_EMAIL_PH: 'Your email',

        PROFILE_EDIT_PHONE: 'Phone',
        PROFILE_EDIT_PHONE_PH: 'Phone number',

        PROFILE_EDIT_LOCATION: 'Location*',
        PROFILE_EDIT_LOCATION_COUNTRY_PH: 'Country',
        PROFILE_EDIT_LOCATION_CITY_PH: 'City',

        PROFILE_EDIT_PASSWORD: 'Change Your Password',
        PROFILE_EDIT_CURRENT_PW: 'Current password',
        PROFILE_EDIT_NEW_PW: 'New password',
        PROFILE_EDIT_CONFIRM_PW: 'Confirm password',

        PROFILE_EDIT_GENDER: 'Gender',
        PROFILE_EDIT_BIRTHDAY: 'Date of Birth',
        PROFILE_EDIT_RELATIONSHIP_STATUS: 'Relationship Status',

        PROFILE_EDIT_PLACE_ADD: 'Add',
        PROFILE_EDIT_PLACE_TITLE: 'Places Where You Lived',
        PROFILE_EDIT_PLACE_PH: 'New place (max:250)',

        PROFILE_EDIT_CURRENT_EMPLOYMENT: 'Company*',
        PROFILE_EDIT_CURRENT_EMPLOYMENT_PH: 'Your company (max:250)',

        PROFILE_EDIT_JOB_TITLE: 'Job Title*',
        PROFILE_EDIT_JOB_TITLE_PH: 'Your job title (max:250)',

        PROFILE_EDIT_EDUCATION: 'Education',
        PROFILE_EDIT_EDUCATION_ADD: 'Add',
        PROFILE_EDIT_EDUCATION_PH: 'Your education institute (max:250)',


        PROFILE_EDIT_PROFESSIONAL: 'Professional Skills',
        PROFILE_EDIT_PROFESSIONAL_ADD: 'Add',
        PROFILE_EDIT_PROFESSIONAL_PH: 'Your skill (max:250)',

        PROFILE_EDIT_PAST_JOBS: 'Past Jobs',
        PROFILE_EDIT_PAST_JOBS_ADD: 'Add',
        PROFILE_EDIT_PAST_JOBS_TITLE_PH: 'Job title (max:250)',
        PROFILE_EDIT_PAST_JOBS_COMPANY_PH: 'Company (max:250)',

        PROFILE_EDIT_INTERESTS: 'Interests',
        PROFILE_EDIT_INTERESTS_ADD: 'Add',
        PROFILE_EDIT_INTERESTS_PH: 'Your likes and interests (max:250)',

        PROFILE_EDIT_ABOUT_ME: 'About Me',
        PROFILE_EDIT_ABOUT_ME_PH: 'Tell us something about yourself...',

        PROFILE_EDIT_QUOTE: 'Your Favourite Quotes',
        PROFILE_EDIT_QUOTE_ADD: 'Add',
        PROFILE_EDIT_QUOTE_PH: 'You favourite quotes... (max:250)',

        PROFILE_EDIT_CONTACT_EMAIL : 'Contact Email',
        PROFILE_EDIT_CONTACT_EMAIL_PH: 'Your contact email',
        PROFILE_EDIT_INVALID_CONTACT_EMAIL: 'Invalid contact email.',
        PROFILE_EDIT_CONTACT_EMAIL_MAX: 'Contact email should be less than 128 characters',

        PROFILE_EDIT_CURRENT_EMPLOYMENT_MAX_LENGTH: 'Company length should be less than 100 characters',
        PROFILE_EDIT_CURRENT_EMPLOYMENT_REQUIRED: 'Company is required',
        PROFILE_EDIT_CURRENT_JOB_TITLE_MAX_LENGTH: 'Current job title length should be less than 100 characters',
        PROFILE_EDIT_CURRENT_JOB_TITLE_REQUIRED: 'Current job title is required.',

        PROFILE_EDIT_DATE_ERROR: 'Invalid date format.',
        PROFILE_EDIT_DATE_MAX: 'Invalid date of birth.',
        PROFILE_EDIT_DATE_MIN: 'Invalid data of birth.',
        PROFILE_EDIT_SECURITY: 'Security',

        PROFILE_EDIT_LOCATION_EMPTY: 'Location is required.',

        PROFILE_EDIT_INCOMPLETE_ERROR: 'Please update required fields first',
        PROFILE_EDIT_IMAGE_EMPTY: 'Profile image is empty',


        BRAND_EDIT_NAME: 'Company Name*',
        BRAND_EDIT_NAME_PH: 'Your company Name (max:50)',
        BRAND_EDIT_EMAIL: 'Email*',
        BRAND_EDIT_EMAIL_PH: 'Company email*',
        BRAND_EDIT_WEBSITE: 'Website',
        BRAND_EDIT_WEBSITE_PH: 'www.your-company.com',
        BRAND_EDIT_ABOUT: 'About',
        BRAND_EDIT_ABOUT_PH: 'Tell us something about your company... (maximum character :1000)',
        BRAND_EDIT_COUNTRY: 'Country',
        BRAND_EDIT_LOCATION: 'Location*',
        BRAND_EDIT_STATE_PH: 'State',
        BRAND_EDIT_CITY: 'City (optional)',
        BRAND_EDIT_POSTAL_CODE_PH: 'Postal Code (optional)',
        BRAND_EDIT_STREET_PH: 'Street name (optional)',
        BRAND_EDIT_PHONE: 'Phone Number',
        BRAND_EDIT_PHONE_PH: 'Phone number',
        BRAND_EDIT_TAGLINE: 'Tag-line',
        BRAND_EDIT_TAGLINE_PH: 'This will go on top of your profile',
        BRAND_EDIT_IMAGE_EMPTY: 'Company logo is empty',

        BRAND_EDIT_BRAND_NAME_MAX: 'Company name should be less than 50 characters.',
        BRAND_EDIT_BRAND_NAME_REQUIRED: 'Company name is required.',
        BRAND_EDIT_BRAND_EMAIL_REQUIRED: 'Email is required.',
        BRAND_EDIT_BRAND_EMAIL_EMAIL: 'Invalid email.',
        BRAND_EDIT_BRAND_EMAIL_MAX: 'Email should be less than 128 characters.',
        BRAND_EDIT_WEBSITE_INVALID: 'Invalid website URL.',
        BRAND_EDIT_POSTAL_CODE_INVALID: 'Invalid postal code.',


        USER_PROFILE_LOADING_ERROR: 'An error occurred while fetching member profile.',
        USER_PROFILE_FOLLOW_BRANDS: 'Company {{value}} follows',
        USER_PROFILE_ABOUT: 'About',
        USER_PROFILE_PHOTOS: 'Photos',
        USER_PROFILE_FRIENDS: 'Friends',
        USER_PROFILE_CONTACT: 'Contact',


        BRAND_PROFILE_LOADING_ERROR: 'An error occurred while fetching company profile.',
        BRAND_PROFILE_WE_FOLLOW: 'Company we follow',
        BRAND_PROFILE_ABOUT: 'About',
        BRAND_PROFILE_PHOTOS: 'Photos',
        BRAND_PROFILE_RECENT_ALBUMS: 'Recent Albums',
        BRAND_PROFILE_FRIENDS: 'Friends',


        HOME_PROFILE_TEXT : "PROFILE",
        HOME_NEWSFEED_TEXT : "NEWSFEED",
        HOME_EVENTS_TEXT : "EVENTS",
        HOME_MESSENGER_TEXT : "MESSENGER",
        HOME_CONNECT_TEXT : "CONNECT",
        HOME_BRANDS_TEXT : "COMPANY",
        HOME_CALENDAR_TEXT : "CALENDAR",
        HOME_SETTINGS_TEXT : "SETTINGS",
        HOME_JITO_CO_TEXT : "VIEW JOBS",

        MODAL_DIALOG_BRANDS_TITLE : "Company Following",
        MODAL_DIALOG_COMMENTS_TITLE : "Comments",
        MODAL_DIALOG_FRIEND_LIST_TITLE : "People who liked this",
        MODAL_DIALOG_FRIENDS_LIST : "Connections",
        MODAL_DIALOG_FOLLOWERS_LIST : "Followers",
        MODAL_DIALOG_MUTUAL_FRIENDS_LIST : "Mutual Connections",
        MODAL_DIALOG_BRANDS_NOT_FOUND : "No company found.",
        MODAL_DIALOG_FRIENDS_NOT_FOUND : "No connections found.",
        MODAL_DIALOG_MFRIENDS_NOT_FOUND : "No mutual connections found.",
        MODAL_DIALOG_NAME_NOT_FOUND : "Name not found",
        MODAL_DIALOG_EVENT_ATTENDEE_TITLE : "See who is attending",
        MODAL_DIALOG_EVENTS_NOT_FOUND : "No events found.",
        MODAL_DIALOG_LIKERS_NOT_FOUND : "No likes for this post",
        MODAL_DIALOG_COMMENTS_NOT_FOUND : "No comments found",

        MODAL_DIALOG_INFO_TERMS_TITLE : "Terms and Conditions",
        MODAL_DIALOG_INFO_TERMS_DESC : "Terms and conditions goes here...",
        MODAL_DIALOG_INFO_ABOUT_TITLE : "About Us",
        MODAL_DIALOG_INFO_ABOUT_DESC : "Something about Jito.co...",
        MODAL_DIALOG_INFO_PRIVACY_TITLE : "Privacy Policy",
        MODAL_DIALOG_INFO_PRIVACY_DESC : "The privacy policies goes here...",

        ALBUMS_NO_ALBUMS: 'No albums found.',
        ALBUMS_LOADING_ALBUMS: 'Loading albums...',
        ALBUM_LOADING_ERROR: 'An error occurred while loading album details. Please try again later.',
        ALBUM_LOADING_MESSAGE: 'Loading album details...',
        ALBUM_ADD_PHOTOS: 'Add',
        ALBUM_REMOVE_PHOTOS: 'Remove',
        ALBUM_NO_PHOTOS: 'No photos in the album.',
        ALBUM_CREATE_ALBUM: 'Create an album',
        ALBUM_TITLE: 'Title',
        ALBUM_TITLE_MAX_LENGTH: 'Max length is 100',
        ALBUM_DESCRIPTION_MAX_LENGTH: 'Max length is 2000',
        ALBUM_FIELDS_VALIDATION: 'Title is required and max length is 100. Description max length is 2000.',
        ALBUM_DESCRIPTION: 'Description',
        ALBUM_CREATE_LABEL: 'Create',
        ALBUM_CREATE_LOADER: 'Creating album...',
        ALBUM_TITLE_REQ: 'Title is required.',
        ALBUM_CREATE_SUCCESS: 'Album has been successfully created.',
        ALBUM_DELETE_PHOTOS: 'Delete selected',
        ALBUM_EMPTY_SELECT: 'Please select at least one photo to delete',
        ALBUM_DELETING_IMAGES: 'Deleting photos...',
        ALBUM_PHOTOS_DELETED: 'photo(s) deleted.',
        ALBUM_PHOTOS_DELETE_ERROR: 'photo(s) were not removed.',
        ALBUM_EDIT_ALBUM: 'Edit album details',
        ALBUM_UPDATE_ALBUM: 'Update',
        ALBUM_UPDATE_LOADER: 'Updating album details...',
        ALBUM_UPDATE_SUCCESS: 'Album details have been successfully updated.',
        ALBUM_DELETE_ALBUM: 'Delete an album',
        ALBUM_DELETE_CONFIRM: 'Are you sure ?',
        ALBUM_DELETE_YES: 'Yes',
        ALBUM_DELETE_NO: 'No',
        ALBUM_DELETE_LOADER: 'Deleting album...',
        ALBUM_DELETE_SUCCESS: 'Album successfully deleted.',
        ALBUM_PHOTOS_UPLOAD_SUCCESS: 'photo(s) uploaded.',
        ALBUM_PHOTOS_UPLOAD_ERROR: 'photo(s) were not uploaded.',
        ALBUM_PHOTO_SUCCESS: 'Success',
        ALBUM_PHOTO_FAILED: 'Failed',

        FRIENDS_LOADING_FRIENDS: 'Loading connections...',
        FRIENDS_HEADING_FRIENDS: 'Connections',
        FRIENDS_HEADING_SEARCH: 'Search Members',
        FRIENDS_HEADING_REQUESTS: 'Requests',
        FRIENDS_NO_RESULTS_FOUND: 'No connections found.',
        FRIENDS_LOADING_ERROR: 'An error occurred while retrieving connections information. Please try again later.',

        CONNECTIONS_HEADING_PPLYOUMAY: 'People you may know',
        CONNECTIONS_HEADING_PENDINGINVI: 'Pending invitations',
        CONNECTIONS_HEADING_CONNECTION: 'Connections',

        FRIENDS_SEARCH_PLACEHOLDER: 'Search for your connections',
        FRIENDS_SEARCH_PLACEHOLDER_NEW: 'Search connections',
        FRIENDS_SEARCH_BUTTON: 'Search',
        FRIENDS_SEARCH_TEXT: 'No connections yet? Search for your connections in network.',

        FRIENDS_NEW_SEARCH_PLACEHOLDER: 'Search for new connections',
        FRIENDS_NEW_SEARCH_BUTTON: 'Search',
        FRIENDS_NEW_SEARCH_REMOVE_FRIEND: 'Add connection',
        FRIENDS_SENT_A_REQUEST: 'Request sent',
        FRIENDS_SENT_A_REQUEST_NEW: 'Sent',
        FRIENDS_NEW_SEARCH_ADD_FRIEND: 'Add connection',
        FRIENDS_NEW_SEARCH_TEXT: 'Find your connections by their name, email, profession or company.',
        FRIENDS_NEW_NO_RESULTS_FOUND: 'No results for: {{term}}',

        FRIENDS_REQUESTS_INCOMING: 'Received',
        FRIENDS_REQUESTS_OUTGOING: 'Sent',
        FRIENDS_REQUESTS_NOT_FOUND: 'No connection requests found.',
        FRIENDS_REQUESTS_ACCEPT: 'Accept',
        FRIENDS_REQUESTS_DELETE: 'Delete',
        FRIENDS_REQUESTS_ACCEPTED: 'You and {{name}} are now connected.',
        FRIENDS_REQUESTS_DELETED: 'Request deleted successfully.',
        FRIENDS_REQUESTS_LOADING_ERROR: 'An error occurred while performing your request. Please try again later.',

        BRANDS_FOLLOWED: 'Company followed',
        BRANDS_TITLE: 'Companies',
        BRANDS_SEARCH: 'Search',
        BRANDS_FOLLOW: 'Follow',
        BRANDS_FOLLOWING: 'Following',
        BRANDS_SEARCH_PLACEHOLDER: 'Search for company',

        POSTS_LIKE: 'Like',
        POSTS_UNLIKE: 'Unlike',
        POSTS_COMMENT: 'Comment',
        POSTS_SHARE: 'Share',
        POSTS_SHARED: 'shared',
        POST_SHARED_SUCCESS: 'This has been shared.',
        POSTS_PEOPLE: 'people',
        POSTS_LIKE_THIS: 'like this',
        POSTS_POST: 'POST',
        POSTS_NO_POSTS: 'No posts found',
        POSTS_EMPTY_TEXT: 'Status update appears to be blank.',
        POSTS_COMMENTS: 'comments',

        BRANDS_NO_BRANDS: 'No company found.',
        BRANDS_ABOUT_CONTACT: 'Contact Information',

        WRITE_POST_CONTENT_REQUIRED: 'This status update appears to be blank. Please write something or attach a photo to update your status.',
        WRITE_POST_TEXT_PH: "What's on your mind?",


        POST_WAIT_MESSAGE: "Posting you status...",
        SEARCH_NOT_ITEMS_FOUND: "No search results found.",
        EVENTS_TEXT: "Events",
        PEOPLE_TEXT: "People",
        BRAND_TEXT: "Company",

        SEARCH_VIEW_MORE: "View more",

        INTERESTS_TITLE: "Interests",
        INTERESTS_SEARCH_INTERESTS_PH: "Search your interests",
        INTERESTS_SEARCH_PH: "Search",
        INTERESTS_SUBSCRIBED: "Subscribed",
        INTERESTS_SUBSCRIBE: "Subscribe",
        INTERESTS_NO_INTERESTS: "No interests found.",

        MODAL_DIALOG_NO_ATTENDEES_FOUND: "No event attendees found.",

        MESSAGES_NO_MESSAGES: "No message threads found.",
        MESSAGES_EMPTY_CONVERSATION: "It's empty here. Send a message to start the conversation.",
        MESSAGE_TEXT_AREA_PH: "Type your message here... (max:1000)",
        MESSAGE_CONTENT_EMPTY: "Message content is empty.",
        MESSAGE_CONTENT_USER_NOT_ACTIVE: "This user is not active.",

        SET_AN_REMINDER: "Add a reminder",
        CREATE_EVENT_ERROR: "Unable to create event",

        NOTIFICATIONS_EMPTY: "No new notifications.",
        NOTIFICATIONS_TITLE: "Notifications",

        REPORT_CONTENT_EMPTY: "Please provide a reason",
        REPORT_REASON_TEXT: "Reason:",
        REPORT_TITLE: "Report",
        REPORT_PH: "Feel free to share your concerns",

        USER_BLOCK_TEXT: "Block this member",
        REPORT_TEXT: "Report",

        WRITE_POST_IMAGE_UPLOAD_LIMIT: "A post can have one image only",
        TIME_LINE_COMMENT_PH: "Write a comment",

        SETTINGS_INVITE_FRIENDS: "Invite Friends via Email",
        SETTINGS_INVITE_FRIENDS_TITLE: "Invite your friends via email",
        SETTINGS_INVITE_FRIENDS_DESC: "Enter an email address that you wish to send an invitation to. Click the + button to add more emails.",

        INVITE_EMAIL_LIST_EMPTY: "Email list is empty",
        INVITE_EMAIL_ALREADY_INCLUDED: "This email is already added",
        INVITE_INVALID_EMAIL: "Invalid Email",
        INVITE_EMPTY_EMAIL: "Email field is empty",
        INVITE_EMAIL_MAXIMUM_AMOUNT: "You have reached the maximum email count",

        FEEDBACK_TITLE: "We would love to hear from you",
        FEEDBACK_DESC: "We love to hear from our members and get their opinions! We are always looking for great new ideas to make Jito connected better.",
        FEEDBACK_PH: "Feel free to share your thoughts (max:1000)",
        FEEDBACK_EMPTY: "Feedback is empty",

        EDIT_POST_TITLE: "Edit Post",
        EDIT_POST_UPDATE_BUTTON: "Update",


        OPTIONS_PUSH_NOTIFICATIONS: "Push Notifications",
        OPTIONS_LIKES_NOTIFICATIONS: "Likes",
        OPTIONS_COMMENTS_NOTIFICATIONS: "Comments",
        OPTIONS_MESSAGES_NOTIFICATIONS: "Messages",
        OPTIONS_FRIEND_REQUESTS_NOTIFICATIONS: "Connection requests",
        OPTIONS_NOTIFICATIONS: "Notifications",
        OPTIONS_EMAIL_NOTIFICATIONS: "Email notifications",

        EDIT_POST_CONTENT_EMPTY: "Please add an image or a status update",

        WARNING_CANCEL_CHECKOUT: "You are in the middle of a checkout process. Do you wish to cancel this checkout and exit ?",
        TICKET_SESSION_SELECTION_ERROR: "Please select appropriate session for each time slot.",
        YOUR_PROFILE: 'Your profile',

        TABLE_TICKET_MANAGEMENT: {
            TITLE: 'Invite attendees to your table',
            COMPANY_NAME: 'Company name',
            COMPANY_NAME_REQUIRED: 'Company name is required.',
            COMPANY_NAME_MAX_LENGTH: 'Company name should be less than 250 characters.',
            SUBMIT: 'Update',
            FILL_INSTRUCTIONS: 'Please fill the details of the attendees to your table. If you are an attendee, please add in your details as first attendee.',
            ATTENDEE: 'Attendee',
            EMAIL: 'Email',
            INVITE_ATTENDEE: 'Invite attendee',
            DELETE: 'Delete',
            RESEND: 'Resend',
            RESEND_TICKET: 'Resend ticket',
            DOWNLOAD_TICKET: 'Download ticket',
            EDIT: 'Edit',
            SAVE: 'Save',
            CANCEL: 'Cancel',
            DELETE_CONFIRM: 'Do you really want to delete this ticket details?',
            RESEND_ATTENDEE_DETAILS_CONFIRM: 'Do you really want to resent the ticket details?',
            RESEND_INVITATION_CONFIRM: 'Do you really want to resent the invitation?',
            EMAIL_REQ: 'Email is required.',
            EMAIL_INVALID: 'Email is invalid.',
            EMAIL_MAX_LENGTH: 'Email should be less than 250 characters.',
            FIRST_NAME_REQ: 'First name is required.',
            FIRST_NAME_MAX_LENGTH: 'First name should be less than 250 characters.',
            LAST_NAME_REQ: 'Last name is required.',
            LAST_NAME_MAX_LENGTH: 'Last name should be less than 250 characters.',
            JOB_TITLE_REQ: 'Job title is required.',
            JOB_TITLE_MAX_LENGTH: 'Job title should be less than 250 characters.',
            DIETARY_MAX_LENGTH: 'Dietary requirements should be less than 2000 characters.'
        },

        REGULAR_TICKET_MANAGEMENT: {
            TITLE: 'Invite attendees',
            FILL_INSTRUCTIONS: 'Please fill the details of the attendees. If you are an attendee, please add in your details as first attendee.'
        },

      ARTICLE_TOPIC_EMPTY_ERROR: 'You must have a topic',
      ARTICLE_HEADER_UPLOAD_ERROR: 'Header image not uploaded properly',
      ARTICLE_CONTENT_EMPTY_ERROR: 'Article content is empty'
    };

    app.config(['$translateProvider', function ($translateProvider) {
        "ngInject";
        $translateProvider
            .translations('en', translations)
            .useSanitizeValueStrategy('sanitize')
            .preferredLanguage('en');
    }]);


})();