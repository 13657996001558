/**
 * Created by isuru_b on 5/17/2016.
 */


app.controller('AdvertisementController', function ($scope, $sce, $timeout, $routeParams, $window, $location, $translate, jitoCommon,
                                                     jitoHybrid, jitoEvents, jitoShared, jitoSettings, jitoUser, jitoAjax, $interval) {

    "ngInject";
    $scope.advertisements = [];
    $scope.position = '';

    $scope.initialize = function( position ) {
        $scope.position = position;
        render();
    };

    var listSuccess = function (response) {
        if (response && response.status && response.status === 200) {
            if (response.data && Array.isArray(response.data) && response.data.length && response.data.length > 0) {

                var topMenuHeight = 84;
                var windowHeight = $(window).height() - topMenuHeight;

                angular.forEach(response.data, function(item){
                    item.description  = $sce.trustAsHtml(item.description);

                    $timeout(function (){
                        var nowHeight = $('#advertisement-list').height();
                        if (windowHeight > nowHeight) {
                            $scope.advertisements.push(item);
                        } else {
                            $('#advertisement-list .jt-webapp-side-box:last-child').remove();
                        }

                    }, 500);

                    $timeout(function() {
                        setAdsWidth(); // This function is defined in desktop.blade.php
                    }, 100);
                });                
            } else {
                /*TODO: Move to lang*/
                listError('Sorry, No advertisement found.')
            }

            $interval(function () {
                var nowHeight = $('#advertisement-list').height();
                if ( windowHeight < nowHeight ) {
                     while (windowHeight < nowHeight) {
                     $('#advertisement-list .jt-webapp-side-box:last-child').remove();
                     nowHeight = $('#advertisement-list').height();
                     }
                 }
            }, 500);

        } else {
            listError(response);
        }

        jitoEvents.triggerLoader(false);
    };

    var listError = function (response) {
        if (response.status > 200 && response.status < 500 && response.data) {
            $scope.errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        } else if (angular.isString(response)) {
            $scope.errorMessage = response
        }
        else {
            $scope.errorMessage = $translate.instant('COMMON_ERROR');
        }

        jitoEvents.triggerLoader(false);
    };

    var render = function () {
        jitoEvents.triggerLoader(true);
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getAdvertisementList(),
            params:{
                position : $scope.position
            },
            timeout: 60000
        }, listSuccess, listError);
    };
});