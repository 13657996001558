/**
 * Created by thusitha_m on 1/12/2016.
 */

app.factory('jitoStorage', function ($window, ipCookie) {
    "ngInject";

    var tempMemory = {};

    return {

        //Set an item in the local storage by given key and value.
        set: function (key, data, serialize) {

            var options = {
                path: '/',
                expires: 30
            };

            try {
                if (serialize) {
                    //$window.localStorage.setItem(key, JSON.stringify(data));
                    ipCookie(key, JSON.stringify(data, null, 2), options);
                } else {
                    //$window.localStorage.setItem(key, data);
                    ipCookie(key, data, options);
                }
            } catch (ex) {
                ipCookie(key, data, options);
            }
        },

        //Return the value of a stored item in the local storage by key
        get: function (key) {
          
            try {

                var userData = ipCookie(key);
                if(angular.isDefined(userData) && angular.isDefined(userData.display_name)) {
                    userData.display_name = userData.display_name.replace(/\+/g,' ');
                }
                return userData;

               /*var data = $window.localStorage.getItem(key);
               if (data){
                   let userData = JSON.parse(data);
                   if(angular.isDefined(userData) && angular.isDefined(userData.display_name)) {
                       userData.display_name = userData.display_name.replace(/\+/g,' ');
                   }
                   return userData;
               }else{
                   let userData = ipCookie(key);
                   if(angular.isDefined(userData) && angular.isDefined(userData.display_name)) {
                       userData.display_name = userData.display_name.replace(/\+/g,' ');
                   }
                   return userData;
               }*/
              
            } catch (exception) {
                var userData = ipCookie(key);
                if(angular.isDefined(userData) && angular.isDefined(userData.display_name)) {
                    userData.display_name = userData.display_name.replace(/\+/g,' ');
                }
                return userData;
            }
        },

        //Remove a specific key from local storage
        remove: function (key) {
            
            try {
                $window.localStorage.removeItem(key);
                ipCookie.remove(key, {path: '/'});
            } catch (ex) {
               
            }
        },

        //Clear the local storage
        clear: function () {
            
           try {
                $window.localStorage.clear();
                var cookies = ipCookie();
                angular.forEach(cookies, function (v, k) {
                    ipCookie.remove(k, {path: '/'});
                });
           } catch (ex) {
                
                
           }
        }

    };
});