/**
 * Created by thusitha_m on 1/24/2016.
 */

app.controller('BrandsListController', function (titleChange, $scope, $rootScope, $routeParams, $location, $translate,
                                                 jitoHybrid, jitoCommon, jitoUser, jitoEvents, jitoAjax, jitoShared, jitoValidations, jitoSettings, jitoPaths) {
    "ngInject";
    var currentPage = 0, isMoreToFetch = true, searchTerm="";

    $scope.isBrand = false;
    $scope.brands = [];

    //Title Change
     titleChange.setTitle('Companies');

    var listSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;
        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.brands.push.apply($scope.brands, response.data.data);
                if ($scope.brands.length < 1) {
                    $scope.isNoBrands = true;
                } else if(!(response.data.data.length > 0)){
                    isMoreToFetch = false;
                }
            }
            else {
                onError(response);
            }
        } else {
            onError(response);
        }
    };

    var listError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;
        $scope.isError = true;
        $scope.errorMessage = errorMessage;
    };

    var loadItemList = function (term) {
        if (!($scope.brands.length > 0)) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        }
        currentPage += 1;

        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getBrandsListAPI(),
            params:{
                page : currentPage,
                term : term
            }, timeout: jitoSettings.DEFAULT_TIME_OUT
        }, listSuccess, listError);
    };

    var noEventsError = function () {
        $scope.isNoBrands = true;
    };

    var onError = function (response) {

        if (!($scope.brands.length > 0)) {
            noEventsError(response);
        }
        if (!(response.data.data.length > 0)) {
            isMoreToFetch = false;
        }

    };

    var onFollowError = function (response) {

        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.popupError(errorMessage, 0);

    };

    $scope.loadMoreBrands = function () {
        if (isMoreToFetch) {
            $scope.isMoreLoading = true;
            loadItemList(searchTerm);
        }
    };

    $scope.goToBrandPage = function(userID){
        $location.path(jitoPaths.BRAND_PROFILE + userID);
    };

    $scope.search = function(term){
        //Reset current page
        $scope.isNoBrands = false;
        $scope.isError = false;
        searchTerm = term;
        $scope.brands = [];
        currentPage = 0;
        loadItemList(searchTerm);
    };

    var followSuccess = function(response){
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            if (response.data) {
            }
            else {
                onFollowError(response);
            }
        } else {
            onFollowError(response);
        }
    };

    var followError = function(response){
        var errorMessage = '';

        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.popupError(errorMessage, 0);
        jitoEvents.triggerLoader(false);
        //An error occurred... Revert the changes
        if($scope.brands[index].is_follow == 1){
            $scope.brands[index].is_follow = 0;
        } else{
            $scope.brands[index].is_follow = 1;
        }

    };

    $scope.subscribe = function(index){
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        if($scope.brands[index].is_follow == 1){
            $scope.brands[index].is_follow = 0;
        } else{
            $scope.brands[index].is_follow = 1;
        }

        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getFollowUnfollowBrandAPI(),
            data:{
                status : $scope.brands[index].is_follow,
                brand_id : $scope.brands[index].id
            }, timeout: jitoSettings.DEFAULT_TIME_OUT
        }, followSuccess, followError);
    };

    if(jitoValidations.empty($.trim($routeParams.SearchTerm))){
        loadItemList(searchTerm);
        $scope.searchText = "";
    } else{
        searchTerm = $.trim($routeParams.SearchTerm);
        $scope.searchText = searchTerm;
        $scope.search(searchTerm);
    }

});