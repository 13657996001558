/**
 * Created by prasanna_j on 22-Jan-2016.
 */

app.controller('CreateAlbumController', function ($scope, $routeParams,$window,$location,$translate,
                                                  jitoCommon, jitoHybrid, jitoEvents, jitoShared, jitoSettings, jitoUser, jitoAjax, jitoValidations, jitoPaths) {
  "ngInject";
  //Initialization
  $scope.isLoading = false;

  $scope.createAlbum = function(isValidForm) {
    if(isValidForm) {
      createUserAlbum({
        title: $scope.album.title,
        description: $scope.album.description
      });
    }
  };

  var albumCreateSuccess = function(response) {
    jitoEvents.triggerLoader(false);

    if (response && response.status && response.status === 200) {
      var albumID = response.data.albumId;
      jitoHybrid.popup($translate.instant('ALBUM_CREATE_SUCCESS'));
      $location.path(jitoPaths.ALBUM_VIEW + $scope.userID + "/" + albumID);
    } else {
      showErrorMessage(response);
    }
  };

  var albumCreateError = function(response) {
    showErrorMessage(response);
  };

  var showErrorMessage = function (response) {
    var errorMessage = '';
    if (response.status > 200 && response.status < 500 && response.data) {
      errorMessage = jitoCommon.normalizeErrorMessages(response.data);
    }
    else {
      errorMessage = $translate.instant('COMMON_NO_NETWORK');
    }

    jitoEvents.triggerLoader(false);
    jitoHybrid.alert(errorMessage, 0);
  };

  var createUserAlbum = function(albumObject) {
    jitoEvents.triggerLoader(true, $translate.instant('ALBUM_CREATE_LOADER'), true);

    jitoAjax.call({
      method: 'post',
      data: JSON.stringify(albumObject),
      url: jitoSettings.getAPIDomain() + jitoSettings.getCreateAlbum(),
      timeout: jitoSettings.PICTURE_TIME_OUT
    }, albumCreateSuccess, albumCreateError);
  };

});