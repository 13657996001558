app.controller('MessagingController', function ($scope, $timeout, $rootScope, jitoSettings, jitoAjax, jitoHybrid,
                                                SharedState,$translate, jitoCommon, jitoPaths, jitoUser,
                                                $location, jitoEvents) {
    "ngInject";

    $scope.threads = [];
    $scope.isCreatingNewMessage = false;
    $scope.currentThreadId = null;
    $scope.createThreadMembers = [];
    $scope.createThreadMemberIds = [];
    $scope.isLoading = false;
    $scope.isMessageSending = false;
    $scope.users = [];
    $scope.params = {
        term: $location.search().term ? $location.search().term : ''
    };
    $scope.createThreadForm = {
        member_search: "",
        group_name: "",
        message: ""
    };
    $scope.friendId = $location.search().member ? $location.search().member : null;
    var currentPage = 0, isMoreToFetch = true;

    if($scope.friendId) {
        $scope.isCreatingNewMessage = true;
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.geUserDetailsAPI() + $scope.friendId,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, function (response) {
            var user = response.data;
            var member = {
                company: user.user_meta.company,
                display_name: user.display_name,
                first_name: user.user_meta.first_name,
                id: user.id,
                job_title: user.user_meta.current_job,
                last_name: user.user_meta.last_name,
                type: user.type,
                profile_pic_id: (user.profile_pic_id) ? user.profile_pic_id.id : null,
                url: (user.profile_pic_id) ? user.profile_pic_id.url : ""
            };

            $scope.addMemberToThread(member);
        }, function () {});
    }

    $scope.clearThreadSearch = function () {
        $timeout(function () {
            $scope.params.term = '';
            $scope.search();
        }, 100);
    };

    $scope.createNewThread = function () {
        if(!$scope.isCreatingNewMessage) {
            $scope.isCreatingNewMessage = true;
        }
    };

    $scope.toggleThreadsContainer = function () {
        $scope.isCreatingNewMessage = false;
        $timeout(function() {
            $scope.setMessageSidebarHeight();
        }, 100);
    };

    //If the user is already logged in redirects him to home page
    $scope.goToPage = function(path){
        $location.path(path);
    };

    var threadSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isLoading = false;
        $scope.isMoreLoading = false;

        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.threads.push.apply($scope.threads, response.data);

                if(response.data.length > 0 && $scope.params.term == '' && !$scope.friendId) {
                    $location.path("/message/" + response.data[0].id).search({redirect: 'true'});
                }

                if ($scope.threads.length < 1) {
                    $scope.noMessages = true;

                    if($scope.params.term == '') {
                        $scope.isCreatingNewMessage = true;
                    }
                } else if(!(response.data.length > 0)){
                    isMoreToFetch = false;
                }
            }
            else {
                onError(response);
            }
        } else {
            onError(response);
        }
    };

    var noEventsError = function () {
        $scope.noMessages = true;
    };

    var onError = function (response) {
        if (!($scope.threads.length > 0)) {
            noEventsError(response);
        }
        if (!(response.data.length > 0)) {
            isMoreToFetch = false;
        }
    };

    $scope.loadMoreThreads = function () {
        if (isMoreToFetch && $scope.threads.length > 0) {
            $scope.isMoreLoading = true;
            loadThreadList();
        }
    };

    var threadError = function (response) {
        $scope.isLoading = false;
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;

        $scope.isError = true;
        $scope.errorMessage = errorMessage;
    };

    var loadThreadList = function () {
        if($scope.currentLoggedUserType == 1) {
            if (!($scope.threads.length > 0)) {
                jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
            }

            currentPage += 1;
            $scope.isLoading = true;

            jitoAjax.call({
                method: 'get',
                url: jitoSettings.getAPIDomain() + jitoSettings.getMessageThreadsListAPI(),
                params: {
                    page: currentPage,
                    term: $scope.params.term
                }, timeout: jitoSettings.DEFAULT_TIME_OUT
            }, threadSuccess, threadError);
        }
    };

    loadThreadList();

    $scope.search = function () {
        if($scope.params.term != "") {
            $location.search({
                term: $scope.params.term
            });
        } else {
            $location.search({});
        }

        currentPage = 0;
        $scope.threads = [];
        loadThreadList();
    };

    var userSearchSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isLoading = false;

        if (response && response.status && response.status === 200) {
            if (response.data && response.data.data) {
                $scope.users.push.apply($scope.users, response.data.data.data);
            } else {
                //onError(response);
            }
        } else {
            //onError(response);
        }
    };

    var userSearchError = function (response) {
        $scope.isLoading = false;
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);

        $scope.isError = true;
        $scope.errorMessage = errorMessage;
    };

    $scope.userSearch = function () {
        $scope.users = [];
        $scope.isLoading = true;

        if($scope.createThreadForm.member_search != "") {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);

            jitoAjax.call({
                method: 'get',
                url: jitoSettings.getAPIDomain() + jitoSettings.getAllUsersForMessaging(),
                params:{
                    term: $scope.createThreadForm.member_search
                }, timeout: jitoSettings.DEFAULT_TIME_OUT
            }, userSearchSuccess, userSearchError);
        }
    };

    $scope.addMemberToThread = function (user) {
        $scope.users = [];
        $scope.createThreadForm.member_search = "";

        if($scope.createThreadMemberIds.indexOf(user.id) === -1) {
            $scope.createThreadMembers.push(user);
            $scope.createThreadMemberIds.push(user.id);
        }
    };

    $scope.removeMemberFromThread = function (user_id) {
        angular.forEach($scope.createThreadMembers, function (value, index) {
            if(value.id === user_id) {
                $scope.createThreadMembers.splice(index, 1);
            }
        });

        angular.forEach($scope.createThreadMemberIds, function (value, key) {
            if(value === user_id) {
                $scope.createThreadMemberIds.splice(key, 1);
            }
        });
    };

    var sendMessageSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isLoading = false;
        $scope.isMessageSending = false;

        if (response && response.status && response.status === 200) {
            jitoHybrid.popup(response.data.message);
        } else {
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }

        $scope.goToPage('/message/' + $scope.currentThreadId);
    };

    var sendMessageError = function (response) {
        $scope.isLoading = false;
        jitoEvents.triggerLoader(false);
        var errorMessage = '';
        $scope.isMessageSending = false;

        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        jitoHybrid.popupError(errorMessage);

        $scope.goToPage('/message/' + $scope.currentThreadId);
    };

    var sendThreadMessage = function () {
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        $scope.isLoading = true;

        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getSendNewMessageAPI(),
            data: {
                message_thread_id: $scope.currentThreadId,
                message: $scope.createThreadForm.message
            }, timeout: jitoSettings.DEFAULT_TIME_OUT
        }, sendMessageSuccess, sendMessageError);
    };

    var createThreadSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isLoading = false;

        if (response && response.status && response.status === 200 && response.data.data) {
            $scope.currentThreadId = response.data.data.thread.id;
            sendThreadMessage();
        } else {
            $scope.isMessageSending = false;
            jitoHybrid.popupError($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    var createThreadError = function (response) {
        $scope.isLoading = false;
        jitoEvents.triggerLoader(false);
        var errorMessage = '';
        $scope.isMessageSending = false;

        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        jitoHybrid.popupError(errorMessage);
    };

    $scope.createAndSendMessage = function () {
        if($scope.createThreadMemberIds.length == 0) {
            jitoHybrid.popupError("Atleast one member is required.");
        } else if($scope.createThreadForm.message == "") {
            jitoHybrid.popupError("Please enter the message.");
        } else {
            if(!$scope.isLoading) {
                jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
                $scope.isLoading = true;
                $scope.isMessageSending = true;

                jitoAjax.call({
                    method: 'post',
                    url: jitoSettings.getAPIDomain() + jitoSettings.getCreateNewThreadAPI(),
                    data: {
                        user_ids: $scope.createThreadMemberIds,
                        group_name: $scope.createThreadForm.group_name
                    }, timeout: jitoSettings.DEFAULT_TIME_OUT
                }, createThreadSuccess, createThreadError);
            }
        }
    };
});