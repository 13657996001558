/**
 * Created by thusitha_m on 1/27/2016.
 */

app.controller('InterestsListController', function (titleChange, $scope, $rootScope, $routeParams, $location, $translate,
                                                    jitoHybrid, jitoCommon, jitoUser, jitoEvents, jitoAjax, jitoShared, jitoValidations, jitoSettings, jitoPaths) {
    "ngInject";
    $scope.interests = [];
    $scope.isInterest = false;

    var searchTerm="", currentPage = 0, isMoreToFetch = true;

    //Title Change
    titleChange.setTitle('Interest');

    var listSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;
        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.interests.push.apply($scope.interests, response.data);
                if ($scope.interests.length < 1) {
                    $scope.isNoInterests = true;
                } else if(!(response.data.length > 0)){
                    isMoreToFetch = false;
                }
            }
            else {
                onError(response);
            }
        } else {
            onError(response);
        }
    };

    var listError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        jitoHybrid.alert(errorMessage, 0);
        $scope.isMoreLoading = false;

        $scope.isError = true;
        $scope.errorMessage = errorMessage;
    };

    var loadItemList = function (term) {
        if (!($scope.interests.length > 0)) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        }
        currentPage += 1;

        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getInterestsListAPI(),
            params:{
                page : currentPage,
                term : term
            },
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, listSuccess, listError);
    };

    var noEventsError = function () {
        $scope.isNoInterests = true;
    };

    var onError = function (response) {

        if (!($scope.interests.length > 0)) {
            noEventsError(response);
        }
        if (!(response.data.length > 0)) {
            isMoreToFetch = false;
        }

    };

    var onFollowError = function (response) {

        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.popupError(errorMessage, 0);

    };

    $scope.loadMoreInterests = function () {
        if (isMoreToFetch) {
            $scope.isMoreLoading = true;
            loadItemList(searchTerm);
        }
    };

    $scope.goToInterestPage = function(userID){
        $location.path(jitoPaths.INTEREST + userID);
    };

    $scope.search = function(term){
        //Reset current page
        $scope.isNoInterests = false;
        $scope.isError = false;
        searchTerm = term;
        $scope.interests = [];
        currentPage = 0;
        loadItemList(searchTerm);
    };

    var followSuccess = function(response){
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            if (response.data) {
            }
            else {
                onFollowError(response);
            }
        } else {
            onFollowError(response);
        }
    };

    var followError = function(response){
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.popupError(errorMessage, 0);
        jitoEvents.triggerLoader(false);
        //An error occurred... Revert the changes
        if($scope.interests[index].is_subscribed == true){
            $scope.interests[index].is_subscribed = false;
        } else{
            $scope.interests[index].is_subscribed = true;
        }

    };

    $scope.subscribe = function(index){
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        var subscribedStatus = 0;
        if($scope.interests[index].is_subscribed == true){
            $scope.interests[index].is_subscribed = false;
            subscribedStatus = 0;
        } else{
            $scope.interests[index].is_subscribed = true;
            subscribedStatus = 1;
        }

        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getInterestsSubscribeAPI(),
            data:{
                status : subscribedStatus,
                interest_id : $scope.interests[index].id
            },
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, followSuccess, followError);
    };

    if(jitoValidations.empty($.trim($routeParams.SearchTerm))){
        loadItemList(searchTerm);
        $scope.searchText = "";
    } else{
        searchTerm = $.trim($routeParams.SearchTerm);
        $scope.searchText = searchTerm;
        $scope.search(searchTerm);
    }

});