/**
 * Created by thusitha_m on 1/27/2016.
 */

app.controller('InterestViewController', function (titleChange,$scope, $rootScope, $routeParams, $translate, $location,
                                                   jitoHybrid, jitoCommon, jitoUser, jitoEvents, jitoAjax, jitoShared, jitoValidations, jitoSettings) {
    "ngInject";
    var interestID = $.trim($routeParams.InterestID), currentPage = 0, isMoreToFetch = true;

    $scope.events = [];

    //Title Change
    titleChange.setTitle('Interest');

    var listSuccess = function (response) {
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;
        if (response && response.status && response.status === 200) {
            if (response.data) {
                $scope.events.push.apply($scope.events, response.data);
                jitoCommon.prepareAnchorTags();
                if ($scope.events.length < 1) {
                    $scope.isNoEvents = true;
                } else if(!(response.data.length > 0)){
                    isMoreToFetch = false;
                }
            }
            else {
                onError(response);
            }
        } else {
            onError(response);
        }
    };

    $scope.goToEventPage = function(id){
        $location.path('/event/' + id);
    };

    var noEventsError = function () {
        $scope.isNoEvents = true;
    };

    var onError = function (response) {

        if (!($scope.events.length > 0)) {
            noEventsError(response);
        }
        if (!(response.events.length > 0)) {
            isMoreToFetch = false;
        }

    };

    var listError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        $scope.isMoreLoading = false;

        $scope.isError = true;
        $scope.errorMessage = errorMessage;
    };


    var loadEvents = function () {
        if (!($scope.events.length > 0)) {
            jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        }
        currentPage += 1;

        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getInterestEventDataAPI() + interestID,
            params:{
                page : currentPage
            }, timeout: jitoSettings.DEFAULT_TIME_OUT
        }, listSuccess, listError);
    };

    $scope.loadMoreEvents = function () {
        if (isMoreToFetch) {
            $scope.isMoreLoading = true;
            loadEvents();
        }
    };

    var interestSuccess = function (response) {
        $scope.isMoreLoading = false;
        if (response && response.status && response.status === 200 && response.data) {
            $scope.interestDetails = response.data;
            jitoCommon.prepareAnchorTags();
            loadEvents();
            $scope.isLoaded = true;

        } else {
            //onError(response);
        }
    };

    var interestError = function (response) {
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoEvents.triggerLoader(false);
        $scope.isError = true;
        $scope.errorMessage = errorMessage;
    };

    var loadInterestData = function () {
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getInterestsDetailsAPI() + interestID,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, interestSuccess, interestError);
    };

    //////////////////////////////////////////////////////////////////////
    //Follow
    /////////////////////////////////////////////////////////////////////

    var followSuccess = function(response){
        jitoEvents.triggerLoader(false);
        if (response && response.status && response.status === 200) {
            if (response.data) {
            }
            else {
                onFollowError(response);
            }
        } else {
            onFollowError(response);
        }
    };

    var onFollowError = function (response) {

        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.popupError(errorMessage, 0);

    };

    var followError = function(response){
        var errorMessage = '';
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        jitoHybrid.popupError(errorMessage, 0);
        jitoEvents.triggerLoader(false);
        //An error occurred... Revert the changes
        if($scope.interestDetails.is_subscribed == true){
            $scope.interestDetails.is_subscribed = false;
        } else{
            $scope.interestDetails.is_subscribed = true;
        }

    };

    $scope.subscribe = function(){
        jitoEvents.triggerLoader(true, $translate.instant('COMMON_PLEASE_WAIT'), true);
        var subscribedStatus = 0;
        if($scope.interestDetails.is_subscribed == true){
            $scope.interestDetails.is_subscribed = false;
            subscribedStatus = 0;
        } else{
            $scope.interestDetails.is_subscribed = true;
            subscribedStatus = 1;
        }

        jitoAjax.call({
            method: 'post',
            url: jitoSettings.getAPIDomain() + jitoSettings.getInterestsSubscribeAPI(),
            data:{
                status : subscribedStatus,
                interest_id : $scope.interestDetails.id
            },
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, followSuccess, followError);
    };

    loadInterestData();

});